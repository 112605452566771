import React from "react";
import { useState, useEffect } from "react";
import DataTable from "../../../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import UserHoc from "hoc/userHoc";
import { GetCustomerList } from "services/clientservices";
import { useAPICall } from "utils/useapicall";
import CircularProgress from "@mui/material/CircularProgress";
import { green, yellow } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { eventBus } from "utils/eventbus";
import EditCustomer from "./edit-cusomer";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import Loader from "layouts/accountants/user/components/imgLoader/loader";

const Customer = ({ businessId }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [alert, setAlert] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [openEditCustomer, setOpenEditCustomer] = useState(false);
  const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
  const { pathname } = useLocation();

  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await CustomerListAPICall({
        payload: [{ businessId: `${businessId}`, keyword: `${search}` }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
  }, [loading]);

  eventBus.$on("reloadCustomerDetailsList", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    setLoading(true);
    CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await CustomerListAPICall({
          payload: [{ businessId: `${businessId}`, keyword: `${search}` }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "---";
  }

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      setTotalRecords(CustomerResponse.data.data.totalRecords);
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          name: prop.name,
          email: prop.email ? prop.email : "-",
          balance: prop.balance,
          phone: prop.phone ? formatPhoneNumber(prop.phone) : "-",
          createdat: Createdate.toLocaleDateString(),
          action: (
            <MDBox display="flex">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.edittooltip}
                  placement="top"
                  onClick={(value) => {
                    setCustomerId(prop._id), setOpenEditCustomer(true);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.deletetooltip}
                  placement="top"
                  onClick={(value) => {
                    setCustomerId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setCustomerList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [CustomerResponse]);

  const dataTableData1 = {
    columns: [
      {
        Header: "Customer NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: customerList,
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const successDelete = async () => {
    setLoading(true);
    setAlert(false);
  };

  return (
    <>
      <MDBox
        px={2}
        sx={{ pr: 3, mb: 2 }}
        component="form"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h5" fontWeight="medium">
          Customers
        </MDTypography>
        <MDBox px={2} display="flex">
          <MDBox sx={{ pr: 3, mb: 2 }}>
            <MDInput
              type="search"
              label="Search Customer"
              onKeyDown={handleKeyDown}
              variant="standard"
              onChange={handleSearch}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      {totalRecords === 0 ? (
        <MDBox my={5} sx={{ mt: 1 }}>
          <DataTable
            table={dataTableData1}
            entriesPerPage={false}
            pagination={false}
            showTotalEntries={true}
          />
          <MDTypography
            variant="body2"
            color="secondary"
            sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "-2%" }}
          >
            No records found
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox my={5} sx={{ mt: 1 }}>
          <DataTable
            table={dataTableData1}
            entriesPerPage={false}
            pagination={false}
            showTotalEntries={true}
          />
        </MDBox>
      )}
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
      <MDBox display="flex" justifyContent="center">
        {loading && <Loader />}
      </MDBox>
      <MDBox>
        <Dialog
          open={alert}
          onClose={() => setAlert(false)}
          aria-labelledby="alert-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "45%",
                maxWidth: "450px",
              },
            },
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
              fontSize: 50,
            }}
          >
            <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
              <h3>Are you sure?</h3>
            </DialogContentText>
            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center" }}
              >
                Once you delete this Customer there is no way of getting data back?
              </MDTypography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <MDButton variant="gradient" color="success" onClick={successDelete}>
              Yes, delete it!
            </MDButton>
            <MDButton variant="gradient" color="error" onClick={() => setAlert(false)}>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openEditCustomer}
          onClose={() => setOpenEditCustomer(false)}
          aria-labelledby="alert-dialog-title"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "570px",
                minHeight: "30%",
              },
            },
          }}
        >
          {" "}
          <DialogTitle
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            Edit Customer
          </DialogTitle>
          <DialogContent>
            <EditCustomer
              customerId={customerId}
              businessId={businessId}
              onClose={() => setOpenEditCustomer(false)}
            />
          </DialogContent>
        </Dialog>
      </MDBox>
    </>
  );
};

export default UserHoc(Customer);
