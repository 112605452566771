import * as Yup from "yup";

export const EditSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last name should be more than 3 characters.'),
    email: Yup.string()
        .email('Your email address is invalid')
        .required('Email address is required.'), 
    role: Yup.string().required('Role is required!'),
});