import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import FormField from "layouts/clients/components/FormField";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { GetuserClientDetails } from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";

function EditClients({ businessId }) {
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [bussinessId, setBusinessId] = useState(businessId);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isDisable, setIsDisable] = useState(false);
  const [activeUser, setActiveUser] = useState(false);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", !showForm);
    eventBus.$emit("OnSetBlurEdit", !showForm);
  }, [showForm]);

  const sidenavItems = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_long", label: "basic info", href: "basic-info" },
  ];

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    clientname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: "",
  };

  const [
    {
      isLoading: isGetDetailsLoading,
      isError: isGetDetailsError,
      response: clientDetailsDataResponse,
    },
    getClientDetailsAPICall,
  ] = useAPICall(GetuserClientDetails, { onLoad: false });

  useEffect(async () => {
    setBusinessId(businessId);
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${bussinessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isGetDetailsLoading) {
      return () => null;
    }

    if (isGetDetailsError) {
      return () => null;
    }

    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      const adminresult = clientDetailsDataResponse?.data?.data?.client;
      setprofileEmail(adminresult?.owner?.email);
      setprofileFname(adminresult?.owner?.fname);
      setprofileLname(adminresult?.owner?.lname);
      setprofileBusinessName(adminresult?.name);
      setprofileNumber(adminresult?.owner?.phone);
      setActiveUser(adminresult?.isBlocked);
      setShowForm(true);
    }
  }, [clientDetailsDataResponse]);

  const handleSubmit = async (values) => {
    setIsDisable(true);
  };

  const ToggleChange = async () => {
    setActiveUser(!activeUser);
  };

  return (
    <>
      {!showForm ? (
        <MDBox display="flex" justifyContent="center">
          {<Loader />}
        </MDBox>
      ) : (
        <>
          <Grid item xs={12}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <MDBox lineHeight={0}>
                    <MDTypography variant="h5">Business Info</MDTypography>
                  </MDBox>
                  <MDBox mt={1.625}>
                    <Grid item xs={12}>
                      <FormField type="text" label="Business Name *" name="clientname" disabled />
                    </Grid>
                  </MDBox>
                  <MDBox mt={2} width="100%" display="flex">
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        disabled
                        control={<Checkbox checked={!activeUser} onChange={ToggleChange} />}
                        label={<spna style={darkMode ? { color: "#ffffff" } : {}}>Is Active</spna>}
                      />
                    </Grid>
                  </MDBox>
                </Form>
              )}
            </Formik>
            {/* </Card> */}
          </Grid>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              content="Client Updated Successfully!"
              open={showSnackbar}
              // autoHideDuration={3000}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={ErrorMessage}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      )}
    </>
  );
}

export default UserHoc(EditClients);
