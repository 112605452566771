import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { EditCategoryApi, GetCategoryList } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { EditCategorySchema } from "../components/schemas/editcategory";
import { data } from "../QboAccountTypes";

function EditCategory({ onClose, categoryId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [classification, setClassification] = useState("");
  const [accountType, setAccountType] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [jsondata] = useState(data[0].classifications);
  const [jsonName, setJsonName] = useState("");
  const [accountSubTypeJson, setAccountSubTypeJson] = useState([]);
  const [classificationJson, setClassificationJson] = useState([]);
  const [accountTypeName, setAccountTypeName] = useState("");
  const [accountSubTypeName, setAccountSubTypeName] = useState("");
  const [{isLoading: isEditCategoryLoading,isError: isEditCategoryError,response: EditCategoryResponse},EditCategoryListAPICall] = useAPICall(EditCategoryApi, { onLoad: false });
  const [{isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });

  useEffect(async () => {
    if (categoryList.length != 0) {
      const Data = categoryList.find((obj) => {
        return obj._id === categoryId;
      });
      setCategoryName(Data?.name !== "---" ? Data?.name : "");
      setAccountType(Data?.accountType !== "---" ? Data?.accountType : "");
      setClassification(
        Data?.classification.toUpperCase() !== "---" ? Data?.classification.toUpperCase() : ""
      );
      setJsonName(
        Data?.classification.toUpperCase() !== "---" ? Data?.classification.toUpperCase() : ""
      );
      setShowDetails(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    if (categoryId) {
      setTimeout(() => {
        setShowDetails(true);
        setLoading(false);
      }, 2000);
    }
  }, [categoryList, categoryId]);

  useEffect(async () => {
    jsondata.map((x) => (x.name === jsonName ? setClassificationJson(x.accountTypes) : ""));
  }, [jsonName]);

  useEffect(() => {
    const obj = {};
    classificationJson.map(
      (x, index) => (
        x.name === accountTypeName
          ? x.subTypes.forEach((element, index) => {
              obj[`${index}`] = element;
            })
          : "",
        setAccountSubTypeJson(Object.values(obj))
      )
    );
  }, [accountTypeName]);

  useEffect(async () => {
    setLoading(true);
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoading(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      setCategoryList(CategoryResponse.data.data.records);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isEditCategoryLoading) {
      return () => null;
    }
    if (isEditCategoryError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditCategoryResponse && EditCategoryResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
      eventBus.$emit("reloadCategoryDetailsList", EditCategoryResponse);
    }
  }, [EditCategoryResponse]);

  let initialValues = {
    category: categoryName,
    accountType: accountType,
    classification: classification,
  };

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditCategoryListAPICall({
      payload: [
        {
          name: values.category,
          subAccount: false,
          parentId: null,
          classification: values.classification,
          accountType: values.accountType,
          accountSubType: values.accountSubType,
          categoryId: `${categoryId}`,
        },
      ],
    });
  };

  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={EditCategorySchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Name *" name="category" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                  <InputLabel sx={{ mt: -3 }}>Classification *</InputLabel>
                                  <Field
                                    as="select"
                                    name="classification"
                                    variant="standard"
                                    style={{
                                      marginTop: "18px",
                                      borderTop: "0px",
                                      borderLeft: "0px",
                                      borderRight: "0px",
                                      color: "slategray",
                                    }}
                                  >
                                    <option value="">Select Classification</option>
                                    {jsondata.map((x) => (
                                      <option value={x.name}>{x.name}</option>
                                    ))}
                                  </Field>
                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      <ErrorMessage name="classification" />
                                    </MDTypography>
                                  </MDBox>
                                  <>{setJsonName(values.classification)}</>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                  <InputLabel sx={{ mt: -3 }}>Account Type *</InputLabel>
                                  <Field
                                    as="select"
                                    name="accountType"
                                    variant="standard"
                                    style={{
                                      marginTop: "18px",
                                      borderTop: "0px",
                                      borderLeft: "0px",
                                      borderRight: "0px",
                                      color: "slategray",
                                    }}
                                  >
                                    <option value="">Select AccountType</option>
                                    {classificationJson.map((x) => (
                                      <option value={x.name}>{x.name}</option>
                                    ))}
                                  </Field>
                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      <ErrorMessage name="accountType" />
                                    </MDTypography>
                                  </MDBox>
                                  <>{setAccountTypeName(values.accountType)}</>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                  <InputLabel sx={{ mt: -3 }}>AccountSubType</InputLabel>
                                  <Field
                                    as="select"
                                    name="accountSubType"
                                    variant="standard"
                                    style={{
                                      marginTop: "18px",
                                      borderTop: "0px",
                                      borderLeft: "0px",
                                      borderRight: "0px",
                                      color: "slategray",
                                    }}
                                  >
                                    <option value="">Select AccountSubType</option>
                                    {accountSubTypeJson.map((x) => (
                                      <option value={x.name}>{x.name}</option>
                                    ))}
                                  </Field>
                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      <ErrorMessage name="accountSubType" />
                                    </MDTypography>
                                  </MDBox>
                                  <>{setAccountSubTypeName(values.accountSubType)}</>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default EditCategory;
