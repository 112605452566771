import { useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DataTable from "../../components/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { green } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { useAPICall } from "../../../../utils/useapicall";
import { GetDeleteList, EditAccountantInfo } from "../../../../services/accountantservices";
import Hoc from "hoc";
import RestoreIcon from "@mui/icons-material/Restore";
import Cookies from "universal-cookie";
import { useMaterialUIController } from "context";
const cookies = new Cookies();

function DeleteAccountants() {
  const navigate = useNavigate();
  const action = { route: "", edittooltip: "Restore", deletetooltip: "Delete" };
  const [openAccount, setOpenAccount] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openClientPopup, setOpenClientPopup] = useState(false);
  const [alert, setAlert] = useState(false);
  const [profileInfo, setProfileInfo] = useState(cookies.get("accountant_profile_info"));
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetDeleteList, {onLoad: false});
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [{isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },removeaccountantAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
  const [accountantId, setAccountantId] = useState();
  const [{isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse },UserStatusAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(async () => {
    setLoading(true);
    await setAPICall({
      payload: [{ isArchived: "1" }],
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await setAPICall({
          payload: [{ keyword: `${search}`, isArchived: "1" }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    setProfileInfo(cookies.get("accountant_profile_info"));
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        // setActiveUser(!prop.isBlocked);
        return {
          snum: key + 1,
          firstName: (
            <MDBox>
              <Link to={`/accountants/${prop._id}/edit-accountant`}>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                >
                  {prop.fname}
                </MDTypography>
              </Link>
            </MDBox>
          ),
          business: prop.businessName,
          email: prop.email,
          phone: prop.phone,
          switch: prop.isBlocked ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="error"
              >
                Inactive
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="success"
              >
                Active
              </MDTypography>
            </MDBox>
          ),
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: prop.totalEmployees,
          totalclients: prop.totalClients,
          monthlycost: "-",
          action:
            profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? (
              ""
            ) : (
              <Link
                to={`/admin/accountants/deleted/list`}
                onClick={async () => {
                  setAccountantId(prop._id);
                  warningWithConfirmMessage();
                }}
              >
                <MDBox display="flex">
                  <MDTypography
                    variant="body2"
                    color="success"
                    sx={{ fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon>restore</Icon>
                    </Tooltip>
                  </MDTypography>
                </MDBox>
              </Link>
            ),
        };
      });
      setAdminList(data);
      setLoading(false);
    }
  }, [response]);

  useEffect(() => {
    if (isUserStatusLoading) {
      return () => null;
    }

    if (isUserStatusError) {
      setLoading(false);
      return () => null;
    }

    if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
      setLoading(true);
      setAPICall({
        payload: [{ isArchived: "1" }],
      });
    }
  }, [UserStatusResponse]);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);

      setAPICall({
        payload: [],
      });
      // navigate('/accountants/list');
    }
  }, [removeResponse]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const successDelete = async () => {
    await UserStatusAPICall({
      payload: [
        {
          isArchived: false,
          isBlocked: false,
          bussinessId: accountantId,
        },
      ],
    });
    setAlert(false);
  };
  const hideAlert = () => {
    setAlert(false);
  };

  const handleClickOpenAccount = () => {
    setOpenAccount(true);
  };

  const handleCloseAccount = () => {
    setOpenAccount(false);
  };

  const handleClickOpenClient = () => {
    setOpenClient(true);
  };

  const handleCloseClient = () => {
    setOpenClient(false);
  };

  const handleClickOpenClientPopup = () => {
    setOpenClientPopup(true);
  };

  const handleCloseClientPopup = () => {
    setOpenClientPopup(false);
  };

  const dataTableData = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Business",
        accessor: "business",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total Accountants",
        accessor: "totalaccountants",
        Cell: ({ value }) => (
          <a style={{ cursor: "pointer", color: "blue" }} onClick={handleClickOpenAccount}>
            {value}{" "}
          </a>
        ),
      },
      {
        Header: "Total Clients",
        accessor: "totalclients",
        Cell: ({ value }) => (
          <a style={{ cursor: "pointer", color: "blue" }} onClick={handleClickOpenClient}>
            {value}{" "}
          </a>
        ),
      },
      {
        Header: "Monthly Cost",
        accessor: "monthlycost",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  const dataTableData2 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Account Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "AccountLevel",
        accessor: "accountlevel",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Accountant to Clients",
        accessor: "totalclients",
        Cell: ({ value }) => (
          <a style={{ cursor: "pointer", color: "blue" }} onClick={handleClickOpenClient}>
            {value}{" "}
          </a>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [
      {
        id: "#10421",
        firstName: "Orlando Imieto",
        business: "Imieto",
        email: "Orlando@mailinator.com",
        phone: "8436331724",
        createdat: "30/11/2022",
        accountlevel: "Admin",
        totalclients: "4",
        monthlycost: "$400",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        id: "#10422",
        firstName: "Alice ",
        business: "Murinho",
        email: "Alice@mailinator.com",
        phone: "9993298123",
        createdat: "20/11/2022",
        accountlevel: "User",
        totalclients: "10",
        monthlycost: "$350",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        id: "#10423",
        firstName: "Michael ",
        business: "Mirra",
        email: "Michael@mailinator.com",
        phone: "1234567898",
        createdat: "29/11/2022",
        accountlevel: "User",
        totalclients: "9",
        monthlycost: "$900",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        id: "#10424",
        firstName: "Andrew ",
        business: "Nichel",
        email: "Andrew@mailinator.com",
        phone: "1234456789",
        createdat: "29/11/2022",
        accountlevel: "User",
        totalclients: "7",
        monthlycost: "$700",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        id: "#10425",
        firstName: "Sebastian ",
        business: "Koga",
        email: "Sebastian@mailinator.com",
        phone: "837888333",
        createdat: "29/11/2022",
        accountlevel: "User",
        totalclients: "6",
        monthlycost: "$200",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top" onClick={(value) => {}}>
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
    ],
  };

  const dataTableData3 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Number of Users Accounts",
        accessor: "Number",
        Cell: ({ value }) => (
          <a style={{ cursor: "pointer", color: "blue" }} onClick={handleClickOpenClientPopup}>
            {value}{" "}
          </a>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [
      {
        firstName: "Orlando Imieto",
        Number: "3",
        status: "active",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Alice ",
        Number: "2",
        status: "inactive",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Michael ",
        Number: "1",
        status: "active",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
    ],
  };

  const dataTableData4 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "AccountLevel",
        accessor: "accountalevel",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [
      {
        firstName: "Orlando Imieto",
        email: "Orlando@mailinator.com",
        accountalevel: "Admin",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Alice ",
        email: "Alice@mailinator.com",
        accountalevel: "User",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Michael ",
        email: "Michael@mailinator.com",
        accountalevel: "User",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
    ],
  };

  const handleSearch = async (event) => {
    setSearch(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Archived Accountants
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Users"
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{
                    style: darkMode
                      ? {
                          color: "#ffffff",
                        }
                      : {},
                  }}
                  sx={{
                    "& input": {
                      color: darkMode ? "#ffffff" : "#000000", // Change input text color
                    },
                    "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <Dialog
            open={openAccount}
            onClose={handleCloseAccount}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
          >
            <DialogContent>
              <MDBox my={5}>
                <DataTable
                  table={dataTableData2}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={true}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAccount} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openClient}
            onClose={handleCloseClient}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
          >
            <DialogContent>
              <MDBox my={5}>
                <DataTable
                  table={dataTableData3}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={true}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClient} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openClientPopup}
            onClose={handleCloseClientPopup}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
          >
            <DialogContent>
              <MDBox my={5}>
                <DataTable
                  table={dataTableData4}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={true}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClientPopup} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* {alert} */}
          <Dialog
            open={alert}
            onClose={hideAlert}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "50%",
                  maxWidth: "510px",
                },
              },
            }}
          >
            <DialogContent>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography variant="body2" color="secondary" sx={{ fontSize: 70 }}>
                  <RestoreIcon sx={{ color: green[500], fontSize: 50 }} />
                </MDTypography>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <h1>Are you sure?</h1>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ fontWeight: 200, fontSize: "1.20rem" }}
                >
                  Are you sure you want to restore account?
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <MDButton variant="gradient" color="success" onClick={successDelete}>
                Yes, restore it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={hideAlert}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content="Accountant Deleted Successfully."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          {tableEmpty == "0" ? (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "-2%" }}
              >
                No records found
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                pagination={false}
                showTotalEntries={true}
              />
            </MDBox>
          )}
          <MDBox>
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Hoc(DeleteAccountants);
