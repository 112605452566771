import { styled } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { BASE_URL_API } from "helpers/config";
import { getuserToken } from "helpers/getusertoken";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { useMaterialUIController } from "context";
// import "./styles.css";

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        opacity: 1,
        background: "transparent",
        color: "#7b809a",
        borderRadius: "none",
        boxShadow: "none",
        height: "35px",
      },
      textContainer: {
        width: "100%",
      },
      text: {
        fontWeight: 300,
        fontSize: "1rem",
        marginLeft: "120px",
        marginRight: "120px",
      },
      root: {
        minHeight: "120px",
        padding: "0px",
      },
      invalid: {
        backgroundImage: "none", // Remove background image for invalid state
        color: "white", // Change text color to white for invalid state
        backgroundColor: "white", // Change background color to white for invalid state
        border: "solid",
        borderColor: "#7986cb",
      },
    },
  },
});

const darkModetheme = createMuiTheme({
  palette: {
    type: "dark", // Set theme type to dark
  },
  overrides: {
    MuiDropzoneArea: {
      icon: {
        opacity: 1,
        background: "transparent",
        color: "#ffffff",
        borderRadius: "none",
        boxShadow: "none",
        height: "35px",
      },
      textContainer: {
        width: "100%",
      },
      text: {
        fontWeight: 300,
        fontSize: "1rem",
        marginLeft: "120px",
        marginRight: "120px",
        color: "#ffffff",
      },
      root: {
        border: "2px dotted #ffffff !important", // Change border color
        borderRadius: "8px", // Optional: Add border radius for rounded corners
        minHeight: "120px",
        padding: "0px",
        backgroundColor: "#202940",
      },
      invalid: {
        backgroundImage: "none", // Remove background image for invalid state
        color: "#202940", // Change text color to white for invalid state
        backgroundColor: "#202940", // Change background color to white for invalid state
        border: "solid",
        borderColor: "#ffffff",
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function NewInvoiceDialog({ classes, businessId, invoiceId, disButton }) {
  const [business_id, setBusinessId] = useState(businessId);
  const [showLoder, setShowLoder] = useState(false);
  const [width, SetWidth] = useState({ marginLeft: 7 });
  const [hideUpload, setHideUpload] = useState(true);
  const [progresBar, setProgresBar] = useState(0);
  const [uploadText, setUploadText] = useState("Please Wait...");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [counter, setCounter] = useState(0);
  const MAX_SIZE = 10485760;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform any actions you want before the page refreshes
      // For example, you can show a confirmation dialog
      const confirmationMessage = "Are you sure you want to leave this page?";
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup: remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setCounter(progresBar);
  }, [progresBar]);

  const clientId = businessId;
  let updateText = ``;

  const config = {
    headers: {
      Authorization: `${getuserToken()}`,
      "content-type": "application/json",
    },
  };

  const step1Url = BASE_URL_API + `/accountants/profiles/startocr`;
  const step2Url = BASE_URL_API + `/accountants/profiles/uploader`;
  const step4Url = BASE_URL_API + `/accountants/clients/${business_id}/documents`;
  const configStep4 = {
    headers: {
      Authorization: `${getuserToken()}`,
    },
  };
  let ProcessId = "";
  const handleFile = async (file) => {
    handleUploadChange(file);
    setShowLoder(true);
    setHideUpload(false);
  };

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  async function uploadFileOnS3(s3BucketUrl, FormData, Number, i) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => setProgresBar(Math.trunc(100 / (Number / i))),
    };

    await axios.post(s3BucketUrl, FormData, config).then((response) => {
      if (response.status == 200 || response.status == 204) {
        console.log("");
      }
    });
  }

  async function handleUploadChange(file) {
    const uploadedFiles = file;
    let failedUpload = 0;
    let successUpload = 0;
    let failedFiles = [];
    eventBus.$emit("disableclosebutton", true);
    const documentsCount = file.length;
    updateText = ` Initiating upload of ${documentsCount} files.`;
    const dataRaw1 = {
      clientId,
      documentsCount,
    };

    try {
      // step 1
      const processRes = await axios.post(step1Url, dataRaw1, config);
      ProcessId = processRes?.data?.data?.processId;
      // step 2, 3, 4 will run in loop
      for (let i = 0; i < documentsCount; i++) {
        try {
          updateText = `Starting to upload ${i + 1} of ${uploadedFiles.length} file(s).`;
          SetWidth({ marginLeft: 1 });
          setUploadText(updateText);
          const file = uploadedFiles[i];
          const extension = getExtension(file?.name);
          const dataRawStep2 = {
            scopeType: "INVOICE",
            scopeId: clientId,
            extension: extension,
          };
          // const mimeType = getMime(file);
          // step 2 -> get upload body (same code as now)
          const response = await axios.post(step2Url, dataRawStep2, config);
          // step 3 - s3 upload (same code as now)
          const s3BucketUrl = `${response.data.data.SignedObject.postData.url}`;
          const createData = {
            uploadedFileUrl: `${response.data.data.SignedObject.uploadUrl}`,
            mimetype: `${response.data.data.ContentType}`,
          };
          const url2 = `${response.data.data.SignedObject.postData.url}`;
          let formData = new FormData();

          for (const [key, value] of Object.entries(
            response.data.data.SignedObject.postData.fields
          )) {
            formData.append(key, value);
          }
          
          formData.append("file", uploadedFiles[i]);
          if (documentsCount === i + 1) {
            SetWidth({ marginLeft: 3 });
            updateText = `Uploaded ${i + 1} of ${uploadedFiles.length} file(s).`;
            setUploadText(updateText);
          }
          await uploadFileOnS3(url2, formData, documentsCount, i + 1);
          const dataRawStep4 = {
            processId: `${ProcessId}`,
            files: [createData],
          };
          if (documentsCount === i + 1) {
            eventBus.$emit("closeNewInvoicePopup", response);
            updateText = `Uploaded ${i + 1} of ${uploadedFiles.length} file(s).`;
            setUploadText(updateText);
            // eventBus.$emit("closeNewInvoicePopup", response)
            setUploadText("Document Uploded");
            setShowLoder(false);
            setHideUpload(true);
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 3000);
          }
          await axios.post(step4Url, dataRawStep4, configStep4);
          if (documentsCount === i + 1) {
            eventBus.$emit("closeNewInvoicePopup1", response);
            updateText = `Uploaded ${i + 1} of ${uploadedFiles.length} file(s).`;
            setUploadText(updateText);
            // eventBus.$emit("closeNewInvoicePopup", response)
            setUploadText("Document Uploded");
            setShowLoder(false);
            setHideUpload(true);
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 3000);
          }
          successUpload++;
          // updateText = `${successUpload} of ${uploadedFiles.length} file(s) uploaded successfully.`;
          setUploadText(updateText);
        } catch (uploadErr) {
          eventBus.$emit("disableclosebutton", false);
          failedUpload++;
          failedFiles.push(file.name);
          updateText = `${failedUpload} of ${uploadedFiles.length} file(s) failed to upload.`;
        }
      }

      if (failedUpload) {
        updateText = `${failedUpload} of ${
          uploadedFiles.length
        } file(s) failed to upload. Please retry, the files are ${failedFiles.join(", ")}`;
      } else {
        updateText = "Document upload is completed.";
      }
    } catch (err) {
      updateText = "Upload failed!! Please try again";
    }
    // eventBus.$emit('disableclosebutton', true);
  }

  const Input = styled("input")({
    display: "none",
  });
  const dropzoneStyle = {
    width: "100%",
    height: "20%",
    border: "1px solid black",
  };
  return (
    <MDBox mb={3}>
      <MDBox my={2} display="flex" justifyContent="center">
        <Box style={{ overflow: "hidden" }}>
          <Grid item xs={12} sm={12} md={12}>
            <MDBox sx={{ fontSize: "2rem", display: "flex", justifyContent: "center" }}>
              {showLoder && (
                <MDBox sx={{ mt: 3 }}>
                  <Line
                    percent={progresBar}
                    strokeWidth={6}
                    strokeColor="#0d87f6"
                    width={225}
                    trailWidth={6}
                  />
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="1rem"
                    sx={{ ml: 1 }}
                  >
                    {progresBar === 99 ? `100%` : counter + "%"}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
            {showLoder && (
              <MDBox sx={width}>
                <MDTypography variant="h5" fontWeight="regular">
                  {uploadText}
                </MDTypography>
              </MDBox>
            )}
            <MDBox>
              <label style={{ minHeight: "150px" }} htmlFor="icon-button-file">
                {hideUpload && (
                  <>
                    <MuiThemeProvider theme={darkMode ? darkModetheme : theme}>
                      <DropzoneArea
                        filesLimit={100}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/jpg",
                          "image/JPG",
                          "image/JPEG",
                          "image/tiff",
                          "image/tif",
                          "application/pdf",
                          "image/png",
                        ]}
                        onDrop={handleFile}
                        maxFileSize={MAX_SIZE}
                      />
                    </MuiThemeProvider>
                  </>
                )}
              </label>
            </MDBox>
          </Grid>
        </Box>
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content="Invoice has been successfully updated."
          // autoHideDuration={3000}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
      </MDBox>
    </MDBox>
  );
}

export default NewInvoiceDialog;
