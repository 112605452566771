import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import AddNewClass from "./components/schemas/AddnewClass";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddClasses } from "../../../../services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";

function AddClassDialog({ onClose, invoiceId, businessId }) {
  const [business_id, setBusiness] = useState(businessId);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let initialValues = {
    class: "",
  };

  const [{ isLoading: isAddClassLoading, isError: isAddClassError, response: AddClassResponse }, AddClassListAPICall] = useAPICall(AddClasses, { onLoad: false });

  useEffect(() => {
    if (isAddClassLoading) {
      return () => null;
    }
    if (isAddClassError) {
      setErrorMessage(AddClassResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (AddClassResponse && AddClassResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewClassComplete", AddClassResponse?.data?.data);
      onClose();
    }
  }, [AddClassResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await AddClassListAPICall({
      payload: [
        {
          name: values?.class,
          clientId: `${business_id}`,
        },
      ],
    });
  };

  return (
    <>
      {
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={AddNewClass}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3} pl={"30%"}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <FormField type="text" label="Class Name *" name="class" />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      }
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddClassDialog;
