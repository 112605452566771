// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "../dashboards/components/ComplexStatisticsCard";

//data
import defaultLineChartData from "layouts/dashboards/analytics/data/defaultLineChartData"
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

import UserHoc from "hoc/userHoc";
function Analytics() {
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="groups"
                  title="Total Client"
                  count="00"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="receiptLong"
                  title="Total Documents"
                  count="00"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="pending"
                  title="Pending Approvals "
                  count="00"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="receiptLong"
                  title="Completed Documents"
                  count="00"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mb={3}>
        <Grid item xs={12} sm={12} lg={12}>
          <DefaultLineChart
            title="Revenue"
            description={
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" ml={-1}>
                  <MDBadgeDot color="info" size="sm" badgeContent="Total Client" />
                  <MDBadgeDot color="dark" size="sm" badgeContent="Total Documents" />
                  <MDBadgeDot color="error" size="sm" badgeContent="Pending Approvals Documents" />
                  <MDBadgeDot color="success" size="sm" badgeContent="Completed Documents
                       "/>
                </MDBox>
              </MDBox>
            }
            chart={defaultLineChartData}
          />
        </Grid>
      </MDBox>    
    </DashboardLayout>
  );
}

// export default Analytics;
export default UserHoc(Analytics);
