import { useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import { NavLink, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DataTable from "../components/Tables/DataTable";
import DataTable1 from "components/NewTable/table";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { Link } from "react-router-dom";
import { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAPICall } from "../../../utils/useapicall";
import { useMaterialUIController } from "context";
import {GetList,EditAccountantInfo,GetEmployeesList,GetClientsList,sendReinviteEmailList} from "../../../services/accountantservices";
import Hoc from "hoc";
import TextField from "@mui/material/TextField";
import Cookies from "universal-cookie";
import { Chip } from "@mui/material";
import Loader from "./components/imgLoader/loader";
import { eventBus } from "utils/eventbus";
import { green } from "@mui/material/colors";

const cookies = new Cookies();

function Accountants() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const action = {
    route: "",
    resendInvite: "Resend Invite",
    edittooltip: "Edit",
    deletetooltip: "Delete",
    billingtooltip: "Billing",
  };
  const [openAccount, setOpenAccount] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openClientPopup, setOpenClientPopup] = useState(false);
  const [alert, setAlert] = useState(false);
  const [profileInfo, setProfileInfo] = useState(cookies.get("accountant_profile_info"));
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [successMassageText, setSuccessMassageText] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [{isError, isLoading, response }, setAPICall] = useAPICall(GetList, { onLoad: false });
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },removeaccountantAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
  const [accountantId, setAccountantId] = useState();
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [employeeTableEmplty, setEmployeeTableEmpty] = useState("");
  const [clientTableEmpty, setClientTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [searchBusinessName, setSearchBusinessName] = useState("");
  const [typeBusiness, setTypeBusiness] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [disabledBusinessName, setDisabledBusinessName] = useState(true);
  const [helperText, setHelperText] = useState("");
  const [employessList, setemployessList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [showReinvoiteEmailDialog, setShowReinvoiteEmailDialog] = useState(false);
  const { pathname } = useLocation();
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [{isError: isEmployeesError, isLoading: isEmployeesLoading, response: Employeesresponse },EmployeessetAPICall] = useAPICall(GetEmployeesList, { onLoad: false });
  const [{isError: isClientsError, isLoading: isClientsLoading, response: Clientsresponse },ClientssetAPICall] = useAPICall(GetClientsList, { onLoad: false });
  const [loaderClass, setLoaderClass] = useState({});
  const [{isError: isReinviteEmailError, isLoading: isReinviteEmailLoading, response: ReinviteEmailresponse},ReinviteEmailsetAPICall] = useAPICall(sendReinviteEmailList, { onLoad: false });

  useEffect(() => {
    if (isReinviteEmailLoading) {
      return () => null;
    }
    if (isReinviteEmailError) {
      setSuccessMassageText("Faild to send Reinvite email");
      setShowErrorSnackbar(true);
      setShowReinvoiteEmailDialog(false);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
      setLoading(false);
      return () => null;
    }
    if (ReinviteEmailresponse && ReinviteEmailresponse.data.isSuccess === true) {
      setShowReinvoiteEmailDialog(false);
      setSuccessMassageText("Reinvite email sent successfully");
      setShowSuccessSnackbar(true);
      setTimeout(() => {
        setShowSuccessSnackbar(false);
      }, 4000);
      setLoading(false);
    }
  }, [ReinviteEmailresponse]);

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const offset =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(offset));
    setAPICall({
      payload: [{ keyword: `${search}`, offset: Number(offset), limit: PerPagelimit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await setAPICall({
        payload: [{ offset: ThisOffsetuse, limit: 10 }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const str1 = searchBusinessName.replace(/ /g, "");
    const str2 = businessName.replace(/ /g, "");
    const timeOutId = setTimeout(async () => {
      if (str1 == str2.toUpperCase() && searchBusinessName.length !== 0) {
        setDisabledBusinessName(false);
        setHelperText("");
      } else {
        setDisabledBusinessName(true);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchBusinessName]);

  useEffect(() => {
    if (isClientsLoading) {
      return () => null;
    }
    if (isClientsError) {
      setLoading(false);
      return () => null;
    }
    if (Clientsresponse && Clientsresponse.data.isSuccess === true) {
      setClientTableEmpty(Clientsresponse.data.data.totalRecords);
      const data = Clientsresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          firstName: prop.name,
          business: prop.businessName,
          email: prop.email,
          phone: prop.phone == null ? "-" : prop.phone,
          Numberofuser: prop?.numberOfEmployees ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
              >
                {prop?.numberOfEmployees}
              </MDTypography>
            </MDBox>
          ) : 0,
          switch: prop.isBlocked ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="error"
              >
                Inactive
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="success"
              >
                Active
              </MDTypography>
            </MDBox>
          ),
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: prop.totalEmployees,
          accountanttoclients: "-",
          monthlycost: "-",
          accountlevel: "-",
          // (prop.role.template == 'executive' ? "User" : "Admin"),
          action:
            profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? (
              ""
            ) : (
              <MDBox display="flex">
                <Link to={`/admin/admin/accountants/${prop._id}/edit-accountant`}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                </Link>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setBusinessName(prop?.business?.name);
                      setAccountantId(prop._id);
                      setTypeBusiness(true);
                    }}
                  >
                    <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ),
        };
      });
      setClientsList(data);
      setLoading(false);
      setOpenClient(true);
    }
  }, [Clientsresponse]);

  useEffect(() => {
    setProfileInfo(cookies.get("accountant_profile_info"));
  }, []);

  useEffect(() => {
    if (isEmployeesLoading) {
      return () => null;
    }
    if (isEmployeesError) {
      setLoading(false);
      return () => null;
    }
    if (Employeesresponse && Employeesresponse.data.isSuccess === true) {
      setEmployeeTableEmpty(Employeesresponse.data.data.totalRecords);
      const data = Employeesresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          firstName: prop.fname,
          business: prop.businessName,
          email: prop.email,
          phone: prop.phone,
          switch: prop.isBlocked ? (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="error"
              >
                Inactive
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                color="success"
              >
                Active
              </MDTypography>
            </MDBox>
          ),
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: prop?.totalEmployees ? prop?.totalEmployees : "-",
          accountanttoclients: prop?.clientsCount ? (
            prop?.clientsCount
          ) : (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                sx={{ color: darkMode ?"#ffffff" : "#7b809a" }}
              >
                0
              </MDTypography>
            </MDBox>
          ),
          monthlycost: "-",
          accountlevel: prop?.role?.template == "executive"
              ? "User"
              : prop?.role?.template == "owner"
              ? "Admin"
              : "Manager",
          action: profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
              <MDBox display="flex">
                <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                </Link>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setBusinessName(prop?.business?.name);
                      setAccountantId(prop._id);
                      setTypeBusiness(true);
                    }}
                  >
                    <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ),
        };
      });
      setemployessList(data);
      setLoading(false);
      setOpenAccount(true);
    }
  }, [Employeesresponse]);

  const handleCloseAccount = () => {
    setOpenAccount(false);
  };

  const date_diff_indays = function (date2) {
    const dt1 = new Date();
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  };

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        // setActiveUser(!prop.isBlocked);
        const HasJoined = prop.joinedAt;
        const ontrial = prop?.subscriptions[0]?.ontrial === true ? `Trial:${date_diff_indays(prop?.subscriptions[0]?.trialEndDate)}days` : "";
        return {
          snum: key + 1,
          firstName: (
            <MDBox>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                fontSize="0.875rem"
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              >
                {prop.fname && prop.lname == null ? "-" : prop.fname + " " + prop.lname + " "}
              </MDTypography>
            </MDBox>
          ),
          business: (
            <MDBox>
              <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                >
                  {prop?.business?.name}
                </MDTypography>
              </Link>
            </MDBox>
          ),
          email: prop.email,
          phone: prop.phone == null ? "-" : prop.phone,
          switch:
            HasJoined == null ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="warning"
                >
                  Pending Confirmation
                </MDTypography>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.20rem",
                  }}
                >
                  {prop?.subscriptions[0]?.ontrial === true ? (
                    <Chip
                      sx={{
                        marginLeft: "3px",
                        fontSize: "0.6125rem",
                        height: "17px",
                        color: "#ffffff",
                      }}
                      label={ontrial}
                      color="warning"
                    />
                  ) : ("")}
                </MDBox>
              </MDBox>
            ) : prop.isBlocked ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="error"
                >
                  Inactive
                </MDTypography>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.20rem",
                  }}
                >
                  {prop?.subscriptions[0]?.ontrial === true ? (
                    <Chip
                      sx={{
                        marginLeft: "3px",
                        fontSize: "0.6125rem",
                        height: "17px",
                        color: "#ffffff",
                      }}
                      label={ontrial}
                      color="warning"
                    />
                  ) : (
                    ""
                  )}
                </MDBox>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="success"
                >
                  Active
                </MDTypography>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.20rem",
                  }}
                >
                  <Chip
                    sx={{
                      marginLeft: "3px",
                      fontSize: "0.6125rem",
                      height: "17px",
                      color: "#ffffff",
                    }}
                    label={ontrial}
                    color="warning"
                  />
                </MDBox>
              </MDBox>
            ),
          createdat: Createdate.toLocaleDateString(),
          totalaccountants:
            prop?.totalEmployees == 0 ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                >
                  {prop.totalEmployees}
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setLoading(true);
                    await EmployeessetAPICall({
                      payload: [{ employessId: prop._id }],
                    });
                  }}
                  // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                >
                  {prop.totalEmployees}
                </MDTypography>
              </MDBox>
            ),
          totalclients:
            prop?.totalClients == 0 ? (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                >
                  {prop.totalClients}
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setLoading(true);
                    await ClientssetAPICall({
                      payload: [{ clientsId: prop._id }],
                    });
                  }}
                >
                  {prop.totalClients}
                </MDTypography>
              </MDBox>
            ),
          monthlycost: `$${prop?.subscriptions[0]?.nextBillAmount}`,
          action: profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
              <MDBox display="flex">
                <MDBox sx={{ mr: 2 }}>
                  <Link to={`/admin/billing/${prop._id}`}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ "@media (min-width: 1440px)": {
                        marginLeft: "14px",
                      },
                      "@media (min-width: 1441px)": {
                        marginLeft: "25px",
                      },  fontSize: "1.20rem", cursor: "pointer" }}
                    >
                      <Tooltip title={action.billingtooltip} placement="top">
                        <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>receiptLong</Icon>
                      </Tooltip>
                    </MDTypography>
                  </Link>
                </MDBox>
                <MDBox>
                  <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                    >
                      <Tooltip title={action.edittooltip} placement="top">
                        <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                      </Tooltip>
                    </MDTypography>
                  </Link>
                </MDBox>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ ml: 1, fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setBusinessName(prop?.business?.name);
                      setAccountantId(prop._id);
                      setTypeBusiness(true);
                    }}
                  >
                    <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
                {HasJoined == null && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ ml: 1, fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.resendInvite}
                      placement="top"
                      onClick={(value) => {
                        setAccountantId(prop._id);
                        setShowReinvoiteEmailDialog(true);
                      }}
                    >
                      {!darkMode ? (
                        <img
                          height={21}
                          width={21}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD80lEQVR4nO1ZTWgVVxQeW0m1FbGhBbFScCMuFKFVKGjF/m3cdeHClT8LFyVRRKx59yR890UlSnEREJNzRhGEbh74g65UELppGkWjLkxc2EU1gi78pyBiI2fm5eW9eTOZeZN574XyDtzNzP35vnO/c+65M47Tspa1rGUta9n/0YBT8wx4F1keJvBrsjLR0AZdk4cVg2KpCbw5OPAFWR5pOGgb1XgEOLEkoecLbQZyq/mgJYREoS2WAFnpbD5YCW0G3JGAAA83GyhF78JQPAHw5Vm7A1YuJYoDAv84m+LAgO9Snjc7tRiAD3SQAf/dPI/zAwN35+ZC4cPEwIOdvfPAyj6CPG0YeMhTXTOY/xMRidqurr7jnxL4MEH+raPH35Blzh06+XmUrGMJTE3IQ7m8fBt8D8hSXcRYeZsdcHlHkAIdkGXB9XryvJYgVyf71kCgNPmV7l5eFeyXA6/wFp05+CvdvQOrq3YcstybH/xfef+aCRQXeateDzvODeQbY+WP2sFrjeVurN5h/kyl6supelwqAlPBxa+N5QNA/8LKURNzTF5+JiujCcCPal8d44QYcGxxN9xNBLYGPJYtgSkiT/wqsbI2ATBX056xPB4SoOP6TvvEgigzwuCG8urAibMaZXCfrGwJehPgjwliCPK82Iw+q+zjthvwDgKf9zyt5bPuMHjMWDlH1t2umc/vPTHHgH9RWWVMoNRu5PLuT2F61lb+bM/Ro/ONZTJWXsTvtE9ex+jYsKyYFYFSRjHgr6Lm1iRgINdSBPzIfvCXseBnSqBI4h1Z+T2Y073zA/Iw/bz8QC9adSdQtuCbbsu7J2WjMksyLpd31xkMrjFWHoe8vx57vcyKgK/hwQ06p2o+6ZieXvdrHZPDiZWhJMBdjdkByB1fOm57ooC1JQk+VvCRJCDPp7JTfSXU43lfU2Xo7vAFPYn3/nb6k+kcqnIKcc62uhPI5Qd/8OYDnw8hl5tWBjEECHzWqTeBybqJLN8Ler709cPKEYI8qklC/g1trAEE/GxB4JeVBPwCzrtb2BRB7DvhZSQBY+VVPQkAxxb461TXS+SDWz9NGk1AAPJXPSUUS8DGtzgJdWYZxH5hVv6cvys+70tNwPKZSAKd/f0fZfFd1FiB7xB3e2Dxi2VB3JduJ3hrJIHsPu7ybR+o2+5VlZUkaFoAjlae7roI7z8DTi1K+JGXOwjyZ+rALpYS3t2gGshFgnw/GRNB02AOn5N/dRptmpG0EJt5bPFwzf8KsjKVpZbEqWMK/E/ifwT1MgWQ5uu3Ad9MfKFpiJzAXcHApoiAVc1rZnRmm2lJbCDbiufEqCaKYrIY1WJNU2WibNMyp7n2Hl0mzWUZZEXOAAAAAElFTkSuQmCC"
                        ></img>
                      ) : (
                        <img
                          height={21}
                          width={21}
                          style={{ filter: "brightness(0) invert(1)" }}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/0lEQVR4nO2ZS2sUQRDHfz7QjYqoKEhWBC/iQRE0gpAoPi/ePHjwZJKDB9F4MugGUfAQ/Qj5AF4Ek6AnDQhe1FV8Htx40IMaIR7iahYhiI601MDQdO/0PHeQ+UPB0t1VXf+equqaWShRokSJEiX+U1SAIaAOtAAvZ2nJ3kPiSyRUgZcdcNqziPKl29X5ZcCrAjjtGUgo30JxrgDOehY560KgXgBHPYs8diFwvwCOeha5hyOOFCwP3gIniIjFovShg45/Ak4DS6I4ri9WNfgCMJej43Oyp17/nYjYHtda4DrwM0PHF4AxYEObsA5FMOP3GeY3ySa/UnT8N3AL2GLYbw/wILDWmYAvU8AOw7ptsmlS56eAnQb7W8X+H219ZAKenPaY5TrfCzyM4bi6bw4Y7K2XUF2w6MUiEGywrgGrNZ1FwHGg4eB4Q9YqHRM2AseAq8B02gR8+Spdot6bLJWyN2PQmZE5tSYK9mvdQSoEfHkPnDSc5gqgBjRFajIWxDpgEJiUk26JqN8TwIBUPsT+GQmrVAn48hw4aolnJUF0ASPAdwe7Pnmlg6UqpkIgWFF2tbGtisDTmK30ZhfnkxLwa/pNQ01X98fnhG1FNQ8Cvqh4PS82uyTMXPR6gR5g1jD3zOX10ktRVAVBYt5VZ7fobLeQuJgXgTeBauOSsJ7IrDhvI9EMVKdMCVwWe4OW+TtyE68MOdAeg25/HgQOi71Jw9ylsDAIITBODgT8vumd4eSRW/wG8CViCHly2WVOwK8WP7Rxv4FTDVucJPbEphXzGRNYJeOmfklJX5sy6kTgScYhFEbAc5DpPD5s+Uk8oY0flPHRBLZvtyOwPKXvolfE3oA2fjeQxKMxn8Qpcvi4+zpwkTW1OXUzh6HXYvcbsMZB/98JqW+RjxIktt9K1Axz6kkcCuSEjj6LzWE6gIo0YknDsh7nv4K0UJWWOK7zH6P8R5AVumN+/X4R5YUma1SkJdYT25aww1IZCwfVEvfLPdGQQjEvv8elVDpVmxJ0EH8B1uk46eiPBnAAAAAASUVORK5CYII="
                        ></img>
                      )}
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
            ),
        };
      });
      setAdminList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      setLoading(false);
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, [removeResponse]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const successDelete = async () => {
    setLoading(true);
    setTypeBusiness(false);
    await removeaccountantAPICall({
      payload: [
        {
          isBlocked: true,
          isArchived: true,
          bussinessId: `${accountantId}`,
        },
      ],
    });
    setAlert(false);
  };
  const hideAlert = () => {
    setAlert(false);
    setTypeBusiness(false);
  };

  const handleCloseClient = () => {
    setOpenClient(false);
  };

  const handleCloseClientPopup = () => {
    setOpenClientPopup(false);
  };

  const dataTableData = {
    columns: [
      {
        Header: "Business Name",
        accessor: "business",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Full Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email Address",
        accessor: "email",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total Users",
        accessor: "totalaccountants",
        Cell: ({ value }) => <a style={{ cursor: "pointer", color: "blue" }}>{value} </a>,
      },
      {
        Header: "Total Clients",
        accessor: "totalclients",
        Cell: ({ value }) => <a style={{ cursor: "pointer", color: "blue" }}>{value} </a>,
      },
      {
        Header: "Monthly Cost",
        accessor: "monthlycost",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  const dataTableData2 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Account Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Account Level",
        accessor: "accountlevel",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Accountant to Clients",
        accessor: "accountanttoclients",
        Cell: ({ value }) => <a>{value} </a>,
      },
    ],

    rows: employessList,
  };

  const dataTableData3 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Number of Users Accounts",
        accessor: "Numberofuser",
        Cell: ({ value }) => <a>{value} </a>,
      },
      {
        Header: "status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: clientsList,
  };

  const dataTableData4 = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "AccountLevel",
        accessor: "accountalevel",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: [
      {
        firstName: "Orlando Imieto",
        email: "Orlando@mailinator.com",
        accountalevel: "Admin",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Alice ",
        email: "Alice@mailinator.com",
        accountalevel: "User",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
      {
        firstName: "Michael ",
        email: "Michael@mailinator.com",
        accountalevel: "User",
        action: (
          <MDBox display="flex">
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.edittooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography variant="body2" color="secondary">
              <Tooltip title={action.deletetooltip} placement="top">
                <Icon sx={{ color: darkMode ? "#ffffff" : "" }}>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ),
      },
    ],
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    setLoadingCircul(true);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
  };

  const handleBusiness = async (event) => {
    setSearchBusinessName(event.target.value.toUpperCase());
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }} sx={loaderClass}>
          <MDBox display="flex" justifyContent="center">
            {loading && <Loader />}
          </MDBox>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Accountants
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search Accountant"
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{
                    style: darkMode
                      ? {
                          color: "#ffffff",
                        }
                      : {},
                  }}
                  sx={{
                    input: { color: darkMode ? "#ffffff" : "#000000" },
                    "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                  }} 
                />
              </MDBox>
              <MDBox>
                <MDButton
                  component={NavLink}
                  to="/admin/accountants/new-accountant"
                  variant="gradient"
                  color="info"
                  disabled={loading}
                >
                  {" "}
                  Add New Accountant
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <Dialog
            open={openAccount}
            onClose={handleCloseAccount}
            aria-labelledby="responsive-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "auto",
                  maxWidth: "1000px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                },
              },
            }}
          >
            <DialogContent>
              <MDBox my={5}>
                {employeeTableEmplty == "0" ? (
                  <MDBox my={5} sx={{ mt: 1 }}>
                    <DataTable
                      table={dataTableData2}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={true}
                    />
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{
                        marginLeft: "43%",
                        fontSize: "1.20rem",
                        marginTop: "5%",
                        marginBottom: "5%",
                      }}
                    >
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDBox my={5} sx={{ mt: 1 }}>
                    <DataTable
                      table={dataTableData2}
                      entriesPerPage={false}
                      pagination={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                )}
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAccount}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openClient}
            onClose={handleCloseClient}
            aria-labelledby="responsive-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "auto",
                  maxWidth: "1000px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                },
              },
            }}
          >
            <DialogContent>
              <MDBox my={5}>
                {clientTableEmpty == "0" ? (
                  <MDBox my={5} sx={{ mt: 1 }}>
                    <DataTable
                      table={dataTableData3}
                      entriesPerPage={false}
                      pagination={false}
                      showTotalEntries={true}
                    />
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{
                        marginLeft: "43%",
                        fontSize: "1.20rem",
                        marginTop: "5%",
                        marginBottom: "5%",
                      }}
                    >
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDBox my={5} sx={{ mt: 1 }}>
                    <DataTable
                      table={dataTableData3}
                      entriesPerPage={false}
                      pagination={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                )}
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClient}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openClientPopup}
            onClose={handleCloseClientPopup}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
          >
            <DialogContent>
              <MDBox my={5}>
                <DataTable
                  table={dataTableData4}
                  entriesPerPage={false}
                  pagination={false}
                  showTotalEntries={true}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClientPopup}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* {alert} */}
          <Dialog
            open={typeBusiness}
            onClose={hideAlert}
            // aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "50%",
                  maxWidth: "500px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
                },
              },
            }}
          >
            <DialogTitle sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
              Delete {businessName}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 4, mt: 4, color: darkMode ? "#ffffff" : "#000000" }}>
                Are you sure want to delete this accountant?
              </DialogContentText>
              <TextField
                margin="dense"
                id="name"
                label="Business name"
                type="text"
                inputProps={{
                  style: { textTransform: "uppercase", color: darkMode ? "#ffffff" : "#000000" },
                }}
                InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000000" } }}
                style={{ width: 250, borderColor: darkMode ? "#ffffff" : "#000000" }}
                error={disabledBusinessName}
                helperText={helperText}
                variant="outlined"
                onChange={handleBusiness}
              />
              {/* <TextField
                                margin="dense"
                                id="name"
                                label="Business name"
                                type="text"
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                style={{ width: 250 }}
                                error={disabledBusinessName}
                                helperText={helperText}
                                variant="outlined"
                                onChange={handleBusiness}
                            /> */}
            </DialogContent>
            <DialogActions>
              <MDButton variant="gradient" color="error" onClick={() => setTypeBusiness(false)}>
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => warningWithConfirmMessage()}
                disabled={disabledBusinessName}
              >
                Delete Business
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={alert}
            onClose={hideAlert}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",
                  backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                  color: darkMode ? '#ffffff':'#7b809a',
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: "hidden" }}>
              <DialogContentText sx={{color: darkMode ? '#ffffff':'#7b809a', display: "flex", justifyContent: "center", mt: 4 }}>
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{color: darkMode ? '#ffffff':'#7b809a', fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                >
                  Once you delete this Accountant there is no way of getting any of the documents or
                  data back.
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
              <MDButton variant="gradient" color="success" onClick={successDelete}>
                Yes, delete it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={hideAlert}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showReinvoiteEmailDialog}
            onClose={() => setShowReinvoiteEmailDialog(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: "hidden" }}>
              <DialogContentText
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{
                    fontWeight: 300,
                    fontSize: "0.90rem",
                    textAlign: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  Are you sure you want to resend the invite email?
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
              <MDButton
                variant="gradient"
                color="success"
                onClick={(value) => {
                  setLoading(true);
                  ReinviteEmailsetAPICall({
                    payload: [{ userId: accountantId }],
                  });
                }}
              >
                Yes, send it!
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => {
                  setLoading(false);
                  setShowReinvoiteEmailDialog(false);
                }}
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content="Accountant Deleted Successfully."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content={successMassageText}
              open={showSuccessSnackbar}
              close={() => setShowSuccessSnackbar(false)}
            />
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={successMassageText}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
          {
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable1
                table={dataTableData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                response={tableEmpty}
                showCheck={false}
                ClientHeigth={true}
              />
            </MDBox>
          }
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Hoc(Accountants);
