import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import pattern from "assets/images/illustrations/pattern-tree.svg";
import { Tooltip } from "@mui/material";
import { eventBus } from "utils/eventbus";
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';

function CardImg({ color, number, holder, expires, cardBrand }) {
    const numbers = [...`${number}`];
    const CardLogo = ({ cardBrand }) => {
        return (
          <>
            {cardBrand && (<PaymentIcon type={cardBrand} format="logo" width={100} />)}
          </>
        );
      };

    const num1 = numbers.slice(0, 4).join("");
    const num2 = numbers.slice(4, 8).join("");
    const num3 = numbers.slice(8, 12).join("");
    const num4 = numbers.slice(12, 16).join("");

    return (
        <Card
            sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
                background: gradients[color]
                    ? linearGradient(gradients[color].main, gradients[color].state)
                    : linearGradient(gradients.dark.main, gradients.dark.state),
                boxShadow: xl,
                position: "relative",
                width: `360px`, height: `224px`
            })}
        >
            <MDBox
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                opacity={0.2}
                sx={{
                    backgroundImage: `url(${pattern})`,
                    backgroundSize: "cover",
                }}
            />
            <MDBox position="relative" zIndex={2} p={2} >
                <MDBox display='flex' >
                    <MDBox color="white" p={1} lineHeight={0} display="inline-block">
                        <MDTypography
                            variant="h5"
                            color="white"
                            fontWeight="regular"
                            textTransform="capitalize"
                            opacity={0.8}>
                            {`Card Details`}
                        </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" p={1.3} lineHeight={0} color={"white"} display="inline-block">
                        <Tooltip title="Edit Card" placement="top">
                            <Icon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => eventBus.$emit("EditPaymentForm", 'click')} >
                                edit
                            </Icon>
                        </Tooltip>
                    </MDBox>
                </MDBox>
                <MDTypography variant="h5" color="white" fontWeight="medium" sx={{
                    '@media (max-width: 1920px)': {
                        mb: 5
                    },
                    '@media (max-width: 1440px)': {
                        mb: 5
                    },
                    mt: 3, mb: 5, pb: 1,
                }}>
                    {num1}&nbsp;&nbsp;&nbsp;{num2}&nbsp;&nbsp;&nbsp;{num3}&nbsp;&nbsp;&nbsp;{num4}
                </MDTypography>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox display="flex" alignItems="center">
                        <MDBox lineHeight={1}>
                            <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                                Expires
                            </MDTypography>
                            <MDTypography variant="h6" color="white" fontWeight="medium">
                                {expires}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end" width="20%">
                        <CardLogo cardBrand={cardBrand} />
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of MasterCard
CardImg.defaultProps = {
    color: "info",
};

// Typechecking props for the MasterCard
CardImg.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    number: PropTypes.number.isRequired,
    holder: PropTypes.string.isRequired,
    expires: PropTypes.string.isRequired,
};

export default CardImg;
