import * as Yup from "yup";

export const EditNewClientSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last name should be more than 3 characters.'),
});
