import * as Yup from "yup";

export const AddNewSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last name should be more than 3 characters.'),
    phone: Yup.string()
        .nullable()
        .min(10, "Phone number is invalid")
        .matches(
            /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            "Phone number is not valid"
        ),
    role: Yup.string().required('Role is required.'),
});
