import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import { green } from "@mui/material/colors";
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable from "components/NewTableSelectAll/table";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { SITE_URL } from "helpers/config";
import { User1 } from "helpers/getUser";
import UserHoc from "hoc/userHoc";
import { EditSchema } from "layouts/accountants/user/components/schemas/edituser";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import { EditUserInfo, GetRolescope, GetuserClientList, GetuserDetailsList } from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";

function EditUsers() {
  let newObj = {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [bussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [allClient, setAllClient] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessages, setErrorMessage] = useState("Failed");
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [UserRole, setUserRole] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [adminRoleId, setAdminRoleId] = useState("");
  const [clientRoleId, setclientRoleId] = useState("");
  const [ownerRoleId, setOwnerRoleId] = useState("");
  const [activeUser, setActiveUser] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState([]);
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchClient , setSearchClient] = useState('');
  const [openClient, setOpenClient] = useState(false);
  const [permissionsData, setPermissionsData] = useState({});
  const [adminList, setAdminList] = useState([]);
  const [tableEmpty, setTableEmpty] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedClientLength, setSelectedClientLength] = useState("");
  const [selectAllcehck ,setSelectAllCheck] = useState(false);
  const [assignedClients , setAssignedClients] = useState([]);
  const [allRows , setAllRows] = useState([]);
  const limit = localStorage.getItem("limit");
  const [pushPopFun, setPushPopFun] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await setClientAPICall({
            payload: [{ keyword: `${searchClient}`, offset: ThisOffsetuse, limit: limit }],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
  }, [searchClient]);

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    businessname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: UserRole,
  };

  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditUserInfo, { onLoad: false});
  const [{ isLoading: isRoleLoading, isError: isRoleError, response: roleResponse }, getRoleAPICall] = useAPICall(GetRolescope, { onLoad: false });
  const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall] = useAPICall(GetuserDetailsList, { onLoad: false });
  const [{ isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse }, UserStatusAPICall] = useAPICall(EditUserInfo, { onLoad: false });
  const [{ isLoading: isClientError, isError: isClientLoading, response: Clientresponse },setClientAPICall] = useAPICall(GetuserClientList, { onLoad: false });

  useEffect(async () => {
    await getRoleAPICall({
      payload: [],
    });
    setTimeout(async () => {
      await getUserDteailsAPICall({
        payload: [{ bussinessId: `${bussinessId}` }],
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (isClientLoading) {
      return () => null;
    }
    if (isClientError) {
      setLoading(false);
      return () => null;
    }
    if (Clientresponse && Clientresponse.data.isSuccess === true) {
      setTableEmpty(Clientresponse.data.data.totalRecords);
      const Allrowdata = Clientresponse.data.data.records.map((prop, key) => {
        return {
          id:prop?._id,
          name: prop.name,
        };
      });
      setAllRows(Allrowdata);
      const data = Clientresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          id:prop?._id,
          snum: key + 1,
          client: prop.name,
          approve: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id, prop.name)}
                // checked={checked[prop._id]}
              />
            </MDBox>
          ),
          doctoreview: prop.toReview,
          unmappedvendors: prop.unmappedVendor,
          clientapproval: Createdate.toLocaleDateString(),
          yourapproval: prop.approved,
          pendingapproval: prop.pendingApproval,
          unreconciled: "-",
        };
      });
      setAdminList(data);
      setLoading(false);
    }
  }, [Clientresponse]);

  useEffect(() => {
    if (isUserStatusLoading) {
      return () => null;
    }

    if (isUserStatusError) {
      return () => null;
    }

    if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
      // getAccountanatAPICall({
      //     payload: []
      // })
    }
  }, [UserStatusResponse]);

  useEffect(() => {
    if (isRoleLoading) {
      return () => null;
    }

    if (isRoleError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      return () => null;
    }

    if (roleResponse && roleResponse.data.isSuccess === true) {
      const adminresult = roleResponse.data.data.records.find(
        ({ template }) => template === "admin"
      );
      const managerresult = roleResponse.data.data.records.find(
        ({ template }) => template === "manager"
      );
      const clientresult = roleResponse.data.data.records.find(
        ({ template }) => template === "user"
      );
      const ownerresult = roleResponse.data.data.records.find(
        ({ template }) => template === "owner"
      );
      setAdminRoleId(adminresult?._id);
      setclientRoleId(clientresult?._id);
      setOwnerRoleId(ownerresult?._id);
      // navigate('/accountants/list');
    }
  }, [roleResponse]);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      setSelectedClientIds(userDetailsDataResponse?.data?.data?.employee?.assignedClients);
      setAssignedClients(userDetailsDataResponse?.data?.data?.employee?.assignedClients);
      const adminresult = userDetailsDataResponse.data.data.employee;
      setSelectedClientLength(adminresult?.totalClients);
      setprofileEmail(adminresult?.email);
      setprofileFname(adminresult?.fname);
      setprofileLname(adminresult?.lname);
      setprofileBusinessName(adminresult?.businessName);
      setprofileNumber(adminresult?.phone);
      setPhone(adminresult?.phone);
      setActiveUser(adminresult?.isBlocked);
      setSelectAllCheck(adminresult?.allClients);
      setAllClient(adminresult?.permissions[0]?.allScopes);
      setUserRole(
        adminresult?.permissions[0]?.role == "user"
          ? "client"
          : adminresult?.permissions[0]?.role === "admin"
          ? "domain1"
          : "domain"
      );
      if (adminresult?.permissions[0]?.scopeIds) {
        if (roleResponse && roleResponse.data.isSuccess === true) {
          adminresult?.assignedClients.map((v) => {
            selectedClientId.push(v._id);
            permissionsData[v._id] = {
              clientId: v._id,
              name: v.name,
              roleId:
                adminresult?.role?.template === "domain1"
                  ? ownerRoleId
                  : selectedRole === "domain"
                  ? adminRoleId
                  : clientRoleId,
            };
          });
        }
      }
      setShowForm(true);
    }
  }, [userDetailsDataResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 10000);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setBtnDisable(false);
      navigate("/accountant/users/list");
      setTimeout(() => {
        setShowSnackbar(false);
      }, 2000);
    }
  }, [response]);

  const toggleCheck = async (clientId, clientName) => {
    if (pushPopFun) {
      if (selectedClientId.includes(clientId)) {
        var index = selectedClientId.indexOf(clientId);
        selectedClientId.splice(index, 1);
        delete permissionsData[clientId];
      } else {
        selectedClientId.push(clientId);
        permissionsData[clientId] = {
          clientId: clientId,
          name: clientName,
          roleId:
            selectedRole === "domain1"
              ? ownerRoleId
              : selectedRole === "domain"
              ? adminRoleId
              : clientRoleId,
        };
      }
      // setSelectedClientLength(selectedClientId.length);
      setPermissionsData(permissionsData);
    }
  };

  const handleSubmit = async (values) => {
    setSelectedRole(values.role);
    setIsDisable(true);

    // const roleScope = values.role === 'domain1' ? 'domain' : values.role;
    {
      values.role === "domain1"
        ? await setAPICall({
            payload: [
              {
                email: `${values.email}`,
                bussinessId: bussinessId,
                // "businessName": `${ values.businessname }`,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                // "scope": `${roleScope}`,
                returnLink: `${SITE_URL}/user/create-password`,
                // "roleId": RoleId,
                // "allClient": true,
                permissions: [
                  {
                    category: "accounting",
                    role: "admin",
                    allScopes: true,
                  },
                ],
                // "permissions": Object.values(permissionsData),
              },
            ],
          })
        : allClient == true
        ? values.role === "domain"
          ? await setAPICall({
              payload: [
                {
                  email: `${values.email}`,
                  bussinessId: bussinessId,
                  phone: phone,
                  fname: `${values.firstname}`,
                  lname: `${values.lastname}`,
                  returnLink: `${SITE_URL}/user/create-password`,
                  permissions: [
                    {
                      category: "accounting",
                      role: "manager",
                      allScopes: true,
                    },
                  ],
                },
              ],
            })
          : await setAPICall({
              payload: [
                {
                  email: `${values.email}`,
                  bussinessId: bussinessId,
                  phone: phone,
                  fname: `${values.firstname}`,
                  lname: `${values.lastname}`,
                  returnLink: `${SITE_URL}/user/create-password`,
                  permissions: [
                    {
                      category: "accounting",
                      role: "user",
                      allScopes: true,
                    },
                  ],
                },
              ],
            })
        : values.role === "domain"
        ? await setAPICall({
            payload: [
              {
                email: `${values.email}`,
                bussinessId: bussinessId,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                returnLink: `${SITE_URL}/user/create-password`,
                permissions: [
                  {
                    category: "accounting",
                    role: "manager",
                    scopeIds: selectedClientIds,
                  },
                ],
              },
            ],
          })
        : await setAPICall({
            payload: [
              {
                email: `${values.email}`,
                bussinessId: bussinessId,
                phone: phone,
                fname: `${values.firstname}`,
                lname: `${values.lastname}`,
                returnLink: `${SITE_URL}/user/create-password`,
                permissions: [
                  {
                    category: "accounting",
                    role: "user",
                    scopeIds: selectedClientIds,
                  },
                ],
              },
            ],
          });
    }
  };

  const ToggleChange = async () => {
    setActiveUser(!activeUser);
    await UserStatusAPICall({
      payload: [
        {
          isBlocked: !activeUser,
          // "isBlocked": `${!activeUser}`,
          bussinessId: `${bussinessId}`,
        },
      ],
    });
  };
  
  const handleChange = () => {
    setSelectedClientLength(0);
    setAllClient(!allClient);
  };

  const handleClickOpenClient = async () => {
    localStorage.setItem('selectedIdsForAllPage',[]);
    localStorage.setItem("offset", 0);
    setPushPopFun(true);
    setLoading(true);
    await setClientAPICall({
      payload: [{ keyword: `${searchClient}` ,limit: limit, offset: 0}]
    });
    setOpenClient(true);
  };

  const handleCloseClient = () => {
    setOpenClient(false);
  };

  eventBus.$on("clientRowSelected", (data) => clientRowSelected(data));
  function clientRowSelected(data) {
   setSelectedClientLength(data.length);
   setSelectedClientIds(data);
  }

  eventBus.$on("onclickselectclientchangeTotalPerPage", (data) => onclickselectclientchangeTotalPerPage(data));
  function onclickselectclientchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("limit", Number(data));
    localStorage.setItem("offset", 0);
    setClientAPICall({payload: [{ keyword: `${searchClient}`, limit: data,offset: 0}]});
  }

  eventBus.$on("onclickselectclientPagechnage", (data) => onclickselectclientPagechnage(data));
  function onclickselectclientPagechnage(data) {
    const offset =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(limit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(limit) - Number(limit);
        localStorage.setItem("offset", offset);
    setLoading(true);
    setClientAPICall({payload: [{ keyword: `${searchClient}`, limit: limit, offset: offset,}]});
    }

  const dataTableData = {
    columns: [
      {
        Header: "Client",
        accessor: "client",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearchClient(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoading(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <>
      {showForm ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card
                        id="basic-info"
                        sx={{
                          overflow: "visible",
                          backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                        }}
                      >
                        <Formik
                          initialValues={initialValues}
                          validationSchema={EditSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                              <Card
                                sx={{
                                  height: "100%",
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                }}
                              >
                                <MDBox p={3}>
                                  <MDBox>
                                    <MDBox lineHeight={0}>
                                      <MDTypography variant="h5">Edit User</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625}>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="First Name *"
                                            name="firstname"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="Last Name *"
                                            name="lastname"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormField
                                          type="email"
                                          label="Email *"
                                          name="email"
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <NumberFormat
                                          customInput={TextField}
                                          variant="standard"
                                          type="tel"
                                          value={phone}
                                          fullWidth
                                          InputLabelProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          InputProps={{
                                            style: darkMode ? { color: "#ffffff",borderBottom:"2px solid" } : {},
                                          }}
                                          label="Phone Number"
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                              WebkitTextFillColor: "black",
                                            },
                                            mb: 1,
                                            mt: -0.3,
                                            fontSize: `0.823rem !important`,
                                          }}
                                          format="(###) ###-####"
                                          mask=""
                                          onValueChange={(value) => setPhone(value.formattedValue)}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                          <InputLabel
                                            sx={
                                              darkMode ? { mt: -3, color: "#ffffff" } : { mt: -3 }
                                            }
                                          >
                                            Role *
                                          </InputLabel>
                                          <Field
                                            as="select"
                                            name="role"
                                            variant="standard"
                                            style={
                                              darkMode
                                                ? {
                                                  width:"100%",
                                                  borderRadius : '0px',
                                                    marginTop: "18px",
                                                    borderTop: "0px",
                                                    borderLeft: "0px",
                                                    borderRight: "0px",
                                                    color: "lightgrey",
                                                    borderBottom: "2px solid",
                                                    backgroundColor: "transparent",
                                                  }
                                                : {
                                                  width:"100%",
                                                  borderRadius : '0px',
                                                    marginTop: "22px",
                                                    borderTop: "0px",
                                                    borderLeft: "0px",
                                                    borderRight: "0px",
                                                    color: "slategray",
                                                  }
                                            }
                                          >
                                            <option
                                              style={
                                                darkMode
                                                  ? { color: "#ffffff", backgroundColor: "#202940" }
                                                  : {}
                                              }
                                              value=""
                                            >
                                              Select Role
                                            </option>
                                            {Object.keys(User1).map((key) => (
                                              <option
                                                style={
                                                  darkMode
                                                    ? {
                                                        color: "#ffffff",
                                                        backgroundColor: "#202940",
                                                      }
                                                    : {}
                                                }
                                                value={key}
                                              >
                                                {User1[key]}
                                              </option>
                                            ))}
                                          </Field>
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              <ErrorMessage name="role" />
                                            </MDTypography>
                                          </MDBox>

                                          <>
                                            {values.role == "domain1" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have access to the entire Paynthr
                                                account and be able to add/edit users.
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                            {values.role == "domain" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have access to the entire account
                                                except the billing page and be able to add/edit
                                                users.
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                            {values.role == "client" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have only have access to the
                                                Documents & Inventory page
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                          <>{setSelectedRole(values.role)}</>
                                        </FormControl>
                                      </Grid>
                                      <>
                                        {values.role === "client" || values.role === "domain"
                                          ? setShowCheck(true)
                                          : setShowCheck(false)}
                                      </>
                                      <>
                                        <Grid item xs={12}>
                                          {showCheck && (
                                            <>
                                              <MDBox>
                                                <Field
                                                  as={FormControlLabel}
                                                  type="checkbox"
                                                  name="allClient"
                                                  control={<Checkbox />}
                                                  label={
                                                    <spna
                                                      style={darkMode ? { color: "#ffffff" } : {}}
                                                    >
                                                      All Clients
                                                    </spna>
                                                  }
                                                  checked={allClient}
                                                  onChange={handleChange}
                                                  sx={{ mt: 1.5 }}
                                                />
                                              </MDBox>

                                              {!allClient ? (
                                                <MDBox sx={{ mt: 1 }}>
                                                  <Button
                                                    // color="info"
                                                    style={{ color: "white" }}
                                                    onClick={() => {
                                                      handleClickOpenClient();
                                                    }}
                                                    variant="contained"
                                                  >
                                                    {selectedClientLength > 0 && !allClient
                                                      ? `${selectedClientLength} Selected Clients`
                                                      : `Select Clients`}
                                                  </Button>
                                                </MDBox>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </Grid>
                                        <Grid item xs={3} sx={{ pr: 11 }}>
                                          <MDBox sx={{ mt: 1 }}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={!activeUser}
                                                  onChange={ToggleChange}
                                                />
                                              }
                                              label={
                                                <spna style={darkMode ? { color: "#ffffff" } : {}}>
                                                  Is Active
                                                </spna>
                                              }
                                              // disabled={allClient}
                                            />
                                          </MDBox>
                                        </Grid>
                                      </>
                                    </MDBox>
                                    <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                      <MDButton
                                        disabled={btnDisable}
                                        variant="gradient"
                                        color="light"
                                        component={Link}
                                        to="/accountant/users/list"
                                      >
                                        Cancel
                                      </MDButton>
                                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                        <MDButton
                                          disabled={isDisable}
                                          type="submit"
                                          variant="gradient"
                                          color="info"
                                        >
                                          {isDisable ? "updating..." : "update"}
                                        </MDButton>
                                      </MDBox>
                                    </MDBox>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Dialog sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            height: "73%",
                            maxWidth: "650px",
                            backgroundColor: "#202940 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            height: "73%",
                            maxWidth: "650px",
                            // maxHeight: "600px",
                          },
                        },
                      }
                }
              open={openClient} onClose={handleCloseClient} fullWidth="true">
              <DialogTitle
                sx={darkMode
                  ? {
                      justifyContent: "center",
                      display: "flex",
                      mt:1,
                     color: "#ffffff",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      mt:1,
                    }
                  }
              >
                SELECT CLIENTS
              </DialogTitle>
              <MDBox sx={{ pr: 3, mb: 2 , display:'flex' , justifyContent:'end' , marginTop:'-9%' }}>
                <MDInput
                  type="search"
                  label="Search Client"
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                  InputProps={{ style: darkMode ? { color: "#ffffff" } : { color: "#000000" } }}
                  sx={{
                    input: { color: darkMode ? "#ffffff" : "#000000" },
                    "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                  }} 
                />
              </MDBox>
              <DialogContent sx={{overflow:'hidden'}} >
                <MDBox my={5} sx={{ mt: 1 }}>
                  <MDBox>
                    {loading && (
                      <CircularProgress
                        size={50}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </MDBox>
                  <DataTable
                    table={dataTableData}
                    entriesPerPage={true}
                    pagination={true}
                    showTotalEntries={true}
                    response={tableEmpty}
                    showCheckBoxs={true}
                    ClientHeigth={true}
                    allrows={allRows} 
                    allClients={selectAllcehck}
                    assignedClients={assignedClients}
                  />
                </MDBox>
              </DialogContent>
              <DialogActions sx={{display:'flex' , justifyContent:'space-between'}}>
                  <MDTypography
                      component="div"
                      variant="caption"
                      color="info"
                      fontWeight="regular"
                    >
                      {selectedClientLength} Client Selected
                  </MDTypography>
              <Button
                onClick={handleCloseClient}
                sx={{
                  color: darkMode ? "#ffffff" : "",
                }}
              >
                OK
              </Button>
            </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              content="User Updated Successfully!"
              open={showSnackbar}
              // close={() => setShowSnackbar(false)}
              // autoHideDuration={3000}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={ErrorMessages}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </DashboardLayout>
      ) : (
        <DashboardLayout>
          <MDBox display="flex" justifyContent="center">
            {loading && (
              <img
                src={LoaderPng}
                width="600"
                height="auto"
                style={{
                  position: "absolute",
                  top: "350%",
                  bottom: "100%",
                  left: "20%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default UserHoc(EditUsers);
