import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Cookies from 'universal-cookie';
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";
import NotificationItem from "../../Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "../DashboardNavbar/styles";

import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
} from "context";

function DashboardNavbar({ absolute, light, isMini, showBilling }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode , transparentSidenav ,  whiteSidenav  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(4);
  const [show, setShow] = useState(false);
  const [reDate, setReDate] = useState();
  const cookies = new Cookies();
  const accountAccess = localStorage.getItem('accountAccess');
  const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
  const [showTrial, setShowTrial] = useState(false);
  const [showBillingButton, setShowBillingButton] = useState(false);

  const date_diff_indays = function (date2) {
    const dt1 = new Date();
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
  };

  useEffect(() => {
    const onTrialValue = profileInfo?.user?.subscriptions[0].ontrial;
    const onPrimary = profileInfo?.user?.isPrimary;
    setShowBillingButton(onPrimary);
    setShowTrial(onPrimary && onTrialValue);
    // profileInfo?.user?.isPrimary == true ? setShowTrial(onTrialValue) : setShowTrial(false);
    setReDate(date_diff_indays(profileInfo?.user?.trialEndDate ? profileInfo?.user?.trialEndDate : profileInfo?.user?.subscriptions[0]?.trialEndDate))
  }, [profileInfo])
  useEffect(() => {
    const showw = localStorage.getItem("ShowBilling")
    setShow(showw === null ? false : showw)
  }, [])
  const FinalShow = show ? show : showBilling;

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      PaperProps={{
        sx: {
            backgroundColor: darkMode ? '#202940 !important' : '#ffffff', // Change background color
            mt: 2,
        }
    }}
    >{accountAccess !== 'false' && 
      <Link to="/accountants/profile-overview">
        <NotificationItem title="Profile" />
      </Link>
    }
      {
       (accountAccess !== 'false' && showBillingButton) && <Link to="/accountants/billing">
          <NotificationItem title="Billing" />
        </Link>
      }
      <Link to="/user/logout">
        <NotificationItem title="Logout" />
      </Link>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;
      return colorValue;
    },
  });
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      justifyContent='end'
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          sx={{
            '@media (max-width: 1920px)': {
              ml: '86%'
            },
            '@media (max-width: 1440px)': {
              ml: '80%'
            },
            '@media (max-width: 1150px)': {
              ml: '75%'
            },
            ml: '88%'
          }} display="flex" justifyContent="end" >
          {showTrial &&
            <>
              <MDTypography
                variant="caption" fontWeight="regular" fontSize="1rem" sx={{ color: "#104d9e" }}
              >
                TRIAL
              </MDTypography>
              <MDTypography variant="caption" fontWeight="regular" fontSize="1rem" color="warning" ml={1} >
                {reDate} days left.
              </MDTypography>
            </>
          }
        </MDBox>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
            <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox> */}
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="large"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                disableRipple>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>

              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
