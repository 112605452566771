import { TextField } from "@material-ui/core";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "context";
import { useState } from "react";
import NumberFormat from "react-number-format";

export const handleValueChange = (name, setFieldValue) => (val) =>
  setFieldValue(name, val.floatValue);

const CurrencyFieldText = ({ currencySymbol,disabled = false, priceMismatch = false, ...props }) => {
  const [displayValue, setDisplayValue] = useState();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return priceMismatch === true ? (
    <NumberFormat
      customInput={TextField}
      variant="standard"
      isNumericString={true}
      thousandSeparator={true}
      value={displayValue}
      decimalScale={2}
      fixedDecimalScale={true}
      sx={{
        color: darkMode ? "#ffffff" : "#949494",
      }}
      onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
      InputProps={{
        startAdornment: <span>{`$`}</span>,
        style: { borderBottom: darkMode && "1px solid red", color: darkMode ? "#ffffff" : "#000" },
        endAdornment: (
          <Tooltip title={props.ErrorToolTip} placement="top">
            <InputAdornment position="end">
              <ErrorOutlineIcon sx={{ fontSize: "1rem !important", mr: "14px", color: "red" }} />
            </InputAdornment>
          </Tooltip>
        ),
      }}
      {...props}
    />
  ) : (
    <NumberFormat
      customInput={TextField}
      variant="standard"
      isNumericString={true}
      thousandSeparator={true}
      value={displayValue}
      decimalScale={2}
      disabled={disabled}
      fixedDecimalScale={true}
      sx={{color: darkMode ? "#ffffff" : disabled ? "#7a7a7acc" : '#7a7a7a'}}
      onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
      InputLabelProps={{ style: { color: darkMode ? "#ffffff" : disabled ? "#7a7a7acc" : '#7a7a7a' } }}
      InputProps={{
        startAdornment: <span>{`$`}</span>,
        style: { borderBottom: darkMode
          ? disabled ?  "1px dotted #ffffff" : "1px solid #ffffff"// White border in dark mode
          : disabled
          ? "1px dotted #9e9e9e" // Gray dotted border when not editable
          : "0px solid #000", color: darkMode ? "#ffffff" : disabled ? "#bfbdbd" : '#837c7c' },
      }}
      {...props}
    />
  );
};

export default CurrencyFieldText;
