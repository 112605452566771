import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Field, Form, Formik } from "formik";
import NumberFieldText from "layouts/accountants/user/components/NumberField";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { AddNewItem, GetCategoryList, GetClassesList, GetCustomerList } from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import CurrencyFieldText from "../components/CurrencyField-Text";

function AddCategoryItemDialog({invoiceId, documentId, onClose, currencySymbol, businessId, itemList, showClasses}) {
  const [invoiceIds, setInvoiceIds] = useState(invoiceId);
  const [isDisable, setBtnDisable] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [price, setPrice] = useState(0);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [classIdIds, setClassIdId] = useState("");
  const [priceerror, setPriceerror] = useState(false);
  const [customerIds, setCustomerIdId] = useState("");
  const [categoryIds, setCategoryIds] = useState("");
  const [adjustInvoice, setAdjustInvoice] = useState(false);
  const [categoryerrormsg, setCategoryerrormsg] = useState("");
  const [categoryerror, setCategoryerror] = useState(false);
  const [quantityerror, setQuantityrror] = useState(false);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [className, setClassName] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
  const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
  const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
  const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },CategoryListAPICall ] = useAPICall(GetCategoryList, { onLoad: false });

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(async () => {
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  const initialValues = {
    product: "",
    checked: "",
    item: description,
    sku: "",
    price: unitPrice,
    total: "",
    qty: "",
    services: "",
    class: "",
    customer: "",
    unit: "",
    category: "",
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      eventBus.$emit("closeErrorAddNewItemPopup", response);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeAddNewItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          customerId: prop._id,
        };
      });
      setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      setCategoryList(data);
    }
  }, [CategoryResponse]);

  const handleSubmit = async (values) => {
    if (categoryIds) {
      setCategoryerrormsg("");
      setCategoryerror(false);
      setBtnDisable(true);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
      return;
    }

    const finalprice = typeof price == "string" ? price.replaceAll(",", "") : price;
    const finalTotalPrice = typeof TotalPrice == "string" ? TotalPrice.replaceAll(",", "") : TotalPrice;
    const finalquantity = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;

    await setAPICall({
      payload: [
        {
          itemType: "AccountBasedExpenseLineDetail",
          unitOfMeasure: values.unit,
          discount: 0,
          description: values.item,
          documentId: `${invoiceIds}`,
          classId: classIdIds,
          customerId: customerIds,
          categoryId: categoryIds,
          adjustDocument: adjustInvoice,
          unitPrice: Number(finalprice),
          amount: Number(finalTotalPrice),
          quantity: Number(finalquantity),
        },
      ],
    });
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleChangeCategory = (event, newValue) => {
    if (newValue) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }

    setCategoryName(newValue);
    const found = categoryList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setCategoryIds(found.categoryId);
      setDescription(found.description);
      setUnitPrice(found.unitPrice);
    }
  };

  const handleChangeClass = (event, newValue) => {
    setClassName(newValue);
    const found1 = classesList.find((obj) => {
      return obj.name === newValue;
    });
    if (found1) {
      setClassIdId(found1.classId);
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    setCustomerName(newValue);
    const found2 = customerList.find((obj) => {
      return obj.name === newValue;
    });
    if (found2) {
      setCustomerIdId(found2.customerId);
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={2} pt={2}>
                  <MDBox>
                    <MDBox>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                             sx={
                              darkMode
                                ? {
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white",
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                      {
                                        color: "white !important",
                                      },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                      {
                                        color: "white !important",
                                      },
                                  }
                                : {
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                  }
                            }
                            value={{ name: categoryName }}
                            options={categoryList}
                            getOptionLabel={(option) => option.name}
                            onInputChange={(event, newInputValue) => {
                              handleChangeCategory(event, newInputValue);
                            }}
                            InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                            id="controllable-states-demo"
                            disableCloseOnSelect={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category *"
                                variant="standard"
                                // helperText={categoryerrormsg}
                                // error={categoryerror}
                                InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                              />
                            )}
                          />
                          {categoryerror && (
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {categoryerrormsg}
                            </MDTypography>
                          </MDBox>
                        )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                             sx={
                              darkMode
                                ? {
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white",
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                      {
                                        color: "white !important",
                                      },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                      {
                                        color: "white !important",
                                      },
                                  }
                                : {
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                  }
                            }
                            options={customerList}
                            value={{ name: customerName }}
                            getOptionLabel={(option) => option.name}
                            onInputChange={(event, newInputValue) => {
                              handleChangeCustomer(event, newInputValue);
                            }}
                            InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                            id="controllable-states-demo"
                            disableCloseOnSelect={false}
                            renderInput={(params) => (
                              <TextField {...params} label="Customer" variant="standard" InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }} />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses ? (
                          <>
                            <Grid item xs={12} sm={6} mt={0.5}>
                              <Autocomplete
                               sx={
                                darkMode
                                  ? {
                                      "& .MuiInput-underline": {
                                        borderBottom: "2px solid #ffffff",
                                      },
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                      "& .MuiInput-root .MuiInput-input": {
                                        WebkitTextFillColor: "white",
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                        {
                                          color: "white !important",
                                        },
                                      "& .MuiInput-input": {
                                        padding: `6px 4px 4px 0px !important`,
                                      },
                                      "& .MuiInputBase-input": {
                                        height: `1.2em !important`,
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                        {
                                          color: "white !important",
                                        },
                                    }
                                  : {
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                      "& .MuiInput-input": {
                                        padding: `6px 4px 4px 0px !important`,
                                      },
                                      "& .MuiInputBase-input": {
                                        height: `1.2em !important`,
                                      },
                                    }
                              }
                                value={{ name: className }}
                                options={classesList}
                                getOptionLabel={(option) => option.name}
                                onInputChange={(event, newInputValue) => {
                                  handleChangeClass(event, newInputValue);
                                }}
                                InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                id="controllable-states-demo"
                                disableCloseOnSelect={false}
                                renderInput={(params) => (
                                  <TextField {...params} label="Class" variant="standard" InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" }} } />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={-0.5}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Unit" name="unit" fullWidth />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses && (
                          <>
                            <Grid item xs={12} sm={6} mt={-0.7}>
                              <FormField type="text" label="Unit" name="unit" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CurrencyFieldText
                                label="Price"
                                name="price"
                                value={price}
                                fullWidth
                                error={priceerror}
                                onValueChange={(event) => {
                                  setPrice(event.value);
                                }}
                              />
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  {priceErrorMsg}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {!showClasses && (
                          <Grid item xs={12} sm={6} mt={1}>
                            <CurrencyFieldText
                              label="Price"
                              name="price"
                              value={price}
                              fullWidth
                              // error={priceerror}
                              onValueChange={(event) => {
                                setPrice(event.value);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {priceErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {!showClasses && (
                          <Grid item xs={12} sm={6} mt={1}>
                            <NumberFieldText
                              label="Quantity"
                              name="qty"
                              fullWidth
                              // error={quantityerror}
                              value={quantity}
                              onValueChange={(event) => {
                                setQuantity(event.value);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {showClasses && (
                          <Grid item xs={12} sm={6}>
                            <NumberFieldText
                              label="Quantity *"
                              name="qty"
                              fullWidth
                              error={quantityerror}
                              value={quantity}
                              onValueChange={(event) => {
                                setQuantity(event.value);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {showClasses && (
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {!showClasses && (
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDBox>
                          <Field
                            as={FormControlLabel}
                            type="checkbox"
                            name="checkbox"
                            control={<Checkbox />}
                            label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</span>}
                            checked={adjustInvoice}
                            onChange={handleChange}
                            sx={{ mt: 2 }}
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                    <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="light"
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          // disabled={isDisable || errorButtonDis}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {isDisable ? "Submitting..." : "Submit"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
    </>
  );
}

export default AddCategoryItemDialog;
