import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicLayout from "layouts/auth/components/BasicLayout";
import bgImage from "assets/images/backgroundimage.jpg";

const cookies = new Cookies();

function AccountantLogout() {
  const navigate = useNavigate();
  const allCookies = cookies.getAll();

  // Iterate over all cookies and remove each one
  Object.keys(allCookies).forEach((cookieName) => {
    cookies.remove(cookieName, { path: "/" }); // Use the appropriate path if needed
  });
  useEffect(() => {
    cookies.remove("user_access_token", { path: "/" });
    cookies.remove("user_permissions", { path: "/" });
    cookies.remove("user_profile_info", { path: "/" });
    cookies.remove("backup_remind_add_client", { path: "/" });
    cookies.remove("remind_add_client", { path: "/" });
    cookies.remove("admin_profile_info", { path: "/" });
    cookies.remove("user_role", { path: "/" });
    cookies.remove("user_type", { path: "/" });
    localStorage.removeItem("ShowBilling");
    localStorage.clear();
  }, []);
  setTimeout(() => {
    navigate("/sign-in");
  }, 1000);
  return <BasicLayout image={bgImage}></BasicLayout>;
}

export default AccountantLogout;
