import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import { DeleteInvoice, GetInvoiceDetails, GetItemList, UpdateInvoiceDetails } from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import EditVendorDialog from "./editvendordialog";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ErrorEditInvoiceDialog({ invoiceId, businessId }) {
  const [invoice_id, setInvoiceId] = useState(invoiceId);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, {onLoad: false});
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse },DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [{ isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
  const [{isLoading: isDeleteInvoiceLoading,isError: isDeleteInvoiceError, response: DeleteInvoiceResponse,},DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
      setLoading(true);
    }
  }, [DeleteInvoiceResponse]);

  useEffect(() => {
    if (isGetItemListLoading) {
      return () => null;
    }
    if (isGetItemListError) {
      return () => null;
    }
    if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
      const data = GetItemListResponse.data.data.records.map((prop, key) => {
        return {
          item: prop.description,
          qty: prop.quantity,
          unit: prop.unit_of_measure,
          price: prop.unit_price,
          total: prop.total,
          id: prop._id,
          sku: prop.sku,
          action: (
            <MDBox>
              <MDTypography variant="body2" color="secondary">
                <Tooltip
                  title="edit"
                  placement="top"
                  sx={{ cursor: "pointer", ml: 2 }}
                  onClick={(value) => {
                    // setAccountantId(prop._id);
                    handleEditItem();
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setItemList(data);
      setLoading(false);
    }
  }, [GetItemListResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      setLoading(true);
    }
  }, [response]);

  useEffect(async () => {
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }, []);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAddItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAddItem]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setCreateObjectURL(DetailsResponse.data.data.invoice.uploadedFileUrl);
      setShowDetails(true);
      setLoading(false);
    }
  }, [DetailsResponse]);

  const handleEditItem = () => {
    setOpenEditItem(true);
  };

  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };
  const successInoiceDelete = () => {
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: [invoiceId],
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setLoading(true);
  };
 
  eventBus.$on("closeAddNewItemPopup", (data) => fetchNewInvoicesAdd(data));

  function fetchNewInvoicesAdd(data) {
    setOpenAddItem(false);
    setLoading(true);

    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeEditItemPopup", (data) => fetchNewInvoices(data));

  function fetchNewInvoices(data) {
    setOpenEditItem(false);
    setLoading(true);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  return (
    <>
      {!showDetails ? (
        <>
          <center>
            <CircularProgress
              size={50}
              sx={{ color: green[500], position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}}
            />
          </center>
        </>
      ) : (
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={5}>
              <Card sx={{ height: "100%" }}>
                {createObjectURL ? (
                  <embed src={createObjectURL + "#toolbar=0"} height="600" />
                ) : (
                  <h4>You can only add image after you save changes</h4>
                )}
              </Card>
              {<center>1 of 1</center>}
            </Grid>
            <Grid item xs={12} sm={6} lg={7}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30%",
                    }}
                  >
                    <MDTypography variant="h2" color="error">
                      Failed to fetch data please retry
                    </MDTypography>
                  </MDBox>
                </CardContent>
              </Card>

              <MDBox>
                {loading && (
                  <CircularProgress
                    size={50}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
          <MDBox>
            <MDBox>
              <Dialog
                open={openEditVendor}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                    },
                  },
                }}
              >
                <DialogContent>
                  <EditVendorDialog
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenEditVendor(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
          </MDBox>
          <MDBox>
            <Dialog
              open={alertDeleteInvoice}
              onClose={hideInvoiceAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    height: "45%",
                    maxWidth: "450px",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                  >
                    Once you delete this Invoice there is no way of getting any of the documents or
                    data back.
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                  Yes, delete it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default ErrorEditInvoiceDialog;
