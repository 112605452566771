import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Form, Formik } from "formik";
import BasicLayout from "layouts/auth/components/BasicLayout";
import FormField from "layouts/auth/components/Admin/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { CreatePassSchema } from "layouts/auth/components/schemas/validations";
import bgImage from "assets/images/backgroundimage.jpg";
import ReactRecaptcha3 from "react-google-recaptcha3";
import Cookies from "universal-cookie";
import { CreateAccountantPassword, VerifyToken } from "../../../services/authservices";
import { useAPICall } from "../../../utils/useapicall";
const cookies = new Cookies();

function UserCreatePassword() {
  const navigate = useNavigate();
  const [pathKey, setPathKey] = useState();
  const [pathToken, setPathToken] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("Failed");
  const [successMessage, setSuccessMessage] = useState("");
  const [googleToken, setGoogleToken] = useState("");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(CreateAccountantPassword, {onLoad: false});
  const [{ isLoading: isCheckTokenLoading, isError: isCheckTokenError, response: CheckTokenResponse },CheckTokenAPICall] = useAPICall(VerifyToken, { onLoad: false });
  const handleClickShowPassword = () => setShowPassword(true);
  const handleClickClosePassword = () => setShowPassword(false);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword(true);
  const handleClickCloseRepeatPassword = () => setShowRepeatPassword(false);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setIsDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      if (response?.data?.message) {
        setSuccessMessage(response.data.message);
      }
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setTimeout(() => {
        cookies.remove("user_access_token", { path: "/" });
        cookies.remove("user_permissions", { path: "/" });
        cookies.remove("user_profile_info", { path: "/" });
        cookies.remove("backup_remind_add_client", { path: "/" });
        cookies.remove("remind_add_client", { path: "/" });
        cookies.remove("admin_profile_info", { path: "/" });
        cookies.remove("user_role", { path: "/" });
        cookies.remove("user_type", { path: "/" });
        cookies.remove('user_access_token', { path: '/' });
        cookies.remove('client_profile_info', { path: '/' });
        cookies.remove('client_permissions', { path: '/' });
        localStorage.removeItem("ShowBilling");
        localStorage.clear();
        navigate("/sign-in");
      }, 2000);
    }
  }, [response]);

  useEffect(() => {
    if (isCheckTokenLoading) {
      return () => null;
    }

    if (isCheckTokenError) {
      navigate("/sign-in");
      return () => null;
    }

    if (CheckTokenResponse && CheckTokenResponse.data.isSuccess === true) {
      setLoading(false);
      setEmailId(CheckTokenResponse.data.data.email);
    }
  }, [CheckTokenResponse]);

  useEffect(async () => {
    const Key = new URLSearchParams(location.search).get("key");
    setPathKey(Key);
    const token = new URLSearchParams(location.search).get("token");
    setPathToken(token);
    setLoading(true);
    setTimeout(() => {
      ReactRecaptcha3.getToken().then(
        (resp) => {
          CheckTokenAPICall({
            payload: [
              {
                token: `${token}`,
                siteToken: `${resp}`,
              },
            ],
          });
          setGoogleToken(resp);
        },
        (error) => {
          console.log(error);
          setErrorMessage("Google reCAPTCHA verification failed.");
          setShowErrorSnackbar(true);
          setTimeout(() => {
            setShowErrorSnackbar(false);
          }, 3000);
        }
      );
    }, 3000);
  }, []);

  const onSubmit = async (values) => {
    setIsDisable(true);
    ReactRecaptcha3.getToken().then(
      (resp) => {
        setAPICall({
          payload: [
            {
              domainKey: `${pathKey}`,
              email: `${emailId}`,
              token: `${pathToken}`,
              password: `${values.password}`,
              siteToken: `${resp}`,
            },
          ],
        });
        setGoogleToken(resp);
      },
      (error) => {
        console.log(error);
        setIsDisable(false);
        setErrorMessage("Google reCAPTCHA verification failed.");
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
      }
    );
  };

  return (
    <BasicLayout image={bgImage}>
      {!loading && (
        <Card>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            sx={{
              backgroundColor: "#80D6C3",
            }}
            textAlign="center"
          >
            <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
              Welcome to Paynthr
            </MDTypography>
            <MDTypography fontWeight="200" color="white" fontSize="small" textAlign="center">
              Create New Password
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={CreatePassSchema}
            onSubmit={onSubmit}
          >
            <Form autoComplete="off">
              <div>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox mb={2}>
                    <FormField
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ fontSize: "0.9rem" }}>
                              {!showPassword ? (
                                <VisibilityOff onClick={handleClickShowPassword} />
                              ) : (
                                <Visibility onClick={handleClickClosePassword} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <FormField
                      type={showRepeatPassword ? "text" : "password"}
                      label="Confirm Password"
                      name="repeatPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ fontSize: "0.9rem" }}>
                              {!showRepeatPassword ? (
                                <VisibilityOff onClick={handleClickShowRepeatPassword} />
                              ) : (
                                <Visibility onClick={handleClickCloseRepeatPassword} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={isDisable}
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      {isDisable ? "Generating..." : "create"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </div>
            </Form>
          </Formik>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              // autoHideDuration={3000}
              content={successMessage}
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              // autoHideDuration={3000}
              title="Failed."
              content={ErrorMessage}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </Card>
      )}

      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </BasicLayout>
  );
}

export default UserCreatePassword;
