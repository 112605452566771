import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Autocomplete, Button, FormControl, Icon, Modal, Snackbar, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { green, red, yellow } from "@mui/material/colors";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import "layouts/accountants/user/invoice/styles.css";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {DeleteInventory, ExportDocument, GetInventoryList, GetLocationDetails, GetRestaurantLocationList, GetRestaurantdetails, GetclientRestaurantNoLimitList,LocationInventoryStop, LocationInventoryStrat} from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import DetailsInventoryDialog from "./DetailsInventoryDialog";
import CheckItemExistDialog from "./checkItemExist";
import EditInventoryDialog from "./editInventory";
import ImportCsvDialog from "./importCsv";
import InventoryList from "./inventoryList";
import RapidInventoryList from "./rapidInventoryList.js";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./styles.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0",
  boxShadow: 24,
  outline: "none",
  p: "16px 24px 8px 24px",
};

const darkModeStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: colors.background.card,
  border: "0",
  boxShadow: 24,
  outline: "none",
  p: "16px 24px 8px 24px",
  "& .MuiInputLabel-root": {
    color: "#fff", // Label text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff", // Underline color when not focused
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff", // Underline color when focused
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: "#fff", // Color of the arrow icon
  },
};

const styleInventory = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (max-width: 460px)": {
    width: "370px",
  },
  width: 500,
  height: "auto",
  bgcolor: "background.paper",
  border: "0",
  boxShadow: 24,
  outline: "none",
  p: 3,
};

const styleInventoryDark = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (max-width: 460px)": {
    width: "370px",
  },
  width: 500,
  height: "auto",
  bgcolor: "#202940",
  border: "0",
  boxShadow: 24,
  outline: "none",
  p: 3,
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: "20%",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  "@media(max-width: 440px)": {
    tabs: {
      "& button": {
        padding: 8,
        paddingLeft: "5px !important",
        paddingRight: "6px !important",
        fontSize: `12px !important`,
        fontWeight: 400,
        textTransform: "none",
        color: theme.palette.text.primary,
        border: "1px solid gray",
        borderRadius: "1px !important",
      },

      "& button[aria-selected='true']": {
        position: "relative",
        color: "white !important",
        border: "0px solid gray",
        background: "green",

        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          border: "1px solid black",
          zIndex: 0,
        },

        "& > *": { zIndex: 0 },
        "& > .MuiTab-wrapper": {
          background: "#fff",
          height: "100%",
        },
      },
    },
  },
  tabs: {
    "& button": {
      padding: 8,
      paddingLeft: "20px",
      paddingRight: "20px",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "none",
      color: theme.palette.text.primary,
      border: "1px solid gray",
      borderRadius: "1px !important",
    },

    "& button[aria-selected='true']": {
      position: "relative",
      color: "white !important",
      border: "0px solid gray",
      background: "green",

      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        border: "1px solid black",
        zIndex: 0,
      },

      "& > *": { zIndex: 0 },
      "& > .MuiTab-wrapper": {
        background: "#fff",
        height: "100%",
      },
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Inventory() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [loaderClass, setLoaderClass] = useState({});
  const { pathname } = useLocation();
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [tabValue, setTabValue] = useState("");
  const [disButton, setDisButton] = useState(false);
  const [showImportPopUp, setShowImportPopUp] = useState(false);
  const [notShowExportPopUp, setNotShowExportPopUp] = useState(false);
  const [notShowImportPopUp, setNotShowImportPopUp] = useState(false);
  const [restaurantId, setRestaurantId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [restaurantsId, setRestaurantsId] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [locationNewValue, setLocationNewValue] = useState("");
  const [openDetailsInventory, setOpenDetailsInventory] = useState(false);
  const [openAddInventory, setOpenAddInventory] = useState(false);
  const [openEditInventory, setOpenEditInventory] = useState(false);
  const [itemId, setItemId] = useState("");
  const [openSelectInvoice, setOpenSelectInvoice] = useState(false);
  const [clientNewValue, setClientNewValue] = useState("");
  const [productId, setProductId] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [productName, setProductName] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [totalLowStock, settotalLowStock] = useState(0);
  const [totalPar, settotalPar] = useState(0);
  const [totalunderpar, settotalunderpar] = useState(0);
  const [totalOutOfStock, settotalOutOfStock] = useState(0);
  const [deleteInventoryDialog, setDeleteInventoryDialog] = useState(0);
  const [clearFilter, setClearFilter] = useState(false);
  const [openRapidInventory, setOpenRapidInventory] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const [openInventoryList, setOpenInventoryList] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [showSelectInventory, setShowSelectInventory] = useState(true);
  const [showselectedInventoryrowsData, setShowselectedInventoryrowsData] = useState("");
  const [rowData, setRowData] = useState([]);
  const [restaurantData, setRestaurantData] = useState([]);
  const [openInventoryBlockedDialog, setopenInventoryBlockedDialog] = useState(false);
  const [pagePerRow, setPagePerRow] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [disableExport, setDisableExport] = useState(false);
  const [filters, setFilters] = useState({});
  const [massage, setMassage] = useState("");
  const [showSuccessImport, setShowSuccessImport] = useState(false);
  const [showSuccessExport, setShowSuccessExport] = useState(false);
  const [inventoryBlockmassage, setInventoryBlockMassage] = useState("");
  const [importBlockmassage, setImportBlockMassage] = useState("");
  const [exportBlockmassage, setExportBlockMassage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [disDoneButton, setDisDoneButton] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [totalInventoryCost, setTotalInventoryCost] = useState(0);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInventoryList, {onLoad: false});
  const [autoCompleteObj, setAutoCompleteObj] = useState([]);
  const [restaurantNewValue, setRestaurantNewValue] = useState("");
  const [InventorySelectId, setInventorySelectId] = useState([]);
  const [reloadApiImportApi, setReloadApiImportApi] = useState(0);
  const [reloadApiExportApi, setReloadApiExportApi] = useState(0);
  const [importBlock, setImportBlock] = useState(false);
  const [exportBlock, setExportBlock] = useState(false);
  const [clientId, setClientId] = useState("");
  const [savebtn, setSavebtn] = useState(false);
  const [saveAndCloseButton, setSaveAndCloseButton] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [gridApi, setGridApi] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0); // Total rows from API
  const [page, setPage] = useState(1); // Current page
  const entries =  ["10", "20", "50", "100"];
  const offset = localStorage.getItem("Inventoryoffset")
  const defaultOffset = offset ? offset : 0;
  const limit = localStorage.getItem("Inventorylimit")
  const defaultLimit = limit ? limit : 10;
  
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {window.removeEventListener('resize', handleResize);};
}, []);

  const [{isLoading: isGetLoading, isError: isGetError, response: isGetResponse },GetRestaurantAPICall] = useAPICall(GetclientRestaurantNoLimitList, { onLoad: false });
  const [{isLoading: isLocationLoading, isError: isLocationError, response: LocationResponse },LocationListAPICall] = useAPICall(GetRestaurantLocationList, { onLoad: false });
  const [{isLoading: isGetResDetailLoading, isError: isGetResDetailError, response: restaurantDataResponse},getRestaurantDetailsAPICall] = useAPICall(GetRestaurantdetails, { onLoad: false });
  const [{isLoading: isExportDocumentLoading, isError: isExportDocumentError, response: ExportDocumentResponse},getExportDocumentAPICall] = useAPICall(ExportDocument, { onLoad: false });
  const [{isLoading: isGetLocationDetailsLoading,isError: isGetLocationDetailsError,response: GetLocationDetailsResponse},GetLocationDetailsListAPICall] = useAPICall(GetLocationDetails, { onLoad: false });
  const [{isLoading: isLocationInventoryLoading,isError: isLocationInventoryError,response: LocationInventoryResponse},LocationInventoryListAPICall] = useAPICall(LocationInventoryStrat, { onLoad: false });
  const [{isLoading: isLocationInventoryStopLoading,isError: isLocationInventoryStopError,response: LocationInventoryStopResponse},LocationInventoryStopListAPICall] = useAPICall(LocationInventoryStop, { onLoad: false });
  const [{isLoading: isCheckImportCompleteLoading,isError: isCheckImportCompleteError,response: CheckImportCompleteResponse},CheckImportCompleteAPICall] = useAPICall(GetRestaurantdetails, { onLoad: false });
  const [{isLoading: isCheckExportCompleteLoading,isError: isCheckExportCompleteError,response: CheckExportCompleteResponse},CheckExportCompleteAPICall] = useAPICall(GetRestaurantdetails, { onLoad: false });
  const [{isLoading: isdeleteInventoryLoading, isError: isdeleteInventoryError, response: deleteInventoryResponse}, deleteInventoryAPICall] = useAPICall(DeleteInventory, { onLoad: false });

  const setCSSVariables = (darkMode) => {
    const root = document.documentElement;
    root.style.setProperty("--ag-header-cell", darkMode ? "#202940" : "#7b809a");
    root.style.setProperty("--theme-background-color", darkMode ? "#202940" : "#ffffff");
    root.style.setProperty("--ag-header-cell-label-color", darkMode ? "#ffffff" : "#7b809a");
  };

  setCSSVariables(darkMode);

  useEffect(async () => {
    await GetRestaurantAPICall({
      payload: [{ keyword: `` }],
    });
    setLoading(true);
    setLoadingCircul(true);
    localStorage.setItem("TabFilter", "");
  }, []);

  useEffect(() => {
    if (isdeleteInventoryLoading) {
      return () => null;
    }

    if (isdeleteInventoryError) {
      setLoadingCircul(false);
      setMassage("Failed To Delete Inventory");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }

    if (deleteInventoryResponse && deleteInventoryResponse.data.isSuccess === true) {
      const Curfilter = localStorage.getItem("TabFilter");
      const finalFilter = Curfilter ? Curfilter : tabValue;
      setMassage("Inventory Delete Successfully");
      getRestaurantDetailsAPICall({
        payload: [
          {
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
      if (Object.keys(filters).length !== 0) {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              finalQuery: filters,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
            },
          ],
        });
      } else {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
            },
          ],
        });
      }
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setLoadingCircul(false);
      setDeleteInventoryDialog(false);
    }
  }, [deleteInventoryResponse]);

  useEffect(() => {
    setSavebtn(false);
    setSaveAndCloseButton(false);
  }, []);

  useEffect(() => {
    if (isCheckExportCompleteLoading) {
      return () => null;
    }

    if (isCheckExportCompleteError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (CheckExportCompleteResponse && CheckExportCompleteResponse.data.isSuccess === true) {
      setShowSuccessExport(CheckExportCompleteResponse?.data?.data?.restaurant?.exportBlocked);
      setExportBlock(CheckExportCompleteResponse?.data?.data?.restaurant?.exportBlocked);
      if (CheckExportCompleteResponse?.data?.data?.restaurant?.exportBlocked == true) {
        setReloadApiExportApi(reloadApiExportApi + 1);
      }
      setLoadingCircul(false);
    }
  }, [CheckExportCompleteResponse]);

  useEffect(() => {
    if (isCheckImportCompleteLoading) {
      return () => null;
    }

    if (isCheckImportCompleteError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (CheckImportCompleteResponse && CheckImportCompleteResponse.data.isSuccess === true) {
      setShowSuccessImport(CheckImportCompleteResponse?.data?.data?.restaurant?.importBlocked);
      setImportBlock(CheckImportCompleteResponse?.data?.data?.restaurant?.importBlocked);
      if (CheckImportCompleteResponse?.data?.data?.restaurant?.importBlocked == true) {
        setReloadApiImportApi(reloadApiImportApi + 1);
      }
      setLoadingCircul(false);
    }
  }, [CheckImportCompleteResponse]);

  useEffect(() => {
    const checkOldState = localStorage.getItem("SuccessExport");
    const FinalCheck = checkOldState ? checkOldState : "false";
    if (CheckExportCompleteResponse?.data?.data?.restaurant?.exportBlocked == false) {
      setTimeout(() => {
        if (FinalCheck === "true") {
          localStorage.setItem("SuccessExport", false);
          setMassage(
            "Your export file has been successfully sent to your email address. Please check your inbox and download it from there."
          );
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
          }, 3000);
        }
      }, 2000);
    }
  }, [CheckExportCompleteResponse]);

  useEffect(() => {
    const checkOldState = localStorage.getItem("SuccessImport");
    const FinalCheck = checkOldState ? checkOldState : "false";
    if (CheckImportCompleteResponse?.data?.data?.restaurant?.importBlocked == false) {
      setTimeout(() => {
        if (FinalCheck === "true") {
          localStorage.setItem("SuccessImport", false);
          setMassage(
            "The import of the file was successful. Please refresh the screen to view the new data."
          );
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
          }, 5000);
        }
      }, 2000);
    }
  }, [CheckImportCompleteResponse]);

  useEffect(() => {
    if (openSelectInvoice === true) {
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [openSelectInvoice]);

  useEffect(() => {
    if (response?.data?.data?.totalRecords == 0) {
      setDisableExport(true);
    } else {
      setDisableExport(false);
    }
  }, [response]);

  var nietos = [];
  var obj = {};
  useEffect(() => {
    if (isLocationInventoryStopLoading) {
      return () => null;
    }

    if (isLocationInventoryStopError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (LocationInventoryStopResponse && LocationInventoryStopResponse.data.isSuccess === true) {
      setLoadingCircul(false);
    }
  }, [LocationInventoryStopResponse]);

  useEffect(() => {
    if (isLocationInventoryLoading) {
      return () => null;
    }

    if (isLocationInventoryError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (LocationInventoryResponse && LocationInventoryResponse.data.isSuccess === true) {
      setLoadingCircul(false);
    }
  }, [LocationInventoryResponse]);

  useEffect(() => {
    if (isGetLocationDetailsLoading) {
      return () => null;
    }

    if (isGetLocationDetailsError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (GetLocationDetailsResponse && GetLocationDetailsResponse.data.isSuccess === true) {
      setInventoryBlockMassage(GetLocationDetailsResponse?.data?.data?.location?.lastUpdateMessage);
      if (GetLocationDetailsResponse?.data?.data?.location?.inventoryBlocked === true) {
        setopenInventoryBlockedDialog(true);
      } else {
        LocationInventoryListAPICall({
          payload: [
            {
              locationId: locationId,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantsId,
              start: true,
            },
          ],
        });
        setopenInventoryBlockedDialog(false);
        setOpenRapidInventory(false);
        setOpenInventoryList(true);
      }
      setLoadingCircul(false);
    }
  }, [GetLocationDetailsResponse]);

  useEffect(() => {
    if (totalRecords > pagePerRow) {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
  }, [pagePerRow, response]);

  useEffect(() => {
    if (isExportDocumentLoading) {
      return () => null;
    }

    if (isExportDocumentError) {
      setLoadingCircul(false);
      setShowExportModal(false);
      setMassage(ExportDocumentResponse?.data?.data?.message);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }

    if (ExportDocumentResponse && ExportDocumentResponse.data.isSuccess === true) {
      setLoadingCircul(false);
      setShowExportModal(true);
      setShowSuccessExport(true);
      localStorage.setItem("SuccessExport", true);
      CheckExportCompleteAPICall({
        payload: [{ restaurantId: restaurantId ? restaurantId : restaurantsId }],
      });
    }
  }, [ExportDocumentResponse]);

  useEffect(() => {
    if (isGetResDetailLoading) {
      return () => null;
    }

    if (isGetResDetailError) {
      return () => null;
    }
    let count = 0;
    if (restaurantDataResponse && restaurantDataResponse.data.isSuccess === true) {
      const restaurantresult = restaurantDataResponse?.data?.data?.restaurant;
      setClientId(restaurantresult?.business?._id);
      setImportBlockMassage(restaurantresult?.importData?.message);
      setExportBlockMassage(restaurantresult?.exportData?.message);
      settotalLowStock(restaurantresult?.totalLowStock);
      settotalPar(restaurantresult?.totalPar);
      settotalOutOfStock(restaurantresult?.totalOutOfStock);
      settotalunderpar(restaurantresult?.totalUnderPar);
      setImportBlock(restaurantresult?.importBlocked);
      setExportBlock(restaurantresult?.exportBlocked);
      setTimeout(() => {
        setShowSuccessExport(restaurantresult?.exportBlocked);
        setShowSuccessImport(restaurantresult?.importBlocked);
      }, 1000);
      if (count == 0) {
        localStorage.setItem("SuccessImport", restaurantresult?.importBlocked);
        localStorage.setItem("SuccessExport", restaurantresult?.exportBlocked);
        count = count + 1;
      }
    }
  }, [restaurantDataResponse]);

  useEffect(() => {
    if (restaurantId == ":id") {
      setRestaurantId(location.pathname.split("/").slice(-2)[0]);
    }
  }, []);

  let timeoutFilter = null;

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map((key) => {
      let finalVal = obj[key] ? obj[key] : null;
      return encodeURIComponent(key) + "=" + encodeURIComponent(finalVal);
    });
    return keyValuePairs.join("&");
  }
  const SliderFloatingFilter = (props, ref) => {
    const [currentValue, setCurrentValue] = useState("");
    const valueChanged = (event) => {
      clearTimeout(timeoutFilter);
      setCurrentValue(event.target.value);
      if (props?.filterName == "keyword") {
        obj["keyword"] = event.target.value.trim();
      } else if (props?.filterName == "parFlag") {
        setLoadingCircul(true);
        obj["parFlag"] = event.target.value.trim();
      } else if (props?.filterName == "unit") {
        setLoadingCircul(true);
        obj["unit"] = event.target.value.trim();
      } else if (props?.filterName == "unitPrice") {
        setLoadingCircul(true);
        obj["unitPrice"] = event.target.value.trim();
      } else if (props?.filterName == "lastPurchaseDate") {
        setLoadingCircul(true);
        obj["lastPurchaseDate"] = event.target.value.trim();
      } else if (props?.filterName == "currentInventory") {
        setLoadingCircul(true);
        obj["currentInventory"] = event.target.value.trim();
      } else if (props?.filterName == "estimatedSupplyDate") {
        setLoadingCircul(true);
        obj["estimatedSupplyDate"] = event.target.value.trim();
      } else if (props?.filterName == "location") {
        setLoadingCircul(true);
        obj["location"] = event.target.value.trim();
      } else if (props?.filterName == "lowStockFlag") {
        setLoadingCircul(true);
        obj["lowStockFlag"] = event.target.value.trim();
      }

      if (props?.filterName == "unitPrice" || props?.filterName == "keyword" || props?.filterName == "parFlag" || props?.filterName == "unit" || props?.filterName == "lastPurchaseDate" || props?.filterName == "currentInventory" || props?.filterName == "estimatedSupplyDate" || props?.filterName == "location" || props?.filterName == "lowStockFlag" )
        {
        nietos.push(obj);
        let finalQuery = objectToQueryString(...new Set(nietos));
        localStorage.setItem("finalQuery",finalQuery);
        setFilters(finalQuery);
        setLoadingCircul(true);
        const tabValues = localStorage.getItem("TabFilter");
        if (tabValues !== null) {
          timeoutFilter = setTimeout(function () {
            setAPICall({
              payload: [
                {
                  offset: defaultOffset,
                  limit: defaultLimit,
                  moveTabs: tabValues && `${tabValues}=true`,
                  restaurantId: restaurantId ? restaurantId : restaurantsId,
                  finalQuery: finalQuery,
                },
              ],
            });
          }, 1000);
        } else if (tabValues.length === 0) {
          timeoutFilter = setTimeout(function () {
            setAPICall({
              payload: [
                {
                  restaurantId: restaurantId ? restaurantId : restaurantsId,
                  finalQuery: finalQuery,
                  offset: defaultOffset,
                  limit: defaultLimit,
                },
              ],
            });
          }, 1000);
        }
      }
    };

    return (
      <input
        type="text"
        value={currentValue}
        onChange={() => valueChanged(event)}
        style={{
          width: "100%",
          padding: "6px",
          minHeight: "28px",
          fontSize: "inherit",
          lineHeight: "inherit",
          borderRadius: "3px",
          color: darkMode ? "white" : "inherit",
          border: `0.5px solid #babfc7`,
        }}
      />
    );
  };

  useEffect(() => {
    if (isGetLoading) {
      return () => null;
    }
    if (isGetError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (isGetResponse && isGetResponse.data.isSuccess === true) {
      setRestaurantData(isGetResponse?.data?.data?.records);
      const foundName = isGetResponse?.data.data.records.find((obj) => {
        return obj._id === restaurantId;
      });
      if (foundName?.name) {
        setShowselectedInventoryrowsData(foundName?.name);
      }

      if (foundName?.business?._id) {
        setBusinessId(foundName?.business?._id);
      }
      const array = isGetResponse?.data.data.records.map((obj) => Object.values(obj));
      var obj = array.map((x) => ({
        label: x[1],
        id: x[0],
        Business: x[2],
      }));
      if (obj.length === 1) {
        setOpenSelectInvoice(false);
        setRestaurantNewValue(obj[0]?.label);
        navigate(`/client/inventory/${obj[0]?.id}/list`);
        setRestaurantsId(obj[0]?.id);
        setClientNewValue(obj[0]?.label);
        setBusinessId(obj[0]?.Business?._id);
        setShowselectedInventoryrowsData(obj[0]?.label);
      }
      setAutoCompleteObj(obj);
    }
  }, [isGetResponse]);

  function actionCellRenderer(params) {
    return (
      <MDBox>
        <MDTypography
          variant="caption"
          fontWeight="regular"
          fontSize="0.875rem"
          color="info"
          sx={{ 
            cursor: "pointer" ,
            "&:hover": {
                color: "#007bff", // Change to blue on hover
              },
          }}
          onClick={async (value) => {
            setProductName(params?.data?.itemName);
            setProductId(params?.data?.productId);
            setInventoryId(params?.data?.id);
            setLocationId(params?.data?.LocationId);
            setOpenEditInventory(true);
          }}
        >
          {params?.data?.itemName}
        </MDTypography>
      </MDBox>
    );
  }

  function LoctionCellRenderer(params) {
    return (
      <MDBox>
        <MDTypography
          variant="caption"
          fontWeight="regular"
          fontSize="0.875rem"
          color="info"
          sx={{ cursor: "pointer",
            "&:hover": {
                color: "#007bff", // Change to blue on hover
              },
           }}
          onClick={async (value) => {
            setLoadingCircul(true);
            setLocationId(params?.data?.LocationId);
            setLocationNewValue(params?.data?.location);
            await LocationInventoryListAPICall({
              payload: [
                {
                  locationId: params?.data?.LocationId,
                  restaurantId: location.pathname.split("/").slice(-2)[0]
                    ? location.pathname.split("/").slice(-2)[0]
                    : restaurantsId,
                  start: true,
                },
              ],
            });
            await GetLocationDetailsListAPICall({
              payload: [
                {
                  locationId: params?.data?.LocationId,
                  restaurantId: location.pathname.split("/").slice(-2)[0]
                    ? location.pathname.split("/").slice(-2)[0]
                    : restaurantsId,
                },
              ],
            });
          }}
        >
          {params?.data?.location}
        </MDTypography>
      </MDBox>
    );
  }

  const [columnOrder, setColumnOrder] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "itemName",
      headerName: "ITEM NAME",
      colId: "itemName",
      minWidth: 180,
      cellRenderer: actionCellRenderer,
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "keyword",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "currentInventory",
      headerName: "CURRENT INVENTORY",
      minWidth: 170,
      colId: "currentInventory",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "currentInventory",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "inventoryUnits",
      headerName: "UNIT OF MEASURE",
      minWidth: 160,
      colId: "inventoryUnits",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "unit",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "unitPrice",
      headerName: "UNIT PRICE",
      minWidth: 170,
      colId: "unitPrice",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "unitPrice",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "lowStockFlag",
      headerName: "LOW STOCK FLAG",
      minWidth: 140,
      colId: "lowStockFlag",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "lowStockFlag",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "parFlag",
      headerName: "PAR FLAG",
      minWidth: 100,
      colId: "parFlag",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "parFlag",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: 200,
      allowResize: true,
      colId: "location",
      cellRenderer: LoctionCellRenderer,
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "location",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "estimateddaysofSupply",
      headerName: "ESTIMATED DAYS OF SUPPLY",
      colId: "estimateddaysofSupply",
      minWidth: 210,
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "estimatedSupplyDate",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
    {
      field: "lastPurchaseDate",
      headerName: "LAST PURCHASE DATE",
      minWidth: 190,
      colId: "lastPurchaseDate",
      floatingFilterComponent: SliderFloatingFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        filterName: "lastPurchaseDate",
      },
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "0.875rem",
        fontWeight: "400",
        color: darkMode ? "#ffffff" : "#7b809a",
      }),
    },
  ]);

  const sortedColumnDefs = columnOrder.map((colId) =>
    columnDefs.find((col) => col.colId === colId.colId)
  );

  useEffect(async () => {
    if (location.pathname.split("/").slice(-2)[0] !== ":id") {
      setLoadingCircul(true);
      setLoading(true);
      const savedColumnOrder = JSON.parse(localStorage.getItem("columnOrder"));
      if (savedColumnOrder) {
        setColumnOrder(savedColumnOrder);
      }
      if (location.pathname.split("/").slice(-2)[0] == ":id") {
        if (autoCompleteObj.length == 1) {
          setOpenSelectInvoice(false);
          setLoading(false);
          setLoadingCircul(false);
        } else {
          setOpenSelectInvoice(true);
        }
      } else if (restaurantData) {
        const foundName = restaurantData.find((obj) => {
          return obj._id === location.pathname.split("/").slice(-2)[0];
        });
        setShowselectedInventoryrowsData(foundName?.name);
      }
      const Curfilter = localStorage.getItem("TabFilter");
      const finalFilter = Curfilter ? Curfilter : tabValue;
      if (Object.keys(filters).length !== 0) {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              finalQuery: filters,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantsId,
              keyword: `${search}`,
            },
          ],
        });
      } else {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantsId,
              keyword: `${search}`,
            },
          ],
        });
      }
      await LocationListAPICall({
        payload: [
          { inventoryBlocked: false, businessId: restaurantId ? restaurantId : restaurantsId },
        ],
      });
      await getRestaurantDetailsAPICall({
        payload: [{ restaurantId: restaurantId ? restaurantId : restaurantsId }],
      });
    } else {
      setTimeout(() => {
        if (location.pathname.split("/").slice(-2)[0] == ":id") {
          if (autoCompleteObj.length == 1) {
            setOpenSelectInvoice(false);
            setLoading(false);
            setLoadingCircul(false);
          } else {
            setOpenSelectInvoice(true);
          }
        }
      }, 2000);
    }
  }, [restaurantId, restaurantsId]);

  useEffect(async () => {
    await LocationListAPICall({
      payload: [
        { inventoryBlocked: false, businessId: restaurantId ? restaurantId : restaurantsId },
      ],
    });
    await getRestaurantDetailsAPICall({
      payload: [
        {
          restaurantId: restaurantId ? restaurantId : restaurantsId,
        },
      ],
    });
  }, [restaurantsId]);

  useEffect(() => {
    if (isLocationLoading) {
      return () => null;
    }
    if (isLocationError) {
      setLoadingCircul(false);
      return () => null;
    }
    if (LocationResponse && LocationResponse.data.isSuccess === true) {
      const data = LocationResponse?.data?.data?.records.map((prop, key) => {
        return {
          name: prop?.name,
          locationId: prop?._id,
          inventoryBlocked: prop?.inventoryBlocked,
        };
      });
      setLocationList(data);
      setLoadingCircul(false);
    }
  }, [LocationResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      const data = response?.data?.data?.records.map((prop, key) => {
        setTotalInventoryCost(response?.data?.data?.totalInventoryCost);
        setTotalRecords(response?.data?.data?.totalRecords);
        return {
          id: prop?._id,
          itemName: prop?.name ? prop?.name : "-",
          currentInventory: prop?.currentInventory ? prop?.currentInventory : "-",
          updateInventory: prop?.currentInventory ? prop?.currentInventory : "-",
          inventoryUnits: prop?.unit?.name ? prop?.unit?.name : "-",
          lowStockFlag: prop?.lowStockFlag ? prop?.lowStockFlag : "-",
          unitPrice: prop?.unitPrice ? `$${prop?.unitPrice}` : "-",
          parFlag: prop?.parFlag ? prop?.parFlag : "-",
          location: prop?.location?.name ? prop?.location?.name : "-",
          LocationId: prop?.location?._id ? prop?.location?._id : "",
          productId: prop?.productId,
          estimateddaysofSupply: prop?.estimatedSupplyDate ? prop?.estimatedSupplyDate : "-",
          lastPurchaseDate: prop?.lastPurchaseDate ? prop?.lastPurchaseDate : "-",
        };
      });
      setRowData(data);
      setTotalRows(response?.data?.data?.totalRecords);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [response]);

  let timeoutExport = null;

  // stop reload api
  useEffect(() => {
    if (showSuccessExport == true) {
      clearTimeout(timeoutExport);
      const fetchData = async () => {
        await CheckExportCompleteAPICall({
          payload: [{ restaurantId: restaurantId ? restaurantId : restaurantsId }],
        });
      };

      timeoutExport = setTimeout(() => {
        fetchData();
      }, 30000);
    }
  }, [showSuccessExport, reloadApiExportApi]);

  let timeout = null;

  useEffect(() => {
    if (showSuccessImport == true) {
      clearTimeout(timeout);
      const fetchData = async () => {
        await CheckImportCompleteAPICall({
          payload: [{ restaurantId: restaurantId ? restaurantId : restaurantsId }],
        });
      };

      timeout = setTimeout(() => {
        fetchData();
      }, 30000);
    }
  }, [showSuccessImport, reloadApiImportApi]);

  const handleAddinventory = () => {
    setLoadingCircul(true);
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    }
    LocationListAPICall({
      payload: [
        {
          inventoryBlocked: false,
          businessId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    getRestaurantDetailsAPICall({
      payload: [
        {
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    setOpenAddInventory(false);
    setOpenEditInventory(false);
  };

  eventBus.$on("editItemSucess", (data) => editItemSucess(data));
  function editItemSucess(data) {
    if (data == true) {
      setMassage("Item Update Successfully");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    } else {
      setMassage("Failed to Update Item");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
    }
  }

  eventBus.$on("editInventoryFound", (data) => editInventoryFound(data));
  function editInventoryFound(data) {
    setProductName(data?.data?.data?.product?.name);
    setProductId(data?.data?.data?.product?._id);
    setBusinessId(data?.data?.data?.product?.business?.id);
    setOpenEditInventory(true);
  }

  eventBus.$on("deleteItem", (data) => deleteItem(data));
  function deleteItem(data) {
    setLoading(true);
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    setMassage("Inventory Delete Successfully");
    getRestaurantDetailsAPICall({
      payload: [
        {
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantId,
        },
      ],
    });
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    }
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
    setOpenEditInventory(false);
    setLoadingCircul(false);
    setDeleteInventoryDialog(false);
  }

  eventBus.$on("deleteItemFailed", (data) => deleteItemFailed(data));
  function deleteItemFailed(data) {
    setLoadingCircul(false);
    setMassage("Failed To Delete Inventory");
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeImportFilePopup", (data) => closeImportFilePopup(data));
  function closeImportFilePopup(data) {
    setShowSuccessImport(true);
    localStorage.setItem("SuccessImport", true);
    CheckImportCompleteAPICall({
      payload: [{ restaurantId: restaurantId ? restaurantId : restaurantsId }],
    });
  }

  eventBus.$on("closeFailImportFilePopup", (data) => closeFailImportFilePopup(data));
  function closeFailImportFilePopup(data) {
    setMassage(data);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("startUpdateLoading", (data) => startUpdateLoading(data));
  function startUpdateLoading(data) {
    setDisDoneButton(data);
    setLoadingCircul(true);
    setSavebtn(false);
    setMassage("Inventory Update Successfully");
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    }
  }

  eventBus.$on("disableclosebutton", (data) => disableclosebutton(data));
  function disableclosebutton(data) {
    setDisButton(true);
  }

  eventBus.$on("FailedToAddInventoryItem", (data) => FailedToAddInventoryItem(data));
  function FailedToAddInventoryItem(data) {
    setMassage("Failed to Add Item");
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("IsSuccessToAddInventoryItem", (data) => IsSuccessToAddInventoryItem(data));
  function IsSuccessToAddInventoryItem(data) {
    setMassage("Item Added Successfully");
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  }

  eventBus.$on("updateinventoryId", (data) => updateinventoryId(data));
  function updateinventoryId(data) {
    setInventorySelectId(data);
  }

  const hideInventoryBlockAlert = () => {
    setLocationNewValue("");
    setOpenRapidInventory(false);
    setopenInventoryBlockedDialog(false);
    LocationInventoryStopListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          items: InventorySelectId,
        },
      ],
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      minWidth: 200,
      resizable: true,
      sortable: false,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    };
  }, []);

  const onRowDragEnter = useCallback((e) => {
    console.log("onRowDragEnter", e);
  }, []);

  const onRowDragEnd = useCallback((e) => {
    console.log("onRowDragEnd", e);
  }, []);

  const onRowDragMove = useCallback((e) => {
    console.log("onRowDragMove", e);
  }, []);

  const onRowDragLeave = useCallback((e) => {
    console.log("onRowDragLeave", e);
  }, []);

  const onColumnMoved = (params) => {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("columnOrder", columnState);
    if (params.columnApi.getColumnState()) {
      setColumnOrder(params.columnApi.getColumnState());
    }
  };

  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  useEffect(() => {
    const timeOutId = setTimeout(
      async () =>
        await setAPICall({
          payload: [{ offset: defaultOffset, limit: defaultLimit, restaurantId: restaurantId, keyword: `${search}` }],
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleSearch = async (event) => {
    setLoadingCircul(true);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setSearch(event.target.value);
    // setLoading(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const successDelete = async () => {
    setLoadingCircul(true);
    await deleteInventoryAPICall({
      payload: [
        {
          inventoryId: inventoryId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
  };

  const hideAlert = () => {
    setDeleteInventoryDialog(false);
  };

  const handleCloseModelLocation = () => {
    LocationInventoryListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          start: true,
        },
      ],
    });
    setopenInventoryBlockedDialog(false);
    setOpenRapidInventory(false);
    setOpenInventoryList(true);
  };

  const handlecloseRapidinventory = () => {
    LocationInventoryStopListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          items: InventorySelectId,
        },
      ],
    });
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    }
    setOpenRapidInventory(false);
    setLocationNewValue("");
    setOpenInventory(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setClearFilter(true);
    localStorage.setItem("TabFilter", newValue);
    setTabValue(newValue);
    setPageSize(defaultLimit);
    setPage(1);
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          { offset: 0, limit: defaultLimit, moveTabs:newValue && `${newValue}=true`, finalQuery: filters, restaurantId: restaurantId },
        ],
      });
    } else {
      setAPICall({
        payload: [{ offset: 0, limit: defaultLimit, moveTabs: newValue && `${newValue}=true`, restaurantId: restaurantId }],
      });
    }
  };

  const handleCloseSelect = () => {
    navigate("/client/restaurant/list");
  };

  const handleClearTab = async (event) => {
    setClearFilter(false);
    setTabValue("");
    localStorage.setItem("TabFilter", "");
    const Curfilter = localStorage.getItem("TabFilter");
    setPageSize(defaultLimit);
    setPage(1);
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: 0,
            limit: defaultLimit,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: 0,
            limit: defaultLimit,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    }
  };

  const handleChangeInventory = (event, newValue) => {
    if (event.target.value) {
      const found1 = restaurantData.find((obj) => {
        return obj.name === event.target.value;
      });
      if (found1) {
        navigate(`/client/inventory/${found1?._id}/list`);
        setRestaurantId(found1?._id);
        const Curfilter = localStorage.getItem("TabFilter");
        const finalFilter = Curfilter ? Curfilter : tabValue;
        if (Object.keys(filters).length !== 0) {
          setAPICall({
            payload: [
              {
                offset: defaultOffset,
                limit: defaultLimit,
                moveTabs: finalFilter && `${finalFilter}=true`,
                finalQuery: filters,
                restaurantId: found1?._id,
                keyword: `${search}`,
              },
            ],
          });
        } else {
          setAPICall({
            payload: [
              {
                offset: defaultOffset,
                limit: defaultLimit,
                moveTabs: finalFilter && `${finalFilter}=true`,
                restaurantId: found1?._id,
                keyword: `${search}`,
              },
            ],
          });
        }
        GetRestaurantAPICall({
          payload: [{ keyword: `` }],
        });
        LocationListAPICall({
          payload: [{ inventoryBlocked: false, businessId: found1?._id }],
        });
        getRestaurantDetailsAPICall({
          payload: [
            {
              restaurantId: found1?._id,
            },
          ],
        });
        setLoadingCircul(true);
      }
    }
    setShowselectedInventoryrowsData(event.target.value);
  };

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: "transparent" };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "transparent" };
    }
  };

  const handleChangeLocation = (event, newValue) => {
    setLoadingCircul(true);
    setLocationNewValue(newValue);
    const found = locationList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      GetLocationDetailsListAPICall({
        payload: [
          {
            locationId: found?.locationId,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
          },
        ],
      });
      setLocationId(found?.locationId);
    }
  };

  const onPageSizeChanged = (event) => {
    setPagePerRow(event.target.value);
  };

  const containerStyle = useMemo(() => ({ width: "auto", height: screenSize < 1440 ? '50vh' : '62vh' , overflow :'auto' }), []);

  const handleImportCsv = () => {
    if (importBlock === true) {
      setNotShowImportPopUp(true);
    } else {
      setShowImportPopUp(true);
    }
  };

  const handleExportCsv = () => {
    if (exportBlock === true) {
      setNotShowExportPopUp(true);
    } else {
      setNotShowExportPopUp(false);
      setLoadingCircul(true);
      const tabValues = localStorage.getItem("TabFilter");
      if (tabValues) {
        getExportDocumentAPICall({
          payload: [
            {
              moveTabs: `${tabValues}=true`,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
            },
          ],
        });
      }
      if (tabValues && Object.keys(filters).length !== 0) {
        getExportDocumentAPICall({
          payload: [
            {
              moveTabs: `${tabValues}=true`,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
              finalQuery: filters,
            },
          ],
        });
      } else if (Object.keys(filters).length !== 0) {
        getExportDocumentAPICall({
          payload: [
            {
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
              finalQuery: filters,
            },
          ],
        });
      } else if (tabValues.length === 0) {
        getExportDocumentAPICall({
          payload: [
            {
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantId,
            },
          ],
        });
      }
    }
  };
  const handleClose = () => {
    setDisButton(false);
    setShowImportPopUp(false);
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantId,
          },
        ],
      });
    }
  };

  const handleOpenUpdateInventory = () => {
    setLocationNewValue("");
    setOpenRapidInventory(true);
  };

  const handleDoneSaveRapidInventory = () => {
    setSavebtn(true);
    setLoadingCircul(true);
    eventBus.$emit("saveAndClose", true);
  };

  const handleDoneRapidInventory = () => {
    setSaveAndCloseButton(true);
    setLoadingCircul(true);
    eventBus.$emit("saveAndClose", true);
    LocationListAPICall({
      payload: [
        {
          inventoryBlocked: false,
          businessId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    getRestaurantDetailsAPICall({
      payload: [
        {
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    LocationInventoryStopListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          items: InventorySelectId,
        },
      ],
    });
    setLocationNewValue("");
    setTimeout(() => {
      const Curfilter = localStorage.getItem("TabFilter");
      const finalFilter = Curfilter ? Curfilter : tabValue;
      if (Object.keys(filters).length !== 0) {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              finalQuery: filters,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantsId,
              keyword: `${search}`,
            },
          ],
        });
      } else {
        setAPICall({
          payload: [
            {
              offset: defaultOffset,
              limit: defaultLimit,
              moveTabs: finalFilter && `${finalFilter}=true`,
              restaurantId: location.pathname.split("/").slice(-2)[0]
                ? location.pathname.split("/").slice(-2)[0]
                : restaurantsId,
              keyword: `${search}`,
            },
          ],
        });
      }
      setSaveAndCloseButton(false);
      setOpenInventory(false);
    }, 1000);
    // setOpenRapidInventory(true)
  };

  const handleCloseInventoryList = () => {
    setOpenInventoryList(false);
    LocationInventoryStopListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          items: InventorySelectId,
        },
      ],
    });
  };

  const handleDoneInventoryList = () => {
    setLoadingCircul(true);
    LocationListAPICall({
      payload: [
        {
          inventoryBlocked: false,
          businessId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    getRestaurantDetailsAPICall({
      payload: [
        {
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
        },
      ],
    });
    LocationInventoryStopListAPICall({
      payload: [
        {
          locationId: locationId,
          restaurantId: location.pathname.split("/").slice(-2)[0]
            ? location.pathname.split("/").slice(-2)[0]
            : restaurantsId,
          items: InventorySelectId,
        },
      ],
    });
    const Curfilter = localStorage.getItem("TabFilter");
    const finalFilter = Curfilter ? Curfilter : tabValue;
    if (Object.keys(filters).length !== 0) {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            finalQuery: filters,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    } else {
      setAPICall({
        payload: [
          {
            offset: defaultOffset,
            limit: defaultLimit,
            moveTabs: finalFilter && `${finalFilter}=true`,
            restaurantId: location.pathname.split("/").slice(-2)[0]
              ? location.pathname.split("/").slice(-2)[0]
              : restaurantsId,
            keyword: `${search}`,
          },
        ],
      });
    }
    setLocationNewValue("");
    setOpenInventory(true);
    setOpenInventoryList(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.paginationSetPageSize(pageSize); // Set initial page size
  };

    // Fetch data from API
    const fetchData = useCallback(async ({ limit, offset }) => {
      try {
        const Curfilter = localStorage.getItem("TabFilter");
        const finalFilter = Curfilter ? Curfilter : tabValue;
        const finalQuery = localStorage.getItem("finalQuery");
        const CheckQuery = finalQuery ? finalQuery : filters;
        if (Object.keys(CheckQuery).length !== 0) {
          setAPICall({
            payload: [
              { 
                limit: limit,
                offset: offset,
                moveTabs: finalFilter && `${finalFilter}=true`,
                finalQuery: CheckQuery,
                restaurantId: location.pathname.split("/").slice(-2)[0]
                  ? location.pathname.split("/").slice(-2)[0]
                  : restaurantId,
              },
            ],
          });
        } else {
          setAPICall({
            payload: [
              {
                limit: limit,
                offset: offset,
                moveTabs: finalFilter && `${finalFilter}=true`,
                restaurantId: location.pathname.split("/").slice(-2)[0]
                  ? location.pathname.split("/").slice(-2)[0]
                  : restaurantId,
              },
            ],
          });
        }
        // setRowData(data.users); // API returns data in `users`
        // setTotalRows(data.total); // Total rows for pagination
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }, []);

  useEffect(() => {
    setLoadingCircul(true);
    const offset = (page - 1) * pageSize;
    fetchData({ limit: pageSize, offset });
    localStorage.setItem("Inventoryoffset", offset);
    localStorage.setItem("Inventorylimit", pageSize);
  }, [page, pageSize, fetchData]);

  // Handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setLoadingCircul(true);
    setPageSize(newPageSize);
    setPage(1); // Reset to the first page
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card sx={{ ...loaderClass, backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
          {!isMobile ? (
            <MDBox
              pt={3}
              px={2}
              sx={{ pr: 3, mb: 2 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {showSelectInventory ? (
                <FormControl variant="standard" sx={{ pl: 3, minWidth: 200 }}>
                  <InputLabel
                    sx={{ pl: 3.5, color: darkMode ? "#ffffff" : "#000000" }}
                    htmlFor="demo-simple-select-standard"
                    id="demo-simple-select-standard-label"
                  >
                    Restaurant
                  </InputLabel>
                  <Select
                    aria-required="true"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={showselectedInventoryrowsData}
                    onChange={handleChangeInventory}
                    label="Select Inventory"
                    sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                  >
                    {restaurantData.map((prop, key) => (
                      <MenuItem value={prop?.name}>{prop?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <MDTypography
                  pt={3}
                  px={2}
                  sx={{ pr: 3, mb: 2, color: darkMode ? "#ffffff" : "#000000" }}
                  variant="h5"
                  fontWeight="medium"
                >
                  Restaurant
                </MDTypography>
              )}
              <MDBox style={{ display: "flex" }}>
                <MDBox>
                  <AppBar position="static">
                    <Tabs
                      sx={darkMode && { backgroundColor: "#1a2035 !important" }}
                      className={classes.tabs}
                      value={tabValue}
                      onChange={handleSetTabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        sx={{
                          color: darkMode ? "#ffffff !important" : "#000000", // Change label color based on dark mode
                        }}
                        label={`Out of Stock (${totalOutOfStock})`}
                        value="isOutOfStock"
                      />
                      <Tab
                        sx={{
                          color: darkMode ? "#ffffff !important" : "#000000", // Change label color based on dark mode
                        }}
                        label={`Low Stock (${totalLowStock})`}
                        value="isLowStock"
                      />
                      <Tab
                        sx={{
                          color: darkMode ? "#ffffff !important" : "#000000", // Change label color based on dark mode
                        }}
                        label={`Under Par (${totalunderpar})`}
                        value="isUnderPar"
                      />
                      <Tab
                        sx={{
                          color: darkMode ? "#ffffff !important" : "#000000", // Change label color based on dark mode
                        }}
                        label={`Par (${totalPar})`}
                        value="isPar"
                      />
                    </Tabs>
                  </AppBar>
                </MDBox>
                {clearFilter ? (
                  <MDBox>
                    <MDButton
                      variant="text"
                      color="info"
                      onClick={handleClearTab}
                      sx={{ pt: 2, cursor: "pointer" }}
                    >
                      Clear Filter
                    </MDButton>
                  </MDBox>
                ) : (
                  <MDBox pl={8.375}>
                    <MDButton
                      variant="gradient"
                      color="light"
                      disabled
                      sx={{ pt: 2, background: "transparent !important", cursor: "inherit" }}
                    ></MDButton>
                  </MDBox>
                )}
              </MDBox>
              <MDBox px={2} display="flex">
                <MDBox sx={{ pr: 3, mb: 2 }}>
                  {/* <MDInput type="search" label="Search" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} /> */}
                </MDBox>
              </MDBox>
            </MDBox>
          ) : (
            <>
              <MDBox
                pt={3}
                pl={2}
                sx={{ mb: 1 }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h5" fontWeight="medium">
                  {clientNewValue?.label} Restaurant
                </MDTypography>
                <MDBox
                  pt={1}
                  sx={{
                    mb: 1,
                    "@media(max-width: 440px)": {
                      marginLeft: "-150px",
                    },
                  }}
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                >
                  <input
                    variant="standard"
                    type="search"
                    class="search-field"
                    onKeyDown={handleKeyDown}
                    onChange={handleSearch}
                    placeholder="Search"
                    title="Search for:"
                  />
                </MDBox>
              </MDBox>
              <MDBox
                pt={1}
                px={2}
                sx={{
                  pr: 1.5,
                  "@media(max-width: 440px)": {
                    justifyContent: "start !important",
                    display: "flex !important",
                  },
                }}
                component="form"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDBox>
                  <AppBar position="static">
                    <Tabs
                      className={classes.tabs}
                      value={tabValue}
                      onChange={handleSetTabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab label={`Out of Stock (${totalOutOfStock})`} value="outofstock" />
                      <Tab label={`Low Stock (${totalLowStock})`} value="lowstock" />
                      <Tab label={`Under Par (${totalunderpar})`} value="underpar" />
                      <Tab label={`Par (${totalPar})`} value="par" />
                    </Tabs>
                  </AppBar>
                </MDBox>
                {clearFilter ? (
                  <MDBox>
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/klEQVR4nO3Tv0oDQRCA8d+BCJpgI1hbWNgIKiRvYKOthSIIdhpBTGWTQjsl2KpvIFhY+DQi+gKCfwotLCQS2MLmvL3b68wHA7vszH4sM8uIEYE2jhKiLYLjmKTU+h4aqtEI9YVM4xxZSUGGPmZiCxZxUFLSRatkjU2sRuauYFtCE+cKcmZj+5DHGC4wlXPexCXGJZI3CFnZRlcZhG6VRhfRx+mv6OAO73jAXoWx//MnN/GEZwzwiS/s1inZCpff4Ar7+MB9nZLDIHnDWhAN96+pkh7mw3oZ37gOouGLXnCbKpnADs6wjpMgGoR4DB+zNlpYwFIY7Q1M1inwf/kB6RQ1RVJg2g8AAAAASUVORK5CYII="
                      onClick={handleClearTab}
                    ></img>
                  </MDBox>
                ) : (
                  <MDBox>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxklEQVR4nO2SMQ6CQBBFacQKsBUPBXIMUW+paKKGRtFroP0zQ6YgBokMsdufTLV//5vdGc9zcvq7gCmwAQ7AS6sA1oA/NnwBlHzXBYjHdF5q0BVIgEArBSo9O5teAmw14AZEHedRC5JbAEe9nPR4luopLICnXg56PKF6agug/gEQjQHIWorSHk/WOGBvAcieo4PsGvIMeDQOWFkAvu656K4DDbWyVvgJmAwGKCRuQbok4XNT+MdLcvln3SypnXyLuXMnJ2+I3lyeaOPFhMAhAAAAAElFTkSuQmCC"></img>
                  </MDBox>
                )}
              </MDBox>
            </>
          )}
          {!isMobile ? (
            <MDBox
              pt={2}
              px={2}
              sx={{ pr: 3, mb: 1 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDButton
                // type="submit"
                variant="gradient"
                color="info"
                onClick={handleImportCsv}
              >
                Import
              </MDButton>
              <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: darkMode ? "1px solid #ffffff" : "1px solid #808080",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    color: darkMode ? "#ffffff" : "#344767",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "15%",
                  }}
                >
                  Total Inventory Costs: ${totalInventoryCost}
                </MDBox>
              <MDBox display="flex">
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => setOpenAddInventory(true)}
                  disabled={loading}
                >
                  {" "}
                  Add Item
                </MDButton>
                <MDButton
                  component={NavLink}
                  to=""
                  sx={{ ml: { xs: 0, sm: 1 } }}
                  variant="gradient"
                  color="info"
                  disabled={loading}
                  onClick={handleOpenUpdateInventory}
                >
                  {" "}
                  Update Inventory
                </MDButton>
                <MDButton
                  sx={{ ml: { xs: 0, sm: 1 } }}
                  // type="submit"
                  disabled={disableExport}
                  variant="gradient"
                  color="info"
                  onClick={handleExportCsv}
                >
                  Export
                </MDButton>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox
              pt={4}
              px={2}
              sx={{ pr: 3, mb: -2 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox>
                <img
                  width="25"
                  height="25"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwUlEQVR4nO2Y3YtOQRzHP+tZu15C613kxgVX1KL8BS5ccCGFJJcuREnRXihx4WJz4e1CYkm52fKSuJC3iNKGXVFeiuSlpESRl2U09T01HfOcPbvOzHNW51vT0/l958zM58zvzMx5oNLwkglUHgPT/wcQExvGqIRoMyqMCQjSq98+YErBfUQFaQN6Ys2MCQhCTBgTGCQajIkAEgXGRAIJDmMiglgFg4kNEgymESBBYBoFgjbJ3qJgQh9RBlP6iui0SP3LQbNUIA0Zh6lAipWpZkSqUmu4pVYN2A48A34A9xyvGegAngBf5Y0FtqjNLqfuXMVeNQrkpPz3wFngsOPtkWdBuoG7ii9W/L5Td5VipxsBsswZ6DSP/wF4C4xOxUdqhr4DLYrtVlubhjCOXMpqoFveBo9nB9gPPAeaPP4N3btA1+d03T6EceRSVgNv5M2q41+Vf9B58on2yluv6xfAZ71XPl0JBdKkJ/5TL7xP85Re9v7bwAzHW6F4JzAe+A1czhjHZMEWDtKsuF2pRmTcP8c5itvfVsWnavAXgUXydw0wliQNC0+tT/IG+k4YpxmxdVc7cbtkvwTWyVtKQGWB3PQMrp7Wqu5OJ3ZCs7JPaWpTzKdJwAVgIoFANst7qBxOq+Z5udc4sY2K2dx/UKePFuC66l3zLBqFgLRqAMmGaPP9uLwx2kPOaPWyT/6p4onmO+0fqtNHV+rD6lgIkGTajwjkmwaNVqhbWlIt0CnPMl1z3jObeml1yPsILNevvd4RAiSUVgK/VOwJAi0G/ZpdN0VLC7IQ+KJ+t6a8bYrbI86SMoPMBF6rT3sg9emo/HfA7DKC2P0m2Tx7PIfNRKOAO6r3CJhQJhD74p/P8ReQz7+UcUb768bQOpDzvyxTp+wv8ze7yQAppLFYMhWIR9WMlDW1TMSSqAIpm0zOvaX0GhTIH1UH8T3jRm+8AAAAAElFTkSuQmCC"
                  title="Import CSV"
                  onClick={handleImportCsv}
                ></img>
              </MDBox>
              <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #808080",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    color: "#344767",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "15%",
                  }}
                >
                  Total Inventory Costs: ${totalInventoryCost}
                </MDBox>
              <MDBox>
                <img
                  style={{ marginLeft: "20px" }}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAW0lEQVR4nO2VQQrAIAwE53n+/wnxIdtLC0WQqKGkhwzkZMzAEhSKTRrQAR2W3TOmWGC4XpIpT9Mp8u6nCxQ8LwHbEWmx/isYqS0iPSKPfIF9/Vy3oMS8D6dg5ALzIZ+a+RX41gAAAABJRU5ErkJggg=="
                  onClick={() => setOpenAddInventory(true)}
                ></img>
                <img
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                  width="24"
                  height="24"
                  src="https://img.icons8.com/pastel-glyph/24/loop.png"
                  alt="loop"
                  onClick={handleOpenUpdateInventory}
                />
                <img
                  width="25"
                  height="25"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACv0lEQVR4nO2X24tOURjGf+ObMQ4hZyI3LriiHMpfMBcUF1JMkksX4oKiuVDiYi7kwqlIOaTcTDkkLkQ5RGnCjCiHIjFKShQ5ztKqZ9fqs75tzzd7rf192k+tdvt91uF91uFd74ISzQUTqDwBZvwPQkxsMUYlRJ9RxZiAQvr07Qem5jxGVCETgd5YK2MCCiGmGBNYSDQxJoKQKGJMJCHBxZiIQiyCiYktJJiYIoQEEVOUEHRJ9uUlJnSKMpTSn8egeWI4iWZDCSnED1MKyRemXBGh3FrNtrUqwA7gOfADuO9wrUAX8BT4Km4ssFV9nnTqzpPtdVFCTot/D5wHjjjcXnFWSA9wT/alsj9w6q6R7WwRQpY7jk738B+AAWB0lb1NK/QdGCnbHvW1uQ4/MiGtgx5xGz2cdfAX8AJo8fA31Hah/i/of1EdfmRCWgdvxc2uwV8Xf8iZ+QTd4jbo/yXwWedqqH5kQq0OWjTjP3XgfZiv7WXb3wFmOtwq2fcB44FB4GodfgxbSKvsNlKNSGk/10nF7bdd9mly/jKwRPxuAiJtJj6J+9c7YZxWxNZd69htyH4FrBfXQUFCbnmcq4VO1d3l2E5pVfZrm9ot5hs7l3Q+reEWcY+AKR6+4jnc6xzbJtnsQX+YMrYJLaRdDiQXot3vJ8SN0R1yTtHLzvwz2RMscPo/nHFsE0KIxWTgmIR8k9MoQt1WSLWCznjCdMU5Z51FCwkJUwrxoFyRHGDKrVXg1jqY8c4wNcqBRhFiQ/HFOoVcScma/2oYAzYnSxLMXs+DLMEo4K7qPQYmNGLUmgW80Zj2Ge3DcfHvgDmNHH4XA1807rYqbrvs9qm8rBnukdXAb5UVsnUoSx6sSj4zwUQuA8526ZLtI7BSX/u/s56ZiS3EVB3go1Vckl03HdqAaxJx03kqNyXsU+ESMCmt1h+SrfNA6d0akAAAAABJRU5ErkJggg=="
                  title="Export CSV"
                  onClick={handleExportCsv}
                />
              </MDBox>
            </MDBox>
          )}
          <MDBox sx={{ mt: 3, mb: 2 }}>
            <div style={containerStyle}>
              <div
                className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
                style={{ height: 520, width: "100%" }}
              >
                 <AgGridReact
                  columnDefs={sortedColumnDefs.length != 0 ? sortedColumnDefs : columnDefs}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  rowDragManaged={true}
                  rowHeight={55}
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                  animateRows={true}
                  suppressPaginationPanel={true} // Suppress default AG Grid pagination
                  onGridReady={onGridReady}
                  paginationPageSize={pageSize}
                  onRowDragEnter={onRowDragEnter}
                  onRowDragEnd={onRowDragEnd}
                  onRowDragMove={onRowDragMove}
                  onRowDragLeave={onRowDragLeave}
                  onColumnMoved={onColumnMoved}
                  loadingOverlayComponentParams={"One moment please..."}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#202940",
                  color: "#ffffff",
                  borderRadius: "8px",
                }}
              >
                {/* Page Size Selector */}
                <Select
                  value={pageSize.toString()} // Current value
                  onChange={(event) => handlePageSizeChange(event.target.value)} // Handle selection change
                  size="small"
                  sx={{
                    width: "3.5rem", // Input width
                    height: "2.1rem", // Input height
                    color: darkMode ? "#ffffff" : "#000000", // Text color based on dark mode
                    textAlign: "center", // Center the selected value
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: darkMode ? "#1a73e8" : "#e0f2ff", // Hover color for dark/light mode
                    },
                    "& .MuiSvgIcon-root": {
                      color: darkMode ? "#ffffff !important" : "#000000 !important", // Arrow icon color
                    },
                    "& .MuiSelect-select": {
                      color: darkMode ? "#ffffff !important" : "#000000", // Selected value color
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: "3.5rem", // Match the width of the input
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#1a73e8", // Blue hover for menu items
                        },
                      },
                    },
                    anchorOrigin: {
                      vertical: "top", // Anchor the dropdown at the top of the select
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "bottom", // Transform the dropdown to appear from the bottom
                      horizontal: "center",
                    },
                  }}
                  disableUnderline
                >
                  {entries.map((entry) => (
                    <MenuItem
                      key={entry}
                      value={entry}
                      sx={{
                        textAlign: "center",
                        "&:hover": {
                          backgroundColor: "#1a73e8", // Blue hover for menu items
                        },
                      }}
                    >
                      {entry}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                  disabled={page === 1}
                  style={{ color: page === 1 ? "#888888" : "#ffffff" }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>

                {Array.from(
                  { 
                    length: Math.min(5, Math.ceil(totalRows / pageSize)) 
                  },
                  (_, i) => {
                    const maxStartPage = Math.max(1, Math.ceil(totalRows / pageSize) - 4); // Ensure we don't go beyond total pages
                    const startPage = Math.min(maxStartPage, page - 2); // Ensure pages are centered around the current page
                    return i + Math.max(1, startPage);
                  }
                ).map((pageNumber) => (
                  <Button
                    key={pageNumber}
                    onClick={() => setPage(pageNumber)}
                    style={{
                      fontSize: "0.85rem", // Extra small font size
                      backgroundColor: pageNumber === page ? "#237dea" : "transparent",
                      color: "#ffffff",
                      padding: "0px 3px", // Minimal padding
                      margin: "0 3px", // Very narrow spacing between buttons
                      borderRadius: "0px", // Remove rounded corners for tight boxes
                      border: "1px solid #ffffff",
                      minWidth: "40px", // Ensures a small fixed width
                      height: "20px", // Ensures a small fixed height
                      textAlign: "center",
                    }}
                  >
                    {pageNumber}
                  </Button>
                ))}

                <IconButton
                  onClick={() =>
                    setPage((prev) => Math.min(prev + 1, Math.ceil(totalRows / pageSize)))
                  }
                  disabled={page === Math.ceil(totalRows / pageSize)}
                  style={{
                    color: page === Math.ceil(totalRows / pageSize) ? "#888888" : "#ffffff",
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>

              </div>
            </div>
          </MDBox>
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
        </Card>
        <MDBox>
          <Dialog
            // fullScreen
            open={openAddInventory}
            // onClose={() => setOpenAddInventory(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
                },
              },
            }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "center",
                display: "flex",
                color: darkMode ? "#ffffff" : "#000000",
              }}
            >
              Add Item
            </DialogTitle>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "end",
                display: "flex",
                mt: -7.5,
              }}
            >
              <Icon
                sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#000000" }}
                onClick={() => setOpenAddInventory(false)}
              >
                close
              </Icon>
            </DialogTitle>
            <DialogContent>
              <CheckItemExistDialog
                clientId={clientId}
                restaurantsId={restaurantId}
                onClose={handleAddinventory}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            // fullScreen
            open={openEditInventory}
            onClose={() => setOpenEditInventory(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  // height: "50%",
                  maxWidth: "570px",
                  minHeight: "40%",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
                },
              },
            }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "center",
                display: "flex",
                color: darkMode ? "#ffffff" : "#000000",
              }}
            >
              Edit Item
            </DialogTitle>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "end",
                display: "flex",
                mt: -7.5,
              }}
            >
              <Icon
                sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#000000" }}
                onClick={handleAddinventory}
              >
                close
              </Icon>
            </DialogTitle>
            <DialogContent>
              <EditInventoryDialog
                inventoryId={inventoryId}
                productName={productName}
                businessId={businessId}
                productId={productId}
                restaurantsId={
                  location.pathname.split("/").slice(-2)[0]
                    ? location.pathname.split("/").slice(-2)[0]
                    : restaurantsId
                }
                onClose={handleAddinventory}
                locationListA={locationList}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            // fullScreen
            open={openDetailsInventory}
            onClose={() => setOpenDetailsInventory(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Edit- Carrots
            </DialogTitle>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                justifyContent: "end",
                display: "flex",
                mt: -7.5,
              }}
            >
              <Icon sx={{ cursor: "pointer" }} onClick={() => setOpenDetailsInventory(false)}>
                close
              </Icon>
            </DialogTitle>
            <DialogContent>
              <DetailsInventoryDialog
                itemId={itemId}
                onClose={() => setOpenDetailsInventory(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
      </MDBox>
      <MDBox>
        <Modal
          open={openSelectInvoice}
          // onClose={handleCloseSelect}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={darkMode ? darkModeStyle : style}>
            <MDTypography sx={{ fontSize: "1.2rem" }} display="flex" justifyContent="center">
              Select Restaurant
            </MDTypography>
            <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
              <FormControl variant="standard" sx={{ minWidth: 150, mt: 2 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                  value={{ label: restaurantNewValue }}
                  options={autoCompleteObj}
                  sx={
                    darkMode
                      ? {
                          width: 300,
                          "& .MuiAutocomplete-clearIndicator": {
                                color: "#FFFFFF", // Change color of clear (X) icon in dark mode
                            },
                            "& .MuiSvgIcon-root": {
                                color: "#FFFFFF", // Change color of dropdown arrow in dark mode
                          },
                          "& .MuiInput-root .MuiInput-input": {
                            WebkitTextFillColor: "white",
                          },
                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                            {
                              color: "white !important",
                            },
                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                            {
                              color: "white !important",
                            },
                        }
                      : {
                          width: 300,
                        }
                  }
                  onChange={(event, newValue) => {
                    navigate(`/client/inventory/${newValue?.id}/list`);
                    setRestaurantsId(newValue?.id);
                    setClientNewValue(newValue?.label);
                    setRestaurantNewValue(newValue?.label);
                    setBusinessId(newValue?.Business?._id);
                    setShowselectedInventoryrowsData(newValue?.label);
                    setAPICall({
                      payload: [{ offset: defaultOffset, limit: defaultLimit, restaurantId: newValue?.id }],
                    });
                    GetRestaurantAPICall({
                      payload: [{ keyword: `` }],
                    });
                    LocationListAPICall({
                      payload: [{ inventoryBlocked: false, businessId: newValue?.id }],
                    });
                    getRestaurantDetailsAPICall({
                      payload: [{ restaurantId: newValue?.id }],
                    });
                    setTimeout(() => {
                      setLoading(false);
                      setLoadingCircul(false);
                      setOpenSelectInvoice(false);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select"
                      variant="standard"
                      sx={{
                        "& .MuiFormLabel-root": {
                          marginTop: "5px",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </MDBox>
            <MDBox
              sx={{
                justifyContent: "end",
                display: "flex",
              }}
            >
              <MDButton
                sx={{
                  mr: -2,
                  background: "transparent",
                  color: "#ffffff",
                  "&:hover": {
                    background: "transparent",
                    color: "#ffffff",
                  },
                }}
                onClick={handleCloseSelect}
              >
                Cancel
              </MDButton>
            </MDBox>
          </Box>
        </Modal>
      </MDBox>
      <Dialog
        open={showExportModal}
        // onClose={() => setShowExportModal(false)}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            color="success"
            height="90"
            viewBox="0 0 24 24"
            width="90"
          >
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
            <path
              fill="#4caf50"
              d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
          </svg>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "center",
              color: darkMode ? "#ffffff" : "#7b809a",
            }}
          >
            <h2>Success</h2>
          </DialogContentText>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{
                fontWeight: 300,
                fontSize: "0.90rem",
                textAlign: "center",
                mt: 1,
                color: darkMode ? "#ffffff" : "#7b809a",
              }}
            >
              {massage}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end", mt: 1 }}>
          <MDButton variant="gradient" color="info" onClick={() => setShowExportModal(false)}>
            ok
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={notShowExportPopUp}
        onClose={() => setNotShowExportPopUp(false)}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            justifyContent: "center",
            display: "flex",
            fontSize: 80,
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: red[500], fontSize: 50 }} />
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <DialogContentText sx={{color: darkMode ? "#ffffff" : "#7b809a", display: "flex", justifyContent: "center" }}>
            <h3>Alert !</h3>
          </DialogContentText>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{color: darkMode ? "#ffffff" : "#7b809a", fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 1 }}
            >
              {exportBlockmassage}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end", mt: 1 }}>
          <MDButton variant="gradient" color="info" onClick={() => setNotShowExportPopUp(false)}>
            ok
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={notShowImportPopUp}
        onClose={() => setNotShowImportPopUp(false)}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            justifyContent: "center",
            display: "flex",
            fontSize: 80,
          }}
        >
          <WarningAmberOutlinedIcon sx={{ color: red[500], fontSize: 50 }} />
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <DialogContentText sx={{color: darkMode ? "#ffffff" : "#7b809a", display: "flex", justifyContent: "center" }}>
            <h3>Alert !</h3>
          </DialogContentText>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{color: darkMode ? "#ffffff" : "#7b809a", fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 1 }}
            >
              {importBlockmassage}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end", mt: 1 }}>
          <MDButton variant="gradient" color="info" onClick={() => setNotShowImportPopUp(false)}>
            ok
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openInventoryBlockedDialog}
        // onClose={() => setopenInventoryBlockedDialog(false)}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#000000",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            justifyContent: "center",
            display: "flex",
            fontSize: 80,
          }}
        >
          <ErrorOutlineIcon sx={{ color: yellow[500], fontSize: 50 }} />
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "center",
              color: darkMode ? "#ffffff" : "#000000",
            }}
          >
            <h3>Are you sure want to update?</h3>
          </DialogContentText>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{
                fontWeight: 300,
                fontSize: "0.90rem",
                textAlign: "center",
                mt: 1,
                color: darkMode ? "#ffffff" : "#000000",
              }}
            >
              {inventoryBlockmassage}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end", mt: 1 }}>
          <MDButton variant="gradient" color="error" onClick={hideInventoryBlockAlert}>
            No
          </MDButton>
          <MDButton variant="gradient" color="info" onClick={handleCloseModelLocation}>
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteInventoryDialog}
        onClose={() => setDeleteInventoryDialog(false)}
        aria-labelledby="alert-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#000000",
            },
          },
        }}
      >
        <DialogContent sx={{ overflowY: "hidden" }}>
          <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4, color: darkMode ? "#ffffff" : "#000000"}}>
            <h3>Are you sure?</h3>
          </DialogContentText>
          <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4,color: darkMode ? "#ffffff" : "#000000",
              }}
            >
              Once you delete this Item there is no way of getting any of the data back.
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
          <MDButton variant="gradient" color="success" onClick={successDelete}>
            Yes, delete it!
          </MDButton>
          <MDButton variant="gradient" color="error" onClick={hideAlert}>
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox>
        <Modal
          open={openRapidInventory}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={darkMode ? styleInventoryDark : styleInventory}>
            <MDTypography sx={{ display: "flex", justifyContent: "center", mt: -1 }}>
              Location to Inventory
            </MDTypography>
            <MDBox sx={{ pl: 0, pb: 2 }} display="flex" justifyContent="center">
              <FormControl variant="standard" sx={{ minWidth: 300, mt: 4, mb: 3 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                  value={{ name: locationNewValue }}
                  options={locationList}
                  getOptionLabel={(option) => option.name}
                  sx={
                    darkMode
                      ? {
                        "& .MuiAutocomplete-clearIndicator": {
                          color: "#FFFFFF", // Change color of clear (X) icon in dark mode
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#FFFFFF", // Change color of dropdown arrow in dark mode
                        },
                          "& .MuiInput-root .MuiInput-input": {
                            WebkitTextFillColor: "white",
                          },
                          "& .MuiFormLabel-root-MuiInputLabel-root": {
                            color: "white",
                          },
                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                            {
                              color: "white !important",
                            },
                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                            {
                              color: "white !important",
                            },
                          width: 300,
                        }
                      : {
                          width: 300,
                        }
                  }
                  onInputChange={(event, newInputValue) => {
                    handleChangeLocation(event, newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Location"
                      InputLabelProps={darkMode ?{
                        style: {color: "#ffffff" },
                      }:{
                        style: {color: "#000000" },
                      }}
                      variant="standard"
                      sx={{
                        "& .MuiFormLabel-root": {
                          marginTop: "5px",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
            <MDBox
              sx={{
                justifyContent: "end",
                display: "flex",
              }}
            >
              <MDButton
                sx={
                  darkMode
                    ? {
                        mb: -2,
                        mr: -2,
                        background: "transparent",
                        color: "#ffffff",
                        "&:hover": {
                          background: "transparent",
                          color: "#ffffff",
                        },
                      }
                    : {
                        mb: -2,
                        mr: -2,
                      }
                }
                onClick={handlecloseRapidinventory}
              >
                Close
              </MDButton>
            </MDBox>
          </Box>
        </Modal>
      </MDBox>
      <MDBox>
        <Dialog
          fullWidth="true"
          maxWidth="sm"
          TransitionComponent={Transition}
          open={showImportPopUp}
          sx={
            darkMode
              ? {
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                }
              : {}
          }
        >
          <DialogTitle align="center" sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>
            <h3>Import File</h3>
          </DialogTitle>
          <DialogContent>
            <ImportCsvDialog restaurantId={restaurantId} onclose={handleClose} />
            <MDBox display="flex" justifyContent="center">
              {!disButton && (
                <DialogContentText align="center">
                  <span style={{ fontSize: `0.99rem`, color: darkMode ? "#ffffff" : "#000000" }}>
                    Upload type must be .csv
                  </span>
                </DialogContentText>
              )}
              <MDBox ml={1.5}>
                <Link
                  href="https://paynthr-assets-uat.s3.amazonaws.com/templates/inventories-imports/inventory-import-template.csv"
                  target="_blank"
                >
                  <MDTypography
                    component="span"
                    variant="body2"
                    color={"info"}
                    opacity={0.8}
                    sx={{ lineHeight: 0 }}
                  >
                    Download Sample
                  </MDTypography>
                </Link>
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              sx={
                darkMode
                  ? {
                      background: "transparent",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent white background on hover
                      },
                    }
                  : {}
              }
              disabled={disButton}
              onClick={handleClose}
            >
              Close
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openInventory}
          // onClose={handlecloseRapidinventory}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "670px",
                maxHeight: "60%",
                backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
              color: darkMode ? "#ffffff" : "#7b809a",
            }}
          >
            {locationNewValue ? `${locationNewValue} -` : ""} Inventory
          </DialogTitle>
          <MDBox sx={{ maxHeight: "800px", overflowY: "auto" }}>
            <RapidInventoryList
              businessId={businessId}
              restaurantId={
                restaurantId !== ":id" ? restaurantId : location.pathname.split("/").slice(-2)[0]
              }
              locationId={locationId}
            />
          </MDBox>
          <DialogActions>
            <MDBox p={1} display="flex">
              {/* {disDoneButton &&
                                <MDBox>
                                    <img height={15} width={15} style={{ marginBottom: '3px !important' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvklEQVR4nO2Z24tNURzHP2aYCaPcxkw08g+Ico8HL8p4cMnlwaskt6TGZfAgpHhwG/4ETUIiI/+DlI54Mg4zZzATwoMZlKNf/aZOv/Y+Z6991s6s2p/61em093et79rr+luQk5OT40gDsBo4CdwHXgJfgd/AL+Cz/ncXOAWs0HcmDAuAi8AAUHaMIeA8sLCK/lZgBBgGNmdhYDZwS1u7XGeIxg2gNaKcoYrnSr5N7NCuUvYcI8AuU5Z9xguNwM0qFRkF+oD9OgakhacATfp7JbAX6AW+V9HpASZnZaQZeBRTcEkrP91BT57dA7yJ0ezTMr0akVZ9GCE6BhwHptapfQL4GaH/2LeRazFfYRX+WAL0JxhHqdkeISbrQRv+aQMKWRiZo/O3nfs7PFZ+E1B0mNlScTViVlqKX0qOU7Qz87XilSIX8M9g1kbOGoEBx+k1KZ3A26yMTALeG4GDBMgyY0J2sHMJkC5jRFb0IOk1Rg4QKM+NkTUEykdjRA5PQVKoMPFpAhxJd+ux+QOwweXF9bptkBe38X9pBL5VNOxrAqXDdHNp3CBZZ4w8I1AOGyO3CZQnxshRAmQe8McYWUyAHDEm5CjsTNJtdVG34r6ZYZJzEmfSCLkcduRw5JtLpgzJsrSHZmR5RCr2clqxpCbkdLfRo4lFEXs9WQRnZWGkkFE6qB14Zcr6C2ypR7TWl+j3nFFZGzG46+pS4yRJX8oA7Na0Z1qmAadjrifu6abRq5FmTSzHjRPJsrc46Lfo9sOOh7LGA83i140VRlP9PVW62w/gDrBP88Kt2gBNukrLf4eAp5oAj9O54uNLVDMyzk69lCl7juEsrteKputYpLWv12jZpDGqX2EmGdCpC91gjXVCDj7nUuRwJd7pZWgWU3lqGjSx163jpKDdb0xnpC/AC72ePqart2Q0c3JyckjMPyc6/HUGvn9gAAAAAElFTkSuQmCC" />
                                    <MDTypography
                                        component="span"
                                        variant="overline"
                                        sx={{ marginLeft: "0.4rem", lineHeight: "3.6" }}
                                    >
                                        Saving...
                                    </MDTypography>
                                </MDBox>
                            } */}
              <MDButton
                sx={{ ml: 1 }}
                variant="gradient"
                disabled={disDoneButton || saveAndCloseButton}
                color="info"
                onClick={handleDoneRapidInventory}
              >
                {saveAndCloseButton ? "Saving" : "Save and Close"}
              </MDButton>
              <MDButton
                sx={{ ml: 1 }}
                variant="gradient"
                disabled={disDoneButton || savebtn}
                color="info"
                onClick={handleDoneSaveRapidInventory}
              >
                {savebtn ? "Saving" : "Save"}
              </MDButton>
            </MDBox>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <Dialog
          open={openInventoryList}
          // onClose={handlecloseRapidinventory}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "670px",
                maxHeight: "60%",
                backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "center",
              display: "flex",
              color: darkMode ? "#ffffff" : "#7b809a",
            }}
          >
            {locationNewValue ? `${locationNewValue} -` : ""} Inventory
          </DialogTitle>
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              justifyContent: "end",
              display: "flex",
              mt: -7.5,
            }}
          >
            <Icon
              sx={{ cursor: "pointer", color: darkMode ? "#ffffff" : "#7b809a" }}
              onClick={handleCloseInventoryList}
            >
              close
            </Icon>
          </DialogTitle>
          <MDBox sx={{ maxHeight: "800px", overflowY: "auto" }}>
            <InventoryList
              businessId={businessId}
              restaurantId={
                restaurantId !== ":id" ? restaurantId : location.pathname.split("/").slice(-2)[0]
              }
              locationId={locationId}
            />
          </MDBox>
          <DialogActions>
            <MDBox p={1} display="flex">
              <MDButton
                sx={{ marginLeft: "0.5rem", minHeight: "2.7rem !important" }}
                variant="gradient"
                disabled={loadingCircul || disDoneButton}
                color="info"
                onClick={handleDoneInventoryList}
              >
                Edit Inventory
              </MDButton>
            </MDBox>
          </DialogActions>
        </Dialog>
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="success"
          content={
            massage
              ? massage
              : "We have taken your export request. We will send you the downloadable csv file through your email"
          }
          // autoHideDuration={3000}
          open={showSnackbar}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          content={massage ? massage : "Failed to Export"}
          // autoHideDuration={3000}
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
        <Snackbar
          open={showSuccessImport}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" sx={{ width: "100%", color: "white !important" }}>
            Inventory Import request is currently in progress.
          </Alert>
        </Snackbar>
        <Snackbar
          open={showSuccessExport}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" sx={{ width: "100%", color: "white !important" }}>
            Inventory export request is currently in progress.
          </Alert>
        </Snackbar>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(Inventory);
