import { makeStyles } from "@material-ui/core/styles";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { DataGrid, GridCellModes, useGridApiContext } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { textEllipsis } from "components/textEllipsis";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text.js";
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import LoaderDailog from "layouts/accountants/user/components/imgLoader/LoaderDailog.js";
import ColumnFilter from "layouts/accountants/user/invoice/ColumnFilter";
import "layouts/accountants/user/invoice/styles.css";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import FormField from "layouts/admin/accountants/components/FormField";
import Moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { eventBus } from "utils/eventbus";
import { inLocalTime } from "utils/timeHelper";
import { canAccess } from "../../../../helpers";
import {
  CreateComments, DeleteInvoice, DeleteItem, EditClientUserItem, EditItem, GetCategoryList, GetCategoryListForParent, GetClassListForParent, GetClassesList,
  GetCommentsList, GetCustomerList, GetCustomerListForParent, GetInvoiceDetails, GetIssueList, GetItemList, GetProductListForParent,
  GetProductsList, GetVendorList, GetclientUserAllList, GetsummoryList, GetuserClientDetails, MoveMenuInvoice, UpdateInvoiceDetails,
} from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import { InvoiceEditSchema } from "../components/schemas/editinvoicevalidation";
import DraggableAddCategoryDialog from "./components/draggableAddNewCategory";
import DraggableAddItemDialog from "./components/draggableAddNewItem";
import EditProductNewItemDialog from "./components/editdraggbleIte,";
import EditCategoryDialog from "./components/editdraggblecategory";
import EditVendorDialog from "./editvendordialog";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const myTheme = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: "rgb(123, 128, 154)",
  },
  components: {
    //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-actionsCell": {
            gridGap: "0px",
          },
          "& .MuiDataGrid-cell": {
            justifyContent: "center",
            outline: " none",
            fontWeight: "bold !important",
            color: "rgb(123 128 154)",
            // border: 0,
          },
          "& .MuiDataGrid-cell:focus": {
            outline: " none",
          },
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function EditInvoiceDialog({ invoiceId, businessId, onClose, selectTab, reloadApiAfterClose }) {
  const [invoice_id, setInvoiceId] = useState(invoiceId);
  const toolbarPluginInstance = toolbarPlugin();
  const [actualSubtotal, setActualSubtotal] = useState(0);
  const [actualTotal, setActualTotal] = useState(0);
  const { Toolbar } = toolbarPluginInstance;
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [value, setValue] = useState(1);
  const [shippingprice, setShippingprice] = useState(0);
  const [notApproved, setNotApproved] = useState(false);
  const [notApprovedCount, setNotApprovedCount] = useState(0);
  const [styles, setStyles] = useState({});
  const [totalErrorMsg, setTotalErrorMsg] = useState("");
  const [taxErrorMsg, setTaxErrorMsg] = useState("");
  const [firstTime, setFirstTime] = useState(true);
  const [checkFound, setCheckFound] = useState(true);
  const [subtotalErrorMsg, setSubtotalErrorMsg] = useState("");
  const [ocrStatus, setOcrstatus] = useState(true);
  const [showClasses, SetShowClasses] = useState(true);
  const [isSynced, setIsSynced] = useState(false);
  const [itemType, setItemType] = useState("");
  const [counts, setCounts] = useState(0);
  const [users, setUsers] = useState([]);
  const [novendorIssue, setNoVendorIssue] = useState(false);
  const [novendorIssueMsg, setNoVendorIssueMsg] = useState("");
  const [priceMismatch, setPriceMismatch] = useState(false);
  const [priceMismatchMsg, setPriceMismatchMsg] = useState("");
  const [totalMismatch, setTotalMismatch] = useState(false);
  const [totalMismatchMsg, setTotalMismatchMsg] = useState(false);
  const [onlytotalMismatch, setOnlyTotalMismatch] = useState(false);
  const [invoiceDateMismatch, setInvoiceDateMismatch] = useState(false);
  const [noProduct, setNoProduct] = useState(false);
  const [noCategory, setNoCategory] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [textAreaVal, setTextAreaVal] = useState("");
  const [submitcomment, setsubmitcomment] = useState(false);
  const [vendorname, setVendorname] = useState("");
  const [vendorname1, setVendorname1] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [invoicenumber, setInvoiceNumber] = useState("");
  const [invoicedate, setInvoiceDate] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [uploaddate, setUploadDate] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [toggleShow, setToggleShow] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openCategoryAddItem, setOpenCategoryAddItem] = useState(false);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [ponumber, setPoNumber] = useState("");
  const [discount, setDiscount] = useState(0);
  const [onChanges, SetonChanges] = useState(false);
  const [discountStore, setDiscountStore] = useState(0);
  const [documentType, setDocumentType] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [summeryList, setSummeryList] = useState([]);
  const [editbleData, setEditbleData] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({pageSize: 5,page: 0});
  const [categoryListData, setCategoryListData] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [showselectedVendorId, setShowselectedVendorId] = useState("");
  const [editItemId, setEditItemId] = useState([]);
  const [editable, SetEditable] = useState(false);
  const [notEdit, setNotEdit] = useState(false);
  const [taxStore, setTaxStore] = useState(0);
  const [subTotStore, setSubTotStore] = useState(0);
  const [issues, setIssues] = useState([]);
  const [TotalStore, setTotalStore] = useState(total);
  const [resetData, setResetData] = useState(false);
  const [documentSource, setDocumnetSource] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");
  const [finalSelect, setFinalSelect] = useState("");
  const [openQboSync, setOpenQboSync] = useState(false);
  const [openQboSyncAskClient, setOpenQboSyncAskClient] = useState(false);
  const [extention, setExtention] = useState("");
  const [itemListResponse, SetItemListResponse] = useState({});
  const [vendorerrormsg, setVendorerrormsg] = useState("");
  const [vendorerror, setVendorerror] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [rotationAngle, setRotationAngle] = useState(0);
  const [SelectedRows, setSelectedRows] = useState([]);
  const [ErrorMessages, setErrorMessage] = useState("");
  const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [controller] = useMaterialUIController();
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [filterloading, setFilterLoading] = useState(false);
  const [searchClass, setSearchClass] = useState('');
  const [classOffset, setClassOffset] = useState(0);
  const [hasMoreClasses, setHasMoreClasses] = useState(true);
  const [filterClassLoading, setFilterClassLoading] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerOffset, setCustomerOffset] = useState(0);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [productSearch, setProductSearch] = useState('');
  const [productOffset, setProductOffset] = useState(0);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const { darkMode } = controller;
  const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse },MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, {onLoad: false});
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall ] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [{ isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse },CommentsListAPICall] = useAPICall(GetCommentsList, { onLoad: false });
  const [{ isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse },DeleteItemListAPICall] = useAPICall(DeleteItem, { onLoad: false });
  const [{isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
  const [{isLoading: isDeleteInvoiceLoading,isError: isDeleteInvoiceError,response: DeleteInvoiceResponse},DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
  const [{isLoading: isCreateComentsLoading,isError: isCreateComentsError,response: CreateComentsResponse},CreateComentsListAPICall] = useAPICall(CreateComments, { onLoad: false });
  const [{isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse },VendorListAPICall] = useAPICall(GetVendorList, { onLoad: false });
  const [{isLoading: isItemEditLoading, isError: isItemEditError, response: ItemEditResponse },ItemEditListAPICall] = useAPICall(EditClientUserItem, { onLoad: false });
  const [{response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails,{ onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryList, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesList, { onLoad: false });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerListAPICall,
  ] = useAPICall(GetCustomerList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsList, { onLoad: false });
  const [
    { isError: isEditeItemError, isLoading: isEditeItemLoading, response: EditeItemResponse },
    setEditItemAPICall,
  ] = useAPICall(EditItem, { onLoad: false });
  const [
    { isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse },
    IssueListAPICall,
  ] = useAPICall(GetIssueList, { onLoad: false });
  const [
    { isError: isGetUserError, isLoading: isGetUserLoading, response: GetUserResponse },
    GetUsersetAPICall,
  ] = useAPICall(GetclientUserAllList, { onLoad: false });

  const [
    { isLoading: isGetsummoryListLoading, isError: isGetsummoryListError, response: GetsummoryListResponse },
    GetsummoryListListAPICall,
  ] = useAPICall(GetsummoryList, { onLoad: false });

  const useStyles = makeStyles((theme) => ({
    tabs: {
      "& .MuiTab-root": {
        backgroundColor: "#202940e3 !important",
        color: "#ffffff !important",
        borderRadius: 4,
        margin: '2px',
        padding:'6px',
        "&:hover": {
          backgroundColor: "#38405585 !important", // Change label color to blue on hover
      },
      },
      "& .MuiTabs-flexContainer": {
        backgroundColor: darkMode ?  "#1a2035 !important" : '#ffffff',
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#ffffff !important",
        backgroundColor: "#006cbe !important" ,
        margin: '2px',
        padding:'6px',
        borderRadius: 4,
        "&:hover": {
          backgroundColor: "#006cbe !important" , // Change label color to blue on hover
      },
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  const classes = useStyles();

  const mentionsInputStyle = {
    control: {
      backgroundColor: 'transparent',
      fontSize: 16,
      border: "2px solid #ccc", // Example: changing border color
      "::placeholder": {
        color: darkMode ? "#888 !important" : "#aaa", // Adjust placeholder color here
      },
    },

    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 70,
        backgroundColor: "transparent", // Example: changing background color
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        border: "1px solid silver",
        color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
        "&::placeholder": {
          color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
        },
      },
    },

    placeholder: {
      color: 'gray', // Change placeholder color here
    },
  
    "&singleLine": {
      display: "inline-block",
      width: 180,
  
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
        color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
        // Add the placeholder color here
        "&::placeholder": {
          color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
          opacity: 1, // Ensure placeholder is fully opaque
        },
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "white",
        },
      },
    },
  };

  useEffect(() => {
    if (isGetsummoryListLoading) {
      return () => null;
    }
    if (isGetsummoryListError) {
      setLoading(false);
      return () => null;
    }
    if (GetsummoryListResponse && GetsummoryListResponse.data.isSuccess === true) {
      const data = GetsummoryListResponse?.data?.data?.summary.map((prop, key) => {
        function generateUniqueNumberID() {
          const timestamp = Date.now(); // Current timestamp in milliseconds
          const randomNum = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
          return `${timestamp}${randomNum}`;
      }
      
        const uniqueID = generateUniqueNumberID();
        return {
          id: uniqueID,
          name: prop?.name ? prop?.name : "---",
          total: prop?.total ? prop?.total : "---",
        };
      });
        setFirstTime(false);
        setSummeryList(data);
        setLoading(false);
        setTimeout(() => {
        setLoading(false);
      }, 2000);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [GetsummoryListResponse]);

  useEffect(async () => {
    GetItemListListAPICall({
      payload: [{ documentId: `${invoice_id}` }],
    });
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }
    if (isGetUserError) {
      return () => null;
    }
    if (GetUserResponse && GetUserResponse.data.isSuccess === true) {
      const data = GetUserResponse?.data?.data?.records.map((prop, key) => {
        return {
          id: prop?._id,
          display: prop?.fname,
        };
      });
      setUsers(data);
    }
  }, [GetUserResponse]);
  useEffect(() => {
    IssueListAPICall({
      payload: [{ businessId: `${businessId}`, documentId: `${invoiceId}` }],
    });
  }, [response]);
  useEffect(() => {
    if (isIssueLoading) {
      return () => null;
    }
    if (isIssueError) {
      setLoading(false);
      return () => null;
    }
    if (IssueResponse && IssueResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ documentId: `${invoiceId}` }],
      });
      if (IssueResponse?.data?.data?.totalRecords === 0) {
        setNoVendorIssue(false);
        setPriceMismatch(false);
        setTotalMismatch(false);
        setInvoiceDateMismatch(false);
        setNoProduct(false);
        setNoCategory(false);
      } else if (editable == false) {
        let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType !== "ITEM");
        IssueResponse?.data?.data?.records?.map((prop, key) => {
          prop?.issueType === "NOVENDOR"
            ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`)
            : setNoVendorIssue(false);
          prop?.issueType === "NOSUBTOTAL"
            ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`)
            : setPriceMismatch(false);
          prop?.issueType === "TOTALMISMATCH"
            ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`)
            : setTotalMismatch(false);
          prop?.issueType === "PRICEMISMATCH"
            ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`)
            : setPriceMismatch(false);
          prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
          prop?.issueType === "NOINVOICE_DATE"
            ? setInvoiceDateMismatch(true)
            : setInvoiceDateMismatch(false);
          prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : setNoProduct(false);
          prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : setNoCategory(false);
        });
      }
    }
  }, [IssueResponse]);

  useEffect(() => {
    const results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType === "DOCUMENT");
    results?.map((prop, key) => {
      prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) : "";
      prop?.issueType === "NOSUBTOTAL" ? setPriceMismatch(true) : "";
      prop?.issueType === "TOTALMISMATCH" ? setTotalMismatch(true) : "";
      prop?.issueType === "PRICEMISMATCH" ? setPriceMismatch(true) : "";
      prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : "";
      prop?.issueType === "NOINVOICE_DATE" ? setInvoiceDateMismatch(true) : "";
      prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : "";
      prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : "";
    });
  }, [IssueResponse, editable, itemList]);

  useEffect(() => {
    finalSelect == "To Review" ? setNotEdit(true) : setNotEdit(false);
  }, [finalSelect]);

  useEffect(async () => {
    if (isEditeItemLoading) {
      return () => null;
    }
    if (isEditeItemError) {
      setLoading(false);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      if (EditeItemResponse?.data?.message) {
        setErrorMessage(EditeItemResponse.data.message);
      }
      setShowErrorMoveSnackbar(true);
      setTimeout(() => {
        setShowErrorMoveSnackbar(false);
      }, 4000);
      return () => null;
    }
    if (EditeItemResponse && EditeItemResponse.data.isSuccess === true) {
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, documentId: `${invoiceId}` }],
      });
      setTimeout(() => {
        GetItemListListAPICall({
          payload: [{ documentId: `${invoice_id}` }],
        });
        DetailsUserAPICall({
          payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }, 2000);
    }
  }, [EditeItemResponse]);
  var resource = "";
  var actions = "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  useEffect(async () => {
    await IssueListAPICall({
      payload: [{ businessId: `${businessId}`, documentId: `${invoiceId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await GetUsersetAPICall({
      payload: [{ keyword: `` }],
    });
    await GetsummoryListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` , businessId: `${businessId}`}],
    });
  }, []);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      setLoading(false);
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      // setClassesList(data);
      setLoading(false);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoading(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      // setCategoryListData(data);
      setLoading(false);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      setLoading(false);
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
          sku: prop.sku,
          unitOfMeasure: prop.unitOfMeasure,
        };
      });
      // setProductsList(data);
      setLoading(false);
    }
  }, [ProductsResponse]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      setLoading(false);
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          customerId: prop?._id,
          productId: prop?._id,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
          sku: prop?.sku,
          unitOfMeasure: prop?.unitOfMeasure,
        };
      });
      // setCustomerList(data);
      setLoading(false);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      SetShowClasses(
        clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs
          ?.ClassTrackingPerTxnLine
      );
    }
  }, [clientDetailsDataResponse]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const AprroveFun = async (itemID) => {
    const ItemResp = GetItemListResponse ? GetItemListResponse : itemListResponse;
    const founds = ItemResp?.data?.data?.records.find((obj) => {
      return obj._id === itemID;
    });
    if (founds) {
      setLoading(true);
      setTimeout(async () => {
        await ItemEditListAPICall({
          payload: [
            {
              documentId: `${invoiceId}`,
              ItemId: itemID,
              askClient: founds?.askClient === true ? false : true,
              itemType: founds?.itemType,
              clientApproved: founds?.clientApproved === true ? false : true,
              unitPrice: founds?.unitPrice,
              amount: founds?.amount,
              discount: founds?.discount,
              categoryId: founds?.category?._id,
              productId: founds?.product?._id,
              quantity: founds?.quantity ? founds?.quantity : 1,
              description: founds?.description,
              classId: founds?.class?._id,
              customerId: founds?.customer?._id,
            },
          ],
        });
      }, 1000);
    }
  };
  useEffect(() => {
    if (isItemEditLoading) {
      return () => null;
    }
    if (isItemEditError) {
      setLoading(false);
      return () => null;
    }
    if (ItemEditResponse && ItemEditResponse.data.isSuccess === true) {
      setLoading(false);
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
    }
  }, [ItemEditResponse]);
  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoading(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      setVendorList(VendorResponse.data.data.records);
    }
  }, [VendorResponse]);
  useEffect(() => {
    if (isMoveMenuLoading) {
      return () => null;
    }
    if (isMoveMenuError) {
      setLoading(false);
      return () => null;
    }
    if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
      closeMoveDialog();
      setLoading(true);
      setShowSnackbar(true);
      setTimeout(() => {
        onClose();
      }, 700);
    }
  }, [MoveMenuResponse]);

  useEffect(() => {
    if (isCreateComentsLoading) {
      return () => null;
    }
    if (isCreateComentsError) {
      setBtnDisable1(false);
      setLoading(false);
      return () => null;
    }
    if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
      setTextAreaVal("");
      setsubmitcomment(false);
      setBtnDisable1(false);
      CommentsListAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  }, [CreateComentsResponse]);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
      setLoading(true);
    }
  }, [DeleteInvoiceResponse]);

  useEffect(() => {
    if (isGetItemListLoading) {
      return () => null;
    }
    if (isGetItemListError) {
      return () => null;
    }
    if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
      SetItemListResponse(GetItemListResponse);
      const data = GetItemListResponse.data.data.records.map((prop, key) => {
        const Error = IssueResponse?.data?.data?.records.find((obj) => {
          return obj.moduleId === prop._id;
        });
        return {
          item: prop?.description ? prop?.description : "---",
          qty: prop?.quantity ? prop?.quantity : "---",
          unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
          product: prop?.product?.name ? prop?.product?.name : "---",
          price: prop?.unitPrice ? prop?.unitPrice : "0",
          total: prop?.amount ? prop?.amount : "---",
          customer: prop?.customer?.name ? prop?.customer?.name : "---",
          category: prop?.category?.name ? prop?.category?.name : "---",
          class: prop?.class?.name ? prop?.class?.name : "---",
          proderror: Error ? true : false,
          proderrorMsg: Error ? Error?.message : "---",
          id: prop._id,
          sku: prop.sku,
          itemType: prop.itemType,
          askClient: prop?.askClient,
        };
      });
      const found = data.find((obj) => {
        return obj.askClient === true;
      });
      setCheckFound(found === undefined);
      // setItemList(data);
      let itemsss = data.find((item) => item.clientApproved === false);
      var obj = {};
      data.forEach(function (item) {
        obj[item.clientApproved] ? obj[item.clientApproved]++ : (obj[item.clientApproved] = 1);
      });
      setNotApprovedCount(obj?.false);
      if (itemsss === undefined) {
        setNotApproved(false);
      } else {
        setNotApproved(true);
      }
      const Categoryresult = data.filter(
        (data) => data.itemType === "AccountBasedExpenseLineDetail"
      );
      const Productresult = data.filter((data) => data.itemType === "ItemBasedExpenseLineDetail");
      setCategoryList(data);
      setProductList(data);
      setLoading(false);
      setShowDetails(true);
      setLoadingCircul(false);
      if (firstTime === true) {
        if (Categoryresult.length === 0) {
          // setToggleShow(true),
          setItemList(data), setLoading(false), setFirstTime(false);
        } else {
          // setToggleShow(false),
          setItemList(data), setLoading(false), setFirstTime(false);
        }
      } else {
        setLoading(false), toggleShow === true ? setItemList(data) : setItemList(data);
      }
    }
  }, [GetItemListResponse]);

  useEffect(() => {
    if (productList || categoryList) {
      {
        toggleShow === true ? setItemList(productList) : setItemList(categoryList);
      }
    }
  }, [toggleShow]);

  const closeProductEdit = async () => {
    setOpenEditItem(false);
  };

  useEffect(() => {
    if (isDeleteItemLoading) {
      return () => null;
    }
    if (isDeleteItemError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
      setLoading(false);
    }
  }, [DeleteItemResponse]);

  useEffect(() => {
    if (isCommentsLoading) {
      return () => null;
    }
    if (isCommentsError) {
      return () => null;
    }
    if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
      const LogCommentdata = CommentsResponse?.data?.data?.records.map((prop, key) => {
        const commentBy =
          (prop?.commentBy?.fname ? prop.commentBy.fname.charAt(0).toUpperCase() + prop.commentBy.fname.slice(1) : "") +
          " " +
          (prop?.commentBy?.lname ? prop.commentBy.lname : "");
      
        const comments = prop.comment;
      
        // Extract {{tags}} from comments
        const found = [];
        const rxp = /{{([^}]+)}}/g;
        let curMatch;
      
        while ((curMatch = rxp.exec(comments))) {
          found.push(curMatch[1]);
        }
      
        // Replace {{tag}} with @username
        function findReplaceString(string, found) {
          let results = string.match(/{{([^}]+)}}/g);
          if (results) {
            found.forEach((id, idx) => {
              const Name = users.find((obj) => obj.id == id); // Find user by ID
              const username = `@${Name?.display || "unknown"}`;
              string = string.replace(new RegExp(`{{\\s*${id}\\s*}}`), `<span class="tagged-name">${username}</span>`);
            });
          }
          return string;
        }
      
        const datacom = findReplaceString(comments, found);
      
        return (
          <Paper
            style={{
              background: darkMode ? "#394366" : "#ffffff",
              padding: "30px 20px",
              borderBottom: darkMode ? "2px solid #ffffff" : "",
            }}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
              </Grid>
              <Grid justifyContent="left" item xs zeroMinWidth>
                <div style={{ display: "flex" }}>
                  <h4
                    style={{
                      margin: 0,
                      textAlign: "left",
                      fontFamily: "Arial",
                      fontSize: "0.775rem",
                      fontWeight: "bold",
                      lineHeight: 1.5,
                      textTransform: "none",
                      marginBottom: "5px",
                      color: darkMode ? "#ffffff" : "",
                    }}
                  >
                    {commentBy}
                  </h4>
                  <MDTypography sx={{ color: darkMode ? "#ffffff" : "", mt: 0.5, ml: 1 }} variant="caption" color="text">
                    {inLocalTime(prop.createdAt)}
                  </MDTypography>
                </div>
                <p
                  style={{
                    color: darkMode ? "#ffffff" : "",
                    margin: 0,
                    fontFamily: "Arial",
                    fontSize: "0.775rem",
                    fontWeight: 300,
                    lineHeight: 1.5,
                    textTransform: "none",
                    textAlign: "left",
                  }}
                  dangerouslySetInnerHTML={{ __html: datacom || comments }}
                ></p>
              </Grid>
            </Grid>
          </Paper>
        );
      });
      
      setCommentsList(LogCommentdata);
      setLoading(false);
    }
  }, [CommentsResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("refreshListAfterUpdate", response);
      DetailsUserAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      setShowSnackbar(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      setLoading(true);
    }
  }, [response]);

  useEffect(async () => {
    setSelectedRows([invoice_id]);
    await DetailsUserAPICall({
      payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    await VendorListAPICall({
      payload: [{ businessId: `${businessId}`, IsSynced: true }],
    });
  }, []);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAddItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAddItem]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      setDocumentStatus(DetailsResponse?.data?.data?.document?.documentStatus? DetailsResponse?.data?.data?.document?.documentStatus : "" );
      DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail" ? setToggleShow(true) : setToggleShow(false);
      DetailsResponse?.data?.data?.document?.documentSource === "QBO" ? setEditbleData(false) : setEditbleData(true);
      setItemType(DetailsResponse?.data?.data?.document?.itemType);
      setOcrstatus( DetailsResponse?.data?.data?.document?.ocrStatus === "INPROGRESS" ? true : false);
      setIsSynced(DetailsResponse?.data?.data?.document?.isSynced);
      setVendorname( DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : "");
      setVendorname1( DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : "" );
      setInvoiceNumber( DetailsResponse.data.data.document.invoice_number ? DetailsResponse.data.data.document.invoice_number : DetailsResponse.data.data.document.docNumber);
      setPoNumber( DetailsResponse.data.data.document.order_no ? DetailsResponse.data.data.document.order_no : "");
      setInvoiceDate(Moment(DetailsResponse.data.data.document.invoice_date).format("YYYY-MM-DD"));
      setTransactionDate(Moment(DetailsResponse.data.data.document.transactionDate).format("YYYY-MM-DD"));
      setUploadDate(Moment(DetailsResponse.data.data.document.createdAt).format("YYYY-MM-DD"));
      setTotal(DetailsResponse.data.data.document.total);
      setTotalStore(DetailsResponse.data.data.document.total);
      setTax(DetailsResponse.data.data.document.tax);
      setTaxStore(DetailsResponse.data.data.document.tax);
      setDiscount(DetailsResponse?.data?.data?.document?.discount);
      setDiscountStore(DetailsResponse?.data?.data?.document?.discount);
      setPoNumber(DetailsResponse.data.data.document.purchaseOrderNo);
      setIssues(DetailsResponse.data.data.document.issues);
      setShippingprice(DetailsResponse?.data?.data?.document?.shippingPrice);
      setSubTotal(DetailsResponse.data.data.document.sub_total ? DetailsResponse.data.data.document.sub_total : DetailsResponse.data.data.document.subTotal);
      setSubTotStore(DetailsResponse.data.data.document.sub_total ? DetailsResponse.data.data.document.sub_total : DetailsResponse.data.data.document.subTotal );
      setCurrencySymbol(DetailsResponse.data.data.document.currencySymbol);
      setDocumnetSource( DetailsResponse.data.data.document.documentSource ? DetailsResponse.data.data.document.documentSource : "" );
      setDocumentType( DetailsResponse.data.data.document.documentType ? DetailsResponse.data.data.document.documentType : "" );
      setCreateObjectURL( DetailsResponse.data.data.document.uploadedFileUrl ? DetailsResponse.data.data.document.uploadedFileUrl : "");
      setImageUrl( DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl  : "");
      setActualSubtotal(DetailsResponse?.data?.data?.document?.actualSubtotal);
      setActualTotal(DetailsResponse?.data?.data?.document?.actualTotal);
      // setShowDetails(true);
      setLoading(false);
      setShowDetailsForm(true);
      setResetData(false);
      setInitialValues({
        vendorname: DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : "",
        documenttype: DetailsResponse.data.data.document.documentType ? DetailsResponse.data.data.document.documentType : "",
        invoicenumber: DetailsResponse.data.data.document.docNumber,
        ponumber: DetailsResponse.data.data.document.purchaseOrderNo,
        invoicedate: Moment(DetailsResponse.data.data.document.invoice_date).format("YYYY-MM-DD"),
        uploaddate: Moment(DetailsResponse.data.data.document.createdAt).format("YYYY-MM-DD"),
        total: `${total}`,
        tax: `${tax}`,
        transactionDate: `${transactionDate}`,
        subtotal: `${subtotal}`,
      });
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, documentId: `${invoiceId}` }],
      });
    }
  }, [DetailsResponse]);

  useEffect(() => {
    setInitialValues({
      documenttype: documentType,
      invoicenumber: invoicenumber,
      ponumber: ponumber,
      invoicedate: invoicedate,
      uploaddate: uploaddate,
      total: `${total}`,
      tax: `${tax}`,
      transactionDate: `${transactionDate}`,
      subtotal: `${subtotal}`,
    });
  }, [resetData]);

  const closeMoveDialog = async () => {
    setOpenQboSync(false);
    setOpenQboSyncAskClient(false);
  };

  const successInoiceMove = async () => {
    setLoading(true);
    closeMoveDialog();
    const MoveTo = issues?.documents?.count === 0 && issues?.items?.count === 0 && issues?.vendor?.count === 0 ? "ToReview" : "Classify";
    await MoveMenuListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: SelectedRows,
            documentStatus: `${MoveTo}`,
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setLoading(true);
  };

  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ");
  }

  const handleClosebtn = async () => {
    setVendorname(vendorname1);
    setResetData(true);
    SetEditable(false);
    await DetailsUserAPICall({
      payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  };

  const handleChange = async (event, newValue) => {
    setFirstTime(true);
    setValue(newValue);
    if (newValue === 1) {
      setLoading(true);
      await GetItemListListAPICall({
        payload: [{ documentId: `${invoice_id}` }],
      });
    } else if (newValue === 2) {
      setLoading(true);
      await GetsummoryListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` , businessId: `${businessId}`}],
      });
    } else if (newValue === 3) {
      setLoading(true);
      await CommentsListAPICall({
        payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  };
  const handleSubmit = async (event, values) => {
    SetonChanges(false);
    await setAPICall({
      payload: [
        {
          txnDate: `${event.transactionDate}`,
          createdAt: `${event.uploaddate}`,
          documentType: `${event.documenttype}`,
          docNumber: `${event.invoicenumber}`,
          vendorId: `${showselectedVendorId}`,
          subTotal: `${subTotStore}`,
          tax: `${taxStore}`,
          total: `${TotalStore}`,
          purchaseOrderNo: `${event.ponumber}`,
          businessId: `${businessId}`,
          documentId: `${invoice_id}`,
        },
      ],
    });
    eventBus.$emit("refreshApiInvoiceList", event);
  };

  const handleToggle = () => {
    setSummeryList([]);
    setToggleShow(!toggleShow);
    setLoading(true),
      setAPICall({
        payload: [
          {
            itemType:
              toggleShow === false ? `ItemBasedExpenseLineDetail` : `AccountBasedExpenseLineDetail`,
            businessId: `${businessId}`,
            documentId: `${invoice_id}`,
          },
        ],
      });
  };
  const handleAddItem = () => {
    setOpenAddItem(true);
  };
  const handleCategort = () => {
    setOpenCategoryAddItem(true);
  };
  const handleEditItem = () => {
    setOpenEditItem(true);
  };

  const hideAlert = () => {
    setAlert(false);
  };
  const successDelete = () => {
    DeleteItemListAPICall({
      payload: [
        {
          adjustInvoice: false,
          documentId: `${invoice_id}`,
          ItemId: `${itemId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };
  const successInoiceDelete = () => {
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: [invoiceId],
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  function SelectCategoryEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleCategoryChanges = async (event) => {
      setSearch(''); // Clear previous search
      setHasMore(true); // Enable fetching more data
      fetchCategories('', 0); // Trigger fresh API call on open
      if (event.target.textContent == "+ Add Category") {
        // setLoadingCircul(true);
        setOpenAddNewCategory(true);
      } else {
        setLoadingCircul(true);
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = categoryListData.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (results) {
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: found?.categoryId,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      }
    };

    const fetchCategories = async (newSearch = '', newOffset = 0) => {  
      setFilterLoading(true);
      try {
        const response = await GetCategoryListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearch}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          categoryId: prop._id,
        }));
  
        if (newOffset === 0) {
          setCategoryListData(data);
        } else {
          setCategoryListData((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMore(false); // No more data to load
        }
  
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
      setFilterLoading(false);
    };
    return (
      <>
      <Autocomplete
        id="category-autocomplete"
        fullWidth
        autoSelect
        open // Always keep the dropdown open
        value={null}
        options={categoryListData}
        getOptionLabel={(option) => option.name}
        disableClearable
        onFocus={() => {
          // Always trigger data fetching on focus
          setSearch(''); // Clear any existing search term
          setHasMore(true); // Reset hasMore for loading more items
          fetchCategories('', 0); // Fetch initial data when input is focused
        }}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
          setHasMore(true); // Reset hasMore for loading more items
          fetchCategories(newInputValue, 0); // Fetch data based on the search term
        }}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            const tolerance = 5; 
            const bottom =
              Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;        
            if (bottom && hasMore) {
              // Increase the offset
              setOffset((prevOffset) => {
                const newOffset = prevOffset + 50; // Compute new offset
                fetchCategories(search, newOffset); // Fetch next set of data using new offset
                return newOffset; // Return new offset to update state
              });
            }
          },
          sx: {
            maxHeight: '200px', // Set a max-height to enable scrolling
            overflowY: 'auto',  // Enable vertical scrolling
            fontSize: '0.8125rem',
            '@media (max-width: 1440px)': {
              fontSize: '0.775rem',
            },
          },
        }}
        
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {filterloading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={handleCategoryChanges}
      />
      </>
    );
  }

  function getPriceProduct(params) {
    return `$${params?.row?.price}`;
  }
  function getTotalProduct(params) {
    return `$${params?.row?.total}`;
  }

  function getQuantity(params) {
    const finalQty =
      params?.row?.itemType === "AccountBasedExpenseLineDetail"
        ? params?.row?.qty
        : params?.row?.qty;
    return finalQty;
  }

  const handleDeleteInvoice = () => {
    // setOpenMenu(false)
    handleClose();

    setAlertDeleteInvoice(true);
  };

  function SelectProductEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleProductChanges = async (event) => {
      if (event.target.textContent == "+ Add Product") {
        setOpenAddOption(true);
      } else {
        setLoadingCircul(true);
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop?.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = productsList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (results) {
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : results?.unit,
                    productId: found?.productId,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
          // setDescription(found.description);
          // setUnitPrice(found.unitPrice)
        }
      }
    };

    const fetchProducts = async (newSearch = '', newOffset = 0) => {
      setIsLoadingProducts(true);
      try {
        const response = await GetProductListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearch}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
          sku: prop.sku,
          unitOfMeasure: prop.unitOfMeasure,
        }));
  
        if (newOffset === 0) {
          setProductsList(data);
        } else {
          setProductsList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreProducts(false); // No more data to load
        }
  
        setProductOffset(newOffset);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
      setIsLoadingProducts(false);
    };

    return (
      <Autocomplete
      id="product-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={productsList}
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        // Always trigger data fetching on focus
        setProductSearch(''); // Clear any existing search term
        setProductOffset(0); // Reset the offset
        setHasMoreProducts(true); // Reset hasMoreProducts for loading more items
        fetchProducts('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setProductSearch(newInputValue);
        setProductOffset(0); // Reset the offset
        setHasMoreProducts(true); // Reset hasMoreProducts for loading more items
        fetchProducts(newInputValue, 0); // Fetch data based on the search term
      }}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreProducts) {
            setProductOffset((prevOffset) => {
              const newOffset = prevOffset + 50; // Compute new offset
              fetchProducts(productSearch, newOffset); // Fetch next set of data using new offset
              return newOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingProducts ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleProductChanges}
      renderOption={(props, option) => (
        <li {...props} title={option.name}>
          {option.name}
        </li>
      )}
    />
    );
  }



  function SelectClassEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleClassChanges = async (event) => {
      setSearchClass(''); // Clear previous search
      setClassOffset(0); // Reset offset
      setHasMoreClasses(true); // Enable fetching more data
      fetchClasses('', 0); // Trigger fresh API call on open
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = classesList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (event.target.textContent == "None") {
          setEditItemAPICall({
            payload: [
              {
                itemType: "AccountBasedExpenseLineDetail",
                unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                unitOfMeasure: results?.unit ? results?.unit : null,
                quantity: Number(Qty ? Qty : 1),
                discount: 0,
                description: results?.item,
                InvoiceId: invoiceId ? invoiceId : documentsId,
                classId: "None",
                customerId: results?.customerId ? results?.customerId : null,
                categoryId: results?.categoryId ? results?.categoryId : null,
                askClient: results.askClients,
                ItemId: results.id,
              },
            ],
          });
        } else if (results) {
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: found?.classId,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: found?.classId,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      
    };

    const fetchClasses = async (newSearchClass = '', newClassOffset = 0) => {
      setFilterClassLoading(true);
      try {
        const response = await GetClassListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearchClass}`,
              offset: newClassOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          classId: prop._id,
        }));
  
        if (newClassOffset === 0) {
          setClassesList(data);
        } else {
          setClassesList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreClasses(false); // No more data to load
        }
  
        setClassOffset(newClassOffset);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
      setFilterClassLoading(false);
    };

    return (
      <Autocomplete
      id="class-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={classesList}
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        setSearchClass(''); // Clear any existing search term
        setClassOffset(0); // Reset the offset
        setHasMoreClasses(true); // Reset hasMore for loading more items
        fetchClasses('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setSearchClass(newInputValue);
        setClassOffset(0); // Reset the offset
        setHasMoreClasses(true); // Reset hasMore for loading more items
        fetchClasses(newInputValue, 0); // Fetch data based on the search term
      }}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom =
            Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreClasses) {
            setClassOffset((prevOffset) => {
              const newClassOffset = prevOffset + 50; // Compute new offset
              fetchClasses(searchClass, newClassOffset); // Fetch next set of data using new offset
              return newClassOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {filterClassLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleClassChanges}
    />
    );
  }



  function SelectCustomerEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const fetchCustomers = async (searchTerm = '', newOffset = 0) => {
      setIsLoadingCustomers(true);
      try {
        const response = await GetCustomerListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${searchTerm}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
        const data = response.data?.data?.records.map((prop) => ({
          name: prop?.name,
          customerId: prop?._id,
          productId: prop?._id,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
          sku: prop?.sku,
          unitOfMeasure: prop?.unitOfMeasure,
        }));

        if (newOffset === 0) {
          setCustomerList(data);
        } else {
          setCustomerList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreCustomers(false); // No more data to load
        }
  
        setCustomerOffset(newOffset);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
      setIsLoadingCustomers(false);
    };

    const handleCustomerChanges = async (event) => {
      setCustomerSearch(''); // Clear previous search
      setCustomerOffset(0); // Reset offset
      setHasMoreCustomers(true); // Enable fetching more data
      fetchCustomers('', 0); // Trigger fresh API call on open
      if (event.target.textContent == "+ Add Customer") {
        setOpenAddNewCustomer(true);
      } else {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = customerList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (event.target.textContent == "None") {
          setEditItemAPICall({
            payload: [
              {
                itemType: "AccountBasedExpenseLineDetail",
                unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                unitOfMeasure: results?.unit ? results?.unit : null,
                quantity: Number(Qty ? Qty : 1),
                discount: 0,
                description: results?.item,
                InvoiceId: invoiceId ? invoiceId : documentsId,
                classId: results?.classId ? results?.classId : null,
                customerId: "None",
                categoryId: results?.categoryId ? results?.categoryId : null,
                askClient: results.askClients,
                ItemId: results.id,
              },
            ],
          });
        } else if (results) {
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: found?.customerId,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: found?.customerId,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
          // setDescription(found.description);
          // setUnitPrice(found.unitPrice)
        }
      }
    };

    return (
      <Autocomplete
      id="customer-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={customerList}
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        // Always trigger data fetching on focus
        setCustomerSearch(''); // Clear any existing search term
        setCustomerOffset(0); // Reset the offset
        setHasMoreCustomers(true); // Reset hasMoreCustomers for loading more items
        fetchCustomers('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setCustomerSearch(newInputValue);
        setCustomerOffset(0); // Reset the offset
        setHasMoreCustomers(true); // Reset hasMoreCustomers for loading more items
        fetchCustomers(newInputValue, 0); // Fetch data based on the search term
      }}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreCustomers) {
            setCustomerOffset((prevOffset) => {
              const newOffset = prevOffset + 50; // Compute new offset
              fetchCustomers(customerSearch, newOffset); // Fetch next set of data using new offset
              return newOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleCustomerChanges}
      renderOption={(props, option) => (
        <li {...props} title={option.name}>
          {option.name}
        </li>
      )}
    />
    );
  }

  const columns = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 110,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editbleData && notEdit,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
              },
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 65,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTION",
      minWidth: 75,
      flex: 0.1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              clientApproved: prop?.clientApproved,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = result.find((obj) => {
          return obj.id === id;
        });
        return [
          <>
            {found?.askClients === true ? (
              <Button
                sx={{
                  "@media (max-width: 1440px)": {
                    fontSize: `0.7rem`,
                  },
                }}
                onClick={() => AprroveFun(id)}
              >
                {" "}
                Approve
              </Button>
            ) : found?.clientApproved === true ? (
              <Icon color="success" fontSize="medium">
                check
              </Icon>
            ) : (
              ""
            )}
            {isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon style={{color: darkMode ? "#ffffff" : "#7b809a"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon fontSize="small" style={{color: darkMode ? "#ffffff" : "#000000"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    // setAccountantId(prop._id);
                    setEditItemId(id);
                    handleEditItem();
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  const columnsWithoutAction = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 110,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editbleData && notEdit,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
              },
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 65,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const columnsWitoutClass = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 60,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editbleData && notEdit,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 25,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 40,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTION",
      minWidth: 55,
      flex: 0.1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              clientApproved: prop?.clientApproved,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = result.find((obj) => {
          return obj.id === id;
        });
        return [
          <>
            {found?.askClients === true ? (
              <Button
                sx={{
                  "@media (max-width: 1440px)": {
                    fontSize: `0.7rem`,
                  },
                }}
                onClick={() => AprroveFun(id)}
              >
                {" "}
                Approve
              </Button>
            ) : found?.clientApproved === true ? (
              <Icon color="success" fontSize="medium">
                check
              </Icon>
            ) : (
              ""
            )}
            {isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon style={{color: darkMode ? "#ffffff" : "#7b809a"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon fontSize="small" style={{color: darkMode ? "#ffffff" : "#000000"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    // setAccountantId(prop._id);
                    setEditItemId(id);
                    handleEditItem();
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  const columnsWitoutClassAction = [
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 60,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editbleData && notEdit,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 25,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 40,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumns = [
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 70,
      disableColumnMenu: true,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      maxWidth: 71,
      flex: 0.2,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              clientApproved: prop?.clientApproved,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = result.find((obj) => {
          return obj.id === id;
        });
        return [
          <>
            {found?.askClients === true ? (
              <Button
                sx={{
                  "@media (max-width: 1440px)": {
                    fontSize: `0.7rem`,
                  },
                }}
                onClick={() => AprroveFun(id)}
              >
                {" "}
                Approve
              </Button>
            ) : found?.clientApproved === true ? (
              <Icon color="success" fontSize="medium">
                check
              </Icon>
            ) : (
              ""
            )}
            {isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon style={{color: darkMode ? "#ffffff" : "#7b809a"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon fontSize="small" style={{color: darkMode ? "#ffffff" : "#000000"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    // setAccountantId(prop._id);
                    setEditItemId(id);
                    handleEditItem();
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  const cetagorycolumnsWithotAction = [
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 70,
      disableColumnMenu: true,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumnsWithoutClassAction = [
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumnsWithoutClass = [
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editbleData && notEdit,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editbleData && notEdit,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      minWidth: 60,
      flex: 0.3,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              clientApproved: prop?.clientApproved,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = result.find((obj) => {
          return obj.id === id;
        });
        return [
          <>
            {found?.askClients === true ? (
              <Button
                sx={{
                  "@media (max-width: 1440px)": {
                    fontSize: `0.7rem`,
                  },
                }}
                onClick={() => AprroveFun(id)}
              >
                {" "}
                Approve
              </Button>
            ) : found?.clientApproved === true ? (
              <Icon color="success" fontSize="medium">
                check
              </Icon>
            ) : (
              ""
            )}
            {isSynced === true ? (
              <>
                <GridActionsCellItem
                  icon={<EditIcon style={{color: darkMode ? "#ffffff" : "#7b809a"}}/>}
                  label="Edit"
                  className="textPrimary"
                  onClick={(value) => {
                    setOpenQboSync(true);
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
                />
              </>
            ) : (
              <>
                <GridActionsCellItem
                  icon={<EditIcon fontSize="small" style={{color: darkMode ? "#ffffff" : "#000000"}} />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    // setAccountantId(prop._id);
                    setEditItemId(id);
                    handleEditItem();
                  }}
                  sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                />
              </>
            )}
          </>,
        ];
      },
    },
  ];

  const summarycetagorycolumns = [
    {
      field: "name",
      headerName: "CATEGORY",
      // renderEditCell: SelectCategoryEditInputCell,
      // editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 75,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const [categoryFilter, setCategoryFilter] = useState({}); // Filter input state for category
const [filteredCategoryRows, setFilteredCategoryRows] = useState(summeryList); // Filtered category rows
const categoryFilterRefs = useRef({}); // Ref for category filter inputs

useEffect(() => {
  const filtered = summeryList.filter((row) =>
    Object.entries(categoryFilter).every(([column, value]) =>
      String(row[column]).toLowerCase().includes(value.toLowerCase())
    )
  );
  setFilteredCategoryRows(filtered);
}, [categoryFilter, summeryList]);

const handleCategoryFilterChange = (field, value) => {
  setCategoryFilter((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const summaryCategoryColumns = summarycetagorycolumns.map((col) => ({
  ...col,
  renderHeader: () => (
    <div style={{ display: 'grid', marginTop: '10px' }}>
      {/* <div
        style={
          darkMode
            ? { display: 'flex', justifyContent: 'center', color: '#ffffff' }
            : { display: 'flex', justifyContent: 'center' }
        }
      >
        {col.headerName}
      </div> */}
      <ColumnFilter
        column={col}
        filterText={categoryFilter[col.field] || ''} // Updated category filter state
        onFilterChange={handleCategoryFilterChange} // Updated filter change handler
        ref={(el) => (categoryFilterRefs.current[col.field] = el)} // Updated ref for category filters
      />
    </div>
  ),
}));

const summaryProductcolumns = [
  {
    field: "name",
    headerName: "PRODUCT",
    // renderEditCell: SelectCategoryEditInputCell,
    // editable: editItemList && editbleData && chenging,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    minWidth: 120,
    flex: 0.4,
    renderCell: (params) => (
      <Tooltip title={params?.formattedValue} placement="top">
        <MDTypography
          sx={{
            "@media (max-width: 1440px)": {
              fontSize: "0.775rem",
              cursor: "pointer",
              color: "#7b809a",
            },
            color: "#7b809a",
          }}
        >
          {" "}
          {params?.formattedValue}
        </MDTypography>
      </Tooltip>
    ),
  },
  {
    headerName: "TOTAL",
    field: "total",
    headerAlign: "center",
    minWidth: 75,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: getTotalProduct,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title={params?.formattedValue} placement="top">
        <MDTypography
          sx={{
            "@media (max-width: 1440px)": {
              fontSize: "0.775rem",
              cursor: "pointer",
              color: "#7b809a",
            },
            color: "#7b809a",
          }}
        >
          {" "}
          {params?.formattedValue}
        </MDTypography>
      </Tooltip>
    ),
  },
];

const [productFilter, setProductFilter] = useState({}); // State for product filters
const [filteredProductRows, setFilteredProductRows] = useState(summeryList); // Filtered product rows
const productFilterRefs = useRef({}); // Ref for product filters

useEffect(() => {
  const filtered = summeryList.filter((row) =>
    Object.entries(productFilter).every(([column, value]) =>
      String(row[column]).toLowerCase().includes(value.toLowerCase())
    )
  );
  setFilteredProductRows(filtered);
}, [productFilter, summeryList]);

const handleProductFilterChange = (field, value) => {
  setProductFilter((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const summaryProductColumns = summaryProductcolumns.map((col) => ({
  ...col,
  renderHeader: () => (
    <div style={{ display: 'grid', marginTop: '10px' }}>
      {/* <div
        style={
          darkMode
            ? { display: 'flex', justifyContent: 'center', color: '#ffffff' }
            : { display: 'flex', justifyContent: 'center' }
        }
      >
        {col.headerName}
      </div> */}
      <ColumnFilter
        column={col}
        filterText={productFilter[col.field] || ''} // Use product filter state
        onFilterChange={handleProductFilterChange} // Handle filter change for product
        ref={(el) => (productFilterRefs.current[col.field] = el)} // Use product filter refs
      />
    </div>
  ),
}));

  const dataTableData = {
    columns: [
      {
        Header: "Category",
        accessor: "category",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      // {
      //     Header: "Qty",
      //     accessor: "qty",
      //     Cell: ({ value }) => <DefaultCell value={value} />,
      // },
      {
        Header: "Price",
        accessor: "total",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };
  const dataTableData1 = {
    columns: [
      {
        Header: "Products/Service",
        accessor: "product",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: itemList,
  };


  eventBus.$on("SendToAccountant", (data) => SendToAccountant(data));
  function SendToAccountant(data) {
    if (data === "move") {
      setOpenQboSync(true);
    }
  }

  eventBus.$on("reloadDetailsTab", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    setShowDetailsForm(false);
    setOpenAddItem(false);
    setLoading(true);
    DetailsUserAPICall({
      payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeErrorEditNewItemPopup", (data) => closeErrorEditNewItemPopup(data));
  function closeErrorEditNewItemPopup(data) {
    setErrorMessage(data?.data?.message)
    setShowErrorMoveSnackbar(true);
    setTimeout(() => {
      setShowErrorMoveSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeErrorAddNewItemPopup", (data) => closeErrorAddNewItemPopup(data));
  function closeErrorAddNewItemPopup(data) {
    setErrorMessage(data?.data?.message)
    setShowErrorMoveSnackbar(true);
    setTimeout(() => {
      setShowErrorMoveSnackbar(false);
    }, 3000);
  }


  eventBus.$on("closeAddNewItemPopup", (data) => fetchNewInvoicesAdd(data));
  function fetchNewInvoicesAdd(data) {
    setOpenAddItem(false);
    setLoading(true);
    setOpenCategoryAddItem(false);
    setShowSnackbar(true);
    GetItemListListAPICall({
      payload: [{ documentId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeEditItemPopup", (data) => fetchNewInvoices(data));
  function fetchNewInvoices(data) {
    setOpenEditItem(false);
    setLoading(true);
    setShowSnackbar(true);
    GetItemListListAPICall({
      payload: [{ documentId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ documentId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  }

  // commented this code as discounted value is not there and total amount are provided in api after discount
  useEffect(() => {
    let taxNow = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
    let subTotalNow =
      typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
    let discountNow =
      typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
    const MyTotal = Number.parseFloat(subTotalNow, 10) - Number.parseFloat(discountNow, 10);
    if (onChanges === true) {
      const timeOutId = setTimeout(
        async () => setTotalStore(MyTotal + Number.parseFloat(taxNow, 10)),
        setTotal(MyTotal + Number.parseFloat(taxNow, 10)),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [taxStore, subTotStore, discountStore]);
  // }, [TotalStore, taxStore, subTotStore, tax, subtotal, total]);

  const getFormattedNumber = (n) => {
    let unFormatted = typeof n == "string" ? n.replaceAll(",", "") : n;
    const numberFormatter = Intl.NumberFormat(
      "en-US",

      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
    const formatted = numberFormatter.format(unFormatted);
    return formatted;
  };

  const handleChangeBusiness = (event, newValue) => {
    if (newValue) {
      setVendorerrormsg("");
      setVendorerror(false);
    } else {
      setVendorerrormsg("Vendor name required");
      setVendorerror(true);
    }
    const found = vendorList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setShowselectedVendorId(found._id);
    }
  };

  useEffect(() => {
    setStyles(
      value == 1
        ? { display: "grid", overflowY: "hidden", overflowX: "hidden", height: "80%" }
        : { height: "80%", overflow: "auto" }
    );
  }, [value]);


  useEffect(() => {
    if (createObjectURL) {
      setExtention(createObjectURL.split(".").pop());
    }
  }, [createObjectURL]);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;


  const moveOptionName = {
    Classify: "Classify",
    AskClient: "To Review",
    ToReview: "To Review",
    PendingApproval: "Pending Approval",
    Approved: "Approved",
    Excluded: "Excluded",
    Synced: "Quickbook",
  };

  useEffect(() => {
    Object.keys(moveOptionName).map(function (k) {
      k === documentStatus ? setFinalSelect(moveOptionName[k]) : "";
    });
  }, [documentStatus]);

  const handleClickImgDownload = () => {
    window.open(`${createObjectURL}`, "_blank");
  };

  async function createFile() {
    if (imageUrl && counts === 0) {
      setCounts(counts + 1);
      let response = await fetch(imageUrl);
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      const imageDataUrl = URL.createObjectURL(file);
      setImageUrl1(imageDataUrl);
    }
  }
  useEffect(() => {
    createFile();
  }, [imageUrl]);

    useEffect(() => {
      // Create an interval to check for textareas every second
      const interval = setInterval(() => {
        if(darkMode){
        const textarea = document.querySelector('textarea[placeholder="Leave Your Comment Here"]');
        if (textarea) {
          textarea.style.setProperty("--placeholder-color", "rgba(255, 255, 255, 0.7)");
    
          // Inject a style tag for the placeholder
          const style = document.createElement("style");
          style.innerHTML = `
            textarea::placeholder {
              color: var(--placeholder-color);
            }
          `;
          document.head.appendChild(style);
        }
      }
      }, 1000); 
  
      // Cleanup the interval when the component is unmounted
      return () => clearInterval(interval);
    }, []);


  const rotateImage = () => {
    // Update the rotation angle by 90 degrees
    setRotationAngle((prevAngle) => prevAngle + 90);
  };
  const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";
  if (darkMode) {
    document.body.classList.add("dark-mode");
  } else {
    document.body.classList.remove("dark-mode");
  }

  const [filterText, setFilterText] = useState({});
  const [filteredRows, setFilteredRows] = useState(itemList);
  const filterRefs = useRef({});

  
  useEffect(() => {
    const filtered = itemList.filter((row) =>
      Object.entries(filterText).every(([column, value]) =>
        String(row[column]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredRows(filtered);
  }, [filterText, itemList]);

  const handleFilterChange = (field, value) => {
    setFilterText((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const catColumns = (finalSelect == "To Review"
    ? showClasses
      ? cetagorycolumns
      : cetagorycolumnsWithoutClass
    : showClasses
    ? cetagorycolumnsWithotAction
    : cetagorycolumnsWithoutClassAction).map((col) => ({
    ...col,
    renderHeader: () => (
      <div style={{display:'grid' ,marginTop:'10px'}}>
        {/* <div style={darkMode ? {display:'flex' , justifyContent:'center' , color:'#ffffff'}:{display:'flex' , justifyContent:'center'}}>{col.headerName}</div> */}
        <ColumnFilter
          column={col}
          filterText={filterText[col.field] || ''}
          onFilterChange={handleFilterChange}
          ref={(el) => (filterRefs.current[col.field] = el)}
        />
      </div>
    ),
  }));

 
  const productColumns = (finalSelect == "To Review"
    ? showClasses
      ? columns
      : columnsWitoutClass
    : showClasses
    ? columnsWithoutAction
    : columnsWitoutClassAction).map((col) => ({ ...col,
    renderHeader: () => (
      <div style={{display:'grid' ,marginTop:'10px'}}>
        {/* <div style={darkMode ? {display:'flex' , justifyContent:'center' , color:'#ffffff'}:{display:'flex' , justifyContent:'center'}}>{col.headerName}</div> */}
        
        <ColumnFilter
          column={col}
          filterText={filterText[col.field] || ''}
          onFilterChange={handleFilterChange}
          ref={(el) => (filterRefs.current[col.field] = el)}
        />
      </div>
    ),
  }));

  return (
    <>
      {!showDetails ? (
        <>
          <MDBox mt={"-10%"} display="flex" justifyContent="center">
            {<LoaderDailog />}
          </MDBox>
        </>
      ) : (
        <>
          <MDBox mb={3} display={"flex"}>
            <Typography variant="h6" className={classes.title}>
              <MDBox display={"flex"}>
                <MDTypography fontSize={"1rem"}>Document in</MDTypography>
                <MDTypography sx={{ ml: 0.5 }} fontSize={"1rem"} fontWeight={"bold"}>
                  {finalSelect ? finalSelect : documentStatus}
                </MDTypography>
              </MDBox>
            </Typography>
            <MDBox>
              <Icon
                sx={darkMode ? { cursor: "pointer", mr: 2, color: "#ffffff" } : { cursor: "pointer", mr: 2 }}
                fontSize="medium"
                onClick={reloadApiAfterClose}
              >
                close
              </Icon>
            </MDBox>
          </MDBox>
          <MDBox
            mb={3}
            style={{
              height: "95%",
              overflow: "auto",
              display: "block",
              marginBottom: 0,
              border: "2px solid #000000",
            }}
          >
            <Grid container style={{ height: "100%", overflow: "auto" }}>
              {createObjectURL ? (
                <Grid item xs={12} sm={4} lg={4.5} ml={-2}
                  style={{
                    height: "100%",
                    overflow: "auto",
                    border: "2px solid #000000",
                    backgroundColor: darkMode ? "#202940" : "#ffffff",
                  }}
                >
                  {extention === "pdf" ? (
                    <div
                      className="rpv-core__viewer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "97%",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: darkMode ? "#202940" : "#eeeeee",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          padding: "0.25rem",
                        }}
                      >
                        <Toolbar>
                          {(props) => {
                            const { CurrentPageInput, NumberOfPages, ZoomIn, ZoomOut, EnterFullScreen, Download } = props;
                            return (
                              <>
                                <div style={{ padding: "0px 5px", width: "4rem", marginLeft: "10px", color: darkMode ? "#ffffff" : "" }} >
                                  <CurrentPageInput />
                                </div>
                                <div style={{ padding: "0px 2px", color: darkMode ? "#ffffff" : "" }} >
                                  of <NumberOfPages />
                                </div>
                                <div style={{ padding: "0px 2px", marginLeft: "35%" }}>
                                  <ZoomOut />
                                </div>
                                <div style={{ padding: "0px 2px", marginLeft: "10px" }}>
                                  <ZoomIn />
                                </div>
                                <div style={{ padding: "0px 2px", display: "flex", marginLeft: "auto", color: darkMode ? "#ffffff" : "" }} >
                                  <Download  style={{color: darkMode ? "#ffffff" : ""}}/>
                                </div>
                                <div style={{alignItems: "center", display: "flex", justifyContent: "end", paddingBottom: "2px", marginLeft: "8px" }} >
                                  <img
                                    style={{marginRight: "20px", cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }}
                                    width="19"
                                    height="19"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                                    alt="fullscreen"
                                    onClick={() => window.open(`${createObjectURL}`, "_blank")}
                                    title="Full Screen"
                                  />
                                </div>
                              </>
                            );
                          }}
                        </Toolbar>
                      </div>
                      <div style={{flex: 1, overflow: "hidden"}} >
                        {extention === "pdf" && (
                          <Worker workerUrl={workerUrl}>
                            <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]} />
                          </Worker>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="rpv-core__viewer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "97%",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: darkMode ? "#202940" : "#eeeeee",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          justifyContent: "end",
                          padding: "0.25rem",
                        }}
                      >
                        <img
                          style={{
                            marginRight: "20px",
                            cursor: "pointer",
                            filter: darkMode ? "brightness(0) invert(1)" : "",
                          }}
                          width="18"
                          height="18"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVR4nN3Vv0scQRjG8Y8edyixsLQVLUSL/AViivR6ooelnahobMXGTpK/I4l/gL2VvXgK/oqNnViImkC6CwuvMMW5d7d6oHlgi5155vnOvLy7w/+uCmr4iTP8xh9cxdgCyol/u5PwWfxCo8VzjWqEZ+8t1YtvScAR1jGOD+jHGFZRbwJsqa9h/IulAOZtZr8TQDUJn2pjM09laQtQSWqe7fzVVUtqnleWwtoNwJou6TwAWbd0RY8BGHjLgMHIuOtWiUbxgMNmkz8C8MXLVMJws4n5ANQLtmk5Wr2UZ7gMyHIBwFas3cszzSS/ik8dAiZx0866nQSyknfkOPUmPiZd1FK9CaSBY2xgIlo4CxkJ72J4stL26VDTuHjmkrlFT9wNB/isoMqYw3ecxsd4jxMMFQ19X/oHPMJfdYMk80cAAAAASUVORK5CYII="
                          onClick={rotateImage}
                          alt="rotate"
                          title="Rotate Image"
                        />
                        <a href={imageUrl1} download={"downloaded-image.jpeg"}>
                          <img
                            style={{
                              marginRight: "20px",
                              cursor: "pointer",
                              marginBottom: "-6px",
                              filter: darkMode ? "brightness(0) invert(1)" : "",
                            }}
                            width="18"
                            height="18"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC"
                            title="Download"
                          />
                        </a>
                        <img
                          style={{
                            marginRight: "20px",
                            cursor: "pointer",
                            filter: darkMode ? "brightness(0) invert(1)" : "",
                          }}
                          width="19"
                          height="19"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                          alt="fullscreen"
                          onClick={handleClickImgDownload}
                          title="Full Screen"
                        />
                      </div>
                      <div>
                        {/(jpg|jpeg|png)$/.test(extention) && (
                          <img
                            src={createObjectURL}
                            width={`100%`}
                            height={"100%"}
                            style={{
                              transform: `rotate(${rotationAngle}deg)`,
                              filter: darkMode ? "" : "",
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CurrentPageLabel>
                          {(props) => (
                            <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
                          )}
                        </CurrentPageLabel>
                      </div>
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} lg={4.5} ml={-2}
                  style={{ height: "100%", overflow: "auto", backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                >
                  <div style={{ display: "flex", flexDirection: "column", height: "100%", border: "0.5px solid gray" }} >
                    <div
                      style={{ flex: 1, overflow: "hidden", display: "flex", justifyContent: "center", marginTop: "50%", color: darkMode ? "white" : "" }} >
                      <h2>
                        {documentSource === "QBO" ? "No image available from Quickbooks" : "No image available from Paynthr"}
                      </h2>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={6} lg={7.59} style={{ height: "100%", overflow: "auto" }} >
                <Card style={{ height: "100%", overflow: "auto", backgroundColor: darkMode ? "#202940" : "#ffffff" }} >
                  <Tabs
                    sx={darkMode && { 
                      padding : '0px !important',
                      backgroundColor: "#1a2035 !important" ,
                      '& .MuiTabs-indicator': {
                          backgroundColor: 'transparent',
                          boxShadow :'none',
                        },
                      '& .Mui-selected': {
                          borderRadius : '0px',
                          backgroundColor: "#006cbe !important" ,
                        }
                      }}
                    className={darkMode ? classes.tabs : ""}
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                  >
                    <Tab
                      label={
                        issues?.documents ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={issues.documents.count}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent",
                              },
                            }}
                          >
                            Details &nbsp; &nbsp; &nbsp;
                          </MDBadge>
                        ) : ("Details")
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        issues?.items ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={issues.items.count}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent ",
                              },
                            }}
                          >
                            Items &nbsp; &nbsp; &nbsp;
                          </MDBadge>) : ("Items")
                      }
                      {...a11yProps(1)}
                    />
                    <Tab label="Summary" {...a11yProps(2)} />
                    <Tab label="Comments" {...a11yProps(3)} />
                    <div>
                      {canAccess((actions = "update"), (resource = "invoices")) && (
                        <MDButton
                          id="fade-button"
                          aria-controls={open ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          color="info"
                        >
                          More <Icon sx={{ ml: 0.8 }}>expand_more</Icon>
                        </MDButton>
                      )}
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        sx={darkMode &&{
                          "& .MuiPaper-root": {
                            backgroundColor: "#1a2035 !important" , // Change this to the desired background color
                          },
                          "& .MuiMenuItem-root": {
                            color: "#ffffff", // Change text color of the MenuItem
                            "&:hover": {
                              backgroundColor: "#38405585", // Change background color on hover
                              color: "#ffffff", // Ensure the text color remains white on hover
                            },
                          },
                        }} 
                      >
                        {/* <MenuItem onClick={handleEditVendor} >Edit Vendor</MenuItem> */}
                        {canAccess((actions = "delete"), (resource = "invoices")) &&
                        documentStatus !== "Synced" ? (
                          <MenuItem style={{ color: "red" }} onClick={handleDeleteInvoice}>
                            Delete Invoice
                          </MenuItem>
                        ) : (
                          <MenuItem style={{ color: "red" }} onClick={() => setOpenQboSync(true)}>
                            Delete Invoice
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  </Tabs>
                  <CardHeader
                    subheader={
                      <Grid item sx={12} sm={12} md={12} style={{ display: "flex", marginLeft: "70px", fontSize: "1rem" }} >
                        <Grid item sx={12} xs={4}>
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>Subtotal</span>
                          <br />
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>
                            {currencySymbol}
                            {actualSubtotal}
                          </span>
                        </Grid>
                        <Grid item sx={12} xs={4}>
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>Discount</span>
                          <br />
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>
                            {currencySymbol}
                            {discountStore ? getFormattedNumber(discountStore) : getFormattedNumber(discountStore)}
                          </span>
                        </Grid>
                        <Grid item sx={12} xs={4}>
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>Shipping Price</span>
                          <br />
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>
                            {currencySymbol}
                            {shippingprice ? getFormattedNumber(shippingprice) : getFormattedNumber(shippingprice)}
                          </span>
                        </Grid>
                        <Grid item sx={12} xs={4}>
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>Taxes & Fees</span>
                          <br />
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>
                            {currencySymbol}
                            {taxStore ? getFormattedNumber(taxStore) : getFormattedNumber(tax)}
                          </span>
                        </Grid>
                        <Grid item sx={12} xs={4}>
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>Total</span>
                          <br />
                          <span style={{ color: darkMode ? "#ffffff" : "" }}>
                            {currencySymbol}
                            {actualTotal}
                          </span>
                        </Grid>
                      </Grid>
                    }
                  />
                  <Divider />
                  <CardContent style={styles}>
                    <TabPanel value={value} index={0}>
                      {showDetailsForm && (
                        <MDBox my={3}>
                          <Box>
                            <Grid item xs={12} sm={12} md={12}>
                              <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={documentStatus !== "Synced" ? InvoiceEditSchema : ""}
                                onSubmit={handleSubmit}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                  <Form autoComplete="off">
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} mt={1}>
                                        <Autocomplete
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                              WebkitTextFillColor: darkMode ? "white" : "black",
                                            },
                                          }}
                                          forcePopupIcon={editable ? true : false}
                                          options={vendorList}
                                          getOptionLabel={(option) => option.name}
                                          value={{ name: vendorname }}
                                          onInputChange={(event, newInputValue) => {
                                            setVendorname(newInputValue),
                                            handleChangeBusiness(event, newInputValue);
                                          }}
                                          id="controllable-states-demo"
                                          disableCloseOnSelect={false}
                                          disabled={!editable}
                                          renderInput={(params) =>
                                            vendorerror || novendorIssue ? (
                                              <TextField
                                                {...params}
                                                label="Vendor Name *"
                                                variant="standard"
                                                error={vendorerror || novendorIssue}
                                                helperText={vendorerrormsg}
                                                InputProps={{
                                                  endAdornment: (
                                                    <Tooltip
                                                      title={novendorIssueMsg}
                                                      placement="top"
                                                    >
                                                      <InputAdornment position="end">
                                                        <ErrorOutlineIcon
                                                          sx={{
                                                            fontSize: "1rem !important",
                                                            mr: "14px",
                                                            color: "red",
                                                          }}
                                                        />
                                                      </InputAdornment>
                                                    </Tooltip>
                                                  ),
                                                }}
                                              />
                                            ) : (
                                              <TextField
                                                {...params}
                                                label="Vendor Name *"
                                                variant="standard"
                                                helperText={vendorerrormsg}
                                                InputLabelProps={ darkMode ? {style: { color: "#ffffff" } }: {} }
                                                InputProps={{sx: darkMode ? {"& .MuiInputBase-input.Mui-disabled": {borderBottom: "2px solid #ffffff !important" }}: {}}}
                                              />
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormField
                                          type="text"
                                          label="Document Type"
                                          name="documenttype"
                                          disabled={!editable}
                                          editable={!editable}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormField
                                          type="text"
                                          label="Invoice Number"
                                          name="invoicenumber"
                                          disabled={!editable}
                                          editable={!editable}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormField
                                          type="text"
                                          label="PO Number"
                                          name="ponumber"
                                          disabled={!editable}
                                          editable={!editable}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      {documentSource === "QBO" ? (
                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                          <FormFieldDate
                                            type="date"
                                            label="Transaction Date"
                                            name="transactionDate"
                                            disabled={!editable}
                                            editable={!editable}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                          <FormFieldDate
                                            type="date"
                                            label="Invoice Date"
                                            dateMismatch={invoiceDateMismatch}
                                            noInvoiceDateMsg={"invoiceDateMismatch"}
                                            name="invoicedate"
                                            disabled={!editable}
                                            editable={!editable}
                                          />
                                        </Grid>
                                      )}
                                      <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                        <FormFieldDate
                                          type="date"
                                          label="Upload Date"
                                          name="uploaddate"
                                          disabled={!editable}
                                          editable={!editable}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Subtotal"
                                          name="subtotal"
                                          error={priceMismatch}
                                          disabled={!editable}
                                          value={subtotal}
                                          ErrorToolTip={priceMismatchMsg}
                                          priceMismatch={priceMismatch}
                                          onValueChange={(event) => {
                                            setSubTotal(event.value);
                                            setSubTotStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                        <MDBox mt={0.75}>
                                          <MDTypography
                                            component="div"
                                            variant="caption"
                                            color="error"
                                            fontWeight="regular"
                                          >
                                            {subtotalErrorMsg}
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Discount"
                                          name="discount"
                                          disabled={!editable}
                                          value={discount}
                                          onValueChange={(event) => {
                                            setDiscount(event.value);
                                            setDiscountStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                        <MDBox mt={0.75}>
                                          <MDTypography component="div" variant="caption" color="error" fontWeight="regular" >
                                            {taxErrorMsg}
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Shipping Price"
                                          name="shippingprice"
                                          disabled={!editable}
                                          value={shippingprice}
                                        />
                                        <MDBox mt={0.75}>
                                          <MDTypography component="div" variant="caption" color="error" fontWeight="regular" >
                                            {taxErrorMsg}
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Taxes & Fees"
                                          name="tax"
                                          disabled={!editable}
                                          value={tax}
                                          onValueChange={(event) => {
                                            setTax(event.value);
                                            setTaxStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                        <MDBox mt={0.75}>
                                          <MDTypography component="div" variant="caption" color="error" fontWeight="regular" >
                                            {taxErrorMsg}
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                        <CurrencyFieldText
                                          label="Total"
                                          name="total"
                                          disabled={!editable}
                                          ErrorToolTip={totalMismatchMsg}
                                          error={totalMismatch}
                                          value={TotalStore}
                                          priceMismatch={totalMismatch}
                                          onValueChange={(event) => {
                                            setTotal(event.value);
                                            setTotalStore(event.value);
                                            SetonChanges(true);
                                          }}
                                        />
                                        <MDBox mt={0.75}>
                                          <MDTypography component="div" variant="caption" color="error" fontWeight="regular" >
                                            {totalErrorMsg}
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} >
                                      {selectTab == "AskClient" && checkFound === true && (
                                        <MDBox mt={6}>
                                          <MDButton
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            onClick={() => setOpenQboSyncAskClient(true)}
                                          >
                                            Send to Accountant
                                          </MDButton>
                                        </MDBox>
                                      )}
                                    </Grid>
                                  </Form>
                                )}
                              </Formik>
                            </Grid>
                          </Box>
                          <MDBox>
                            {documentStatus !== "Synced" ? (
                              <MDSnackbar
                                color="success"
                                icon="done"
                                // autoHideDuration={3000}
                                title="Success"
                                content="Document Moved Successfully."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}
                              />
                            ) : ("")}
                          </MDBox>
                        </MDBox>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <MDBox sx={{maxWidth:'98%'}} mt={-3}>
                        <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                          <Switch checked={toggleShow} onChange={handleToggle} value="toggleShow" />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onClick={handleToggle}
                            sx={darkMode ? { cursor: "pointer", userSelect: "none", color: "#ffffff" } : { cursor: "pointer", userSelect: "none" }}
                          >
                            &nbsp;&nbsp;Split by Products/Services
                          </MDTypography>
                          {finalSelect == "To Review" && (
                            <>
                              {canAccess((actions = "update"), (resource = "documents")) &&
                              isSynced === true ? (
                                <MDButton
                                  disabled={ocrStatus}
                                  variant="contained"
                                  color="info"
                                  sx={{ ml: 2 }}
                                  onClick={() => setOpenQboSync(true)}
                                >
                                  Add New Item
                                </MDButton>
                              ) : (
                                <MDButton
                                  disabled={ocrStatus}
                                  variant="contained"
                                  color="info"
                                  sx={{ ml: 2 }}
                                  onClick={toggleShow ? handleAddItem : handleCategort}
                                  // onClick={handleAddItem}
                                >
                                  Add New Item
                                </MDButton>
                              )}
                            </>
                          )}
                        </MDBox>
                        <MDBox mt={1.5} mb={-5} ml={-6} mr={-6}>
                          {!toggleShow ? (
                            dataTableData?.rows?.length === 0 ? (
                              <MDBox my={5} sx={{ mt: 1 }}>
                                <ThemeProvider theme={myTheme}>
                                  <DataGrid
                                    rows={itemList}
                                    columns={
                                      finalSelect == "To Review"
                                        ? showClasses
                                          ? cetagorycolumns
                                          : cetagorycolumnsWithoutClass
                                        : showClasses
                                        ? cetagorycolumnsWithotAction
                                        : cetagorycolumnsWithoutClassAction
                                    }
                                    components={{
                                      NoRowsOverlay: () => (
                                        <Stack
                                          height="100%"
                                          alignItems="center"
                                          justifyContent="center"
                                          color= {darkMode ? "#ffffff" : ""}
                                        >
                                          No records found
                                        </Stack>
                                      ),
                                    }}
                                    sx={
                                      darkMode
                                        ? {
                                          ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden',
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                          }
                                        : {
                                          ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden',
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                    }
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </ThemeProvider>
                              </MDBox>
                            ) : (
                              <ThemeProvider theme={myTheme}>
                                <Box
                                  sx={{
                                    "& .caterror": { borderBottom: `0.5px solid red !important` },
                                  }}
                                >
                                  <DataGrid
                                     rows={filteredRows}
                                    columns={catColumns}
                                    disableRowSelectionOnClick
                                    sx={
                                      darkMode
                                        ? { 
                                            ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                            "& .MuiDataGrid-row": {
                                              borderTop: '0.5px solid #1a2035'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflowX:'hidden',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              overflowX:'hidden',
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    cellModesModel={cellModesModel}
                                    onCellModesModelChange={handleCellModesModelChange}
                                    onCellClick={handleCellClick}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </Box>
                              </ThemeProvider>
                            )
                          ) : dataTableData1?.rows?.length === 0 ? (
                            <MDBox my={5} sx={{ mt: 1 }}>
                              <ThemeProvider theme={myTheme}>
                                <DataGrid
                                  rows={itemList}
                                  columns={
                                    finalSelect == "To Review"
                                      ? showClasses
                                        ? columns
                                        : columnsWitoutClass
                                      : showClasses
                                      ? columnsWithoutAction
                                      : columnsWitoutClassAction
                                  }
                                  components={{
                                    NoRowsOverlay: () => (
                                      <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        No records found
                                      </Stack>
                                    ),
                                  }}
                                  sx={
                                    darkMode
                                      ? {
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                          ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden',
                                            },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor": {
                                              outline: "none !important",
                                            },
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "#ffffff",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                          ".MuiTypography-root.MuiTypography-body1": {
                                            color: "#ffffff !important",
                                          },
                                        }
                                      : {
                                          ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden',
                                          },
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                          },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          "& .MuiTablePagination-root": {
                                                display : 'none',
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "rgb(123, 128, 154)",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor": {
                                              outline: "none !important",
                                          },
                                        }
                                  }
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </ThemeProvider>
                            </MDBox>
                          ) : (
                            <ThemeProvider theme={myTheme}>
                              <Box
                                sx={{
                                  "& .proerror": {
                                    borderBottom: `0.5px solid red !important`,
                                  },
                                  "& .proerror.tooltiptext": {
                                    visibility: `hidden`,
                                    width: `120px`,
                                    backgroundColor: `black`,
                                    color: `#fff`,
                                    textAlign: `center`,
                                    borderRadius: `6px`,
                                    padding: `5px 0`,
                                    position: `absolute`,
                                    zIndex: 1,
                                    bottom: `100%`,
                                    left: `50%`,
                                    marginLeft: `-60px`,
                                  },
                                  "& .proerror:hover .tooltiptext": {
                                    visibility: `visible`,
                                  },
                                }}
                              >
                                <DataGrid
                                  rows={filteredRows}
                                  columns={productColumns}
                                  getCellClassName={(params) => {
                                    return params?.row?.proderror == true &&
                                      params?.field === "product" ? "proerror" : "";
                                  }}
                                  disableRowSelectionOnClick
                                  sx={
                                    darkMode
                                      ? {
                                        ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important',
                                            outline:'transparent !important'
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode
                                              ? "#ffffff !important"
                                              : "#000000 !important", 
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflowX:'hidden',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                          "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-by9wxd-MuiInputBase-root-MuiTablePagination-select":{
                                              color: darkMode
                                                ? "#ffffff !important"
                                                : "#000000 !important", // Change color to blue
                                            },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "#ffffff",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiTypography-root.MuiTypography-body1": {
                                            color: "#ffffff !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                        }
                                      : {
                                        ".MuiDataGrid-virtualScroller": {
                                          maxHeight:'500px',
                                          minHeight:'261px',
                                          overflowX:'hidden',
                                          overflow: filteredRows.length === 0 && "hidden",
                                      },
                                        '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode
                                              ? "#ffffff !important"
                                              : "#000000 !important", // Change color to blue
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-by9wxd-MuiInputBase-root-MuiTablePagination-select":{
                                              color: darkMode
                                                ? "#ffffff !important"
                                                : "#000000 !important", // Change color to blue
                                            },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "rgb(123, 128, 154)",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                            {
                                              outline: "none !important",
                                            },
                                        }
                                  }
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  cellModesModel={cellModesModel}
                                  onCellModesModelChange={handleCellModesModelChange}
                                  onCellClick={handleCellClick}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </Box>
                            </ThemeProvider>
                          )}
                        </MDBox>
                        {selectTab == "AskClient" && checkFound === true && (
                          <MDBox display="flex" justifyContent="end" mt={6}>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              onClick={() => setOpenQboSyncAskClient(true)}
                            >
                              Send to Accountant
                            </MDButton>
                          </MDBox>
                        )}
                        {/* Delete Dialog */}
                        <MDBox>
                          <Dialog
                            open={alert}
                            onClose={hideAlert}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "450px",
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                  color: darkMode ? "#ffffff" : "#000000",
                                },
                              },
                            }}
                          >
                            <DialogContent sx={{ overflowY: "hidden" }}>
                              <DialogContentText
                                sx={{ display: "flex", justifyContent: "center", mt: 4 , color: darkMode ? "#ffffff" : "#000000",
                                }}
                              >
                                <h3>Are you sure?</h3>
                              </DialogContentText>
                              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                <MDTypography
                                  variant="body2"
                                  color="secondary"
                                  sx={{
                                    fontWeight: 300,
                                    fontSize: "0.90rem",
                                    textAlign: "center",
                                    mt: 4,
                                    color: darkMode ? "#ffffff" : "#000000",
                                  }}
                                >
                                  Once you delete this Item there is no way of getting any of the
                                  documents or data back.
                                </MDTypography>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                              <MDButton variant="gradient" color="success" onClick={successDelete}>
                                Yes, delete it!
                              </MDButton>
                              <MDButton variant="gradient" color="error" onClick={hideAlert}>
                                Cancel
                              </MDButton>
                            </DialogActions>
                          </Dialog>
                        </MDBox>
                        {/* Edit Item Dialog */}
                            <MDBox>
                              {toggleShow || itemType === "ItemBasedExpenseLineDetail" ? (
                                <EditProductNewItemDialog
                                open={openEditItem}
                                  noProduct={noProduct}
                                  showClasses={showClasses}
                                  businessId={businessId}
                                  itemListResponse={
                                    itemListResponse ? itemListResponse : GetItemListResponse
                                  }
                                  currencySymbol={currencySymbol}
                                  invoiceId={invoice_id}
                                  editItemId={editItemId}
                                  itemList={itemList}
                                  documentsId={invoice_id}
                                  onClose={closeProductEdit}
                                />
                              ) : (
                                <EditCategoryDialog
                                 open={openEditItem}
                                  noCategory={noCategory}
                                  showClasses={showClasses}
                                  businessId={businessId}
                                  itemListResponse={itemListResponse}
                                  currencySymbol={currencySymbol}
                                  invoiceId={invoice_id}
                                  editItemId={editItemId}
                                  itemList={itemList}
                                  onClose={closeProductEdit}
                                />
                              )}
                            </MDBox>
                          <MDBox>
                              <DraggableAddItemDialog
                                open={openAddItem}
                                showClasses={showClasses}
                                toggleShow={toggleShow}
                                itemList={itemList}
                                businessId={businessId}
                                currencySymbol={currencySymbol}
                                invoiceId={invoice_id}
                                onClose={() => setOpenAddItem(false)}
                              />
                          </MDBox>
                          <MDBox>
                            <DraggableAddCategoryDialog
                                  openCategoryAddItem={openCategoryAddItem}
                                  showClasses={showClasses}
                                  itemList={itemList}
                                  businessId={businessId}
                                  currencySymbol={currencySymbol}
                                  invoiceId={invoiceId}
                                  toggleShow={toggleShow}
                                  onClose={() => setOpenCategoryAddItem(false)}
                                />
                          </MDBox>
                      </MDBox>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ padding: '24px 0px 0px 24px' ,width:'105%' }}>
                          {!toggleShow ? (
                            summeryList?.length === 0 ? (
                              <MDBox my={5} sx={{ mt: 1 }}>
                                <ThemeProvider theme={myTheme}>
                                  <DataGrid
                                    rows={summeryList}
                                    columns={summarycetagorycolumns}
                                    components={{
                                      NoRowsOverlay: () => (
                                        <Stack
                                          height="100%"
                                          alignItems="center"
                                          justifyContent="center"
                                          color= {darkMode ? "#ffffff" : ""}
                                        >
                                          No records found
                                        </Stack>
                                      ),
                                    }}
                                    sx={
                                      darkMode
                                        ? {
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden'
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              display : 'none'
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                          ".MuiDataGrid-withBorderColor" : {
                                              borderTopColor:'#1a2035 !important'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden'
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                               display : 'none'
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </ThemeProvider>
                              </MDBox>
                            ) : (
                              <ThemeProvider theme={myTheme}>
                              <Box
                                sx={{
                                  "& .caterror": {
                                    borderBottom: `0.5px solid red !important`,
                                  },
                                }}
                              >
                                <DataGrid
                                 rows={filteredCategoryRows} // Use filtered rows
                                   columns={summaryCategoryColumns}
                                  disableRowSelectionOnClick
                                  sx={
                                    darkMode
                                      ? {
                                        ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                        ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            maxHeight:'500px',
                                            minHeight:'261px',
                                            overflow: filteredCategoryRows.length === 0 && "hidden",
                                        },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            display: filteredCategoryRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "#ffffff",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiTypography-root.MuiTypography-body1": {
                                            color: "#ffffff !important",
                                          },
                                        }
                                      : {
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            maxHeight:'500px',
                                            minHeight:'261px',
                                            overflow: filteredCategoryRows.length === 0 && "hidden",
                                        },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            display: filteredCategoryRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "rgb(123, 128, 154)",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                            {
                                              outline: "none !important",
                                            },
                                        }
                                  }
                                  pagination
                                  pageSize={paginationModel.pageSize}
                                  page={paginationModel.page}
                                  onPageChange={(page) =>
                                    setPaginationModel((prevModel) => ({ ...prevModel, page }))
                                  }
                                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                               
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </Box>
                            </ThemeProvider>
                            )
                          ) :  summeryList?.length === 0 ? (
                            <MDBox my={5} sx={{ mt: 1 }}>
                              <ThemeProvider theme={myTheme}>
                                <DataGrid
                                  rows={summeryList}
                                  columns={summaryProductcolumns}
                                  components={{
                                    NoRowsOverlay: () => (
                                      <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                        color= {darkMode ? "#ffffff" : ""}
                                      >
                                        No records found
                                      </Stack>
                                    ),
                                  }}
                                  sx={
                                    darkMode
                                      ? {
                                        "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            minHeight:'200px',
                                        },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important',
                                            outline:'transparent !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "#ffffff",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                             marginTop: "4px"
                                          },
                                          ".MuiTypography-root.MuiTypography-body1": {
                                            color: "#ffffff !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                            {
                                              outline: "none !important",
                                            },
                                        }
                                      : {
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            minHeight:'200px',
                                          },
                                          "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important',
                                            outline:'transparent !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "rgb(123, 128, 154)",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                            {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                              outline:'transparent !importnat'
                                            }
                                        }
                                  }
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </ThemeProvider>
                            </MDBox>
                          ) : (
                            <ThemeProvider theme={myTheme}>
                              <Box
                                sx={{
                                  "& .proerror": {
                                    borderBottom: `0.5px solid red !important`,
                                  },
                                  "& .proerror.tooltiptext": {
                                    visibility: `hidden`,
                                    width: `120px`,
                                    backgroundColor: `black`,
                                    color: `#fff`,
                                    textAlign: `center`,
                                    borderRadius: `6px`,
                                    padding: `5px 0`,
                                    position: `absolute`,
                                    zIndex: 1,
                                    bottom: `100%`,
                                    left: `50%`,
                                    marginLeft: `-60px`,
                                  },
                                  "& .proerror:hover .tooltiptext": {
                                    visibility: `visible`,
                                  },
                                }}
                              >
                                <DataGrid
                                    rows={filteredProductRows} // Use filtered product rows
                                    columns={summaryProductColumns}
                                  getCellClassName={(params) => {
                                    return params?.row?.proderror == true &&
                                      params?.field === "product"
                                      ? "proerror"
                                      : "";
                                  }}
                                  disableRowSelectionOnClick
                                  sx={
                                    darkMode
                                      ? {
                                        ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                        ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            maxHeight:'500px',
                                            minHeight:'261px',
                                            overflow: filteredProductRows.length === 0 && "hidden",
                                        },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            display: filteredProductRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "#ffffff",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiTypography-root.MuiTypography-body1": {
                                            color: "#ffffff !important",
                                          },
                                        }
                                      : {
                                          "@media (max-width: 1440px)": {
                                            fontWeight: `0  !important`,
                                            fontSize: `0.775rem`,
                                          },
                                          ".MuiDataGrid-virtualScroller": {
                                            maxHeight:'500px',
                                            minHeight:'261px',
                                            overflow: filteredProductRows.length === 0 && "hidden",
                                        },
                                          ".MuiDataGrid-columnSeparator": {
                                            display: "none",
                                          },
                                          '& .MuiDataGrid-columnHeaders': {
                                            maxHeight: '75px !important',
                                            minHeight:'75px !important',
                                            lineHeight:'8px !important'
                                          },
                                          '& .MuiDataGrid-columnHeaderTitle': {
                                            overflow: 'visible !important',
                                          },
                                          "& .MuiTablePagination-root": {
                                            color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            display: filteredProductRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                          },
                                          ".MuiDataGrid-columnHeaderDraggableContainer": {
                                            width: "112% !important",
                                          },
                                          border: 0,
                                          ".MuiDataGrid-columnHeaderTitle": {
                                            color: "rgb(123, 128, 154)",
                                            fontWeight: "bold !important",
                                            overflow: "visible !important",
                                          },
                                          ".MuiDataGrid-columnHeader:focus": {
                                            outline: "none !important",
                                          },
                                          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                            {
                                              outline: "none !important",
                                            },
                                        }
                                  }
                                  pageSizeOptions={[5, 10, 25, 50, 100]}
                                  cellModesModel={cellModesModel}
                                  onCellModesModelChange={handleCellModesModelChange}
                                  onCellClick={handleCellClick}
                                  paginationModel={paginationModel}
                                  onPaginationModelChange={setPaginationModel}
                                />
                              </Box>
                            </ThemeProvider>
                          )}
                    </MDBox>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <MDBox>
                        {canAccess((actions = "create"), (resource = "comments")) && (
                          <Formik
                            initialValues={{ name: "" }}
                            // validationSchema={InvoiceEditSchema2}
                            onSubmit={async (values, actions) => {
                              var newStr = textAreaVal.replace(/@/g, "");
                              const newstr2 = newStr.replace(/\(/g, "{{").replace(/\)/g, "}}");
                              const newStr3 = newstr2.replace(/(?:\[.*?\]|[\[\]])/g, "");
                              await CreateComentsListAPICall({
                                payload: [
                                  {
                                    documentId: `${invoice_id}`,
                                    businessId: `${businessId}`,
                                    comment: `${newStr3}`,
                                  },
                                ],
                              });
                              setLoading(true);
                              setBtnDisable1(true);
                              setsubmitcomment(true);
                              setTimeout(() => {
                                setsubmitcomment(false);
                                actions.setSubmitting(false);
                                actions.resetForm();
                              }, 1000);
                            }}
                          >
                            {(props) => (
                              <form onSubmit={props.handleSubmit}>
                                <MentionsInput
                                  style={ mentionsInputStyle}
                                  placeholder="Leave Your Comment Here"
                                  value={textAreaVal}
                                  onChange={(e) => setTextAreaVal(e.target.value)}
                                >
                                  <Mention
                                    data={users}
                                    displayTransform={(id, display) => `@${display}`}
                                  />
                                </MentionsInput>
                                {textAreaVal.length === 0 && submitcomment === true && (
                                  <MDTypography
                                    sx={{ mt: 1 }}
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    Comment is required.
                                  </MDTypography>
                                )}
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mt: 1, display: "flex", justifyContent: "end" }}
                                >
                                  <MDButton variant="gradient" color="info" type="submit">
                                    {btnDisable1 ? "Submiting..." : "Submit"}
                                  </MDButton>
                                </Grid>
                              </form>
                            )}
                          </Formik>
                        )}
                      </MDBox>
                      {canAccess((actions = "read"), (resource = "comments")) && (
                        <>
                          <MDBox style={{ color: darkMode ? "#ffffff" : "#000000" }}>
                            Comments
                          </MDBox>
                          <MDBox my={3}>
                            {CommentsResponse && commentsList.length === 0 ? (
                              <MDTypography
                                variant="body2"
                                color="secondary"
                                sx={{
                                  color: darkMode ? "#ffffff" : "#000000",
                                  marginLeft: "43%",
                                  fontSize: "1.20rem",
                                  marginTop: "10%",
                                }}
                              >
                                No Comments
                              </MDTypography>
                            ) : (
                              commentsList
                            )}
                          </MDBox>
                        </>
                      )}
                    </TabPanel>
                  </CardContent>
                </Card>
                <MDBox>{loading && <LoaderDailog />}</MDBox>
              </Grid>
            </Grid>
            {/* Edit Vendor Dialog */}
            <MDBox>
                <Dialog
                  open={openEditVendor}
                  onClose={() => setOpenEditVendor(false)}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "570px",
                        minHeight: "30%",
                      },
                    },
                  }}
                >
                  {" "}
                  <DialogTitle
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    Edit Vendor
                  </DialogTitle>
                  <DialogContent>
                    <EditVendorDialog
                      invoiceId={invoiceId}
                      businessId={businessId}
                      onClose={() => setOpenEditVendor(false)}
                    />
                  </DialogContent>
                </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openQboSync}
                onClose={closeMoveDialog}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      // height: "40%",
                      maxWidth: "500px",
                    },
                  },
                }}
              >
                <DialogContent>
                  <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                    {notApproved === true ? (
                      <h3>
                        {notApprovedCount} items are awaiting your approval. Are you certain you
                        want to proceed with sending them to the accountant?
                      </h3>
                    ) : (
                      <>
                        <h3>Are you sure want to proceed with sending them</h3>
                      </>
                    )}
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    {
                      <>
                        <h3 display="flex" justifyContent="center" mr={6}>
                          to the accountant?
                        </h3>
                      </>
                    }
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      To Review
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      color="#037ffd"
                      sx={{
                        fontSize: 40,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                    </MDTypography>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {splitCamelCaseToString("Classify")}
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                  <MDButton variant="gradient" color="success" onClick={successInoiceMove}>
                    Yes, Move it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={closeMoveDialog}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openQboSyncAskClient}
                onClose={closeMoveDialog}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      // height: "40%",
                      maxWidth: "500px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                }}
              >
                <DialogContent>
                  <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center",  color: darkMode ? "#ffffff" : "#000000" }}>
                    {notApproved === true ? (
                      <h3>
                        {notApprovedCount} items are awaiting your approval. Are you certain you
                        want to proceed with sending them to the accountant?
                      </h3>
                    ) : (
                      <>
                        <h3>Are you sure want to proceed with sending them</h3>
                      </>
                    )}
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center",  color: darkMode ? "#ffffff" : "#000000" }}>
                    {
                      <>
                        <h3 display="flex" justifyContent="center" mr={6}>
                          to the accountant?
                        </h3>
                      </>
                    }
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      To Review
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      color="#037ffd"
                      sx={{
                        fontSize: 40,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                    </MDTypography>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {issues?.documents?.count === 0 &&
                      issues?.items?.count === 0 &&
                      issues?.vendor?.count === 0
                        ? splitCamelCaseToString("To Accountant")
                        : splitCamelCaseToString("Classify")}
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                  <MDButton variant="gradient" color="success" onClick={successInoiceMove}>
                    Yes, Move it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={closeMoveDialog}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            {/* Delete invoice Dialog */}
            <MDBox>
              <Dialog
                open={alertDeleteInvoice}
                onClose={hideInvoiceAlert}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "450px",
                    },
                  },
                }}
              >
                <DialogContent sx={{ overflowY: "hidden" }}>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                    <h3>Are you sure?</h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                    >
                      Once you delete this Invoice there is no way of getting any of the documents
                      or data back.
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                  <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                    Yes, delete it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={ErrorMessages}
                open={showErrorMoveSnackbar}
                close={() => setShowErrorMoveSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="Item Edit successfully"
                open={showSnackbar}
                close={() => setShowSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="Item Add successfully"
                open={showSnackbar}
                close={() => setShowSnackbar(false)}
              />
            </MDBox>
          </MDBox>
        </>
      )}
    </>
  );
}

export default EditInvoiceDialog;
