import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import { canAccess } from "helpers";
import { SITE_URL } from "helpers/config";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { DeleteBusinesss, GetuserClientList } from "../../../services/clientservices";
import { useAPICall } from "../../../utils/useapicall";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import AddBusinessDialog from "./addBusiness";
import EditBusinessDialog from "./editBusiness";

function UserClientlist() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const action = {
    route: "",
    viewtooltip: "View",
    edittooltip: "Edit",
    deletetooltip: "Delete",
    addowner: "Add Owner",
  };
  const [adminList, setAdminList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [clientId, setClientId] = useState("");
  const [openEditclientPopup, setOpenEditclientPopup] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [qboState, setQboState] = useState("");
  const [qboClientId, setQboClientId] = useState();
  const [showQboErrorSnackbar, setShowQboErrorSnackbar] = useState(false);
  const [openAddOwner, setOpenAddOwner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddSnackbar, setShowAddSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [openNewclientPopup, setOpenNewclientPopup] = useState(false);
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [qboErrorMessage, setQboErrorMessage] = useState("");
  const [qboSuccessMessage, setQboSuccessMessage] = useState("");
  const [tableHeight, setTableHeight] = useState({ marginTop: "8px" });
  const [loaderClass, setLoaderClass] = useState({});
  const [loadingCircul, setLoadingCircul] = useState(false);
  const limit = localStorage.getItem("PerPagelimit");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserClientList, { onLoad: false});
  const { pathname } = useLocation();
  const [{isLoading: isDeleteBusinessLoading, isError: isDeleteBusinessError, response: DeleteBusinessResponse}, DeleteBusinessAPICall] = useAPICall(DeleteBusinesss, { onLoad: false });

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const offset =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(limit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(limit) - Number(limit);
    localStorage.setItem("offset", offset);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: offset, businessView: "client", limit: limit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, businessView: "client", limit: data }],
    });
  }

  useEffect(() => {
    if (isDeleteBusinessLoading) {
      return () => null;
    }
    if (isDeleteBusinessError) {
      setAddErrorMessage("Failed to Remove Business");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      setLoadingCircul(false);
      return () => null;
    }
    if (DeleteBusinessResponse && DeleteBusinessResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setQboSuccessMessage("Business Removed Successfully");
      setShowAddSnackbar(true);
      setTimeout(() => {
        setShowAddSnackbar(false);
      }, 3000);
      setAPICall({
        payload: [
          { keyword: `${search}`, businessView: "client", offset: ThisOffsetuse, limit: limit },
        ],
      });
    }
  }, [DeleteBusinessResponse]);


  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await setAPICall({
        payload: [
          { keyword: `${search}`, businessView: `client`, offset: ThisOffsetuse, limit: 10 },
        ],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  var resource = "";
  var actions = "";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
  };

  eventBus.$on("AddnewBusinessFailed", (data) => AddnewBusinessFailed(data));
  function AddnewBusinessFailed(data) {
    setAddErrorMessage("Failed to add new Business");
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("EditInventoryUnitSchemaFailed", (data) => EditInventoryUnitSchemaFailed(data));
  function EditInventoryUnitSchemaFailed(data) {
    setAddErrorMessage("Failed to Update Business");
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("AddnewBusinessComplete", (data) => AddnewBusinessComplete(data));
  function AddnewBusinessComplete(data) {
    setQboSuccessMessage("Business Created Successfully");
    setShowAddSnackbar(true);
    setTimeout(() => {
      setShowAddSnackbar(false);
    }, 3000);
  }

  eventBus.$on("EditInventoryUnitSchemaComplete", (data) => EditInventoryUnitSchemaComplete(data));
  function EditInventoryUnitSchemaComplete(data) {
    setQboSuccessMessage("Business Updated Successfully");
    setShowAddSnackbar(true);
    setTimeout(() => {
      setShowAddSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeQboWindowApiCall", (data) => closeQboWindowApi(data));
  function closeQboWindowApi(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: limit }],
    });
  }

  eventBus.$on("errorInAddOwner", (data) => errorInAddOwner(data));
  function errorInAddOwner(data) {
    setShowErrorSnackbar(true);
    setAddErrorMessage(data);
  }

  eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
  function successInAddOwner(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setShowAddSnackbar(true);
    setTimeout(() => {
      setShowAddSnackbar(false);
    }, 10000);
    setOpenAddOwner(false);
    setLoading(true);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: limit }],
    });
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const status = queryParameters.get("status");
    const message = queryParameters.get("message");
    const clientId = queryParameters.get("clientId");
    setQboErrorMessage(message);
    setQboSuccessMessage(message);
    setQboState(status);
    setQboClientId(clientId);
    if (status === "Failed") {
      const param = searchParams.get("status");
      if (param) {
        searchParams.delete("status");
        searchParams.delete("message");
        searchParams.delete("clientId");
        setSearchParams(searchParams);
      }
      setShowQboErrorSnackbar(true);
      // history.pushState(null, ``, `${SITE_URL}/accountant/client/list`);
      setTimeout(() => {
        setShowQboErrorSnackbar(false);
      }, 10000);
    }
  }, []);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };

  const successDelete = async () => {
    setLoading(true);
    await DeleteBusinessAPICall({
      payload: [
        {
          bussinessId: `${clientId}`,
        },
      ],
    });
    setAlert(false);
  };

  const hideAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [
              { keyword: `${search}`, businessView: `client`, offset: ThisOffsetuse, limit: limit },
            ],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    
  }, [search]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response?.data?.data?.totalRecords);
      const totalHeight = `${18 - response?.data?.data?.totalRecords * 2}%`;
      setTableHeight( response?.data?.data?.totalRecords > 4 ? { marginTop: "8px" } : { marginTop: "8px", marginBottom: totalHeight } );
      const data = response.data.data.records.map((prop, key) => {
        qboClientId === prop._id ? setOwnerName(prop.name) : "";
        return {
          snum: key + 1,
          client:
            prop.accountant != null ? (
              <MDBox>
                <>
                  <img height={20} width={20} style={{ marginBottom: "-4px", filter: darkMode ? "brightness(0) invert(1)" : "" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYElEQVR4nO2bz48MQRTHPzPIMg5LnFxIWBInNxEHy4UD1j+wszcuItixwYU6WDYuJIhMZJMNsVdHBxIOg7iJSCR2CEcuxK8goaWS2k2ldHdV79bsVPf0N3nZ3q4f8+ql3qvvq+oCN6wEDgKjwKkcyglgCKiRERVgDPgMRAWQT0BDjcuKKjAdgNKdkDsuRhgzGrWBJjARsLxI0LmpnvUy6c6pPq9P+5tAH2FD+vfHFJ3l86ThDrWkzoa0ijM5GLzEPged5bs3Wr39SZ2d1CrdIB845qhz08UNhFZJPucBrjo71ROlAShnQFQAF5Bcpm6wwvu95AIjFkIksnQWOuJ0rlsMMJyls7y6wHACa5Tvq1k6Cx1edRalAShnQFS6AGUMiMogiDWiDqisSv41EcfE5iv1tGWrm6tAW9V7HVNmY2JZpe5JZytEhs5mUgxgY2JZZThEAwyobeeNMWVpTCyrpDK3kghRMkFRUmHKXCDqRhC8AHwDxmPKNgNvPa0Acjt7kyedrRAZOvuq6n2xbK/7kIYnna0QGTobV0Y4nzADzCOp+Uo71BkQCkoDUM4AetoF6o45Q2HT4aoauC1nKGw67BUih+mwV4gcpsNeIXIYBJPijy12FNYAevw50ksGWAIcVjEp0vKUq8DqohugD7ibEkTfA+uKbIDrxoC/Ax+Md09dPpIUOTSAXIV+a3qfBZaqMvmd8w+tbMei0soufCbXiik/rc0KqxtUfdLKRcJF4yvROGwD1lNQHNcM8ApYRo9hixHsbgMr6DFMG0Z4CWy1Nap6TFk7KS6Uth94ZBjhp9pMTWw74jlj66TUHcnQJeCP0fYBsNa25keBSxZOMgi8M9rL/zekGaAVwK0PU1oLIGXSJW4ZRnhiMkEROOvzod8h4K/Wz6DvH+gkfOmnX5c514kf6BSy6LddRfw1MWWjWj8yNZ5DQyuQ10lCw1FH/VYpni/rPYzJ+O5p/cgbcXM4oBW0A7wgtddRv37NAFKm1NmkPE+8pr3/Za4ENXWFbLbCZGBGWG7k9Gn6nXFYSi/HNWzEnMU3A2B/s/IsQT+THcppfyVl8FNqy+w/VNR10iiHEscOdwGPtTrPgd22qVZRkVJ3hzxI0obNHq2OpPvOqKnAKA87us0CF3pYslMF0UT8A7bRGYtXAaNzAAAAAElFTkSuQmCC" ></img>
                  <Link to={`/client/document/${prop._id}`}>
                    <MDTypography variant="caption" fontWeight="regular" fontSize="0.875rem" color="info" ml={1} sx={{"&:hover": { color: "#007bff" }}} >
                      {prop.name}
                    </MDTypography>
                  </Link>
                </>
              </MDBox>
            ) : (prop.name),
            doctoreview: prop.toReview == 0 || prop.toReview == "-" ? '-' : 
            <MDBox sx={{ cursor: "pointer" ,'&:hover': {
                  color: '#1a73e8', // Change to blue on hover
                }}}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              sx={{
                color: darkMode ? '#ffffff' : '#1a73e8',
                '&:hover': {
                  color: '#1a73e8', // Change to blue on hover
                },
              }}
              onClick={async (value) => {
                setLoading(true);
                navigate(`/client/document/${prop._id}?k=&t=${encodeURIComponent('ToReview')}`)
              }}
            >
              {prop.toReview}
            </MDTypography>
          </MDBox>,
          unmappedvendors: prop.unmappedVendor == 0 ? '-' : prop.unmappedVendor ,
          clientapproval: prop.askClient == 0 || !prop.askClient ? '-' : prop.askClient,
          yourapproval: prop.approved == 0 ? '-' : prop.approved,
          pendingapproval: prop.pendingApproval == 0 ? '-' : prop.pendingApproval,
          unreconciled: "-",
          switch: prop.ownerAssigned === true ? (
              prop.isBlocked ? (
                <MDBox>
                  <MDTypography variant="caption" fontWeight="regular" fontSize="0.875rem"  color="error" > Inactive </MDTypography>
                </MDBox>
              ) : (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="success"
                  >
                    Active
                  </MDTypography>
                </MDBox>
              )
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="warning"
                >
                  {" "}
                  Owner Not Assigned
                </MDTypography>
              </MDBox>
            ),
          action: (
            <MDBox display="flex" justifyContent="center">
              {prop?.accountant == null ? (
                <>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.edittooltip}
                      placement="top"
                      onClick={(value) => {
                        setClientId(prop._id);
                        setOpenEditclientPopup(true);
                      }}
                    >
                      <Icon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.deletetooltip}
                      placement="top"
                      onClick={(value) => {
                        setClientId(prop._id);
                        setAlert(true);
                      }}
                    >
                      <Icon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>delete</Icon>
                    </Tooltip>
                  </MDTypography>
                </>
              ) : ("")}
            </MDBox>
          ),
        };
      });
      setAdminList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const dataTableData = {
    columns: [
      {
        Header: "Business Name",
        accessor: "client",
        width : 250,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "To Review",
        accessor: "doctoreview",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unmapped Vendors",
        accessor: "unmappedvendors",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Client Approval",
        accessor: "clientapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Your Approval",
        accessor: "yourapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Pending Approval",
        accessor: "pendingapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unreconciled Statements",
        accessor: "unreconciled",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  useEffect(() => {
    if (qboState === "Success") {
      const param = searchParams.get("status");
      if (param) {
        searchParams.delete("status");
        searchParams.delete("message");
        searchParams.delete("clientId");
        setSearchParams(searchParams);
      }
      setTimeout(() => {
        setOpenAddOwner(true);
      }, 1000);
    }
  }, [qboState]);

  const handleCreateBusiness = async () => {
    const ThisOffsetuse = localStorage.getItem("offset");
    setOpenNewclientPopup(false);
    setLoadingCircul(true);
    await setAPICall({
      payload: [
        { keyword: `${search}`, businessView: `client`, offset: ThisOffsetuse, limit: limit },
      ],
    });
  };

  const handleEditBusiness = async () => {
    const ThisOffsetuse = localStorage.getItem("offset");
    setOpenEditclientPopup(false);
    setLoadingCircul(true);
    await setAPICall({
      payload: [
        { keyword: `${search}`, businessView: `client`, offset: ThisOffsetuse, limit: limit },
      ],
    });
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox my={3}>
          <Card style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }} sx={loaderClass}>
            <MDBox
              pt={3}
              px={2}
              sx={{ pr: 3, mb: 2 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                Business
              </MDTypography>
              <MDBox px={2} display="flex">
                <MDBox sx={{ pr: 3, mb: 2 }}>
                  <MDInput
                    type="search"
                    label="Search Business"
                    onKeyDown={handleKeyDown}
                    variant="standard"
                    onChange={handleSearch}
                    InputLabelProps={{ style: darkMode ? { color: "#ffffff" } : {} }}
                    sx={{ 
                      "& input": { color: darkMode ? "#ffffff" : "#000000" },
                      "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                    }}
                  />
                </MDBox>
                <MDBox>
                  {canAccess((actions = "create"), (resource = "clients")) && (
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => setOpenNewclientPopup(true)}
                    >
                      {" "}
                      Add New Business
                    </MDButton>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox>{alert}</MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
            <Box my={5} style={tableHeight}>
              <DataTable1
                table={dataTableData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                response={tableEmpty}
                showCheck={false}
                ClientHeigth={true}
              />
            </Box>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="delete"
                title="Success"
                content="Client Deleted Successfully."
                open={showSnackbar}
                close={() => setShowSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content={qboSuccessMessage}
                open={showAddSnackbar}
                close={() => setShowAddSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={addErrorMessage}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={qboErrorMessage}
                open={showQboErrorSnackbar}
                close={() => setShowQboErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <Dialog
                open={alert}
                onClose={hideAlert}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "450px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#7b809a",
                    },
                  },
                }}
              >
                <DialogContent sx={{ overflowY: "hidden" }}>
                  <DialogContentText
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 4,
                      color: darkMode ? "#ffffff" : "#7b809a",
                    }}
                  >
                    <h3>Are you sure?</h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{
                        fontWeight: 300,
                        fontSize: "0.90rem",
                        textAlign: "center",
                        mt: 4,
                        color: darkMode ? "#ffffff" : "#7b809a",
                      }}
                    >
                      Once you delete this Business there is no way of getting any of the documents
                      or data back.
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                  <MDButton variant="gradient" color="success" onClick={successDelete}>
                    Yes, delete it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={hideAlert}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddOwner}
                onClose={() => setOpenAddOwner(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      // height: "100%"
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Add Owner For - {ownerName}
                </DialogTitle>
                {/* <DialogContent>
                                    <AddOwnerDetailsDialog businessId={accountantId} />
                                </DialogContent> */}
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                // fullScreen
                open={openNewclientPopup}
                onClose={() => setOpenNewclientPopup(false)}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  Add Business
                </DialogTitle>
                <DialogContent>
                  <AddBusinessDialog onClose={handleCreateBusiness} />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                // fullScreen
                open={openEditclientPopup}
                onClose={() => setOpenEditclientPopup(false)}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                      minHeight: "30%",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  Edit Business
                </DialogTitle>
                <DialogContent>
                  <EditBusinessDialog bussinessId={clientId} onClose={handleEditBusiness} />
                </DialogContent>
              </Dialog>
            </MDBox>
          </Card>
          <MDBox display="flex" justifyContent="center">
            {loading && <Loader />}
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default UserHoc(UserClientlist);
