import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EditCategoryApi, GetCategoryList } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import DraggableAddCategoryDialog from "./components/draggbleAddCategory";
import DraggableEditCategoryDialog from "./components/draggbleEditCategory";
import image from "./output-onlinegiftools.gif";

const Category = ({ businessId, syncPercent, documentSource }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [alert, setAlert] = useState(false);
  const [showList, setShowList] = useState(true);
  const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [{isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
  const [{isLoading: isEditCategoryLoading,isError: isEditCategoryError, response: EditCategoryResponse},EditCategoryListAPICall] = useAPICall(EditCategoryApi, { onLoad: false });
  const { pathname } = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const offset = (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(offset));
    CategoryListAPICall({
      payload: [
        {
          businessId: `${businessId}`,
          keyword: `${search}`,
          offset: Number(offset),
          limit: PerPagelimit,
        },
      ],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    CategoryListAPICall({
      payload: [{ businessId: `${businessId}`, keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await CategoryListAPICall({
        payload: [
          { businessId: `${businessId}`, keyword: `${search}`, offset: ThisOffsetuse, limit: 10 },
        ],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
  }, [loading]);

  eventBus.$on("reloadCategoryDetailsList", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    CategoryListAPICall({
      payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  eventBus.$on("AddnewCategoryFailed", (data) => AddnewCategoryFailed(data));
  async function AddnewCategoryFailed(data) {
    setErrorMessage(data?.message);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("EditnewCategoryFailed", (data) => EditnewCategoryFailed(data));
  async function EditnewCategoryFailed(data) {
    setErrorMessage(data?.message);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("AddnewCategoryComplete", (data) => AddnewCategoryComplete(data));
  async function AddnewCategoryComplete(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    CategoryListAPICall({
      payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  useEffect(() => {
    if (isEditCategoryLoading) {
      return () => null;
    }
    if (isEditCategoryError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditCategoryResponse && EditCategoryResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(false);
      CategoryListAPICall({
        payload: [{ businessId: `${businessId}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, [EditCategoryResponse]);

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await CategoryListAPICall({
            payload: [
              {
                businessId: `${businessId}`,
                keyword: `${search}`,
                offset: ThisOffsetuse,
                limit: PerPagelimit,
              },
            ],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    
  }, [search]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      setTotalRecords(CategoryResponse.data.data.totalRecords);
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          name: prop.name,
          currentBalance: prop.currentBalance,
          accountType: prop.accountType,
          createdat: Createdate.toLocaleDateString(),
          action:
            documentSource === "Paynthr" ? (
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.edittooltip}
                    placement="top"
                    onClick={(value) => {
                      setCategoryId(prop._id);
                      setOpenEditCategory(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setCategoryId(prop._id);
                      setAlert(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : syncPercent === 100 ? (
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.edittooltip}
                    placement="top"
                    onClick={(value) => {
                      setCategoryId(prop._id);
                      setOpenEditCategory(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                  </Tooltip>
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                >
                  <Tooltip
                    title={action.deletetooltip}
                    placement="top"
                    onClick={(value) => {
                      setCategoryId(prop._id);
                      setAlert(true);
                    }}
                  >
                    <Icon style={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            ) : (
              ""
            ),
        };
      });
      setTimeout(() => {
        setShowList(true);
        setLoadingCircul(false);
        setLoading(false);
      }, 1000);
      setCategoryList(data);
    }
  }, [CategoryResponse]);

  const dataTableData1 = {
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Account Type",
        accessor: "accountType",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: categoryList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    if(event.target.value){
      setSearch(event.target.value);
    }else{
      setSearch(event.target.value);
      setLoading(true);
      const ThisOffsetuse = localStorage.getItem("offset");
      await CategoryListAPICall({
        payload: [
          { businessId: `${businessId}`, keyword: "", offset: ThisOffsetuse, limit: 10 },
        ],
      });
    }
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  const successDelete = async () => {
    setLoading(true);
    await EditCategoryListAPICall({
      payload: [
        {
          isArchived: true,
          categoryId: `${categoryId}`,
        },
      ],
    });
    setAlert(false);
  };

  return CategoryResponse?.data?.isSuccess === true && syncPercent < 100 ? (
    <>
      <Grid item xs={12}>
        <MDBox mt={5} mb={5}>
          <MDTypography
            variant="body2"
            color="secondary"
            sx={
              darkMode
                ? {
                    width: "100%",
                    fontSize: "1.20rem",
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                    color: "#ffffff !important",
                  }
                : {
                    width: "100%",
                    fontSize: "1.20rem",
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                  }
            }
          >
            {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
          </MDTypography>
          <MDBox
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: "1.20rem",
            }}
          >
            <div>
              <img
                width={23}
                height={23}
                style={{ marginBottom: "-6px", marginRight: "8px" }}
                src={image}
              />
            </div>
            <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={"warning"}>
              ({syncPercent}% completed)
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
    </>
  ) : (
    showList && (
      <>
        <MDBox
          px={2}
          sx={{ pr: 3, mb: 2 }}
          component="form"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h5" fontWeight="medium">
            Category
          </MDTypography>
          <MDBox px={2} display="flex">
            <MDBox sx={{ pr: 3, mb: 2 }}>
              <MDInput
                sx={{
                  color: darkMode ? "#ffffff" : "inherit", // Text color
                  "& .MuiInputBase-root": {
                    color: darkMode ? "#ffffff" : "inherit", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: darkMode ? "#ffffff" : "#7b809a", // Label color
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: darkMode ? "#ffffff" : "#7b809a", // Label color when focused
                  },
                  "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                }}
                type="search"
                label="Search Category"
                onKeyDown={handleKeyDown}
                variant="standard"
                onChange={handleSearch}
              />
            </MDBox>
            <MDBox sx={{ pr: 3, mb: 2 }}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={() => setOpenAddNewCategory(true)}
              >
                Add Category
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1
              table={dataTableData1}
              entriesPerPage={false}
              pagination={true}
              showTotalEntries={true}
              response={totalRecords}
              showCheck={false}
              ClientHeigth={true}
            />
          </MDBox>
        }
        <MDBox display="flex" justifyContent="center">
          {loading && <Loader />}
        </MDBox>
        <MDBox>
          <Dialog
            open={alert}
            onClose={() => setAlert(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "450px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#7b809a",
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: "hidden" }}>
              <DialogContentText
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  color: darkMode ? "#ffffff" : "#7b809a",
                }}
              >
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{
                    fontWeight: 300,
                    fontSize: "0.90rem",
                    textAlign: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  Once you delete this Category there is no way of getting any of the documents or
                  data back.
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
              <MDButton variant="gradient" color="success" onClick={successDelete}>
                Yes, delete it!
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={() => setAlert(false)}>
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
        <MDBox>
              <DraggableEditCategoryDialog
                open={openEditCategory}
                categoryId={categoryId}
                businessId={businessId}
                onClose={() => setOpenEditCategory(false)}
              />
        </MDBox>
        <MDBox>
              <DraggableAddCategoryDialog
              open={openAddNewCategory}
                invoiceId={""}
                businessId={businessId}
                onClose={() => setOpenAddNewCategory(false)}
              />
        </MDBox>
        <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
      </>
    )
  );
};

export default UserHoc(Category);
