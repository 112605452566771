import { Autocomplete, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormField from "layouts/accountants/user/components/FormFieldAddCategory";
import { useEffect, useState } from "react";
import { EditCategoryApi, GetCategoryList, GetCategoryListForParent, GetReadOneCategoryList } from "services/userservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { EditCategorySchema } from "../components/schemas/editcategory";
import { data } from "../QboAccountTypes";

function EditCategory({ onClose, categoryId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [classification, setClassification] = useState("");
  const [accountType, setAccountType] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [jsondata] = useState(data[0].classifications);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accountSubTypeJson, setAccountSubTypeJson] = useState([]);
  const [classificationJson, setClassificationJson] = useState([]);
  const [accountTypeName, setAccountTypeName] = useState("");
  const [accountSubTypeName, setAccountSubTypeName] = useState("");
  const [isSubAccount , setIsSubAccountant] = useState(false);
  const [number , setNumber] = useState("");
  const [balance ,setBalance] = useState("");
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scrollLoading ,setScrollLoading] = useState(false);
  const [parentAccount , setParentAccount] = useState("");
  const [parentAccountObj , setParentAccountObj] = useState("");
  const [parentAccountId , setParentAccountId] = useState("");
  const [description , setDescription] = useState("");
  const [parentCategoryName, setParentCategoryName] = useState('');
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isLoading: isEditCategoryLoading, isError: isEditCategoryError, response: EditCategoryResponse, }, EditCategoryListAPICall,] = useAPICall(EditCategoryApi, { onLoad: false });
  const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
  const [{ isLoading: isReadOneCategoryLoading, isError: isReadOneCategoryError, response: ReadOneCategoryResponse },ReadOneCategoryListAPICall] = useAPICall(GetReadOneCategoryList, { onLoad: false });

  useEffect(async() => {
    if (isReadOneCategoryLoading) {
      return () => null;
    }
    if (isReadOneCategoryError) {
      setLoading(false);
      return () => null;
    }
    if (ReadOneCategoryResponse && ReadOneCategoryResponse.data.isSuccess === true) {
      setCategoryName(ReadOneCategoryResponse?.data?.data?.account?.name);
      setAccountType(ReadOneCategoryResponse?.data?.data?.account?.accountType);
      setClassification(ReadOneCategoryResponse?.data?.data?.account?.classification);
      setAccountTypeName(ReadOneCategoryResponse?.data?.data?.account?.accountType);
      setNumber(ReadOneCategoryResponse?.data?.data?.account?.accountNo);
      setBalance(ReadOneCategoryResponse?.data?.data?.account?.currentBalance  ? ReadOneCategoryResponse?.data?.data?.account?.currentBalance : ReadOneCategoryResponse?.data?.data?.account?.balance);
      setDescription(ReadOneCategoryResponse?.data?.data?.account?.description);
      setIsSubAccountant(ReadOneCategoryResponse?.data?.data?.account?.subAccount);
      setParentAccountId(ReadOneCategoryResponse?.data?.data?.account?.parent?._id);
      setParentAccountObj(ReadOneCategoryResponse?.data?.data?.account?.parent);
      const accountCategory = jsondata.find(category =>
        category.accountTypes.some(type => type.name === ReadOneCategoryResponse?.data?.data?.account?.accountType)
      );
      if (accountCategory) {
        const accountType = accountCategory.accountTypes.find(type => type.name === ReadOneCategoryResponse?.data?.data?.account?.accountType);
        if (accountType) {
          setParentCategoryName(accountCategory.name);
          setClassificationJson(accountType.subTypes);
          setAccountSubTypeName(accountType.subTypes[0].name);
        }
      }
      if(ReadOneCategoryResponse?.data?.data?.account?.subAccount == true){
        const response = await GetCategoryListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${ReadOneCategoryResponse?.data?.data?.account?.parent?.fullyQualifiedName}`,
              offset: 0,
              limit: 20, // Fetch 10 items at a time
            },
          ],
        });
        const newItems = response?.data?.data?.records || [];
        setItems(newItems);
        setParentAccount(ReadOneCategoryResponse?.data?.data?.account?.parent?.fullyQualifiedName);
      }
      setTimeout(() => {
        setShowDetails(true);
      }, 5000);
      setLoading(false);
    }
  }, [ReadOneCategoryResponse]);

  let initialValues = {
    ParentAccount:parentAccount,
    category: categoryName,
    accountType: accountType,
    classification: classification,
    accountSubType: accountSubTypeName,
    isSubAccountant:false,
    balance:balance,
    description:description,
    accountNo:number,
  };

  useEffect(async () => {
    if (categoryList.length != 0) {
      const Data = categoryList.find((obj) => {
        return obj._id === categoryId;
      });
    }

    if (categoryId) {
      setTimeout(() => {
        setShowDetails(true);
        setLoading(false);
      }, 2000);
    }
  }, [categoryList, categoryId]);

  useEffect(() => {

    if(!accountTypeName){
      setCategoryName('');
    }
    // Find the account type and its parent category
    const accountCategory = jsondata.find(category =>
      category.accountTypes.some(type => type.name === accountTypeName)
    );

    if (accountCategory) {
      // Find the matching account type
      const accountType = accountCategory.accountTypes.find(type => type.name === accountTypeName);

      if (accountType) {
        // Update the parent category name and subtypes
        setParentCategoryName(accountCategory.name);
        setClassificationJson(accountType.subTypes);
        setAccountSubTypeName(accountType.subTypes[0].name);
      }
    }
  }, [accountTypeName, jsondata]);

  useEffect(() => {
    // Update accountSubTypeJson based on classificationJson
    if (classificationJson.length > 0) {
      const obj = {};
      classificationJson.forEach((x, index) => {
        obj[index] = x; // Collect subTypes in an object
      });
      setAccountSubTypeJson(Object.values(obj)); // Update accountSubTypeJson
    }
  }, [classificationJson]);

  useEffect(async () => {
    setLoading(true);
    await ReadOneCategoryListAPICall({
      payload: [{ categoryId: categoryId }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      setLoading(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      setCategoryList(CategoryResponse.data.data.records);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isEditCategoryLoading) {
      return () => null;
    }
    if (isEditCategoryError) {
      eventBus.$emit("EditnewCategoryFailed", EditCategoryResponse?.data);
      setErrorMessage(EditCategoryResponse?.data?.Message);
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditCategoryResponse && EditCategoryResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
      eventBus.$emit("reloadCategoryDetailsList", EditCategoryResponse);
    }
  }, [EditCategoryResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditCategoryListAPICall({
      payload: [
        {
          categoryId: `${categoryId}`,
          name: categoryName,
          description: description,
          subAccount: isSubAccount,
          parentId: isSubAccount == true && parentAccountId ? parentAccountId : null,
          classification: parentCategoryName,
          accountType: accountTypeName,
          accountSubType: accountSubTypeName,
          accountNo: number,
        },
      ],
    });
  };

  const handleChecked = () => setIsSubAccountant(!isSubAccount);
  const handleChange = (event)=>{
    setAccountType(event?.target?.value);
    setAccountTypeName(event?.target?.value);
  }

  const handleChangeDetailtype = (event)=>{
    setAccountSubTypeName(event?.target?.value)
  }

  const handleChangeNumber = (event)=>{
    setNumber(event?.target?.value)
  }

  const handleChangeDesc = (event)=>{
    setDescription(event?.target?.value)
  }

  const onChnageName = (event)=>{
    setCategoryName(event?.target?.value)
  }

  const fetchItems = async (search = "", newOffset = 0) => {
    setScrollLoading(true);
   const response =  await GetCategoryListForParent({
      payload: [
        {
          businessId: `${businessId}`,
          keyword: `${search}`,
          offset: newOffset,
          limit: 20, // Fetch 10 items at a time
        },
      ],
    });
    
      const newItems = response?.data?.data?.records || [];
      setItems(prevItems => newOffset === 0 ? newItems : [...prevItems, ...newItems]);
      setHasMore(newItems.length > 0);
      setOffset(newOffset);
      setScrollLoading(false);
   
};

const handleChangeParentAccount = (event)=>{
  setParentAccountObj(event);
  setParentAccount(event?.name);
  const selectedItem = items.find(item => item.name === event?.name);
  if (selectedItem) {
    const itemId = selectedItem._id;
    setParentAccountId(itemId);
  }
}
  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={EditCategorySchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting ,setFieldValue  }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                        <MDBox mt={1.625}>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6}>
                          <FormControl fullWidth name="accountType" variant="standard" sx={{ m: '5px',ml:0, minWidth: 120 }}>
                            <InputLabel sx={{
                               color: darkMode ? '#ffffff !important' : '#7b809a',
                              '&.Mui-focused': {
                                top: '-6px', // Adjust the position when focused
                              },
                              '&.MuiFormLabel-filled': {
                                top: '-6px', // Adjust the position when there is a value
                              },
                            }} >Account Type *</InputLabel>
                            <Select
                              native
                              value={values.accountType}
                              onChange={(event) => {
                                handleChange(event);
                                setFieldValue('accountType', event.target.value); // Update the value in Formik state
                              }}
                              label={"Select"} // Changed to match the other fields
                              sx={{
                                '& select': {
                                  color: darkMode ? '#c3c2c2' : '#7b809a',
                                  boxShadow: 'none',
                                  WebkitAppearance: 'none', // Removes the default shadow in some browsers
                                  MozAppearance: 'none',
                                  padding: 0,
                                  borderTop: 'none',
                                  borderLeft: 'none',
                                  borderRight: 'none',
                                },
                                '& .MuiSvgIcon-root': {
                                  color: darkMode ? '#ffffff' : '#7b809a', // Color of the dropdown icon in dark mode and light mode
                                },
                                '&:before': {
                                    borderBottomColor: darkMode ? '#ffffff !important' : '#7b809a', // Underline color when not focused
                                  },
                                  '&:after': {
                                    borderBottomColor: darkMode ? '#ffffff  !important' : '#3f51b5', // Underline color when focused
                                  },
                              }}
                            >
                              <option aria-label="None" value="" />
                              {jsondata.map((dataItem, dataIndex) => (
                                <optgroup key={dataIndex} label={dataItem.name}>
                                  {dataItem.accountTypes?.map((accountType, accountIndex) => (
                                    <option key={accountIndex} value={accountType.name}>
                                      {accountType.name}
                                    </option>
                                  ))}
                                </optgroup>
                              ))}
                            </Select>
                            <MDBox mt={0.75}>
                                  <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                  >
                                    <ErrorMessage name="accountType" />
                                  </MDTypography>
                            </MDBox>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormField 
                            type="text" 
                            label="Name *"
                            name="category"
                            onChange={onChnageName}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="standard" sx={{ mt: 0.5 }}>
                              <InputLabel  sx={{
                                color: darkMode ? '#ffffff !important' : '#7b809a',
                              '&.Mui-focused': {
                                top: '-6px', // Adjust the position when focused
                              },
                              '&.MuiFormLabel-filled': {
                                top: '-6px', // Adjust the position when there is a value
                              },
                            }}>Detail Type *</InputLabel>
                              <Field as={Select} sx={{
                                      '& .MuiSelect-select': {
                                        color: darkMode ? '#ffffff' : '#7b809a', // Selected text color
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: darkMode ? '#ffffff' : '#7b809a', // Color of the dropdown icon in dark mode and light mode
                                      },
                                      '&:before': {
                                          borderBottomColor: darkMode ? '#ffffff !important' : '#7b809a', // Underline color when not focused
                                        },
                                        '&:after': {
                                          borderBottomColor: darkMode ? '#ffffff !important' : '#3f51b5', // Underline color when focused
                                        },
                                    }} name="accountSubType" onChange={handleChangeDetailtype}  variant="standard">
                                <MenuItem value="">
                                  <em>Select Detail Type</em>
                                </MenuItem>
                                {accountSubTypeJson.map((x) => (
                                  <MenuItem value={x.name} key={x.name}>
                                    {x.name}
                                  </MenuItem>
                                ))}
                              </Field>
                              <MDBox mt={0.75}>
                                <ErrorMessage name="accountSubType">
                                  {(msg) => (
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                      {msg}
                                    </MDTypography>
                                  )}
                                </ErrorMessage>
                              </MDBox>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormField type="text"  onChange={handleChangeNumber} label="Number" name="accountNo" />
                            {number?.length >= 6 && number?.length <= 7  ? "" :
                            number?.length !== 0 && number !=  null ?
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                Account Number should be 6 or 7 characters.
                              </MDTypography>  :''
                      }
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={12}>
                            <FormField onChange={handleChangeDesc} type="text" label="Description" name="description" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                        <Grid item xs={12} sm={6}>
                          <Field
                              as={FormControlLabel}
                                  type="checkbox"
                                  name="isSubAccountant"
                                  control={<Checkbox />}
                                  label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Sub Account</span>}
                                  checked={isSubAccount}
                                  onChange={handleChecked}
                              sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                          {balance &&
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  sx={{fontSize: '0.9rem' , padding:'10px 0px 0px 3px'}}
                                  fontWeight="regular"
                                >
                                  Balance: {balance}
                                </MDTypography>  
                              }
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={12}>
                          {isSubAccount &&
                            <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                <Autocomplete
                                  options={items}
                                  getOptionLabel={(option) => option.name || ""}
                                  value={parentAccountObj}
                                  onChange={(event, value) => {
                                    handleChangeParentAccount(value); // Store the full selected object
                                  }}
                                  onInputChange={(event, value) => {
                                    if (value.length > 0) {
                                      fetchItems(value); // Fetch items based on input
                                    }
                                  }}
                                  onOpen={() => fetchItems()} // Fetch initial data when dropdown opens
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Parent Account *"
                                      variant="standard"
                                      InputLabelProps={{
                                        sx: {
                                          color: darkMode? '#ffffff !important' : '#7b809a',
                                          '&.Mui-focused': { top: '-6px' },
                                          '&.MuiFormLabel-filled': { top: '-6px' },
                                        },
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        sx:{
                                          '& .MuiSvgIcon-root': {
                                            color: darkMode ? '#ffffff' : '#7b809a', // Color of the dropdown icon in dark mode and light mode
                                          },
                                          color: darkMode ? '#ffffff !important' : '#7b809a', // Text color
                                          "&:before": {
                                             borderBottomColor: darkMode ? "#ffffff !important" : "#7b809a", // Underline color in unfocused state
                                           },
                                           "&:after": {
                                             borderBottomColor: darkMode ? "#ffffff !important" : "#3f51b5", // Underline color in focused state
                                           },
                                       },
                                        endAdornment: (
                                          <>
                                            {scrollLoading ? (
                                              <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                  ListboxProps={{
                                    onScroll: (event) => {
                                      const bottom =
                                        event.target.scrollHeight - event.target.scrollTop ===
                                        event.target.clientHeight;
                                      if (bottom && hasMore) {
                                        fetchItems("", offset + 20); // Load more items when the user scrolls to the bottom
                                      }
                                    },
                                  }}
                                  renderOption={(props, option) => (
                                    <MenuItem {...props} key={option.name} value={option.name}>
                                      {option.name}
                                    </MenuItem>
                                  )}
                                />
                                <MDBox mt={0.75}>
                                  <ErrorMessage name="ParentAccount">
                                    {(msg) => (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {msg}
                                      </MDTypography>
                                    )}
                                  </ErrorMessage>
                                </MDBox>
                            </FormControl>}
                          </Grid>
                          </Grid>
                      </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
       <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default EditCategory;
