import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useRef, useState } from "react";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import { EditInvoiceItem, StatementDocumentList } from "../../../../services/userservices";
import AddNewCategoryDialog from "./addnewcategory";
import AddClassDialog from "./addnewclass";
import AddCustomerDialog from "./addnewcustomer";

function EditStatementInvoiceDialog({itemId ,noCategory, itemListResponse, invoiceId, editItemId, businessId, onClose}) {
  const [isDisable, setBtnDisable] = useState(false);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [balance, setBalance] = useState("");
  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState(0);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchInvoice , setSearchInvoice] = useState('');
  const [statmentDocList ,setStatmentDocList] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditInvoiceItem, { onLoad: false });
  const [{isLoading: isStatmentDocListLoading,isError: isStatmentDocListError,response: StatmentDocListResponse,},StatmentDocListListAPICall,] = useAPICall(StatementDocumentList, { onLoad: false });

  useEffect(async() => {
    setLoading(true);
      await StatmentDocListListAPICall({
        payload: [
          {
            clientId: businessId,
            offset: 0,
            limit: 10,
            keyword: `${searchInvoice}`,
          },
        ],
      });
  }, []);
  
  useEffect(() => {
    if (isStatmentDocListLoading) {
      return () => null;
    }
    if (isStatmentDocListError) {
      setLoading(false);
      return () => null;
    }
    if (StatmentDocListResponse && StatmentDocListResponse.data.isSuccess === true) {
      setLoading(false);
      const data = StatmentDocListResponse.data.data.invoices.map((prop, key) => {
        return {
          docNumber: prop?.docNumber,
          ItemId: prop?._id,
          txnDate: prop?.transactionDate,
          total: prop?.total
        };
      });
      setStatmentDocList(data);
      setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [StatmentDocListResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      eventBus.$emit("closeErrorEditItemPopup", response);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeEditItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    const result = itemListResponse?.data?.data?.records.map((prop, key) => {
      {
        if (editItemId === prop._id) {
          return {
            id: prop._id,
            amount: prop.amount,
            docNumber: prop.docNumber,
            invoiceDate: prop.invoiceDate,
          };
        } else {
          return {
            id: prop.id,
            amount: prop.amount,
            docNumber: prop.docNumber,
            invoiceDate: prop.invoiceDate,
          };
        }
      }
    });
    setTimeout(() => {
      const results = result.find((item) => item.id === editItemId);
      const Str = results?.price;
      const newStr = typeof Str === typeof String() ? (Str.includes(",") ? Str.replace(/,/g, "") : Str) : Str;
      setSearchInvoice(results?.docNumber);
      setInvoice(results?.docNumber);
      setInvoiceDate(results?.invoiceDate);
      setBalance(results?.amount);
      setPrice(newStr);
      setTotal(results?.total);
      setId(results?.id);
      setLoading(false);
    }, 1000);
  }, [itemListResponse]);

  const initialValues = {
    invoice: invoice,
    invoiceDate: invoiceDate,
    balance: balance,
    total: total,
    price: price,
    category: "",
  };
  const handleSubmit = (values) => {
    setBtnDisable(true);
    setAPICall({
      payload: [{itemId: id , InvoiceId: `${invoiceId}`, docNumber : searchInvoice }],
    });
  };

  const handleSearch = async (event ,newInputValue) => {
    localStorage.setItem("offset", 0);
    const matchingDoc = statmentDocList.find(
      (doc) => doc.docNumber === newInputValue
    );
    setInvoiceDate(matchingDoc?.txnDate);
    setBalance(matchingDoc?.total)
    setSearchInvoice(newInputValue);
  };

  useEffect(() => {
    // Create an interval to check for textareas every second
    const interval = setInterval(() => {
      if(darkMode){
      const textarea = document.querySelector('textarea[placeholder="Leave Your Comment Here"]');
      if (textarea) {
        textarea.style.setProperty("--placeholder-color", "rgba(255, 255, 255, 0.7)");
  
        // Inject a style tag for the placeholder
        const style = document.createElement("style");
        style.innerHTML = `
          textarea::placeholder {
            color: var(--placeholder-color);
          }
        `;
        document.head.appendChild(style);
      }
    }
    }, 1000); 

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {(
        <MDBox width="auto">
          <Grid item xs={12} lg={12}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <MDBox p={2} pt={2}>
                    <MDBox>
                      <MDBox>
                        <Grid container spacing={3} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                          <Autocomplete
                              sx={
                                          darkMode
                                            ? {
                                              "& .MuiDataGrid-row": {
                                                borderTop: '0.5px solid #1a2035'
                                              },
                                                "& .MuiInput-underline": {
                                                  borderBottom: "2px solid #ffffff",
                                                },
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                                "& .MuiInput-root .MuiInput-input": {
                                                  WebkitTextFillColor: "white",
                                                },
                                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                                  {
                                                    color: "white !important",
                                                  },
                                                "& .MuiInput-input": {
                                                  padding: `6px 4px 4px 0px !important`,
                                                },
                                                "& .MuiInputBase-input": {
                                                  height: `1.2em !important`,
                                                },
                                                "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                                  {
                                                    color: "white !important",
                                                  },
                                                  marginTop: "6px"
                                                //   width:'300px'
                                              }
                                            : {
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                  WebkitTextFillColor: "black",
                                                },
                                                "& .MuiInput-input": {
                                                  padding: `6px 4px 4px 0px !important`,
                                                },
                                                "& .MuiInputBase-input": {
                                                  height: `1.2em !important`,
                                                },
                                                marginTop: "6px"
                                                // width:'300px'
                                              }
                                        }
                                        value={{ docNumber: searchInvoice }}
                                        options={statmentDocList}
                                        getOptionLabel={(option) => option.docNumber}
                                        onInputChange={(event, newInputValue) => {
                                          handleSearch(event, newInputValue);
                                        }}
                                        InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                        id="controllable-states-demo"
                                        disableCloseOnSelect={false}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Invoice"
                                            variant="standard"
                                            InputLabelProps={{ style: { color: darkMode ? "#ffffff" : "#000" } }}
                                          />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField disabled type="text" label="Invoice Date" name="invoiceDate" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <FormField disabled type="text" label="Balance" name="balance" />
                            </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                        <MDButton
                          disabled={isDisable}
                          variant="gradient"
                          color="light"
                          onClick={onClose}
                        >
                          Cancel
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                          >
                            { isDisable ? "updating..." : "update"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
          <MDBox>
            <Dialog
              open={openAddNewCustomer}
              onClose={() => setOpenAddNewCustomer(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#7b809a",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  color: darkMode ? "#ffffff" : "#7b809a",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Customer
              </DialogTitle>
              <DialogContent>
                <AddCustomerDialog
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewCustomer(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewClass}
              onClose={() => setOpenAddNewClass(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Class
              </DialogTitle>
              <DialogContent>
                <AddClassDialog
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewClass(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewCategory}
              onClose={() => setOpenAddNewCategory(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Category
              </DialogTitle>
              <DialogContent>
                <AddNewCategoryDialog
                  Edit={true}
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewCategory(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
         { loading && <MDBox>
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
        </MDBox>}
        </MDBox>
      )}
    </>
  );
}

export default EditStatementInvoiceDialog;
