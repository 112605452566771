import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Modal, ThemeProvider, createTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { DataGrid, GridCellModes, useGridApiContext } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import CustomDialog from "components/CustomDialog";
import CustomDialog2 from "components/CustomDialog/customDialog2";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { textEllipsis } from "components/textEllipsis";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik, isString } from "formik";
import { DocumnetType } from "helpers/documentType";
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import FormField from "layouts/admin/accountants/components/FormField";
import AddOwnerDetailsDialog from "layouts/clients/add-owner";
import Moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { eventBus } from "utils/eventbus";
import { inLocalTime } from "utils/timeHelper";
import { canAccess } from "../../../../helpers";
import { AddVendors, CreateComments, DeleteInvoice, DeleteItem, EditBulkItem, EditItem, GetAuditLogList, GetCategoryAllList, GetCategoryListForParent, GetClassListForParent, GetClassesAllList, GetCommentsList, GetCustomerAllList, GetCustomerListForParent, GetDocumentIssueList, GetInvoiceDetails, GetIssueList, GetItemList, GetItemsIssueList, GetProductList, GetProductsAllList, GetReportList, GetVendorForList, GetsummoryList, GetuserClientDetails, GetuserClientList, GetuserListAll, MoveMenuInvoice, ReportIssue, UpdateInvoiceDetails, } from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import CurrencyFieldText from "../../user/components/CurrencyField-Text";
import LoaderDailog from "../components/imgLoader/LoaderDailog";
import { InvoiceEditSchema } from "../components/schemas/editinvoicevalidation";
import ColumnFilter from "./ColumnFilter";
import AddNewCategoryDialog from "./addnewcategory";
import AddClassDialog from "./addnewclass";
import AddCustomerDialog from "./addnewcustomer";
import AddNewProductDialog from "./addnewproduct";
import AddVendorDialog from "./addvendor";
import BulkUpdateDialog from "./bulkupdatedialog";
import TimelineItem from "./components/Timeline/TimelineItem";
import DraggableAddCategoryDialog from "./components/draggableAddCategoryItemDialog";
import DraggableAddItemDialog from "./components/draggableAddNewItem";
import DraggableEditItemDialog from "./components/editdraggbleItem";
import DraggableEditCategoryItemDialog from "./components/editdraggblecategory";
import "./styles.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const myTheme = createTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontSize: 13,
    fontWeight: 600,
    backgroundColor: "rgb(123, 128, 154)",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-actionsCell": {
            gridGap: "0px",
          },
          "& .MuiDataGrid-cell": {
            justifyContent: "center",
            outline: " none",
            fontWeight: "bold !important",
            color: "rgb(123 128 154)",
            // border: 0,
          },
          "& .MuiDataGrid-cell:focus": {
            outline: " none",
          },
          "&.Mui-selected": {
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function EditInvoiceDialog({invoiceIds,businessId,onIssueClick,onIssueClickData,documentsId,moduleTypeEdit,selectTab,onClose,storeResponce,onClose1,reloadApiAfterClose,selectedIndex}) {
  let newObj = {};
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const [textAreaVal, setTextAreaVal] = useState("");
  const [rotationAngle, setRotationAngle] = useState(0);
  const [novendorIssue, setNoVendorIssue] = useState(false);
  const [duplicateIssue, setDuplicateIssue] = useState(false);
  const [totalIssue, setTotalIssues] = useState(0);
  const [novendorIssueMsg, setNoVendorIssueMsg] = useState("");
  const [duplicateMsg, setDuplicateMsg] = useState("");
  const [priceMismatch, setPriceMismatch] = useState(false);
  const [priceMismatchMsg, setPriceMismatchMsg] = useState("");
  const [totalMismatch, setTotalMismatch] = useState(false);
  const [totalMismatchMsg, setTotalMismatchMsg] = useState(false);
  const [onlytotalMismatch, setOnlyTotalMismatch] = useState(false);
  const [invoiceDateMismatch, setInvoiceDateMismatch] = useState(false);
  const [noProduct, setNoProduct] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPageRowSize, setCurrentPageRowSize] = useState(false);
  const [showBulkEditButton, setShowBulkEditButton] = useState(false);
  const [noUnitProduct, setNoUnitProduct] = useState(false);
  const [noQuantity, setNoQuantity] = useState(false);
  const [noItem, setNoItem] = useState(false);
  const [showPassDuplicate ,setShowPassDuplicate] = useState(false);
  const [itemPriceMismatch, setItemPriceMismatch] = useState(false);
  const [noPrice, setNoPrice] = useState(false);
  const [noCategory, setNoCategory] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [documentTypeObj ,setDocumentTypeObj] = useState("");
  const [invoiceNumberObj ,setInvoiceNumberObj] = useState("");
  const [ponumberObj ,setPonumberObj] = useState("");
  const [showApprovedModel, setShowApprovedModel] = useState(false);
  const [invoice_id, setInvoice_Id] = useState(invoiceIds);
  const [invoiceId, setInvoiceId] = useState(invoiceIds);
  const [openQboSyncAskClient, setOpenQboSyncAskClient] = useState(false);
  const [createObjectURL, setCreateObjectURL] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [counts, setCounts] = useState(0);
  const [imageUrl1, setImageUrl1] = useState("");
  const [value, setValue] = useState(1);
  const [ErrorMessages, setErrorMessage] = useState("");
  const [passDuplicateMsg, setpassDuplicateMsg] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [openIssueReport, setOpenIssueReport] = useState(false);
  const [openAlreadyIssue, setopenAlreadyIssue] = useState(false);
  const [reportComment, setReportComment] = useState("");
  const [openAddOwner, setOpenAddOwner] = useState(false);
  const [onIssueClick1, setOnIssueClick1] = useState(onIssueClick);
  const [styles, setStyles] = useState({});
  const [itemType, setItemType] = useState("");
  const [editbleData, setEditbleData] = useState(false);
  const [totalErrorMsg, setTotalErrorMsg] = useState("");
  const [taxErrorMsg, setTaxErrorMsg] = useState("");
  const [checkFound, setCheckFound] = useState(false);
  const [subtotalErrorMsg, setSubtotalErrorMsg] = useState("");
  const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarAdd, setShowSnackbarAdd] = useState(false);
  const [showSnackbarEdit, setShowSnackbarEdit] = useState(false);
  const [showBulkEditDialog, setShowBulkEditDialog] = useState(false);
  const [vendorSelect, setVendorSelect] = useState("");
  const [vendorSelect1, setVendorSelect1] = useState("");
  const [vendorname, setVendorname] = useState("");
  const [vendorname1, setVendorname1] = useState("");
  const [vendorname2, setVendorname2] = useState("");
  const [addVendoreerror, setAddVendoreerror] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [showTrueSnackbar, setShowTrueSnackbar] = useState(false);
  const [showFalseSnackbar, setShowFalseSnackbar] = useState(false);
  const [showreportSnackbar, setShowReportSnackbar] = useState(false);
  const [invoicenumber, setInvoiceNumber] = useState("");
  const [totalItemLength, setTotalItemLength] = useState(0);
  const [transactionDate, setTransactionDate] = useState("");
  const [vendorEditable, setVendorEditable] = useState(true);
  const [uploaddate, setUploadDate] = useState("");
  const [alert, setAlert] = useState(false);
  const [passDuplicate , setPassDuplicate] = useState(false);
  const [ocrStatus, setOcrstatus] = useState(false);
  const [ocrStatus1, setOcrstatus1] = useState("");
  const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
  const [toggleShow, setToggleShow] = useState(false);
  const [disButtonOnWork ,setDisButtonOnWork] = useState(false);
  const [disAddButtonOnWork ,setDisAddButtonOnWork] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openCategoryAddItem, setOpenCategoryAddItem] = useState(false);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [total, setTotal] = useState(0);
  const [dottedStyle, setDottedStyle] = useState({});
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderTotalStore, setOrderTotalStore] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shippingprice, setShippingprice] = useState(0);
  const [onChanges, SetonChanges] = useState(false);
  const [discountStore, setDiscountStore] = useState(0);
  const [discountStatic, setDiscountStatic] = useState(0);
  const [shippingpriceStore, setShippingpriceStore] = useState(0);
  const [shippingpriceStatic, setShippingpriceStatic] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [ponumber, setPoNumber] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [subtotal, setSubTotal] = useState(0);
  const [subTotalForMatch, setSubTotalForMatch] = useState(0);
  const [totalForMatch, setTotalForMatch] = useState(0);
  const [transactionDateForMatch, setTransactionDateForMatch] = useState("");
  const [itemList, setItemList] = useState([]);
  const [summeryList, setSummeryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoaderFirInvoice, setShowLoaderFirInvoice] = useState(true);
  const [disableMoveButton, setDisableMoveButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [reportSubmit, setReportSubmit] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [auditLogList, setAuditLogList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [submitcomment, setsubmitcomment] = useState(false);
  const [itemId, setItemId] = useState([]);
  const [oneItemPopup, setOneItemPopup] = useState(false);
  const [unitPrice, setUnitPrice] = useState("");
  const [showselectedVendorId, setShowselectedVendorId] = useState("");
  const [editItemId, setEditItemId] = useState([]);
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [editable, SetEditable] = useState(false);
  const [taxStore, setTaxStore] = useState(0);
  const [actualSubtotal, setActualSubtotal] = useState(0);
  const [actualTotal, setActualTotal] = useState(0);
  const [matchId, setMatchId] = useState("");
  const [subTotStore, setSubTotStore] = useState(0);
  const [issues, setIssues] = useState([]);
  const [TotalStore, setTotalStore] = useState(total);
  const [resetData, setResetData] = useState(false);
  const [documentSource, setDocumnetSource] = useState("");
  const [isSynced, setIsSynced] = useState(false);
  const [documentStatus, setDocumentStatus] = useState("");
  const [openQboSync, setOpenQboSync] = useState(false);
  const [extention, setExtention] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [itemListResponse, SetItemListResponse] = useState([]);
  const [vendorerrormsg, setVendorerrormsg] = useState("");
  const [vendorerror, setVendorerror] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [checked, setChecked] = useState(newObj);
  const [pushPopFun, setPushPopFun] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productIds, setProductId] = useState("");
  const [ownerAssigned, setOwnerAssigned] = useState(false);
  const [assignNewClient, setAssignNewClient] = useState(false);
  const [tabsMove, setTabsMove] = useState("");
  const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [addProductSelectType, setAddProductSelectType] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [show, setShow] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [checkIssue, setCheckIssue] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0 });
  const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
  const [finalSelect, setFinalSelect] = useState("");
  const [transactionDateObj , setTransactionDateObj] = useState("");
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [addNewVendorId, setAddNewVendorId] = useState("");
  const [moveTowhere, setmoveTowhere] = useState("");
  const [users, setUsers] = useState([]);
  const [reportStatus, setReportStatus] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [chenging, setChenging] = useState(true);
  const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [typingSubTimeout, setTypingSubTimeout] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [ErrorMessagesVendorError, setErrorMessagesVendorError] = useState("");
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [filterloading, setFilterLoading] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerOffset, setCustomerOffset] = useState(0);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [productSearch, setProductSearch] = React.useState('');
  const [productOffset, setProductOffset] = React.useState(0);
  const [hasMoreProducts, setHasMoreProducts] = React.useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState(false);
  const [searchClass, setSearchClass] = useState('');
  const [classOffset, setClassOffset] = useState(0);
  const [hasMoreClasses, setHasMoreClasses] = useState(true);
  const [itemTotalIssue, setItemTotalIssues] = useState(0);
  const [documentTotalIssue, setDocumentTotalIssues] = useState(0);
  const [filterClassLoading, setFilterClassLoading] = useState(false);
  const [storeItemId , setStoreItemId] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  var ref = useRef(null);

  const mentionsInputStyle = {
    control: {
      backgroundColor: 'transparent',
      fontSize: 16,
      border: "2px solid #ccc", // Example: changing border color
      "::placeholder": {
        color: darkMode ? "#888 !important" : "#aaa", // Adjust placeholder color here
      },
    },

    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 70,
        backgroundColor: "transparent", // Example: changing background color
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        border: "1px solid silver",
        color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
        "&::placeholder": {
          color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
        },
      },
    },

    placeholder: {
      color: 'gray', // Change placeholder color here
    },
  
    "&singleLine": {
      display: "inline-block",
      width: 180,
  
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
        color: darkMode ? "#ffffff"  :"#333", // Example: changing text color
        // Add the placeholder color here
        "&::placeholder": {
          color: darkMode ? "#ffffff !important" : "#999999", // Custom placeholder color
          opacity: 1, // Ensure placeholder is fully opaque
        },
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 16,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "white",
        },
      },
    },
  };
  
  const [{ isError: isGetUserError, isLoading: isGetUserLoading, response: GetUserResponse }, GetUsersetAPICall] = useAPICall(GetuserListAll, { onLoad: false });
  const [{ isLoading: isInvoiceDetailsLoading, isError: isInvoiceDetailsError, response: InvoiceDetailsResponse}, InvoiceDetailsUserAPICall] = useAPICall(GetuserClientList, { onLoad: false });
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, { onLoad: false });
  const [{ isError: isEditeItemError, isLoading: isEditeItemLoading, response: EditeItemResponse }, setEditItemAPICall] = useAPICall(EditItem, { onLoad: false });
  const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
  const [{ isLoading: isAuditLogLoading, isError: isAuditLogError, response: AuditLogResponse }, AuditLogListAPICall] = useAPICall(GetAuditLogList, { onLoad: false });
  const [{ isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse },CommentsListAPICall,] = useAPICall(GetCommentsList, { onLoad: false });
  const [{isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse }, DeleteItemListAPICall ] = useAPICall(DeleteItem, { onLoad: false });
  const [{isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse },GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
  const [{isLoading: isDeleteInvoiceLoading,isError: isDeleteInvoiceError,response: DeleteInvoiceResponse}, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
  const [{isLoading: isCreateComentsLoading,isError: isCreateComentsError,response: CreateComentsResponse},CreateComentsListAPICall] = useAPICall(CreateComments, { onLoad: false });
  const [{isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse },MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
  const [{isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },CategoryListAPICall] = useAPICall(GetCategoryAllList, { onLoad: false });
  const [{isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },ClassesListAPICall] = useAPICall(GetClassesAllList, { onLoad: false });
  const [{isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },CustomerListAPICall] = useAPICall(GetCustomerAllList, { onLoad: false });
  const [{isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse },VendorListAPICall] = useAPICall(GetVendorForList, { onLoad: false });
  const [{isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse },IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
  const [{isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },ProductsListAPICall] = useAPICall(GetProductsAllList, { onLoad: false });
  const [{response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails,{ onLoad: false });
  const [{response: reportListResponse }, getReportAPICall] = useAPICall(GetReportList, {onLoad: false});
  const [showClasses, SetShowClasses] = useState(true);
  const action = {
    COMPLETED: "Completed",
    Qbotooltip: "QBO",
    INPROGRESS: "In Progress",
    PENDING: "Pendding",
    ERROR: "Error",
    Movetooltip: "Move",
    showtooltip: "View",
    edittooltip: "Edit",
    deletetooltip: "Delete",
  };
  const [{isError: isOnlyUpdateError, isLoading: isOnlyUpdateLoading, response: OnlyUpdateresponse }, setOnlyUpdateAPICall] = useAPICall(UpdateInvoiceDetails, { onLoad: false });
  const [{isLoading: isGetsummoryListLoading, isError: isGetsummoryListError, response: GetsummoryListResponse }, GetsummoryListListAPICall] = useAPICall(GetsummoryList, { onLoad: false });
  const [{isLoading: isDocumentIssueLoading, isError: isDocumentIssueError, response: DocumentIssueResponse },DocumentIssueListAPICall,] = useAPICall(GetDocumentIssueList, { onLoad: false });
  const [{isLoading: isItemIssueLoading, isError: isItemIssueError, response: ItemIssueResponse },ItemIssueListAPICall,] = useAPICall(GetItemsIssueList, { onLoad: false });
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {
    if (isDocumentIssueLoading) {
      return () => null;
    }
    if (isDocumentIssueError) {
        setLoading(false);
      return () => null;
    }
    if (DocumentIssueResponse && DocumentIssueResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoiceId}` }],
      });
      setDocumentTotalIssues(DocumentIssueResponse?.data?.data?.totalRecords);
      if (DocumentIssueResponse?.data?.data?.totalRecords == 0) {
        setNoVendorIssue(false);
        setPriceMismatch(false);
        setDuplicateIssue(false);
        setTotalMismatch(false);
        setOnlyTotalMismatch(false);
        setInvoiceDateMismatch(false);
      } else if (editable == false) {
        const found1 = DocumentIssueResponse?.data?.data?.records?.find((obj) => {
          return obj.issueType === "DUPLICATE";
        });

        const found2 = DocumentIssueResponse?.data?.data?.records?.find((obj) => {
          return obj.issueType === "BADDOCNO";
        });
        if (found1) {
          setDuplicateIssue(true);
          setNoVendorIssueMsg(`${found1?.message}`);
          setDuplicateMsg(`${found1?.message}`);
        } else if (found2) {
          setDuplicateIssue(true);
          setNoVendorIssueMsg(`${found2?.message}`);
          setDuplicateMsg(`${found2?.message}`);
        } else {
          setDuplicateIssue(false);
        }
        DocumentIssueResponse?.data?.data?.records?.map((prop, key) => { prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`): setNoVendorIssue(false);
          prop?.issueType === "NOSUBTOTAL" ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`): setPriceMismatch(false);
          prop?.issueType === "TOTALMISMATCH" ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`) : setTotalMismatch(false);
          prop?.issueType === "PRICEMISMATCH"? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
          prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
          prop?.issueType === "NOINVOICE_DATE" ? setInvoiceDateMismatch(true) : setInvoiceDateMismatch(false);
        });
      }
    }
  }, [DocumentIssueResponse]);

  useEffect(() => {
    if (isItemIssueLoading) {
      return () => null;
    }
    if (isItemIssueError) {
      setLoading(false);
      setDisButtonOnWork(false);
      setDisAddButtonOnWork(false);
      return () => null;
    }
    if (ItemIssueResponse && ItemIssueResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoiceId}` }],
      });
      setTimeout(() => {
        setDisButtonOnWork(false);
        setDisAddButtonOnWork(false);
      }, 2000);
      setItemTotalIssues(ItemIssueResponse?.data?.data?.totalRecords);
      if (ItemIssueResponse?.data?.data?.totalRecords == 0) {
        setNoProduct(false);
        setNoCategory(false);
      } else if (editable == false) {
          ItemIssueResponse?.data?.data?.records?.map((prop, key) => { prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`): setNoVendorIssue(false);
          prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : setNoProduct(false);
          prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : setNoCategory(false);
        });
      }
    }
  }, [ItemIssueResponse]);

  useEffect(() => {
    if (isGetsummoryListLoading) {
      return () => null;
    }
    if (isGetsummoryListError) {
      setLoading(false);
      return () => null;
    }
    if (GetsummoryListResponse && GetsummoryListResponse?.data?.isSuccess === true && GetsummoryListResponse?.data?.data?.summary) {
      const data = GetsummoryListResponse?.data?.data?.summary.map((prop, key) => {
        function generateUniqueNumberID() {
          const timestamp = Date.now(); // Current timestamp in milliseconds
          const randomNum = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
          return `${timestamp}${randomNum}`;
      }
      
        const uniqueID = generateUniqueNumberID();
        return {
          id: uniqueID,
          name: prop?.name ? prop?.name : "---",
          total: prop?.total ? prop?.total : "---",
        };
      });
        setFirstTime(false);
        setSummeryList(data);
      setTimeout(() => {
        setChenging(true);
        setLoading(false);
        setLoadingCircul(false);
      }, 2000);
    }
  }, [GetsummoryListResponse]);

  useEffect(() => {
    if (isOnlyUpdateLoading) {
      return () => null;
    }
    if (isOnlyUpdateError) {
      setErrorMessage(OnlyUpdateresponse?.data?.message);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
      setBtnDisable(false);
      setLoadingCircul(false);
      return () => null;
    }
    
    if (OnlyUpdateresponse && OnlyUpdateresponse.data.isSuccess === true) {
      // setShowSnackbar(true);
      eventBus.$emit("refreshApiInvoiceList", true);
      setBtnDisable(false);
      ItemIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      DocumentIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      SetEditable(false);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [OnlyUpdateresponse]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw', // Adjust the max width as per your needs
      overflow: 'hidden',
      transition: 'all 0.3s',
      '&:focus': {
          outline: 'none',
      },
  },
  dialogTitle: {
      cursor: 'move',
  },
  tabs: {
    "& .MuiTab-root": {
      backgroundColor: "#202940e3 !important",
      color: "#ffffff !important",
      borderRadius: 4,
      margin: '2px',
      padding:'6px',
      "&:hover": {
        backgroundColor: "#38405585 !important", // Change label color to blue on hover
    },
    },
    "& .MuiTabs-flexContainer": {
      backgroundColor: darkMode ?  "#1a2035 !important" : '#ffffff',
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#ffffff !important",
      backgroundColor: "#006cbe !important" ,
      margin: '2px',
      padding:'6px',
      borderRadius: 4,
      "&:hover": {
        backgroundColor: "#006cbe !important" , // Change label color to blue on hover
    },
    },
  },
    root: {
      "& .css-15e8ec1-MuiInputBase-input-MuiInput-input.Mui-disabled": {
        WebkitTextFillColor: darkMode ? "#ffffff" : "#000000",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
      },
      "& .MuiInput-underline:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
      },
      "& .MuiInput-underline:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)", // Adjust opacity here
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    option: {
      "&:first-child": {
        color: "#0000ff",
      },
      "&:hover:first-child": {
        color: "#0000ff",
        backgroundColor: "none !important",
      },
    },
    InputRoot: {
      "&::-webkit-calendar-picker-indicator": {
        display: "none !important",
      },
    },
  }));

  const classes = useStyles();
  const [{ isError: isEditItemError, isLoading: isEditItemLoading, response: EditItemResponse },EditItemAPICall] = useAPICall(EditBulkItem, { onLoad: false });

  useEffect(() => {
    if (reportListResponse && reportListResponse.data.isSuccess === true) {
      const LogReportdata = reportListResponse?.data?.data?.records.map((prop, key) => {
        setReportStatus(prop?.status);
        return (
          <>
            {prop.threads.map((thread, index) => (
              <Paper style={{ padding: "20px 20px", marginBottom: "10px" }} key={`${key}-${index}`}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
                  </Grid>
                  <Grid justifyContent="left" item xs zeroMinWidth>
                    <div style={{ display: "flex" }}>
                      <h4
                        style={{
                          margin: 0,
                          textAlign: "left",
                          fontFamily: "Arial",
                          fontSize: "0.775rem",
                          fontWeight: "bold",
                          lineHeight: 1.5,
                          textTransform: "uppercase",
                          letterSpacing: "0.02857em",
                          opacity: 1,
                          textTransform: "none",
                          verticalAlign: "unset",
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >{`${thread?.commentBy?.name}`}</h4>
                      <MDTypography sx={{ mt: 0.5, ml: 1 }} variant="caption" color="text">
                        {inLocalTime(thread.createdAt)}
                      </MDTypography>
                    </div>
                    <p
                      style={{
                        margin: 0,
                        fontFamily: "Arial",
                        fontSize: "0.775rem",
                        fontWeight: 300,
                        lineHeight: 1.5,
                        textTransform: "uppercase",
                        letterSpacing: "0.02857em",
                        opacity: 1,
                        textTransform: "none",
                        verticalAlign: "unset",
                        textAlign: "left",
                      }}
                    >
                      {thread.comment}
                    </p>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </>
        );
      });
      setReportList(LogReportdata);
      setOpenIssueReport(true);
      setTimeout(() => {
        setLoading(false);
        setLoadingCircul(false);
      }, 1000);
    }
  }, [reportListResponse]);

  useEffect(() => {
    if (isEditItemLoading) {
      return () => null;
    }
    if (isEditItemError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (EditItemResponse && EditItemResponse.data.isSuccess === true) {
      setLoading(true);
      setSelectedRows([]);
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
      ItemIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      DocumentIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
    }
  }, [EditItemResponse]);

  useEffect(async () => {
    setShowLoaderFirInvoice(true);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoiceId}` }],
    });
    await GetUsersetAPICall({
      payload: [{ keyword: `` }],
    });
  }, []);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }
    if (isGetUserError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (GetUserResponse && GetUserResponse.data.isSuccess === true) {
      const data = GetUserResponse?.data?.data?.records.map((prop, key) => {
        return {
          id: prop?._id,
          display: prop?.fname,
        };
      });
      setUsers(data);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [GetUserResponse]);

  const [{ isLoading: isAddVendorLoading, isError: isAddVendorError, response: AddVendorResponse }, AddVendorListAPICall] = useAPICall(AddVendors, { onLoad: false });

  useEffect(() => {
    if (isAddVendorLoading) {
      return () => null;
    }
    if (isAddVendorError) {
      setErrorMessage(AddVendorResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }

    if (AddVendorResponse && AddVendorResponse.data.isSuccess === true) {
      setLoading(false);
      setOpenList(false);
      setAddNewVendorId(AddVendorResponse?.data?.data?.vendor?._id);
      // setVendorname2()
      setOpenEditVendor(false);
      setAddNewVendor(true);
      if (AddVendorResponse?.data?.data?.vendor?._id) {
        setLoading(true);
        VendorListAPICall({
          payload: [{ businessId: `${businessId}`, IsSynced: true }],
        });
        setOnlyUpdateAPICall({
          payload: [
            {
              vendorId: `${
                AddVendorResponse?.data?.data?.vendor?._id
                  ? AddVendorResponse?.data?.data?.vendor?._id
                  : addNewVendorId
              }`,
              businessId: `${businessId}`,
              documnetId: `${invoice_id}`,
            },
          ],
        });
      }
    }
  }, [AddVendorResponse]);

  const [{ isLoading: isReportIssueLoading, isError: isReportIssueError, response: ReportIssueResponse },ReportIssueAPICall] = useAPICall(ReportIssue, { onLoad: false });

  useEffect(() => {
    if (isReportIssueLoading) {
      return () => null;
    }
    if (isReportIssueError) {
      setLoading(false);
      setReportSubmit(false);
      return () => null;
    }

    if (ReportIssueResponse && ReportIssueResponse.data.isSuccess === true) {
      setShowReportSnackbar(true);
      setTimeout(() => {
        setShowReportSnackbar(false);
      }, 4000);
      setOpenIssueReport(false);
      setReportComment("");
      setReportSubmit(false);
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  }, [ReportIssueResponse]);

  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  var resource = "";
  var actions = "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const AddNone = { name: "None" };
  const AddobjClass = { name: "+ Add Class", classId: "" };
  const NewclassesList = [AddobjClass, AddNone, ...classesList];
  const AddobjCustomer = { name: "+ Add Customer", customerId: "" };
  const NewcustomerList = [AddobjCustomer, AddNone , ...customerList];
  const Addvendor = { name: "+ Add Vendor", vendorId: "" };
  const NewVendorList = [Addvendor, ...vendorList];
  const AddProduct = { name: "+ Add Product" };
  const NewProductList = [AddProduct, ...productsList];
  const AddCategory = { name: "+ Add Category" };
  const NewCategoryList = [AddCategory, ...categoryListData];

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinations = new Set();

  // Filter out duplicate combinations
  const uniqueCategoryList = NewCategoryList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinations.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinations.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsProduct = new Set();

  // Filter out duplicate combinations
  const uniqueProductsList = NewProductList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsProduct.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsProduct.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsClass = new Set();

  // Filter out duplicate combinations
  const uniqueClasssList = NewclassesList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsClass.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsClass.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsCustomer = new Set();

  // Filter out duplicate combinations
  const uniqueCustomersList = NewcustomerList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsCustomer.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsCustomer.add(combination);
      return true; // Unique combination, keep it
    }
  });

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ");
  }
  useEffect(async() => {
    await ItemIssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
    await DocumentIssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
    await IssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
  }, [response]);

  useEffect(async() => {
    setLoading(true);
    await GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    await VendorListAPICall({
      payload: [{ businessId: `${businessId}`, IsSynced: true }],
    });
    await InvoiceDetailsUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await GetsummoryListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` , businessId: `${businessId}`}],
    });
    await ItemIssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
    await DocumentIssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
    await IssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${businessId}` }],
    });
  }, []);


  useEffect(()=>{
    setTotalIssues(itemTotalIssue + documentTotalIssue)
  },[documentTotalIssue ,itemTotalIssue])

  useEffect(() => {
    if (isIssueLoading) {
      return () => null;
    }
    if (isIssueError) {
      setLoading(false);
      return () => null;
    }
    if (IssueResponse && IssueResponse.data.isSuccess === true) {
    //   GetItemListListAPICall({
    //     payload: [{ InvoiceId: `${invoiceId}` }],
    //   });
    //   setTotalIssues(IssueResponse?.data?.data?.totalRecords);
    //   if (IssueResponse?.data?.data?.totalRecords == 0) {
    //     setNoVendorIssue(false);
    //     setPriceMismatch(false);
    //     setDuplicateIssue(false);
    //     setTotalMismatch(false);
    //     setOnlyTotalMismatch(false);
    //     setInvoiceDateMismatch(false);
    //     setNoProduct(false);
    //     setNoCategory(false);
    //   } else if (editable == false) {
    //     const found1 = IssueResponse?.data?.data?.records?.find((obj) => {
    //       return obj.issueType === "DUPLICATE";
    //     });

    //     const found2 = IssueResponse?.data?.data?.records?.find((obj) => {
    //       return obj.issueType === "BADDOCNO";
    //     });
    //     if (found1) {
    //       setDuplicateIssue(true);
    //       setNoVendorIssueMsg(`${found1?.message}`);
    //       setDuplicateMsg(`${found1?.message}`);
    //     } else if (found2) {
    //       setDuplicateIssue(true);
    //       setNoVendorIssueMsg(`${found2?.message}`);
    //       setDuplicateMsg(`${found2?.message}`);
    //     } else {
    //       setDuplicateIssue(false);
    //     }
    //     IssueResponse?.data?.data?.records?.map((prop, key) => {
    //       // prop?.issueType === 'DUPLICATE' && setDuplicateIssue(true) || setNoVendorIssueMsg(`${prop?.message}`);
    //       prop?.issueType === "NOVENDOR"
    //         ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`)
    //         : setNoVendorIssue(false);
    //       prop?.issueType === "NOSUBTOTAL"
    //         ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`)
    //         : setPriceMismatch(false);
    //       prop?.issueType === "TOTALMISMATCH"
    //         ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`)
    //         : setTotalMismatch(false);
    //       prop?.issueType === "PRICEMISMATCH"
    //         ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`)
    //         : setPriceMismatch(false);
    //       prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
    //       prop?.issueType === "NOINVOICE_DATE"
    //         ? setInvoiceDateMismatch(true)
    //         : setInvoiceDateMismatch(false);
    //       prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : setNoProduct(false);
    //       prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : setNoCategory(false);
    //     });
    //   }
    }
  }, [IssueResponse]);

  useEffect(() => {
    IssueResponse?.data?.data?.records.map((prop, key) => {
      prop?.issueType === "NOPRODUCT" ? setNoProduct(true) : "";
      prop?.issueType === "NOPRICE" ? setNoPrice(true) : "";
      prop?.issueType === "ITEMPRICEMISMATCH" ? setNoPrice(true) : "";
      prop?.issueType === "NOUNITPRICE" ? setNoUnitProduct(true) : "";
      prop?.issueType === "NOQUANTITY" ? setNoQuantity(true) : "";
      prop?.issueType === "NOITEMS" ? setNoItem(true) : "";
      prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : "";
    });
    const found1 = IssueResponse?.data?.data?.records.find((obj) => {
      return obj._id === selectedIndex;
    });
    if (onIssueClick) {
      if (found1) {
        found1?.elements.forEach((element) => {
          switch (element) {
            case "vendorId":
              setNoVendorIssue(true);
              break;
            case "transactionDate":
              setInvoiceDateMismatch(true);
              break;
            case "subTotal":
              setPriceMismatch(true);
              break;
            case "accountId":
              setNoCategory(true);
              break;
            case "total":
              setTotalMismatch(true);
              break;
            case "productId":
              setNoProduct(true);
              break;
            default:
              break;
          }
        });
        setShow(true);
      } else {
        setNoVendorIssue(false);
        setPriceMismatch(false);
        setTotalMismatch(false);
        setInvoiceDateMismatch(false);
        setOnlyTotalMismatch(false);
      }
    } else {
      let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType === "DOCUMENT");
      results?.map((prop, key) => {
        prop?.issueType === "NOVENDOR" ? setNoVendorIssue(true) : "";
        prop?.issueType === "NOSUBTOTAL" ? setPriceMismatch(true) : "";
        prop?.issueType === "TOTALMISMATCH" ? setTotalMismatch(true) : "";
        prop?.issueType === "PRICEMISMATCH" ? setPriceMismatch(true) : "";
        prop?.issueType === "NOTOTAL" ? setOnlyTotalMismatch(true) : "";
        prop?.issueType === "NOINVOICE_DATE" ? setInvoiceDateMismatch(true) : "";
        prop?.issueType == "NOPRODUCT" ? setNoProduct(true) : "";
        prop?.issueType === "NOCATEGORY" ? setNoCategory(true) : "";
      });
      setShow(true);
      // }
    }
  }, [IssueResponse, editable, itemList]);

  function checkNextOCRCompleted(index, list) {
    // Check if index is within the range of the list
    if (index < 0 || index >= list.length - 1) {
      onClose1();
      return false;
    }
  
    // Loop through the subsequent items starting from the given index
    for (let i = index + 1; i < list.length; i++) {
      // Check if the OCR status of the current item is "COMPLETED"
      if (list[i].ocrStatus === "COMPLETED") {
        return {
          id: list[i]._id,         // Return the _id
          clientId: list[i].client.clientId // Return the clientId
        };
      }
    }
  
    return false; // None of the subsequent items have OCR status "COMPLETED"
  }

  useEffect(() => {
    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      SetShowClasses(clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine);
    }
  }, [clientDetailsDataResponse]);

  useEffect(() => {
    if (DetailsResponse && GetItemListResponse) {
      const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
        return {
          item: prop?.description ? prop?.description : "---",
          qty: prop?.quantity ? prop?.quantity : "---",
          unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
          product: prop?.product?.name ? prop?.product?.name : "---",
          productCheck: prop?.product?.name ? prop?.product?.name : "---",
          price: prop?.unitPrice ? prop?.unitPrice : "0",
          total: prop?.amount ? prop?.amount : "---",
          customer: prop?.customer?.name ? prop?.customer?.name : "---",
          category: prop?.category?.name ? prop?.category?.name : "---",
          class: prop?.class?.name ? prop?.class?.name : "---",
          id: prop._id,
          sku: prop.sku,
          itemType: prop.itemType,
          CheckAskClient: prop?.askClient,
        };
      });
      if (DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail") {
        const foundProducts = data?.find((obj) => {
          return obj.product == "---";
        });
        if (foundProducts === undefined) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      } else if (
        DetailsResponse?.data?.data?.document?.itemType === "AccountBasedExpenseLineDetail"
      ) {
        const foundCategory = data?.find((obj) => {
          return obj.category == "---";
        });
        if (foundCategory === undefined) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [GetItemListResponse, DetailsResponse]);

  useEffect(() => {
    if (isInvoiceDetailsLoading) {
      return () => null;
    }
    if (isInvoiceDetailsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (InvoiceDetailsResponse && InvoiceDetailsResponse.data.isSuccess === true) {
      const found2 = InvoiceDetailsResponse?.data?.data?.records.find((obj) => {
        return obj._id === businessId;
      });
      setOwnerAssigned(found2?.ownerAssigned);
      setOpenAddOwner(false);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [InvoiceDetailsResponse]);

  useEffect(() => {
    if (isMoveMenuLoading) {
      return () => null;
    }
    if (isMoveMenuError) {
      setDisableMoveButton(false);
      setLoading(false);
      setalertMoveInvoice(false);
      return () => null;
    }
    if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
      setLoading(true);
      setSelectedRows([]);
      setalertMoveInvoice(false);
      setDisableMoveButton(false);
      const index = storeResponce?.data?.data?.records.findIndex((x) => x._id === invoiceId);
      setCurrentIndex(index);
      const CheckNextComplete = checkNextOCRCompleted(index, storeResponce?.data?.data?.records);
      if (CheckNextComplete) {
        storeResponce?.data?.data?.totalRecords - 1 === 0 ? onClose() : onClose1();
        setInvoice_Id(CheckNextComplete.id);
        setInvoiceId(CheckNextComplete.id);
        openQboSyncAskClient ? onClose() : onClose1();
        setLoading(true);
        GetItemListListAPICall({
          payload: [{ InvoiceId: `${CheckNextComplete.id}` }],
        });
        DetailsUserAPICall({
          payload: [{ InvoiceId: `${CheckNextComplete.id}`, businessId: `${CheckNextComplete.clientId}` }],
        });
        VendorListAPICall({
          payload: [{ businessId: `${CheckNextComplete.clientId}`, IsSynced: true }],
        });
        InvoiceDetailsUserAPICall({
          payload: [{ businessId: `${CheckNextComplete.clientId}` }],
        });
      } else {
        onClose();
        onClose1();
        storeResponce?.data?.data?.totalRecords - 1 === 0 ? onClose() : onClose1();
        openQboSyncAskClient ? onClose() : onClose1();
      }
    }
  }, [MoveMenuResponse]);

  useEffect(() => {
    if (selectedRows.length !== 0) {
      setCheckFound(true);
    } else {
      setCheckFound(false);
    }
  }, [selectedRows]);

  const successInoiceMove = async () => {
    setOpenQboSyncAskClient(false);
    setLoading(true);
    setDisableMoveButton(true);
    setAnchorEl(null);
    EditItemAPICall({
      payload: [
        {
          documentId: invoiceId,
          itemIds: selectedRows,
          updateObj: {
            askClient: true,
          },
        },
      ],
    });
    MoveMenuListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: [invoiceId],
            documentStatus: openQboSyncAskClient ? moveTowhere : tabsMove,
          },
          businessId: `${businessId}`,
        },
      ],
    });
  };

  const successAssignNewClient = (move) => {
    setOpenAddOwner(true);
    setAssignNewClient(false);
  };

  const onReportApi = (move) => {
    if (reportComment.length > 0) {
      setReportSubmit(true);
      setShowError(false);
      setLoading(true);
      setAnchorEl(null);
      ReportIssueAPICall({
        payload: [{ comment: reportComment, documentId: invoiceIds }],
      });
    } else {
      setShowError(true);
    }
  };

  const onChangeReport = (values) => {
    setShowError(false);
    setReportComment(values?.target?.value);
  };

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        setUnitPrice(prop?.unitPrice);
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
          sku: prop.sku,
          unitOfMeasure: prop.unitOfMeasure,
        };
      });
      // setProductsList(data);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [ProductsResponse]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          customerId: prop?._id,
          productId: prop?._id,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
          sku: prop?.sku,
          unitOfMeasure: prop?.unitOfMeasure,
        };
      });
      // setCustomerList(data);
      setLoadingCircul(false);
      setLoading(false);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      // setChenging(true);
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      // setCategoryListData(data);
      setLoading(false);
      setLoadingCircul(false);
      // setChenging(true);
    }
  }, [CategoryResponse]);

  function SelectCategoryEditInputCell(props) {
    const { id, value, field } = props;
    setStoreItemId(id);
    const apiRef = useGridApiContext();
    const handleCategoryChanges = async (event) => {
      setChenging(false);
      setSearch(''); // Clear previous search
      setOffset(0); // Reset offset
      setHasMore(true); // Enable fetching more data
      fetchCategories('', 0); // Trigger fresh API call on open
      if (event.target.textContent == "+ Add Category") {
        setOpenAddNewCategory(true);
      } else {
        setLoadingCircul(true);
        setDisAddButtonOnWork(true);
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = categoryListData.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (results) {
          // setChenging(false);
          setProductId(results.productId);
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: found?.categoryId,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      }
    };

    const fetchCategories = async (newSearch = '', newOffset = 0) => {  
      setFilterLoading(true);
      try {
        const response = await GetCategoryListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearch}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          categoryId: prop._id,
        }));
  
        if (newOffset === 0) {
          setCategoryListData(data);
        } else {
          setCategoryListData((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMore(false); // No more data to load
        }
  
        setOffset(newOffset);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
      setFilterLoading(false);
    };
    return (
      <>
      <Autocomplete
        id="category-autocomplete"
        fullWidth
        autoSelect
        open // Always keep the dropdown open
        value={null}
        options={
          finalSelect == "Ask Client" ||
          finalSelect == "Classify" ||
          documentStatus == "AskClient" ||
          documentStatus == "Classify"
            ? uniqueCategoryList
              ? uniqueCategoryList
              : NewCategoryList
            : categoryListData
        }
        getOptionLabel={(option) => option.name}
        disableClearable
        onFocus={() => {
          // Always trigger data fetching on focus
          setSearch(''); // Clear any existing search term
          setOffset(0); // Reset the offset
          setHasMore(true); // Reset hasMore for loading more items
          fetchCategories('', 0); // Fetch initial data when input is focused
        }}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
          setOffset(0); // Reset the offset
          setHasMore(true); // Reset hasMore for loading more items
          fetchCategories(newInputValue, 0); // Fetch data based on the search term
        }}
        noOptionsText={
          <div
          style={{
            cursor: "pointer",
          }}
            onClick={handleCategoryChanges}
          >
            + Add Category
          </div>
        }
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            const tolerance = 5; 
            const bottom =
              Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;        
            if (bottom && hasMore) {
              // Increase the offset
              setOffset((prevOffset) => {
                const newOffset = prevOffset + 50; // Compute new offset
                fetchCategories(search, newOffset); // Fetch next set of data using new offset
                return newOffset; // Return new offset to update state
              });
            }
          },
          sx: {
            maxHeight: '200px', // Set a max-height to enable scrolling
            overflowY: 'auto',  // Enable vertical scrolling
            fontSize: '0.8125rem',
            '@media (max-width: 1440px)': {
              fontSize: '0.775rem',
            },
          },
        }}
        
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {filterloading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={handleCategoryChanges}
      />
      </>
    );
  }

  function SelectClassEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleClassChanges = async (event) => {
      setChenging(false);
      setSearchClass(''); // Clear previous search
      setClassOffset(0); // Reset offset
      setHasMoreClasses(true); // Enable fetching more data
      fetchClasses('', 0); // Trigger fresh API call on open
      if (event.target.textContent == "+ Add Class") {
        setOpenAddNewClass(true);
      } else {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = classesList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (event.target.textContent == "None") {
          setEditItemAPICall({
            payload: [
              {
                itemType: "AccountBasedExpenseLineDetail",
                unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                unitOfMeasure: results?.unit ? results?.unit : null,
                quantity: Number(Qty ? Qty : 1),
                discount: 0,
                description: results?.item,
                InvoiceId: invoiceId ? invoiceId : documentsId,
                classId: "None",
                customerId: results?.customerId ? results?.customerId : null,
                categoryId: results?.categoryId ? results?.categoryId : null,
                askClient: results.askClients,
                ItemId: results.id,
              },
            ],
          });
        } else if (results) {
          // setChenging(false);
          setProductId(results.productId);
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: found?.classId,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: found?.classId,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      }
    };

    const fetchClasses = async (newSearchClass = '', newClassOffset = 0) => {
      setFilterClassLoading(true);
      try {
        const response = await GetClassListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearchClass}`,
              offset: newClassOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          classId: prop._id,
        }));
  
        if (newClassOffset === 0) {
          setClassesList(data);
        } else {
          setClassesList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreClasses(false); // No more data to load
        }
  
        setClassOffset(newClassOffset);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
      setFilterClassLoading(false);
    };

    return (
      <Autocomplete
      id="class-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={
        finalSelect == "Ask Client" ||
        finalSelect == "Classify" ||
        documentStatus == "AskClient" ||
        documentStatus == "Classify"
          ? uniqueClasssList
            ? uniqueClasssList
            : NewclassesList
          : classesList
      }
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        setSearchClass(''); // Clear any existing search term
        setClassOffset(0); // Reset the offset
        setHasMoreClasses(true); // Reset hasMore for loading more items
        fetchClasses('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setSearchClass(newInputValue);
        setClassOffset(0); // Reset the offset
        setHasMoreClasses(true); // Reset hasMore for loading more items
        fetchClasses(newInputValue, 0); // Fetch data based on the search term
      }}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom =
            Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreClasses) {
            setClassOffset((prevOffset) => {
              const newClassOffset = prevOffset + 50; // Compute new offset
              fetchClasses(searchClass, newClassOffset); // Fetch next set of data using new offset
              return newClassOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {filterClassLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleClassChanges}
    />
    );
  }

  function SelectCustomerEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const fetchCustomers = async (searchTerm = '', newOffset = 0) => {
      setIsLoadingCustomers(true);
      try {
        const response = await GetCustomerListForParent({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${searchTerm}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
        const data = response.data?.data?.records.map((prop) => ({
          name: prop?.name,
          customerId: prop?._id,
          productId: prop?._id,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
          sku: prop?.sku,
          unitOfMeasure: prop?.unitOfMeasure,
        }));

        if (newOffset === 0) {
          setCustomerList(data);
        } else {
          setCustomerList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreCustomers(false); // No more data to load
        }
  
        setCustomerOffset(newOffset);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
      setIsLoadingCustomers(false);
    };

    const handleCustomerChanges = async (event) => {
      setChenging(false);
      setCustomerSearch(''); // Clear previous search
      setCustomerOffset(0); // Reset offset
      setHasMoreCustomers(true); // Enable fetching more data
      fetchCustomers('', 0); // Trigger fresh API call on open
      if (event.target.textContent == "+ Add Customer") {
        setOpenAddNewCustomer(true);
      } else {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop.unitPrice,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = customerList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (event.target.textContent == "None") {
          setEditItemAPICall({
            payload: [
              {
                itemType: "AccountBasedExpenseLineDetail",
                unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                unitOfMeasure: results?.unit ? results?.unit : null,
                quantity: Number(Qty ? Qty : 1),
                discount: 0,
                description: results?.item,
                InvoiceId: invoiceId ? invoiceId : documentsId,
                classId: results?.classId ? results?.classId : null,
                customerId: "None",
                categoryId: results?.categoryId ? results?.categoryId : null,
                askClient: results.askClients,
                ItemId: results.id,
              },
            ],
          });
        } else if (results) {
          // setChenging(false);
          setProductId(results.productId);
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    productId: results?.productId ? results?.productId : null,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: found?.customerId,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: found?.customerId,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      }
    };

    return (
      <Autocomplete
      id="customer-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={
        finalSelect == "Ask Client" ||
        finalSelect == "Classify" ||
        documentStatus == "AskClient" ||
        documentStatus == "Classify"
          ? uniqueCustomersList
            ? uniqueCustomersList
            : NewcustomerList
          : customerList
      }
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        // Always trigger data fetching on focus
        setCustomerSearch(''); // Clear any existing search term
        setCustomerOffset(0); // Reset the offset
        setHasMoreCustomers(true); // Reset hasMoreCustomers for loading more items
        fetchCustomers('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setCustomerSearch(newInputValue);
        setCustomerOffset(0); // Reset the offset
        setHasMoreCustomers(true); // Reset hasMoreCustomers for loading more items
        fetchCustomers(newInputValue, 0); // Fetch data based on the search term
      }}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreCustomers) {
            setCustomerOffset((prevOffset) => {
              const newOffset = prevOffset + 50; // Compute new offset
              fetchCustomers(customerSearch, newOffset); // Fetch next set of data using new offset
              return newOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleCustomerChanges}
      renderOption={(props, option) => (
        <li {...props} title={option.name}>
          {option.name}
        </li>
      )}
    />
    );
  }

  function getPriceProduct(params) {
    const finalPrice =
      params?.row?.itemType === "AccountBasedExpenseLineDetail"
        ? params?.row?.price
        : params?.row?.price;
    return `$${finalPrice}`;
  }

  function getTotalProduct(params) {
    return `$${params?.row?.total}`;
  }

  function getQuantity(params) {
    const finalQty =
      params?.row?.itemType === "AccountBasedExpenseLineDetail"
        ? params?.row?.qty
        : params?.row?.qty;
    return finalQty;
  }

  function SelectProductEditInputCell(props) {
    const { id, value, field } = props;
    setStoreItemId(id);
    const apiRef = useGridApiContext();

    const handleProductChanges = async (event) => {
      if (event.target.textContent == "+ Add Product") {
        setOpenAddOption(true);
      } else {
        setDisAddButtonOnWork(true);
        setChenging(false);
        setLoadingCircul(true);
        await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
        apiRef.current.stopCellEditMode({ id, field });
        const result = itemListResponse?.data?.data?.records.map((prop, key) => {
          {
            return {
              id: prop._id,
              item: prop.description,
              qty: prop.quantity,
              unit: prop.unitOfMeasure,
              price: prop.unitPrice,
              total: prop.total,
              sku: prop.sku,
              amount: prop?.amount,
              unitPrice: prop?.unitPrice,
              results: prop.results,
              askClients: prop.askClient,
              category: prop.category ? prop.category.name : "",
              categoryId: prop.category ? prop.category._id : "",
              class: prop.class ? prop.class.name : "",
              product: prop.product ? prop.product.name : "",
              productId: prop.product ? prop.product._id : "",
              classId: prop.class ? prop.class._id : "",
              customer: prop.customer ? prop.customer.name : "",
              customerId: prop.customer ? prop.customer._id : "",
            };
          }
        });
        const found = productsList.find((obj) => {
          return obj.name === event.target.textContent;
        });
        const results = result.find((item) => item.id === id);
        var Str = results?.amount;
        var newStr = Str ? Str.replace(/,/g, "") : 0;
        var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
        var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
        const Qty = results?.quantity ? results?.quantity : results?.qty;
        if (results) {
          // setChenging(false);
          setProductId(results.productId);
          toggleShow
            ? setEditItemAPICall({
                payload: [
                  {
                    itemType: "ItemBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    discount: 0,
                    sku: results?.sku,
                    description: results?.item,
                    unitOfMeasure: results?.unit ? results?.unit : results?.unit,
                    productId: found?.productId,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    quantity: Number(Qty ? Qty : 1),
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              })
            : setEditItemAPICall({
                payload: [
                  {
                    itemType: "AccountBasedExpenseLineDetail",
                    unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                    amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                    unitOfMeasure: results?.unit ? results?.unit : null,
                    quantity: Number(Qty ? Qty : 1),
                    discount: 0,
                    description: results?.item,
                    InvoiceId: invoiceId ? invoiceId : documentsId,
                    classId: results?.classId ? results?.classId : null,
                    customerId: results?.customerId ? results?.customerId : null,
                    categoryId: results?.categoryId ? results?.categoryId : null,
                    askClient: results.askClients,
                    ItemId: results.id,
                  },
                ],
              });
        }
      }
    };

    const fetchProducts = async (newSearch = '', newOffset = 0) => {
      setIsLoadingProducts(true);
      try {
        const response = await GetProductList({
          payload: [
            {
              businessId: `${businessId}`,
              keyword: `${newSearch}`,
              offset: newOffset,
              limit: 50, // Fetch 20 items at a time
            },
          ],
        });
  
        const data = response.data.data.records.map((prop) => ({
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
          sku: prop.sku,
          unitOfMeasure: prop.unitOfMeasure,
        }));
  
        if (newOffset === 0) {
          setProductsList(data);
        } else {
          setProductsList((prev) => [...prev, ...data]);
        }
  
        if (data.length < 50) {
          setHasMoreProducts(false); // No more data to load
        }
  
        setProductOffset(newOffset);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
      setIsLoadingProducts(false);
    };

    return (
      <Autocomplete
      id="product-autocomplete"
      fullWidth
      autoSelect
      open // Always keep the dropdown open
      value={null}
      options={
        finalSelect == "Ask Client" ||
        finalSelect == "Classify" ||
        documentStatus == "AskClient" ||
        documentStatus == "Classify"
          ? uniqueProductsList
            ? uniqueProductsList
            : NewProductList
          : productsList
      }
      getOptionLabel={(option) => option.name}
      disableClearable
      onFocus={() => {
        // Always trigger data fetching on focus
        setProductSearch(''); // Clear any existing search term
        setProductOffset(0); // Reset the offset
        setHasMoreProducts(true); // Reset hasMoreProducts for loading more items
        fetchProducts('', 0); // Fetch initial data when input is focused
      }}
      onInputChange={(event, newInputValue) => {
        setProductSearch(newInputValue);
        setProductOffset(0); // Reset the offset
        setHasMoreProducts(true); // Reset hasMoreProducts for loading more items
        fetchProducts(newInputValue, 0); // Fetch data based on the search term
      }}
      noOptionsText={
        <div
        style={{
          cursor: "pointer",
        }}
          onClick={handleProductChanges}
        >
          + Add Product
        </div>
      }
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          const tolerance = 5;

          const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

          if (bottom && hasMoreProducts) {
            setProductOffset((prevOffset) => {
              const newOffset = prevOffset + 50; // Compute new offset
              fetchProducts(productSearch, newOffset); // Fetch next set of data using new offset
              return newOffset; // Return new offset to update state
            });
          }
        },
        sx: {
          maxHeight: '200px', // Set a max-height to enable scrolling
          overflowY: 'auto',  // Enable vertical scrolling
          fontSize: '0.8125rem',
          '@media (max-width: 1440px)': {
            fontSize: '0.775rem',
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoadingProducts ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleProductChanges}
      renderOption={(props, option) => (
        <li {...props} title={option.name}>
          {option.name}
        </li>
      )}
    />
    );
  }

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      setLoading(false);
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      // setClassesList(data);
      setLoading(false);
    }
  }, [ClassesResponse]);

  useEffect(async () => {
    if (isEditeItemLoading) {
      return () => null;
    }
    if (isEditeItemError) {
      setLoading(false);
      setChenging(true);
      setLoadingCircul(false);
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoiceId}` }],
      });
      setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== matchId));
      if (EditeItemResponse?.data?.message) {
        setErrorMessage(EditeItemResponse.data.message);
      }
      setShowErrorMoveSnackbar(true);
      setTimeout(() => {
        setShowErrorMoveSnackbar(false);
      }, 4000);
      return () => null;
    }
    if (EditeItemResponse && EditeItemResponse.data.isSuccess === true) {
      eventBus.$emit("refreshListAfterUpdate", response);
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      ItemIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      DocumentIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      setTimeout(() => {
        GetItemListListAPICall({
          payload: [{ InvoiceId: `${invoice_id}` }],
        });
        DetailsUserAPICall({
          payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
        });
      }, 700);
    }
  }, [EditeItemResponse]);

  const handleOpenAddNewProduct = (data) => {
    setOpenAddNewProduct(true);
    setAddProductSelectType(data);
    setOpenAddOption(false);
  };

  useEffect(() => {
    if (isCreateComentsLoading) {
      return () => null;
    }
    if (isCreateComentsError) {
      setLoading(false);
      setBtnDisable1(false);
      return () => null;
    }
    if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
      // setLoading(false);
      setsubmitcomment(false);
      setBtnDisable1(false);
      setTextAreaVal("");
      CommentsListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  }, [CreateComentsResponse]);

  useEffect(() => {
    if (isDeleteInvoiceLoading) {
      return () => null;
    }
    if (isDeleteInvoiceError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
      eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
      setLoading(true);
    }
  }, [DeleteInvoiceResponse]);

  const toggleCheck = async (clientId) => {
    setMatchId(clientId);
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[clientId] = !prevState[clientId];
      return newState;
    });
    const finalListResponec = GetItemListResponse ? GetItemListResponse : itemListResponse;
    const result = finalListResponec?.data?.data?.records.map((prop, key) => {
      {
        return {
          id: prop?._id,
          item: prop?.description,
          qty: prop?.quantity,
          unit: prop?.unitOfMeasure,
          price: prop?.unitPrice,
          total: prop?.total,
          amount: prop?.amount,
          sku: prop?.sku,
          askClients: prop?.askClient,
          itemTypes: prop?.itemType,
          discounts: prop?.discount,
          category: prop?.category ? prop?.category.name : "",
          categoryId: prop?.category ? prop?.category._id : "",
          class: prop?.class ? prop?.class.name : "",
          product: prop?.product ? prop?.product.name : "",
          productId: prop?.product ? prop?.product._id : "",
          classId: prop?.class ? prop?.class._id : "",
          customer: prop?.customer ? prop?.customer.name : "",
          customerId: prop?.customer ? prop?.customer._id : "",
        };
      }
    });
    const results = result.find((item) => item.id === clientId);
    const found = InvoiceDetailsResponse?.data?.data?.records.find((obj) => {
      return obj._id === businessId;
    });
    setOwnerName(found?.name);
    if (found?.ownerAssigned === true) {
      setPushPopFun(true);
      setLoading(true);
      var Str = results?.amount;
      var newStr = Str ? Str.replace(/,/g, "") : 0;
      var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
      var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
      const Qty = results?.quantity ? results?.quantity : results?.qty;
      {
        results?.itemTypes === "ItemBasedExpenseLineDetail"
          ? setEditItemAPICall({
              payload: [
                {
                  itemType: "ItemBasedExpenseLineDetail",
                  unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                  amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                  discount: 0,
                  sku: results?.sku,
                  description: results?.item,
                  unitOfMeasure: results?.unit ? results?.unit : null,
                  productId: results?.productId ? results?.productId : null,
                  InvoiceId: invoiceId ? invoiceId : documentsId,
                  classId: results?.classId ? results?.classId : null,
                  customerId: results?.customerId ? results?.customerId : null,
                  quantity: Number(Qty ? Qty : 1),
                  askClient: results?.askClients === true ? false : true,
                  ItemId: clientId,
                },
              ],
            })
          : setEditItemAPICall({
              payload: [
                {
                  itemType: "AccountBasedExpenseLineDetail",
                  unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
                  amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
                  unitOfMeasure: results?.unit ? results?.unit : null,
                  quantity: Number(Qty ? Qty : 1),
                  discount: 0,
                  description: results?.item,
                  InvoiceId: invoiceId ? invoiceId : documentsId,
                  classId: results?.classId ? results?.classId : null,
                  customerId: results?.customerId ? results?.customerId : null,
                  categoryId: results?.categoryId ? results?.categoryId : null,
                  askClient: results?.askClients === true ? false : true,
                  ItemId: clientId,
                },
              ],
            });
      }
    } else if (found?.ownerAssigned === false) {
      setAssignNewClient(true);
      setTimeout(() => {
        GetItemListListAPICall({
          payload: [{ InvoiceId: `${invoice_id}` }],
        });
      }, 2000);
    }
  };

  useEffect(() => {
    if (isGetItemListLoading) {
      return () => null;
    }
    if (isGetItemListError) {
      setLoading(false);
      return () => null;
    }
    if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
      // setChenging(true);
      SetItemListResponse(GetItemListResponse);
      setTotalItemLength(GetItemListResponse?.data?.data?.totalRecords);
      if (pushPopFun) {
        const foundId = GetItemListResponse?.data?.data?.records.find((obj) => {
          return obj._id === matchId;
        });
        if (foundId?.askClient === true) {
          setShowTrueSnackbar(true);
          setTimeout(() => {
            setShowTrueSnackbar(false);
          }, 5000);
        } else if (foundId?.askClient === false) {
          setShowFalseSnackbar(true);
          setTimeout(() => {
            setShowFalseSnackbar(false);
          }, 5000);
        }
      }
      setTimeout(() => {
        setPushPopFun(false);
      }, 1000);

      if (onIssueClick1 === true && moduleTypeEdit === "ITEM") {
        setValue(1);
        setOpenEditItem(true);
        setEditItemId(onIssueClickData?.moduleId);
      } else if (onIssueClick1 === true && moduleTypeEdit === "DOCUMENT") {
        setValue(0);
      }
      const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
        const Error = IssueResponse?.data?.data?.records.find((obj) => {
          return obj.moduleId === prop._id;
        });
        return {
          item: prop?.description ? prop?.description : "---",
          qty: prop?.quantity
            ? isString(prop?.quantity)
              ? Number(prop?.quantity)
              : prop?.quantity
            : "---",
          unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
          product: prop?.product?.name ? prop?.product?.name : "---",
          price: prop?.unitPrice ? prop?.unitPrice : "0",
          total: prop?.amount ? prop?.amount : "---",
          customer: prop?.customer?.name ? prop?.customer?.name : "---",
          category: prop?.category?.name ? prop?.category?.name : "---",
          class: prop?.class?.name ? prop?.class?.name : "---",
          proderror: Error ? true : false,
          proderrorMsg: Error ? Error?.message : "---",
          id: prop._id,
          sku: prop.sku,
          itemType: prop.itemType,
          askClient: (
            <MDBox display="flex" alignItems="center">
              <input
                type="checkbox"
                id={prop._id}
                onChange={() => toggleCheck(prop._id) && setLoading(true)}
                style={{ height: "1.3em", width: "1.3em", cursor: "pointer" }}
                // checked={checked[prop?.askClient]}
                checked={prop?.askClient}
              />
            </MDBox>
          ),
          CheckAskClient: prop?.askClient,
        };
      });
      const foundCheck = data.find((obj) => {
        return obj.CheckAskClient === true;
      });
      // setCheckFound(foundCheck !== undefined);
      const Categoryresult = data.filter(
        (data) => data.itemType === "AccountBasedExpenseLineDetail"
      );
      setCategoryList(data);
      setProductList(data);
      if (firstTime === true) {
        if (Categoryresult.length === 0) {
          // setToggleShow(true),
          setItemList(data), setLoading(false), setFirstTime(false);
        } else {
          // setToggleShow(false),
          setItemList(data);
          setLoading(false), setFirstTime(false);
        }
      } else {
        setLoading(false), toggleShow === true ? setItemList(data) : setItemList(data);
      }
      setTimeout(() => {
        setChenging(true);
        setLoading(false);
        setLoadingCircul(false);
      }, 2000);
    }
  }, [GetItemListResponse]);

  useEffect((e) => {
      const data = itemListResponse?.data?.data?.records.map((prop, key) => {
        return {
          item: prop?.description ? (
            <MDBox>
              <MDTypography>
                <Tooltip title={prop?.description} placement="top">
                  <MDTypography
                    sx={{
                      "@media (max-width: 1440px)": {
                        fontSize: "0.775rem",
                        color: "#7b809a",
                      },
                      cursor: "pointer",
                      color: "#7b809a",
                    }}
                  >
                    {" "}
                    {textEllipsis(prop?.description, 15)}
                  </MDTypography>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ) : ("---"),
          qty: prop?.quantity
            ? isString(prop?.quantity)
              ? Number(prop?.quantity)
              : prop?.quantity
            : "---",
          unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : "---",
          product: prop?.product?.name ? (
            <MDBox>
              <MDTypography>
                <Tooltip title={prop?.product?.name} placement="top">
                  <MDTypography
                    sx={{
                      "@media (max-width: 1440px)": {
                        fontSize: "0.775rem",
                        color: "#7b809a",
                      },
                      cursor: "pointer",
                      color: "#7b809a",
                    }}
                  >
                    {" "}
                    {textEllipsis(prop?.product?.name, 18)}
                  </MDTypography>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ) : ("---"),
          price: prop?.unitPrice ? prop?.unitPrice : "0",
          total: prop?.amount ? prop?.amount : "---",
          customer: prop?.customer?.name ? (
            <MDBox>
              <MDTypography>
                <Tooltip title={prop?.customer?.name} placement="top">
                  <MDTypography
                    sx={{
                      "@media (max-width: 1440px)": {
                        fontSize: "0.775rem",
                        color: "#7b809a",
                      },
                      cursor: "pointer",
                      color: "#7b809a",
                    }}
                  >
                    {" "}
                    {textEllipsis(prop?.customer?.name, 11)}
                  </MDTypography>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ) : ("---"),
          category: prop?.category?.name ? (
            <MDBox>
              <MDTypography>
                <Tooltip title={prop?.category?.name} placement="top">
                  <MDTypography
                    sx={{
                      "@media (max-width: 1440px)": {
                        fontSize: "0.775rem",
                        color: "#7b809a",
                      },
                      cursor: "pointer",
                      color: "#7b809a",
                    }}
                  >
                    {" "}
                    {textEllipsis(prop?.category?.name, 18)}
                  </MDTypography>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ) : ("---"),
          class: prop?.class?.name ? (
            <MDBox>
              <MDTypography
                fontWeight="400"
                fontSize="0.775rem"
                color="secondary"
                sx={{ cursor: "pointer", ml: -4 }}
              >
                <Tooltip title={prop?.class?.name} placement="top">
                  <MDTypography
                    sx={{
                      "@media (max-width: 1440px)": {
                        fontSize: "0.775rem",
                        color: "#7b809a",
                      },
                      cursor: "pointer",
                      color: "#7b809a",
                    }}
                  >
                    {" "}
                    {textEllipsis(prop?.class?.name, 18)}
                  </MDTypography>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ) : ("---"),
          id: prop._id,
          sku: prop.sku,
          itemType: prop.itemType,
          action:
            documentStatus === "Synced" || isSynced === true ? (
              <MDBox display="flex">
                {canAccess((actions = "update"), (resource = "documents")) && (
                  <MDTypography variant="body2" color="secondary">
                    {" "}
                    <Tooltip
                      title="Edit"
                      placement="top"
                      sx={{ cursor: "pointer" }}
                      onClick={(value) => {
                        setOpenQboSync(true);
                      }}
                    >
                      <Icon>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                {canAccess((actions = "update"), (resource = "documents")) && (
                  <MDTypography variant="body2" color="secondary">
                    <Tooltip
                      title="Delete"
                      placement="top"
                      sx={{ cursor: "pointer" }}
                      onClick={(value) => {
                        setOpenQboSync(true);
                      }}
                    >
                      <Icon>delete</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
            ) : (
              <MDBox display="flex">
                {canAccess((actions = "update"), (resource = "documents")) && (
                  <MDTypography variant="body2" color="secondary">
                    {" "}
                    <Tooltip
                      title="Edit"
                      placement="top"
                      sx={{ cursor: "pointer" }}
                      onClick={(value) => {
                        // setAccountantId(prop._id);
                        handleEditItem();
                        setEditItemId(prop._id);
                      }}
                    >
                      <Icon>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                {canAccess((actions = "delete"), (resource = "documents")) && (
                  <MDTypography variant="body2" color="secondary">
                    <Tooltip
                      title="Delete"
                      placement="top"
                      sx={{ cursor: "pointer" }}
                      onClick={(value) => {
                        warningWithConfirmMessage();
                        setItemId(prop._id);
                      }}
                    >
                      <Icon>delete</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
            ),
        };
      });
      const Categoryresult = data?.filter(
        (data) => data.itemType === "AccountBasedExpenseLineDetail"
      );
      const Productresult = data?.filter((data) => data.itemType === "ItemBasedExpenseLineDetail");
      setCategoryList(data);
      setProductList(data);
      if (firstTime === true) {
        if (Categoryresult?.length === 0) {
          setItemList(data),
          setFirstTime(false);
        } else {
          setItemList(data);
          setFirstTime(false);
        }
      } else {
        toggleShow === true ? setItemList(data) : setItemList(data);
      }
    },[checked]);

  useEffect(() => {
    if (productList || categoryList) {
      { toggleShow === true ? setItemList(productList) : setItemList(productList);}
    }
  }, [toggleShow]);

  useEffect(() => {
    if (isDeleteItemLoading) {
      return () => null;
    }
    if (isDeleteItemError) {
      setLoading(false);
      return () => null;
    }
    if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
      GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
  }, [DeleteItemResponse]);

  useEffect(() => {
    if (isCommentsLoading) {
      return () => null;
    }
    if (isCommentsError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
      const LogCommentdata = CommentsResponse?.data?.data?.records.map((prop, key) => {
        const commentBy =
          (prop?.commentBy?.fname ? prop.commentBy.fname.charAt(0).toUpperCase() + prop.commentBy.fname.slice(1) : "") +
          " " +
          (prop?.commentBy?.lname ? prop.commentBy.lname : "");
      
        const comments = prop.comment;
      
        // Extract {{tags}} from comments
        const found = [];
        const rxp = /{{([^}]+)}}/g;
        let curMatch;
      
        while ((curMatch = rxp.exec(comments))) {
          found.push(curMatch[1]);
        }
      
        // Replace {{tag}} with @username
        function findReplaceString(string, found) {
          let results = string.match(/{{([^}]+)}}/g);
          if (results) {
            found.forEach((id, idx) => {
              const Name = users.find((obj) => obj.id == id); // Find user by ID
              const username = `@${Name?.display || "unknown"}`;
              string = string.replace(new RegExp(`{{\\s*${id}\\s*}}`), `<span class="tagged-name">${username}</span>`);
            });
          }
          return string;
        }
      
        const datacom = findReplaceString(comments, found);
      
        return (
          <Paper
            style={{
              background: darkMode ? "#394366" : "#ffffff",
              padding: "30px 20px",
              borderBottom: darkMode ? "2px solid #ffffff" : "",
            }}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
              </Grid>
              <Grid justifyContent="left" item xs zeroMinWidth>
                <div style={{ display: "flex" }}>
                  <h4
                    style={{
                      margin: 0,
                      textAlign: "left",
                      fontFamily: "Arial",
                      fontSize: "0.775rem",
                      fontWeight: "bold",
                      lineHeight: 1.5,
                      textTransform: "none",
                      marginBottom: "5px",
                      color: darkMode ? "#ffffff" : "",
                    }}
                  >
                    {commentBy}
                  </h4>
                  <MDTypography sx={{ color: darkMode ? "#ffffff" : "", mt: 0.5, ml: 1 }} variant="caption" color="text">
                    {inLocalTime(prop.createdAt)}
                  </MDTypography>
                </div>
                <p
                  style={{
                    color: darkMode ? "#ffffff" : "",
                    margin: 0,
                    fontFamily: "Arial",
                    fontSize: "0.775rem",
                    fontWeight: 300,
                    lineHeight: 1.5,
                    textTransform: "none",
                    textAlign: "left",
                  }}
                  dangerouslySetInnerHTML={{ __html: datacom || comments }}
                ></p>
              </Grid>
            </Grid>
          </Paper>
        );
      });
      
      setCommentsList(LogCommentdata);
      setTimeout(() => {
        setLoading(false);
        setLoadingCircul(false);
      }, 2000);
    }
  }, [CommentsResponse]);

  useEffect(() => {
    if (isAuditLogLoading) {
      return () => null;
    }
    if (isAuditLogError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (AuditLogResponse && AuditLogResponse.data.isSuccess === true) {
      const Logdata = AuditLogResponse.data.data.records.map((prop, key) => {
        return (
          <TimelineItem
            dateTime={prop.createdAt}
            description={prop.message}
            badges={["design"]}
            type={prop.eventType}
            button={prop.moduleId}
          />
        );
      });
      setAuditLogList(Logdata);
      setTimeout(() => {
        setLoading(false);
        setLoadingCircul(false);
      }, 2000);
    }
  }, [AuditLogResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
        if (response?.code == "ERR100") {
          setpassDuplicateMsg(response?.data?.message);
          setShowPassDuplicate(true);
      }else{
        setShowPassDuplicate(false);
      }
      setErrorMessage(response?.data?.message);
      setBtnDisable(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("refreshListAfterUpdate", response);
      DetailsUserAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
      setBtnDisable(false);
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 10000);
      SetEditable(false);
      setLoading(true);
      setLoadingCircul(false);
      setShowPassDuplicate(false);
    }
  }, [response]);

  useEffect(async () => {
    setFirstTime(onIssueClick1);
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    await VendorListAPICall({
      payload: [{ businessId: `${businessId}`, IsSynced: true }],
    });
  }, []);

  useEffect(() => {
    if (loading == true) {
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }, [loading]);

  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoading(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      setLoading(false);
      setVendorList(VendorResponse?.data?.data?.records);
      const Name = VendorResponse?.data?.data?.records.find((obj) => {
        return obj._id == addNewVendorId;
      });
      Name?.name ? setVendorname2(Name?.name) : "";
    }
  }, [VendorResponse]);

  eventBus.$on("AddnewVendorError", (data) => AddnewVendorError(data));
  function AddnewVendorError(data) {
    setAddVendoreerror(true);
    setErrorMessagesVendorError("Error");
  }

  eventBus.$on("AddnewVendorComplete", (data) => AddnewVendorComplete(data));
  function AddnewVendorComplete(data) {
    setAddNewVendorId(data?.vendor?._id);
    setOpenEditVendor(false);
    setLoading(true);
    setAddNewVendor(true);
    VendorListAPICall({
      payload: [{ businessId: `${businessId}`, IsSynced: true }],
    });
  }

  eventBus.$on("errorInAddOwner", (data) => errorInAddOwner(data));
  function errorInAddOwner(data) {
    setErrorMessage(data);
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 4000);
  }

  eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
  function successInAddOwner(data) {
    InvoiceDetailsUserAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    setOpenAddOwner(false);
    setTimeout(() => {
      setTabsMove("Ask Client");
      setLoading(false);
      setLoadingCircul(false);
      setalertMoveInvoice(true);
    }, 500);
    // navigate('/accountant/client/list');
  }

  eventBus.$on("closeCategoryEdit", (data) => closeCategoryEdit(data));
  function closeCategoryEdit(data) {
    setOpenAddItem(false);
  }

  eventBus.$on("handleChangeRedio", (data) => handleChangeRedioEvent(data));
  function handleChangeRedioEvent(data) {
    data === "ItemBasedExpenseLineDetail" ? handleAddItem() : handleCategort();
  }

  eventBus.$on("handleChangeEditRedio", (data) => handleChangeEditRedioEvent(data));
  function handleChangeEditRedioEvent(data) {
    setToggleShow(!toggleShow);
    data === "ItemBasedExpenseLineDetail" ? setOpenEditItem(true) : setOpenEditItem(true);
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openAddItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openAddItem]);

  useEffect(() => {
    if (isDetailsLoading) {
      return () => null;
    }

    if (isDetailsError) {
      setLoadingCircul(false);
      return () => null;
    }

    if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
      eventBus.$emit("refreshListAfterUpdate", response);
      DetailsResponse?.data?.data?.document?.documentSource === "QBO" ? setEditbleData(false) : setEditbleData(true);
      setOcrstatus( DetailsResponse?.data?.data?.document?.ocrStatus === "INPROGRESS" ? true : false);
      setCreateObjectURL(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl: "");
      setItemType(DetailsResponse?.data?.data?.document?.itemType);
      setOcrstatus1(DetailsResponse?.data?.data?.document?.ocrStatus);
      setActualSubtotal(DetailsResponse?.data?.data?.document?.actualSubtotal);
      setActualTotal(DetailsResponse?.data?.data?.document?.actualTotal);
      setVendorSelect(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
      setVendorname( DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
      setVendorname1(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : "");
      setInvoiceNumber(DetailsResponse?.data?.data?.document?.invoice_number ? DetailsResponse?.data?.data?.document?.invoice_number : DetailsResponse?.data?.data?.document?.docNumber);
      setPoNumber(DetailsResponse?.data?.data?.document?.order_no ? DetailsResponse?.data?.data?.document?.order_no : "");
      setTransactionDate(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD"): null);
      setTransactionDateObj(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD"): null);
      setUploadDate(DetailsResponse?.data?.data?.document?.createdAt ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format("YYYY-MM-DD"): null);
      setTotal(DetailsResponse?.data?.data?.document?.total);
      setTotalStore(DetailsResponse?.data?.data?.document?.total);
      setTotalForMatch(DetailsResponse?.data?.data?.document?.total);
      setTransactionDateForMatch(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD") : null);
      setOrderTotalStore(DetailsResponse?.data?.data?.document?.orderTotal);
      setTax(DetailsResponse?.data?.data?.document?.tax);
      setDiscount(DetailsResponse?.data?.data?.document?.discount);
      setDiscountStore(DetailsResponse?.data?.data?.document?.discount);
      setDiscountStatic(DetailsResponse?.data?.data?.document?.discount);
      setShippingpriceStore(DetailsResponse?.data?.data?.document?.shippingPrice);
      setShippingprice(DetailsResponse?.data?.data?.document?.shippingPrice);
      setShippingpriceStatic(DetailsResponse?.data?.data?.document?.shippingPrice);
      setTaxStore(DetailsResponse?.data?.data?.document?.tax);
      setPoNumber(DetailsResponse?.data?.data?.document?.purchaseOrderNo);
      setIssues(DetailsResponse?.data?.data?.document?.issues);
      setSubTotalForMatch(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
      setSubTotal( DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
      setSubTotStore(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
      setCurrencySymbol(DetailsResponse?.data?.data?.document?.currencySymbol);
      setDocumnetSource(DetailsResponse?.data?.data?.document?.documentSource ? DetailsResponse?.data?.data?.document?.documentSource : "");
      setIsSynced(DetailsResponse?.data?.data?.document?.isSynced);
      setDocumentStatus(DetailsResponse?.data?.data?.document?.documentStatus ? DetailsResponse?.data?.data?.document?.documentStatus : "");
      setDocumentType(DetailsResponse?.data?.data?.document?.documentType ? DetailsResponse?.data?.data?.document?.documentType : "" );
      setImageUrl(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl : "");
      if(DetailsResponse?.data?.data?.document?.documentStatus == "ToReview" && showDetails == false){
        setValue(0);
        setShowDetails(true);
      }else if(showDetails == false) {
        setValue(1);
        setShowDetails(true);
      }
      setLoading(false);
      DetailsResponse?.data?.data?.document?.itemType === "ItemBasedExpenseLineDetail"
        ? setToggleShow(true)
        : setToggleShow(false);
      setLoadingCircul(false);
      setShowDetailsForm(true);
      setResetData(false);
      setShowLoaderFirInvoice(false);
      setInitialValues({
        vendorname: DetailsResponse?.data?.data?.document?.vendor
          ? DetailsResponse?.data?.data?.document?.vendor.name
          : "",
        documenttype: DetailsResponse?.data?.data?.document?.documentType
          ? DetailsResponse?.data?.data?.document?.documentType
          : "",
        invoicenumber: DetailsResponse?.data?.data?.document?.docNumber,
        ponumber: DetailsResponse?.data?.data?.document?.purchaseOrderNo,
        invoicedate: DetailsResponse?.data?.data?.document?.transactionDate
          ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD")
          : null,
        uploaddate: DetailsResponse?.data?.data?.document?.createdAt
          ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format("YYYY-MM-DD")
          : null,
        total: `${total}`,
        tax: `${tax}`,
        transactionDate:DetailsResponse?.data?.data?.document?.transactionDate
        ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format("YYYY-MM-DD")
        : null,
        subtotal: `${subtotal}`,
      });
      ItemIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      DocumentIssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
      IssueListAPICall({
        payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
      });
    }
  }, [DetailsResponse]);

  useEffect(() => {
    setInitialValues({
      vendorname: "test",
      documenttype: documentType,
      invoicenumber: invoicenumber,
      ponumber: ponumber,
      invoicedate: transactionDate,
      uploaddate: uploaddate,
      total: `${total}`,
      tax: `${tax}`,
      transactionDate: `${transactionDate}`,
      subtotal: `${subtotal}`,
    });
  }, [resetData]);

  const handleClosebtn = async () => {
    setVendorname(vendorname1);
    setResetData(true);
    SetEditable(false);
    setOpenEditVendor(false);
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  };

  const handleCloseVendorbtn = async () => {
    setOpenEditVendor(false);
    await DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  };

  const closeProductEdit = async () => {
    setOpenEditItem(false);
    setOnIssueClick1(false);
    eventBus.$emit("closeandremovealldata", "remove");
  };

  const handleOpenIssue = () => {
    setLoading(true);
    getReportAPICall({
      payload: [{ documentId: invoiceId ? invoiceId : documentsId }],
    });
  };

  const finalStoreObj = typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
  const finalTaxObj = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
  const finalShippingPriceObj = typeof shippingpriceStore == "string" ? shippingpriceStore.replaceAll(",", "") : shippingpriceStore;
  const finaldiscountObj = typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
  const finalTotalObj = typeof TotalStore == "string" ? TotalStore.replaceAll(",", "") : TotalStore;

  const handleChange = async (event, newValue) => {
    setFirstTime(true);
    setOnIssueClick1(false);
    setValue(newValue);
    if (newValue === 1) {
      setLoading(true);
      // setLoadingCircul(true);
      await GetItemListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` }],
      });
    } else if (newValue === 2) {
      setLoading(true);
      // setLoadingCircul(true);
      await GetsummoryListListAPICall({
        payload: [{ InvoiceId: `${invoice_id}` , businessId: `${businessId}`}],
      });
    } else if (newValue === 3){
      setLoading(true);
      await CommentsListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }else if (newValue === 4) {
      setLoading(true);
      // setLoadingCircul(true);
      await AuditLogListAPICall({
        payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
      });
    }
    if(isFormDirty){
      setAPICall({
       payload: [
         {
           transactionDate: `${transactionDateObj}`,
           createdAt: `${uploaddate}`,
           documentType: `${documentTypeObj}`,
           docNumber: `${invoiceNumberObj}`,
           vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
           subTotal: Number(finalStoreObj),
           tax: Number(finalTaxObj),
           shippingPrice: Number(finalShippingPriceObj),
           discount: Number(finaldiscountObj),
           total: Number(finalTotalObj),
           purchaseOrderNo: `${ponumberObj}`,
           businessId: `${businessId}`,
           documnetId: `${invoice_id}`,
           passDuplicate:passDuplicate,
         },
       ],
     });
   }
  };

  const handleAddNewVendor = async (values) => {
    setLoading(true);
    DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1
      ? await AddVendorListAPICall({
          payload: [
            {
              name: vendorname,
              billingAddress: {
                line1: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1,
                city: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.city,
                state: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.state,
                zip: DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.zip,
              },
              clientId: `${businessId}`,
            },
          ],
        })
      : await AddVendorListAPICall({
          payload: [
            {
              name: vendorname,
              clientId: `${businessId}`,
            },
          ],
        });
  };

  const handleSubmit = async (event, values) => {
    if(!transactionDateObj){
      return
    }
    setBtnDisable(true);
    setLoadingCircul(true);
    const finalStore =
      typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
    const finalTax = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
    const finalShippingPrice =
      typeof shippingpriceStore == "string"
        ? shippingpriceStore.replaceAll(",", "")
        : shippingpriceStore;
    const finaldiscount =
      typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
    const finalTotal = typeof TotalStore == "string" ? TotalStore.replaceAll(",", "") : TotalStore;
    await setAPICall({
      payload: [
        {
          transactionDate: `${transactionDateObj}`,
          createdAt: `${event.uploaddate}`,
          documentType: `${event.documenttype}`,
          docNumber: `${event.invoicenumber}`,
          vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
          subTotal: Number(finalStore),
          tax: Number(finalTax),
          shippingPrice: Number(finalShippingPrice),
          discount: Number(finaldiscount),
          total: Number(finalTotal),
          purchaseOrderNo: `${event.ponumber}`,
          businessId: `${businessId}`,
          documnetId: `${invoice_id}`,
          passDuplicate:passDuplicate,
        },
      ],
    });
    eventBus.$emit("refreshApiInvoiceList", event);
  };

  const ToggleClick = () => {
    setToggleShow(!toggleShow),
    setDisButtonOnWork(true);
      setLoading(true),
      setAPICall({
        payload: [
          {
            itemType:
              toggleShow === false ? `ItemBasedExpenseLineDetail` : `AccountBasedExpenseLineDetail`,
            businessId: `${businessId}`,
            documnetId: `${invoice_id}`,
          },
        ],
      });
  };

  const handleToggle = () => {
    setSummeryList([]);
    documentStatus === "Synced" || isSynced === true ? setOpenQboSync(true) : ToggleClick();
  };
  const handleAddItem = () => {
    setOpenAddItem(true);
    setOpenCategoryAddItem(false);
  };
  const handleCategort = () => {
    setOpenCategoryAddItem(true);
    setOpenAddItem(false);
  };
  const handleEditItem = () => {
    setOpenEditItem(true);
  };

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };
  const hideAlert = () => {
    setAlert(false);
  };
  const successDelete = () => {
    DeleteItemListAPICall({
      payload: [
        {
          adjustInvoice: false,
          InvoiceId: `${invoice_id}`,
          ItemId: `${itemId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  const hideInvoiceAlert = () => {
    setAlertDeleteInvoice(false);
  };
  const successInoiceDelete = () => {
    DeleteInvoiceListAPICall({
      payload: [
        {
          documentIds: {
            documentIds: [invoiceId],
          },
          businessId: `${businessId}`,
        },
      ],
    });
    setAlert(false);
    setLoading(true);
  };

  const currentPage = paginationModel.page;
  const rowsPerPage = paginationModel.pageSize;
  useEffect(() => {
    if (GetItemListResponse?.data?.isSuccess === true && itemList?.length !== 0) {
      const currentPageRows = itemList.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      );
      if (currentPageRows.length > 0) {
        const currentPageRowIds = currentPageRows.map((item) => item.id);
        const allCurrentPageRowsSelected = currentPageRows.every((item) =>
          selectedRows.includes(item.id)
        );
        setCurrentPageRowSize(allCurrentPageRowsSelected);
      }
    }
  }, [selectedRows, itemList]);

  useEffect(() => {
    if (itemList.length !== 0) {
      const currentPageRows = itemList.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      );
      const currentPageRowIds = currentPageRows.map((item) => item.id);
      const allCurrentPageRowsSelected = currentPageRows.every((item) =>
        selectedRows.includes(item.id)
      );
      setCurrentPageRowSize(allCurrentPageRowsSelected);
    }
  }, [paginationModel]);

  const handleHeaderCheckboxChange = (event) => {
    if (itemList.length !== 0) {
      const currentPageRows = itemList.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      );
      const currentPageRowIds = currentPageRows.map((item) => item.id);
      const allCurrentPageRowsSelected = currentPageRows.every((item) =>
        selectedRows.includes(item.id)
      );
      setSelectedRows(
        event.target.checked
          ? [...selectedRows, ...currentPageRowIds.filter((id) => !selectedRows.includes(id))] // Add new rows
          : selectedRows.filter((id) => !currentPageRowIds.includes(id)) // Remove rows
      );
    }
  };

  const handleRowCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;

    // Calculate the index of the row in the itemList
    const rowIndex = currentPage * rowsPerPage + itemList.findIndex((item) => item.id === id);

    setSelectedRows((prevSelected) => {
      if (isChecked) {
        // Add the row ID if it's checked and belongs to the current page
        return [...prevSelected, id];
      } else {
        // Remove the row ID if it's unchecked
        return prevSelected.filter((rowId) => rowId !== id);
      }
    });
  };

  const callApiAfterPassDuplicate = () => {
    setPassDuplicate(true);
    setAPICall({
      payload: [
        {
          transactionDate: `${transactionDateObj}`,
          createdAt: `${uploaddate}`,
          documentType: `${documentTypeObj}`,
          docNumber: `${invoiceNumberObj}`,
          vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
          subTotal: Number(finalStoreObj),
          tax: Number(finalTaxObj),
          shippingPrice: Number(finalShippingPriceObj),
          discount: Number(finaldiscountObj),
          total: Number(finalTotalObj),
          purchaseOrderNo: `${ponumberObj}`,
          passDuplicate:true,
          businessId: `${businessId}`,
          documnetId: `${invoice_id}`,
        },
      ],
    });
  };

  useEffect(() => {
    if (selectedRows.length !== 0) {
      // toggleCheck(selectedRows)
      setShowBulkEditButton(true);
    } else {
      setShowBulkEditButton(false);
    }
  }, [selectedRows]);

  const closeMoveDialog = async () => {
    setOpenQboSyncAskClient(false);
  };
  const editItemList =
    finalSelect == "Ask Client" ||
    finalSelect == "Classify" ||
    documentStatus == "AskClient" ||
    documentStatus == "Classify";

  const columns = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 110,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editItemList && editbleData && chenging,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 65,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      maxWidth: 75,
      flex: 0.1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          documentStatus === "Synced" || isSynced === true ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ) : (
            <>
              <GridActionsCellItem
                icon={<EditIcon fontSize="small" />}
                label="Edit"
                className="textPrimary"
                onClick={() => {
                  // setAccountantId(prop._id);
                  setEditItemId(id);
                  handleEditItem();
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon fontSize="small" />}
                label="Delete"
                onClick={(value) => {
                  totalItemLength === 1 ? setOneItemPopup(true) : warningWithConfirmMessage();
                  setItemId(id);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ),
        ];
      },
    },

    // {
    //     field: 'askClients',
    //     type: 'actions',
    //     headerName: 'ASK CLIENT',
    //     maxWidth: 95,
    //     flex: 0.15,
    //     cellClassName: 'actions',
    //     getActions: ({ id }) => {
    //         const result = itemListResponse?.data?.data?.records.map((prop, key) => {
    //             {
    //                 return {
    //                     id: prop._id,
    //                     item: prop.description,
    //                     qty: prop.quantity,
    //                     unit: prop.unitOfMeasure,
    //                     price: prop.unitPrice,
    //                     total: prop.total,
    //                     sku: prop.sku,
    //                     unitPrice: prop.unitPrice,
    //                     results: prop.results,
    //                     askClients: prop.askClient,
    //                     category: prop.category ? prop.category.name : '',
    //                     categoryId: prop.category ? prop.category._id : '',
    //                     class: prop.class ? prop.class.name : '',
    //                     product: prop.product ? prop.product.name : '',
    //                     productId: prop.product ? prop.product._id : '',
    //                     classId: prop.class ? prop.class._id : '',
    //                     customer: prop.customer ? prop.customer.name : '',
    //                     customerId: prop.customer ? prop.customer._id : '',
    //                 }
    //             }
    //         });
    //         const found = result.find(obj => {
    //             return obj.id === id;
    //         });

    //         return [
    //             <input
    //                 type="checkbox"
    //                 title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
    //                 disabled={selectTab === "Classify" ? false : true}
    //                 id={id}
    //                 onChange={() => toggleCheck(id)}
    //                 checked={found?.askClients}
    //             />
    //         ];
    //     },
    // },
  ];

  const columnsWithoutAction = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 110,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editItemList && editbleData && chenging,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                // "& .MuiDataGrid-column:hover": {
                //     backgroundColor: "skyblue",
                //     color: "red"
                // },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 65,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const columnsWitoutClass = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 60,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editItemList && editbleData && chenging,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 25,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 70,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 70,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      maxWidth: 82,
      flex: 0.3,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          documentStatus === "Synced" || isSynced === true ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ) : (
            <>
              <GridActionsCellItem
                icon={<EditIcon fontSize="small" />}
                label="Edit"
                className="textPrimary"
                onClick={() => {
                  // setAccountantId(prop._id);
                  setEditItemId(id);
                  handleEditItem();
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon fontSize="small" />}
                label="Delete"
                onClick={(value) => {
                  totalItemLength === 1 ? setOneItemPopup(true) : warningWithConfirmMessage();
                  setItemId(id);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ),
        ];
      },
    },

  ];

  const columnsWitoutClassAction = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      headerName: "PRODUCTS/SERVICE",
      field: "product",
      minWidth: 60,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderEditCell: SelectProductEditInputCell,
      editable: editItemList && editbleData && chenging,
      flex: 0.3,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  cursor: "pointer",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 30,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      minWidth: 60,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 25,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 40,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.1,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumns = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 70,
      disableColumnMenu: true,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.26,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      maxWidth: 73,
      flex: 0.4,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          documentStatus === "Synced" || isSynced === true ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ) : (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={() => {
                  editbleData === true
                    ? setEditItemId(id) || handleEditItem()
                    : setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  totalItemLength === 1
                    ? setOneItemPopup(true)
                    : editbleData === true
                    ? warningWithConfirmMessage() || setItemId(id)
                    : setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ),
        ];
      },
    },
    // {
    //     field: 'askClients',
    //     type: 'actions',
    //     headerName: 'ASK CLIENT',
    //     maxWidth: 97,
    //     flex: 0.52,
    //     cellClassName: 'actions',
    //     getActions: ({ id }) => {
    //         const result = itemListResponse?.data?.data?.records.map((prop, key) => {
    //             {
    //                 return {
    //                     id: prop._id,
    //                     item: prop.description,
    //                     qty: prop.quantity,
    //                     unit: prop.unitOfMeasure,
    //                     price: prop.unitPrice,
    //                     total: prop.total,
    //                     sku: prop.sku,
    //                     unitPrice: prop.unitPrice,
    //                     results: prop.results,
    //                     askClients: prop.askClient,
    //                     category: prop.category ? prop.category.name : '',
    //                     categoryId: prop.category ? prop.category._id : '',
    //                     class: prop.class ? prop.class.name : '',
    //                     product: prop.product ? prop.product.name : '',
    //                     productId: prop.product ? prop.product._id : '',
    //                     classId: prop.class ? prop.class._id : '',
    //                     customer: prop.customer ? prop.customer.name : '',
    //                     customerId: prop.customer ? prop.customer._id : '',
    //                 }
    //             }
    //         });
    //         const found = result.find(obj => {
    //             return obj.id === id;
    //         });

    //         return [
    //             <input
    //                 type="checkbox"
    //                 id={id}
    //                 title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
    //                 disabled={selectTab === "Classify" ? false : true}
    //                 onChange={() => toggleCheck(id)}
    //                 checked={found?.askClients}
    //             />
    //         ];
    //     },
    // },
  ];

  const cetagorycolumnsWithotAction = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "class",
      headerName: "CLASS",
      renderEditCell: SelectClassEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 70,
      disableColumnMenu: true,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumnsWithoutClassAction = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 55,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 55,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const cetagorycolumnsWithoutClass = [
    {
      field: "askClients",
      type: "actions",
      headerName: "",
      maxWidth: 86,
      flex: 0.35,
      cellClassName: "actions",
      renderHeader: () => (
        <input type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
      ),
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={(event) => handleRowCheckboxChange(event, params.row.id)}
          checked={selectedRows.includes(params.row.id)}
        />
      ),
    },
    {
      field: "category",
      headerName: "CATEGORY",
      renderEditCell: SelectCategoryEditInputCell,
      editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) =>
        params?.row?.proderror === true ? (
          <Tooltip title={params?.row?.proderrorMsg} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                  padding: 2,
                },
                cursor: "pointer",
                color: "#7b809a",
                padding: 2,
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ) : (
          <Tooltip title={params?.formattedValue} placement="top">
            <MDTypography
              sx={{
                "@media (max-width: 1440px)": {
                  fontSize: "0.775rem",
                  color: "#7b809a",
                },
                cursor: "pointer",
                color: "#7b809a",
              }}
            >
              {" "}
              {textEllipsis(params?.formattedValue, 18)}
            </MDTypography>
          </Tooltip>
        ),
    },
    {
      field: "item",
      headerName: "DESCRIPTION",
      minWidth: 120,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 15)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "customer",
      headerName: "CUSTOMER",
      renderEditCell: SelectCustomerEditInputCell,
      editable: editItemList && editbleData && chenging,
      minWidth: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                color: "#7b809a",
              },
              cursor: "pointer",
              color: "#7b809a",
            }}
          >
            {" "}
            {textEllipsis(params?.formattedValue, 11)}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "QTY",
      field: "qty",
      minWidth: 20,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.22,
      valueGetter: getQuantity,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "UNIT",
      field: "unit",
      minWidth: 40,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "PRICE",
      field: "price",
      minWidth: 75,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getPriceProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 75,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "ACTIONS",
      maxWidth: 75,
      flex: 0.27,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          documentStatus === "Synced" || isSynced === true ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ) : (
            <>
              <GridActionsCellItem
                icon={<EditIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}/>}
                label="Edit"
                className="textPrimary"
                onClick={() => {
                  editbleData === true
                    ? setEditItemId(id) || handleEditItem()
                    : setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
              <GridActionsCellItem
                icon={<DeleteIcon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }} />}
                label="Delete"
                onClick={(value) => {
                  totalItemLength === 1
                    ? setOneItemPopup(true)
                    : editbleData === true
                    ?
                     warningWithConfirmMessage() || setItemId(id)
                    : setOpenQboSync(true);
                }}
                sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}
              />
            </>
          ),
        ];
      },
    },
    // {
    //     field: 'askClients',
    //     type: 'actions',
    //     headerName: 'ASK CLIENT',
    //     maxWidth: 86,
    //     flex: 0.35,
    //     cellClassName: 'actions',
    //     getActions: ({ id }) => {
    //         const result = itemListResponse?.data?.data?.records.map((prop, key) => {
    //             {
    //                 return {
    //                     id: prop?._id,
    //                     item: prop?.description,
    //                     qty: prop?.quantity,
    //                     unit: prop?.unitOfMeasure,
    //                     price: prop?.unitPrice,
    //                     total: prop?.total,
    //                     sku: prop?.sku,
    //                     unitPrice: prop?.unitPrice,
    //                     results: prop?.results,
    //                     askClients: prop?.askClient,
    //                     category: prop?.category ? prop?.category.name : '',
    //                     categoryId: prop?.category ? prop?.category._id : '',
    //                     class: prop?.class ? prop?.class.name : '',
    //                     product: prop?.product ? prop?.product.name : '',
    //                     productId: prop?.product ? prop?.product._id : '',
    //                     classId: prop?.class ? prop?.class._id : '',
    //                     customer: prop?.customer ? prop?.customer.name : '',
    //                     customerId: prop?.customer ? prop?.customer._id : '',
    //                 }
    //             }
    //         });
    //         const found = result.find(obj => {
    //             return obj.id === id;
    //         });

    //         return [
    //             <input
    //                 type="checkbox"
    //                 id={id}
    //                 title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
    //                 disabled={selectTab === "Classify" ? false : true}
    //                 onChange={() =>
    //                     toggleCheck(id)
    //                 }
    //                 checked={found?.askClients}
    //             />
    //         ];
    //     },
    // },
  ];

  const summarycetagorycolumns = [
    {
      field: "name",
      headerName: "CATEGORY",
      // renderEditCell: SelectCategoryEditInputCell,
      // editable: editItemList && editbleData && chenging,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      minWidth: 120,
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      headerName: "TOTAL",
      field: "total",
      headerAlign: "center",
      minWidth: 75,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTotalProduct,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params?.formattedValue} placement="top">
          <MDTypography
            sx={{
              "@media (max-width: 1440px)": {
                fontSize: "0.775rem",
                cursor: "pointer",
                color: "#7b809a",
              },
              color: "#7b809a",
            }}
          >
            {" "}
            {params?.formattedValue}
          </MDTypography>
        </Tooltip>
      ),
    },
  ];

  const [categoryFilter, setCategoryFilter] = useState({}); // Filter input state for category
  const [filteredCategoryRows, setFilteredCategoryRows] = useState(summeryList); // Filtered category rows
  const categoryFilterRefs = useRef({}); // Ref for category filter inputs

useEffect(() => {
  const filtered = summeryList.filter((row) =>
    Object.entries(categoryFilter).every(([column, value]) =>
      String(row[column]).toLowerCase().includes(value.toLowerCase())
    )
  );
  setFilteredCategoryRows(filtered);
}, [categoryFilter, summeryList]);

const handleCategoryFilterChange = (field, value) => {
  setCategoryFilter((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const summaryCategoryColumns = summarycetagorycolumns.map((col) => ({
  ...col,
  renderHeader: () => (
    <div style={{ display: 'grid', marginTop: '10px' }}>
      {/* <div
        style={
          darkMode
            ? { display: 'flex', justifyContent: 'center', color: '#ffffff' }
            : { display: 'flex', justifyContent: 'center' }
        }
      >
        {col.headerName}
      </div> */}
      <ColumnFilter
        column={col}
        filterText={categoryFilter[col.field] || ''} // Updated category filter state
        onFilterChange={handleCategoryFilterChange} // Updated filter change handler
        ref={(el) => (categoryFilterRefs.current[col.field] = el)} // Updated ref for category filters
      />
    </div>
  ),
}));

const summaryProductcolumns = [
  {
    field: "name",
    headerName: "PRODUCT",
    // renderEditCell: SelectCategoryEditInputCell,
    // editable: editItemList && editbleData && chenging,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    minWidth: 120,
    flex: 0.4,
    renderCell: (params) => (
      <Tooltip title={params?.formattedValue} placement="top">
        <MDTypography
          sx={{
            "@media (max-width: 1440px)": {
              fontSize: "0.775rem",
              cursor: "pointer",
              color: "#7b809a",
            },
            color: "#7b809a",
          }}
        >
          {" "}
          {params?.formattedValue}
        </MDTypography>
      </Tooltip>
    ),
  },
  {
    headerName: "TOTAL",
    field: "total",
    headerAlign: "center",
    minWidth: 75,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: getTotalProduct,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title={params?.formattedValue} placement="top">
        <MDTypography
          sx={{
            "@media (max-width: 1440px)": {
              fontSize: "0.775rem",
              cursor: "pointer",
              color: "#7b809a",
            },
            color: "#7b809a",
          }}
        >
          {" "}
          {params?.formattedValue}
        </MDTypography>
      </Tooltip>
    ),
  },
];

const [productFilter, setProductFilter] = useState({}); // State for product filters
const [filteredProductRows, setFilteredProductRows] = useState(summeryList); // Filtered product rows
const productFilterRefs = useRef({}); // Ref for product filters

  useEffect(() => {
    const filtered = summeryList.filter((row) =>
      Object.entries(productFilter).every(([column, value]) =>
        String(row[column]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredProductRows(filtered);
  }, [productFilter, summeryList]);

  const handleProductFilterChange = (field, value) => {
    setProductFilter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const summaryProductColumns = summaryProductcolumns.map((col) => ({
    ...col,
    renderHeader: () => (
      <div style={{ display: 'grid', marginTop: '10px' }}>
        {/* <div
          style={
            darkMode
              ? { display: 'flex', justifyContent: 'center', color: '#ffffff' }
              : { display: 'flex', justifyContent: 'center' }
          }
        >
          {col.headerName}
        </div> */}
        <ColumnFilter
          column={col}
          filterText={productFilter[col.field] || ''} // Use product filter state
          onFilterChange={handleProductFilterChange} // Handle filter change for product
          ref={(el) => (productFilterRefs.current[col.field] = el)} // Use product filter refs
        />
      </div>
    ),
  }));

  const dataTableData = {
    columns: [
      {
        Header: "Category",
        accessor: "category",
        // renderEditCell: renderCategorySelectEditInputCell,
        editable: editbleData,
        width: 90,
        // Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "total",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Ask Client",
        accessor: "askClient",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };

  const dataTableData1 = {
    columns: [
      {
        Header: "Products/Service",
        accessor: "product",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "item",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Class",
        accessor: "class",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Customer",
        accessor: "customer",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Total",
        accessor: "total",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
      },
      {
        Header: "Actions",
        accessor: "action",
        width: 90,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Ask Client",
        accessor: "askClient",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: itemList,
  };

  eventBus.$on("reloadDetailsTab", (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    setShowDetailsForm(false);
    setOpenAddItem(false);
    setLoading(true);
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeErrorEditNewItemPopup", (data) => closeErrorEditNewItemPopup(data));
  function closeErrorEditNewItemPopup(data) {
    setErrorMessage(data?.data?.message)
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeErrorAddNewItemPopup", (data) => closeErrorAddNewItemPopup(data));
  function closeErrorAddNewItemPopup(data) {
    setErrorMessage(data?.data?.message)
    setShowErrorSnackbar(true);
    setTimeout(() => {
      setShowErrorSnackbar(false);
    }, 3000);
  }

  eventBus.$on("closeAddNewItemPopup", (data) => fetchNewInvoicesAdd(data));
  function fetchNewInvoicesAdd(data) {
    setOpenAddItem(false);
    setLoading(true);
    setShowSnackbarAdd(true);
    setOpenCategoryAddItem(false);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    setTimeout(() => {
      setShowSnackbarAdd(false);
    }, 3000);
  }

  eventBus.$on("closeEditItemPopup", (data) => fetchNewInvoices(data));
  function fetchNewInvoices(data) {
    setOpenEditItem(false);
    setOnIssueClick1(false);
    setLoading(true);
    setShowSnackbarEdit(true);
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    setTimeout(() => {
      setShowSnackbarEdit(false);
    }, 3000);
  }

  eventBus.$on("AddnewCategoryComplete", (data) => AddnewCategoryComplete(data));
  async function AddnewCategoryComplete(data) {    
      const response = await GetCategoryListForParent({
        payload: [
          {
            businessId: `${businessId}`
          },
        ],
      });

      const Catdata = response.data.data.records.map((prop) => ({
        name: prop.name,
        categoryId: prop._id,
      }));
    setLoadingCircul(true);
    const result = itemListResponse?.data?.data?.records.map((prop, key) => {
      {
        return {
          id: prop._id,
          item: prop.description,
          qty: prop.quantity,
          unit: prop.unitOfMeasure,
          price: prop.unitPrice,
          total: prop.total,
          sku: prop.sku,
          amount: prop?.amount,
          unitPrice: prop.unitPrice,
          results: prop.results,
          askClients: prop.askClient,
          category: prop.category ? prop.category.name : "",
          categoryId: prop.category ? prop.category._id : "",
          class: prop.class ? prop.class.name : "",
          product: prop.product ? prop.product.name : "",
          productId: prop.product ? prop.product._id : "",
          classId: prop.class ? prop.class._id : "",
          customer: prop.customer ? prop.customer.name : "",
          customerId: prop.customer ? prop.customer._id : "",
        };
      }
    });
    const results = result.find((item) => item.id === storeItemId);
    var Str = results?.amount;
    var newStr = Str ? Str.replace(/,/g, "") : 0;
    var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
    var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
    const Qty = results?.quantity ? results?.quantity : results?.qty;

    const found = Catdata.find((obj) => {
      return obj.name === data?.data?.account?.name;
    });
    // const results = result.find((item) => item.id === id);
    await setEditItemAPICall({
      payload: [
        {
          itemType: "AccountBasedExpenseLineDetail",
          unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
          amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
          unitOfMeasure: results?.unit ? results?.unit : null,
          quantity: Number(Qty ? Qty : 1),
          discount: 0,
          description: results?.item,
          classId: results?.classId ? results?.classId : null,
          customerId: results?.customerId ? results?.customerId : null,
          askClient: results.askClients,
          itemType: "AccountBasedExpenseLineDetail",
          ItemId: storeItemId,
          InvoiceId: invoiceId ? invoiceId : documentsId,
          categoryId: found?.categoryId,
        },
      ],
    });
    CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewCustomerComplete", (data) => AddnewCustomerComplete(data));
  function AddnewCustomerComplete(data) {
    CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewClassComplete", (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewProductComplete", (data) => AddnewProductComplete(data));
  async function AddnewProductComplete(data) {
  const result = itemListResponse?.data?.data?.records.map((prop, key) => {
    {
      return {
        id: prop._id,
        item: prop.description,
        qty: prop.quantity,
        unit: prop.unitOfMeasure,
        price: prop.unitPrice,
        total: prop.total,
        sku: prop.sku,
        amount: prop?.amount,
        unitPrice: prop.unitPrice,
        results: prop.results,
        askClients: prop.askClient,
        category: prop.category ? prop.category.name : "",
        categoryId: prop.category ? prop.category._id : "",
        class: prop.class ? prop.class.name : "",
        product: prop.product ? prop.product.name : "",
        productId: prop.product ? prop.product._id : "",
        classId: prop.class ? prop.class._id : "",
        customer: prop.customer ? prop.customer.name : "",
        customerId: prop.customer ? prop.customer._id : "",
      };
    }
  });
  const results = result.find((item) => item.id === storeItemId);
  var Str = results?.amount;
  var newStr = Str ? Str.replace(/,/g, "") : 0;
  var Str1 = results?.unitPrice ? results?.unitPrice : results?.price;
  var newStr1 = Str1 ? Str1.replace(/,/g, "") : 0;
  const Qty = results?.quantity ? results?.quantity : results?.qty;
  setLoadingCircul(true);
  setEditItemAPICall({
    payload: [
      {
        itemType: "ItemBasedExpenseLineDetail",
        unitPrice: Number(newStr1 ? (newStr1 === 0 ? newStr : newStr1) : newStr),
        amount: Number(newStr ? (newStr === 0 ? newStr1 : newStr) : newStr1),
        discount: 0,
        sku: results?.sku,
        description: results?.item,
        unitOfMeasure: results?.unit ? results?.unit : null,
        productId: data?.product?._id,
        InvoiceId: invoiceId ? invoiceId : documentsId,
        classId: results?.classId ? results?.classId : null,
        customerId: results?.customerId ? results?.customerId : null,
        quantity: Number(Qty ? Qty : 1),
        askClient: results?.askClients,
        ItemId: results?.id,
      },
    ],
  })
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("closeBulkEditItemPopup", (data) => closeBulkEditItemPopup(data));
  function closeBulkEditItemPopup(data) {
    setLoading(true);
    setSelectedRows([]);
    DetailsUserAPICall({
      payload: [{ InvoiceId: `${invoice_id}`, businessId: `${businessId}` }],
    });
    GetItemListListAPICall({
      payload: [{ InvoiceId: `${invoice_id}` }],
    });
    IssueListAPICall({
      payload: [{ businessId: `${businessId}`, InvoiceId: `${invoice_id}` }],
    });
  }

  // commented this code as discounted value is not there and total amount are provided in api after discount
  useEffect(() => {
    let taxNow = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
    let subTotalNow =
      typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
    let discountNow =
      typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
    let shippingPriceNow =
      typeof shippingpriceStore == "string"
        ? shippingpriceStore.replaceAll(",", "")
        : shippingpriceStore;
    let orderTotalNow =
      typeof orderTotalStore == "string" ? orderTotalStore.replaceAll(",", "") : orderTotalStore;
    const finaldiscountNow = discountNow ? discountNow : 0;
    const MyTotal = Number.parseFloat(subTotalNow, 10) - Number.parseFloat(finaldiscountNow, 10);
    const finalshippingPriceNow = shippingPriceNow ? shippingPriceNow : 0;
    const finaltaxNow = taxNow ? taxNow : 0;

    if (onChanges === true) {
      const timeOutId = setTimeout(
        async () =>
          setTotalStore(
            MyTotal +
              Number.parseFloat(finaltaxNow, 10) +
              Number.parseFloat(finalshippingPriceNow, 10)
          ),
        setTotal(
          MyTotal +
            Number.parseFloat(finaltaxNow, 10) +
            Number.parseFloat(finalshippingPriceNow, 10)
        ),
        setOrderTotal(Number.parseFloat(orderTotalNow, 10) + Number.parseFloat(finaltaxNow, 10)),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [taxStore, subTotStore, discountStore, orderTotalStore, orderTotal, shippingpriceStore]);

  const getFormattedNumber = (n) => {
    let unFormatted = typeof n == "string" ? n.replaceAll(",", "") : n;
    const numberFormatter = Intl.NumberFormat(
      "en-US",

      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
    const formatted = numberFormatter.format(unFormatted);
    return formatted;
  };

  const isVenorButtonDisabled = () => {
    const trueCount = [vendorerror, novendorIssue].filter(Boolean).length;
    return trueCount !== 0;
  };

  const handleChangeSubTotalValue = (event) => {
    setSubTotal(event.value);
    setSubTotStore(event.value);
    SetonChanges(true);
    const val = event.value;

    if (typingSubTimeout) {
      clearTimeout(typingSubTimeout);
    }

    setTypingSubTimeout(
      setTimeout(() => {
        // Call your API here
        if (!editable && priceMismatch) {
          const finalStore = typeof subTotStore == "string" ? val.replaceAll(",", "") : val;
          const finalTax = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
          const finalShippingPrice =
            typeof shippingpriceStore == "string"
              ? shippingpriceStore.replaceAll(",", "")
              : shippingpriceStore;
          const finaldiscount =
            typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
          const finalTotal =
            typeof TotalStore == "string" ? TotalStore.replaceAll(",", "") : TotalStore;

          setOnlyUpdateAPICall({
            payload: [
              {
                subTotal: Number(finalStore),
                tax: Number(finalTax),
                shippingPrice: Number(finalShippingPrice),
                discount: Number(finaldiscount),
                total: Number(finalTotal),
                businessId: `${businessId}`,
                documnetId: `${invoice_id}`,
              },
            ],
          });
        }
      }, 3000)
    ); // 500ms delay before triggering API call after typing stops
  };

  const handleChangeTotalValue = (event) => {
    setTotal(event.value);
    setTotalStore(event.value);
    SetonChanges(true);
    const val = event.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        // Call your API here
        if (!editable && totalMismatch) {
          const finalStore =
            typeof subTotStore == "string" ? subTotStore.replaceAll(",", "") : subTotStore;
          const finalTax = typeof taxStore == "string" ? taxStore.replaceAll(",", "") : taxStore;
          const finalShippingPrice =
            typeof shippingpriceStore == "string"
              ? shippingpriceStore.replaceAll(",", "")
              : shippingpriceStore;
          const finaldiscount =
            typeof discountStore == "string" ? discountStore.replaceAll(",", "") : discountStore;
          const finalTotal = typeof TotalStore == "string" ? val.replaceAll(",", "") : val;
          setOnlyUpdateAPICall({
            payload: [
              {
                subTotal: Number(finalStore),
                tax: Number(finalTax),
                shippingPrice: Number(finalShippingPrice),
                discount: Number(finaldiscount),
                total: Number(finalTotal),
                businessId: `${businessId}`,
                documnetId: `${invoice_id}`,
              },
            ],
          });
        }
      }, 3000)
    ); // 500ms delay before triggering API call after typing stops
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleBlurDate = (event) => {
    setTransactionDate(event?.target?.value);
    setTransactionDateObj(event?.target?.value);
    if (!editable && invoiceDateMismatch && event?.target?.value != transactionDateForMatch)
      setAPICall({
        payload: [
          {
            transactionDate: `${event?.target?.value}`,
            businessId: `${businessId}`,
            documnetId: `${invoice_id}`,
          },
        ],
      });
  };

  const handleChangeBusiness = (event, newValue) => {
    if (newValue === "+ Add Vendor") {
      setVendorSelect(vendorSelect);
    } else {
      setVendorSelect1(newValue);
    }
    setAddNewVendor(false);
    setVendorname2("");
    if (newValue == "+ Add Vendor") {
      setOpenEditVendor(true);
    } else {
      if (newValue) {
        setVendorerrormsg("");
        setVendorerror(false);
      } else {
        setVendorerrormsg("Vendor name required");
        setVendorerror(true);
      }
      const found = vendorList.find((obj) => {
        return obj.name === newValue;
      });
      const Checkselected = event?.target?.outerText ? event?.target?.outerText.length : 0;
      if (found && found._id) {
        setShowselectedVendorId(found._id);
        if (!editable && isVenorButtonDisabled() && Checkselected != 0) {
          setAPICall({
            payload: [
              {
                vendorId: `${addNewVendorId ? addNewVendorId : found._id}`,
                businessId: `${businessId}`,
                documnetId: `${invoice_id}`,
              },
            ],
          });
          eventBus.$emit("refreshApiInvoiceList", event);
        }
      } else {
        setShowselectedVendorId("");
      }
    }
  };

  useEffect(() => {
    setStyles(
      value === 2 || value === 3
        ? { display: "grid", overflowY: "scroll", overflowX: "scroll", height: "80%" }
        : { height: "80%", overflow: "auto" }
    );
  }, [value]);

  const AsignNewClientMsg = () => {
    return (
      <MDBox sx={{ ml: 4, mb: -1 }}>
        <MDTypography>No email has been associated with this client account.</MDTypography>
        <MDTypography display="flex" justifyContent="center">
          Click here to add and email for this client
        </MDTypography>
      </MDBox>
    );
  };

  useEffect(() => {
    if (createObjectURL) {
      setExtention(createObjectURL.split(".").pop());
    }
  }, [createObjectURL]);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const QboSyncMsg = () => {
    return (
      <MDBox sx={{ ml: 1, mb: -1 }}>
        <MDTypography>We are not allowed to update once they have been synced</MDTypography>
        <MDTypography sx={{ display: "flex", justifyContent: "center" }}>
          to quickbooks.
        </MDTypography>
      </MDBox>
    );
  };
  useEffect(() => { !editable
      ? setDottedStyle({
          border: "none", // Remove all borders
          borderRadius: 0, // Optionally remove border radius
          background: "none", // Optionally remove background
          color: "inherit", // Optionally inherit text color
          width: "100%", // Optionally set width
          borderBottom: darkMode ? "2px solid #ffffff" : `dotted 0.8px`,
          marginTop: "16px",
          appearance: "none",
          borderTop: "0px",
          borderLeft: "0px",
          borderRight: "0px",
          color: darkMode ? "#ffffff" : "black",
          fontSize: "1rem",
          width: "100%",
          backgroundColor: darkMode ? "#202940" : "#ffffff",
          boxShadow: "0px",
        })
      : setDottedStyle({
          border: "none", // Remove all borders
          borderRadius: 0, // Optionally remove border radius
          background: "none", // Optionally remove background
          color: "inherit", // Optionally inherit text color
          width: "100%", // Optionally set width
          borderBottom: darkMode ? "2px solid #ffffff" : "1px solid #949494",
          marginTop: "16px",
          borderTop: "0px",
          borderLeft: "0px",
          borderRight: "0px",
          color: darkMode ? "#ffffff" : "black",
          fontSize: "1rem",
          backgroundColor: darkMode ? "#202940" : "#ffffff",
          boxShadow: "none",
          transition: "border-color 0.3s ease", // Smooth transition for border color change
          "&:hover": {
            borderBottomColor: darkMode ? "#ffffff" : "#000000", // Change bottom border color on hover
          },
        });
  }, [editable]);

  const hideMoveAlert = () => {
    setAnchorEl(null);
    setalertMoveInvoice(false);
  };

  useEffect(() => {
    if (
      issues?.documents?.count !== 0 ||
      issues?.items?.count !== 0 ||
      issues?.vendor?.count !== 0
    ) {
      setCheckIssue(true);
    } else {
      setCheckIssue(false);
    }
  }, [issues]);

  const tabRowsDataToReview = [
    {
      key: {
        AskClient: "Ask Client",
        ToReview: "To Review",
        Excluded: "Excluded",
      },
      Tab: "Classify",
      Permission: ["Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "AskClient",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        PendingApproval: "Pending Approval",
      },
      Tab: "ToReview",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        Approved: "Approved",
      },
      Tab: "PendingApproval",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Approved",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Excluded",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {},
      Tab: "AllDoc",
      Permission: [""],
    },
  ];

  const tabRowsData = [
    {
      key: {
        AskClient: "Ask Client",
        Excluded: "Excluded",
      },
      Tab: "Classify",
      Permission: ["Admin", "Manager", "User"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "AskClient",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        PendingApproval: "Pending Approval",
      },
      Tab: "ToReview",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
        Approved: "Approved",
      },
      Tab: "PendingApproval",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Approved",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {
        Classify: "Classify",
      },
      Tab: "Excluded",
      Permission: ["Admin", "Manager"],
    },
    {
      key: {},
      Tab: "AllDoc",
      Permission: [""],
    },
  ];
  const finaltabRowsData = checkIssue ? tabRowsData : tabRowsDataToReview;
  const moveSelect = finaltabRowsData.find((obj) => {
    return obj.Tab === documentStatus ? obj.Tab === documentStatus : obj.Tab === "AllDoc";
  });

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  const onButtonItemClick = async (move) => {
    const selectMoveTab = move.replace(/ /g, "");
    if (ownerAssigned === false && selectMoveTab === "AskClient") {
      setAssignNewClient(true);
    } else {
      setTabsMove(selectMoveTab);
      setalertMoveInvoice(true);
    }
  };

  const onMenuItemClick = async (event, key) => {
    if (selectTab === "Approved") {
      setShowApprovedModel(true);
    } else {
      setAnchor(null);
      const selectMoveTab = event.target.textContent.replace(/ /g, "");
      if (ownerAssigned === false && selectMoveTab === "AskClient") {
        setAssignNewClient(true);
      } else {
        setTabsMove(selectMoveTab);
        setalertMoveInvoice(true);
      }
    }
  };

  const onMenuExcludeClick = async (event, key) => {
    if (selectTab === "Approved") {
      setShowApprovedModel(true);
    } else {
      setAnchor(null);
      const selectMoveTab = "Excluded";
      if (ownerAssigned === false && selectMoveTab === "AskClient") {
        setAssignNewClient(true);
      } else {
        setTabsMove(selectMoveTab);
        setalertMoveInvoice(true);
      }
    }
  };

  const moveOptionName = {
    Classify: "Classify",
    AskClient: "Ask Client",
    ToReview: "To Review",
    PendingApproval: "Pending Approval",
    Approved: "Approved",
    Excluded: "Excluded",
    Synced: "Quickbook",
  };

  useEffect(() => {
    Object.keys(moveOptionName).map(function (k) {
      k === documentStatus ? setFinalSelect(moveOptionName[k]) : "";
    });
  }, [documentStatus]);

  const handleClickImgDownload = () => {
    window.open(`${createObjectURL}`, "_blank");
  };

  async function createFile() {
    if (imageUrl && counts === 0) {
      setCounts(counts + 1);
      let response = await fetch(imageUrl);
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      const imageDataUrl = URL.createObjectURL(file);
      setImageUrl1(imageDataUrl);
    }
  }
  useEffect(() => {
    createFile();
  }, [imageUrl]);

  const rotateImage = () => {
    // Update the rotation angle by 90 degrees
    setRotationAngle((prevAngle) => prevAngle + 90);
  };

  const Controls = () => {
    const { zoomIn, zoomOut } = useControls();
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "23px",
          marginTop: "-23px",
          marginRight: "125px",
        }}
      >
        <a style={{ display: "flex", justifyContent: "center", marginLeft: "100px" }}>
          <img
            onClick={() => zoomOut()}
            style={{
              marginRight: "20px",
              cursor: "pointer",
              marginBottom: "-6px",
              filter: darkMode ? "brightness(0) invert(1)" : "",
            }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVR4nLXTTSvFQRTH8c9FKAsLS9lYWSgpG5dXwC1ZKTuLKwsbpWxEnp+SDStLKRsskYW8IW+Bpob+6c7/zs31q9Oc5kzfc+bMGf5RQzjFK57ieomRViCLeMEUKoX9MTxgLQdSww26EvEKzlAvg/TgHb1NknXEigdSB2axnlM2FrCSCm6hGv0+DDew/sJjXKdAhxiP/iiOG9h0jIdr3aZAy7HkHFWxlwoO4j4TdIWJsgMXmG8CmcRds0zdeMTSr2H81hw+cC5DYRg38IYjrGIXz9jECT6xL1OdsQ+12NzioB60CivTToSFz/1nhREIsO12wAJkph2gH30BrGgnHq5WuyEAAAAASUVORK5CYII="
            title="ZoomIn"
          />
        </a>
        <a style={{ display: "flex", justifyContent: "center" }}>
          <img
            onClick={() => zoomIn()}
            style={{
              marginRight: "20px",
              cursor: "pointer",
              marginBottom: "-6px",
              filter: darkMode ? "brightness(0) invert(1)" : "",
            }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nLXTTyuFQRTH8c9FKMrCUjZWFkrKxuUVcEtWyu4uyMJGKQsi//8lG1aWUjZYIgvxQrwEb4Gmhp6e7lzPzfWr05zzm/o2Z+YM/6h+HOMZD3E9x2AjkDk8YRyljD+MOywXgVRwhbbEfgknmK8H6cArOnP+Wq5uiSfuTYGmsFLDf6zhzWIxBdpAOeZdGIjxlsl7Mo9xmQLtYyTmQziM8Z7JJ+J+aOs6BVqIRy7SWhk7KVAfbguCLjCqjs4wk/O6c/UYbvyidtyjmhvGb03jA6cKKAzjKl5wgCVsxxbXcYRP7Cqo1ngPlXi52UHdaxRWT1sRFj73nxVGIMA2mwELkMlmgH70Bf8ZKsMW9hShAAAAAElFTkSuQmCC"
            title="ZoomIn"
          />
        </a>
      </div>
    );
  };
  const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";
  if (darkMode) {
    document.body.classList.add("dark-mode");
  } else {
    document.body.classList.remove("dark-mode");
  }

  const ApiCallBeforeClose = () => {
    // Update the rotation angle by 90 degrees
    reloadApiAfterClose()
    if(isFormDirty){
       setAPICall({
        payload: [
          {
            transactionDate: `${transactionDateObj}`,
            createdAt: `${uploaddate}`,
            documentType: `${documentTypeObj}`,
            docNumber: `${invoiceNumberObj}`,
            vendorId: `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
            subTotal: Number(finalStoreObj),
            tax: Number(finalTaxObj),
            shippingPrice: Number(finalShippingPriceObj),
            discount: Number(finaldiscountObj),
            total: Number(finalTotalObj),
            purchaseOrderNo: `${ponumberObj}`,
            passDuplicate:passDuplicate,
            businessId: `${businessId}`,
            documnetId: `${invoice_id}`,
          },
        ],
      });
    }
  };

  const [filterText, setFilterText] = useState({});
  const [filteredRows, setFilteredRows] = useState(itemList);
  const filterRefs = useRef({});

  useEffect(() => {
    const filtered = itemList.filter((row) =>
      Object.entries(filterText).every(([column, value]) =>
        String(row[column]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredRows(filtered);
  }, [filterText, itemList]);

  const handleFilterChange = (field, value) => {
    setFilterText((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const catColumns = (finalSelect === "Ask Client" ||
    finalSelect === "Classify" ||
    documentStatus === "AskClient" ||
    documentStatus === "Classify"
      ? showClasses
        ? cetagorycolumns
        : cetagorycolumnsWithoutClass
      : showClasses
      ? cetagorycolumnsWithotAction
      : cetagorycolumnsWithoutClassAction).map((col) => ({
    ...col,
    renderHeader: () => (
      <div style={{display:'grid' ,marginTop:'10px'}}>
        {/* <div style={darkMode ? {display:'flex' , justifyContent:'center' , color:'#ffffff'}:{display:'flex' , justifyContent:'center'}}>{col.headerName}</div> */}
        {
        col.field == 'askClients' ? 
        <input style={{marginTop:'15px' ,marginBottom:'9px'}} type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
        :
        <ColumnFilter
          column={col}
          filterText={filterText[col.field] || ''}
          onFilterChange={handleFilterChange}
          ref={(el) => (filterRefs.current[col.field] = el)}
        />}
      </div>
    ),
  }));
 
  const productColumns = (finalSelect == "Ask Client" || finalSelect == "Classify" || documentStatus == "AskClient" ||
    documentStatus == "Classify" ? showClasses ? columns : columnsWitoutClass : showClasses ? columnsWithoutAction : columnsWitoutClassAction).map((col) => ({ ...col,
    renderHeader: () => (
      <div style={{display:'grid' ,marginTop:'10px'}}>
        {/* <div style={darkMode ? {display:'flex' , justifyContent:'center' , color:'#ffffff'}:{display:'flex' , justifyContent:'center'}}>{col.headerName}</div> */}
        {col.field == 'askClients' ? 
        <input style={{marginTop:'15px' ,marginBottom:'9px'}} type="checkbox" onChange={handleHeaderCheckboxChange} checked={currentPageRowSize} />
        :
        <ColumnFilter
          column={col}
          filterText={filterText[col.field] || ''}
          onFilterChange={handleFilterChange}
          ref={(el) => (filterRefs.current[col.field] = el)}
        />}
      </div>
    ),
  }));

  useEffect(() => {
    const activeElement = document.activeElement;
    if (activeElement && activeElement.tagName === 'INPUT') {
      const fieldName = activeElement.getAttribute('data-field');
      if (fieldName && filterRefs.current[fieldName]) {
        filterRefs.current[fieldName].focus();
      }
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const textareas = document.querySelectorAll("textarea");

      textareas.forEach((textarea) => {
        textarea.style.setProperty("--placeholder-color", "rgba(255, 255, 255, 0.7)");
      });
    }, 1000); 

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if(darkMode){
      const textarea = document.querySelector('textarea[placeholder="Leave Your Comment Here"]');
      if (textarea) {
        textarea.style.setProperty("--placeholder-color", "rgba(255, 255, 255, 0.7)");
        const style = document.createElement("style");
        style.innerHTML = `
          textarea::placeholder {
            color: var(--placeholder-color);
          }
        `;
        document.head.appendChild(style);
      }
    }
    }, 1000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!showDetails ? (
        <>
          <MDBox mt={"-10%"} display="flex" justifyContent="center">
            {<LoaderDailog />}
          </MDBox>
        </>
      ) : (
        <>
          <MDBox mb={3} display={"flex"}>
            <Typography variant="h6" className={classes.title}>
              <MDBox display={"flex"}>
                <MDTypography fontSize={"1rem"}>Document in</MDTypography>
                <MDTypography sx={{ ml: 0.5 }} fontSize={"1rem"} fontWeight={"bold"}>
                  {finalSelect ? finalSelect : documentStatus}
                </MDTypography>
                <MDBox>
                  {ocrStatus1 !== "COMPLETED" || documentSource === "QBO" ? ("") : (
                    <Tooltip title={action.Movetooltip}>
                      <IconButton
                        onClick={openMenu}
                        sx={{ mt: -0.3 }}
                        aria-controls={openMenu ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                      >
                        <DriveFileMoveOutlinedIcon
                          sx={{
                            color: darkMode ? "#ffffff" : "#000000",
                            height: `0.7em`,
                            width: `1em`,
                            mb: 1,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} sx={darkMode &&{
                      "& .MuiPaper-root": {
                        backgroundColor: "#1a2035 !important" , // Change this to the desired background color
                      },
                      "& .MuiMenuItem-root": {
                        color: "#ffffff", // Change text color of the MenuItem
                        "&:hover": {
                          backgroundColor: "#38405585", // Change background color on hover
                          color: "#ffffff", // Ensure the text color remains white on hover
                        },
                      },
                    }}  keepMounted>
                    {Object.keys(moveSelect.key).map((key, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => onMenuItemClick(event, key)}
                        // selected={index === selected}
                      >
                        {moveSelect.key[key]}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              </MDBox>
            </Typography>
            <MDBox>
              <Icon
                sx={
                  darkMode
                    ? { cursor: "pointer", mr: 2, color: "#ffffff" }
                    : { cursor: "pointer", mr: 2 }
                }
                fontSize="medium"
                onClick={ApiCallBeforeClose}
              >
                close
              </Icon>
            </MDBox>
          </MDBox>
          <MDBox
            mb={3}
            style={{ height: "95%", overflow: "auto", display: "block", marginBottom: 0 }}
          >
            <Grid container style={{ height: "100%", overflow: "auto" }}>
              {showLoaderFirInvoice ? (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={4.5}
                  ml={-2}
                  style={{ height: "98%", overflow: "auto", border: "2px solid #000000" }}
                >
                  <CircularProgress
                    size={50}
                    sx={{
                      color: green[500],
                      marginTop: "50%",
                      marginLeft: "45%",
                      zIndex: 999,
                    }}
                  />
                </Grid>
              ) : createObjectURL ? (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={4.5}
                  ml={-2}
                  style={{
                    height: "98%",
                    overflow: "auto",
                    border: "2px solid #000000",
                    backgroundColor: darkMode ? "#202940" : "#ffffff",
                  }}
                >
                  {extention == "pdf" ? (
  <div
    className="rpv-core__viewer"
    style={{
      display: "flex",
      flexDirection: "column",
      height: "97%",
    }}
  >
    <div
      style={{
        alignItems: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        padding: "0.25rem",
        backgroundColor: darkMode ? "#202940" : "#eeeeee",
        justifyContent: "space-between", // Space the groups evenly
      }}
    >
      <Toolbar>
        {(props) => {
          const { CurrentPageInput, NumberOfPages, ZoomIn, ZoomOut, Download } = props;
          return (
            <>
              {/* First Group: Current Page and Number of Pages */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    padding: "0px 5px",
                    width: "4rem",
                    marginLeft: "10px",
                    color: darkMode ? "#ffffff" : "",
                  }}
                >
                  <CurrentPageInput />
                </div>
                <div
                  style={{
                    padding: "0px 2px",
                    color: darkMode ? "#ffffff" : "",
                  }}
                >
                  of <NumberOfPages />
                </div>
              </div>

              {/* Second Group: Zoom Controls */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0px 2px", marginRight: "10px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>
              </div>

              {/* Third Group: Download and Fullscreen */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0px 2px", display: "flex" }}>
                  <Download />
                </div>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "end",
                    paddingBottom: "2px",
                    marginLeft: "8px",
                  }}
                >
                  <img
                    style={{
                      marginRight: "20px",
                      cursor: "pointer",
                      filter: darkMode ? "brightness(0) invert(1)" : "",
                    }}
                    width="19"
                    height="19"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                      alt="fullscreen"
                      onClick={() => window.open(`${createObjectURL}`, "_blank")}
                      title="Full Screen"
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Toolbar>
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        {extention == "pdf" && (
          <Worker workerUrl={workerUrl}>
            <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]} />
          </Worker>
        )}
      </div>
    </div>
  ) : (
                    <div className="rpv-core__viewer" style={{ display: "flex", flexDirection: "column", height: "97%" }} >
                      <div style={{ alignItems: "center", backgroundColor: "transparent", display: "flex", justifyContent: "center" }} >
                        <CurrentPageLabel>
                          {(props) => (<span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span> )}
                        </CurrentPageLabel>
                      </div>
                      <div style={{alignItems: "center", backgroundColor: darkMode ? "#1a2035" : "#eeeeee", borderBottom: "1px solid rgba(0, 0, 0, 0.1)", display: "flex", justifyContent: "end", padding: "0.25rem" }} >
                        <img
                          style={{ marginRight: "20px", cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }}
                          width="18" height="18"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVR4nN3Vv0scQRjG8Y8edyixsLQVLUSL/AViivR6ooelnahobMXGTpK/I4l/gL2VvXgK/oqNnViImkC6CwuvMMW5d7d6oHlgi5155vnOvLy7w/+uCmr4iTP8xh9cxdgCyol/u5PwWfxCo8VzjWqEZ+8t1YtvScAR1jGOD+jHGFZRbwJsqa9h/IulAOZtZr8TQDUJn2pjM09laQtQSWqe7fzVVUtqnleWwtoNwJou6TwAWbd0RY8BGHjLgMHIuOtWiUbxgMNmkz8C8MXLVMJws4n5ANQLtmk5Wr2UZ7gMyHIBwFas3cszzSS/ik8dAiZx0866nQSyknfkOPUmPiZd1FK9CaSBY2xgIlo4CxkJ72J4stL26VDTuHjmkrlFT9wNB/isoMqYw3ecxsd4jxMMFQ19X/oHPMJfdYMk80cAAAAASUVORK5CYII="
                          onClick={rotateImage} alt="rotate" title="Rotate Image"
                        />
                        <a href={imageUrl1} download={"downloaded-image.jpeg"}>
                          <img
                            style={{ marginRight: "20px", cursor: "pointer", marginBottom: "-6px", filter: darkMode ? "brightness(0) invert(1)" : "" }}
                            width="18"
                            height="18"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC"
                            title="Download"
                          />
                        </a>
                        <img
                          style={{ marginRight: "20px", cursor: "pointer", filter: darkMode ? "brightness(0) invert(1)" : "" }}
                          width="19"
                          height="19"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC"
                          alt="fullscreen"
                          onClick={handleClickImgDownload}
                          title="Full Screen"
                        />
                      </div>
                      <div>
                        <TransformWrapper
                          defaultScale={1}
                          defaultPositionX={0}
                          defaultPositionY={0}
                        >
                          <Controls />
                          <TransformComponent
                            contentStyle={{
                              width: "100% !important",
                              height: "110%",
                              marginBottom: "100px",
                              marginLeft: "15px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={createObjectURL}
                              alt="test"
                              width="95%"
                              height="98%"
                              style={{ transform: `rotate(${rotationAngle}deg)` }}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} lg={4.5} ml={-2}
                  style={{ height: "98%", overflow: "auto", border: "2px solid #000000", backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      border: "0.5px solid gray",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50%",
                        color: darkMode ? "white" : "",
                      }}
                    >
                      <h2>
                        {documentSource === "QBO"
                          ? "No image available from Quickbooks"
                          : "No image available from Paynthr"}
                      </h2>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={8} lg={7.5} style={{ height: "100%", overflow: "auto" }}>
                <Card
                  style={{
                    height: "100%",
                    overflow: "auto",
                    backgroundColor: darkMode ? "#202940" : "#ffffff",
                  }}
                >
                  <Tabs
                    sx={darkMode && { 
                      padding : '0px !important',
                      backgroundColor: "#1a2035 !important" ,
                      '& .MuiTabs-indicator': {
                          backgroundColor: 'transparent',
                          boxShadow :'none',
                        },
                      '& .Mui-selected': {
                          borderRadius : '0px',
                        }
                      }}
                    className={darkMode ? classes.tabs : ""}
                    value={value}
                    onChange={handleChange}
                    aria-label="tabs"
                  >
                    <Tab
                      className={darkMode ? classes.tabs : ""}
                      disabled={loading || loadingCircul}
                      label={
                        DocumentIssueResponse?.data?.data?.records ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={DocumentIssueResponse?.data?.data?.totalRecords}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent",
                              },
                            }}
                          >
                            Details &nbsp; &nbsp; &nbsp;
                          </MDBadge>
                        ) : (
                          "Details"
                        )
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      disabled={loading || loadingCircul}
                      label={
                        ItemIssueResponse?.data?.data?.records ? (
                          <MDBadge
                            overlap="circular"
                            badgeContent={ItemIssueResponse?.data?.data?.totalRecords}
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "#ef5350",
                                background: "transparent ",
                              },
                            }}
                          >
                            Items &nbsp; &nbsp; &nbsp;
                          </MDBadge>
                        ) : (
                          "Items"
                        )
                      }
                      {...a11yProps(1)}
                    />
                    <Tab disabled={loading || loadingCircul} label="Summary" {...a11yProps(2)} />
                    <Tab disabled={loading || loadingCircul} label="Comments" {...a11yProps(3)} />
                    <Tab disabled={loading || loadingCircul} label="Audit Log" {...a11yProps(4)} />
                    <div>
                      {ocrStatus1 == "COMPLETED" ? (
                        <MDButton
                          id="fade-button"
                          aria-controls={open ? "fade-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          color="light"
                          sx={{
                            background : darkMode ? '#202940e3' : '',
                            boxShadow : 'none',
                            color: darkMode ? '#ffffff' : '',
                            mt: 0.2,
                            mb: screenSize < 1440 ? 0.7 : 0.3,
                            '&:hover': {
                              background: darkMode ? '#202940e3' : '',
                              boxShadow : darkMode ? 'none' : ''
                            },
                            '&:focus:not(:hover)': {
                              background: darkMode ? '#202940e3' : '',
                              boxShadow : darkMode ? 'none' : ''
                            },
                          }}
                        >
                          More
                          <Icon sx={{ ml: 0.8 }}>expand_more</Icon>
                        </MDButton>
                      ) : ("")}
                      <Menu
                        id="fade-menu"
                        MenuListProps={{ "aria-labelledby": "fade-button"}}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        sx={darkMode &&{
                          "& .MuiPaper-root": {
                            backgroundColor: "#1a2035 !important" , // Change this to the desired background color
                          },
                          "& .MuiMenuItem-root": {
                            color: "#ffffff", // Change text color of the MenuItem
                            "&:hover": {
                              backgroundColor: "#38405585", // Change background color on hover
                              color: "#ffffff", // Ensure the text color remains white on hover
                            },
                          },
                        }} 
                      >
                        <MenuItem onClick={(event) => onMenuExcludeClick(event)}>
                          Move to Exclude
                        </MenuItem>
                        <MenuItem style={{ color: "red" }} onClick={handleOpenIssue}>
                          Report Issue
                        </MenuItem>
                      </Menu>
                    </div>
                  </Tabs>
                  {/* <Divider /> */}
                  <CardHeader
                    subheader={
                      <MDBox>
                        <Grid
                          item
                          sx={12}
                          sm={12}
                          md={12}
                          style={{ display: "flex", marginLeft: "70px", fontSize: "1rem" }}
                        >
                          <Grid item sx={12} xs={4}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              Subtotal
                            </span>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              {currencySymbol}
                              {actualSubtotal}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              Discount
                            </span>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              {currencySymbol}
                              {discountStatic
                                ? getFormattedNumber(discountStatic)
                                : getFormattedNumber(discountStatic)}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              Shipping Price
                            </span>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              {currencySymbol}
                              {shippingpriceStatic
                                ? getFormattedNumber(shippingpriceStatic)
                                : getFormattedNumber(shippingpriceStatic)}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              Taxes & Fees
                            </span>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              {currencySymbol}
                              {taxStore ? getFormattedNumber(taxStore) : getFormattedNumber(tax)}
                            </span>
                          </Grid>
                          <Grid item sx={12} xs={4}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              Total
                            </span>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: darkMode ? "#ffffff" : "",
                              }}
                            >
                              {currencySymbol}
                              {actualTotal}
                            </span>
                          </Grid>
                        </Grid>
                        {ocrStatus ? (
                          <MDBox sx={{ mb: -2, mt: 1, display: "flex", justifyContent: "center" }}>
                            <MDTypography fontSize={"1rem"} color={"warning"}>
                              Modifications cannot be made during an ongoing document upload
                              process.
                            </MDTypography>
                          </MDBox>
                        ) : ("")}
                      </MDBox>
                    }
                  />
                  <Divider />
                  <CardContent style={styles}>
                    <TabPanel value={value} index={0}>
                      {showDetailsForm && (
                        <MDBox my={2}>
                          <Box>
                            <Grid item xs={12} sm={12} md={12}>
                              <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={InvoiceEditSchema}
                                onSubmit={handleSubmit}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, dirty }) => {
                                  if (dirty !== isFormDirty) {
                                    setIsFormDirty(dirty);
                                  }
                                  useEffect(() => {
                                    if(values?.documenttype){
                                      setDocumentTypeObj(values?.documenttype);
                                    }
                                    if(values?.invoicenumber){
                                      setInvoiceNumberObj(values?.invoicenumber);
                                    }
                                    if(values?.ponumber){
                                      setPonumberObj(values?.ponumber);
                                    }
                                  }, [values]);

                                  return (
                                    <Form autoComplete="off">
                                      {/* <Card sx={{ height: "100%" }}> */}
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <div
                                            onClick={() =>
                                              isVenorButtonDisabled()
                                                ? setVendorEditable(false)
                                                : setVendorEditable(true)
                                            }
                                            onBlur={() => setVendorEditable(true)}
                                          >
                                            <Autocomplete
                                              disableClearable
                                              open={openList}
                                              onOpen={() => setOpenList(true)}
                                              onClose={() => setOpenList(false)}
                                              onFocus={() => setVendorEditable(true)}
                                              sx={
                                                darkMode
                                                  ? {
                                                      "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#ffffff",
                                                        borderBottom: "2px solid #ffffff",
                                                      },
                                                      "& .MuiInput-root .MuiInput-input": {
                                                        WebkitTextFillColor: "white",
                                                        borderBottom: "2px solid #ffffff",
                                                      },
                                                      "& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
                                                        paddingRight: "0px !important",
                                                      },
                                                      "& .MuiFormLabel-root-MuiInputLabel-root": {
                                                        color: "white",
                                                      },
                                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                                        {
                                                          color: "white !important",
                                                        },
                                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                                        {
                                                          color: "white !important",
                                                        },
                                                    }
                                                  : {
                                                      "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "black",
                                                      },
                                                    }
                                              }
                                              noOptionsText={
                                                <MDTypography
                                                  sx={{color:'#000000', fontSize: "0.775rem", cursor: "pointer" }}
                                                  color="info"
                                                  onClick={handleAddNewVendor}
                                                >
                                                  {vendorname + " " + `(New Vendor)`}
                                                </MDTypography>
                                              }
                                              disableCloseOnSelect={false}
                                              // classes={{ option: classes.option }}
                                              forcePopupIcon={editable ? true : false}
                                              options={
                                                finalSelect == "Ask Client" ||
                                                finalSelect == "Classify" ||
                                                documentStatus == "AskClient" ||
                                                documentStatus == "Classify"
                                                  ? vendorList
                                                  : vendorList
                                              }
                                              getOptionLabel={(option) => option.name}
                                              value={{
                                                name: vendorname2 ? vendorname2 : vendorname,
                                              }}
                                              onInputChange={(event, newInputValue) => {
                                                setVendorname(newInputValue),
                                                  handleChangeBusiness(event, newInputValue);
                                              }}
                                              id="controllable-states-demo"
                                              disabled={
                                                editable ? !editable : !isVenorButtonDisabled()
                                              }
                                              renderInput={(params) =>
                                                (duplicateIssue || vendorerror || novendorIssue) &&
                                                vendorEditable &&
                                                !editable ? (
                                                  <TextField
                                                    {...params}
                                                    label="Vendor Name *"
                                                    variant="standard"
                                                    helperText={vendorerrormsg}
                                                    error={vendorerror || novendorIssue}
                                                    InputLabelProps={
                                                      darkMode
                                                        ? { style: { color: "#ffffff" } }
                                                        : {}
                                                    }
                                                    onClick={() =>
                                                      isVenorButtonDisabled()
                                                        ? setVendorEditable(false)
                                                        : setVendorEditable(true)
                                                    }
                                                    InputProps={{
                                                      endAdornment: (
                                                        <Tooltip
                                                          title={novendorIssueMsg}
                                                          placement="top"
                                                        >
                                                          <InputAdornment position="end" sx={{marginLeft:-3.4}}>
                                                            <ErrorOutlineIcon
                                                              sx={{
                                                                fontSize: "1rem !important",
                                                                mr: "14px",
                                                                color: "red",
                                                              }}
                                                            />
                                                          </InputAdornment>
                                                        </Tooltip>
                                                      ),
                                                    }}
                                                  />
                                                ) : (
                                                  <TextField
                                                    {...params}
                                                    label="Vendor Name *"
                                                    variant="standard"
                                                    helperText={vendorerrormsg}
                                                    error={vendorerror || novendorIssue}
                                                    InputLabelProps={
                                                      darkMode
                                                        ? { style: { color: "#ffffff" } }
                                                        : {}
                                                    }
                                                  />
                                                )
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        {editable ? (
                                          <Grid item xs={12} sm={6}>
                                            <FormControl
                                              fullWidth
                                              variant="standard"
                                              sx={{
                                                mt: 1,
                                                mb: 1,
                                                border: "none",
                                              }}
                                            >
                                              <InputLabel
                                                style={{
                                                  fontSize: "0.8rem",
                                                  lineHeight: 1,
                                                  color: darkMode ? "#ffffff" : "#000000",
                                                }}
                                                sx={{ mt: -3 }}
                                              >
                                                Document Type
                                              </InputLabel>
                                              <Field
                                                as="select"
                                                name="documenttype"
                                                variant="standard"
                                                disabled={!editable}
                                                style={dottedStyle}
                                              >
                                                <option value="">Select Type</option>
                                                {Object.keys(DocumnetType).map((key) => (
                                                  <option value={key}>{DocumnetType[key]}</option>
                                                ))}
                                              </Field>
                                              <MDBox mt={0.75}>
                                                <MDTypography
                                                  component="div"
                                                  variant="caption"
                                                  color="error"
                                                  fontWeight="regular"
                                                >
                                                  <ErrorMessage error={true} name="documenttype" />
                                                </MDTypography>
                                              </MDBox>
                                            </FormControl>
                                          </Grid>
                                        ) : (
                                          <Grid item xs={12} sm={6} sx={{ mt: -1.5 }}>
                                            <FormField
                                              type="text"
                                              label="Document Type"
                                              name="documenttype"
                                              disabled={!editable}
                                              editable={!editable}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                      <Grid container spacing={3}>
                                        {duplicateIssue && !editable ? (
                                          <Grid item xs={12} sm={6} sx={{ mt: 1.7 }}>
                                            <TextField
                                              label="Invoice Number"
                                              variant="standard"
                                              helperText={vendorerrormsg}
                                              disabled
                                              name="invoicenumber"
                                              defaultValue={invoicenumber}
                                              InputLabelProps={
                                                darkMode ? { style: { color: "#ffffff" } } : {}
                                              }
                                              classes={{ root: classes.root }} // Apply custom styles
                                              style={
                                                darkMode
                                                  ? {
                                                      marginBottom: "-2px",
                                                      borderBottom: "2px solid white",
                                                      "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "white",
                                                      },
                                                    }
                                                  : {
                                                      marginBottom: "-2px",
                                                      borderBottom: "1px solid black",
                                                      "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                      },
                                                    }
                                              }
                                              fullWidth
                                              InputProps={{
                                                endAdornment: (
                                                  <Tooltip title={duplicateMsg} placement="top">
                                                    <InputAdornment position="end">
                                                      <ErrorOutlineIcon
                                                        sx={{
                                                          fontSize: "1rem !important",
                                                          mr: "14px",
                                                          color: "red",
                                                        }}
                                                      />
                                                    </InputAdornment>
                                                  </Tooltip>
                                                ),
                                              }}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                            <FormField
                                              type="text"
                                              label="Invoice Number"
                                              name="invoicenumber"
                                              disabled={!editable}
                                              editable={!editable}
                                            />
                                          </Grid>
                                        )}
                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                          <FormField
                                            type="text"
                                            label="PO Number"
                                            name="ponumber"
                                            disabled={!editable}
                                            editable={!editable}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={3}>
                                        {documentSource === "QBO" ? (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{ mt: 1 }}
                                            className={!editable ? "bg-salmon" : ""}
                                          >
                                            <FormFieldDate
                                              type="date"
                                              label="Transaction Date"
                                              name="transactionDate"
                                              disabled={!editable}
                                              editable={!editable}
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid item xs={12} sm={6} sx={{ mt: 1 }} className={!invoiceDateMismatch && !editable ? "bg-salmon" : ""} >
                                            <FormFieldDate
                                              classes={{ root: classes.InputRoot }}
                                              type="date"
                                              label="Transaction Date"
                                              name="invoicedate"
                                              value={transactionDateObj}
                                              dateMismatch={!transactionDateObj && invoiceDateMismatch}
                                              noInvoiceDateMsg={"invoiceDateMismatch"}
                                              autoFocus={invoiceDateMismatch}
                                              editable={!editable}
                                              onChange={handleBlurDate}
                                            />
                                            {!transactionDateObj &&
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              Transaction date is required
                                            </MDTypography>}
                                          </Grid>
                                        )}
                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }} className={"bg-salmon"} >
                                          <FormFieldDate
                                            type="date"
                                            label="Upload Date"
                                            name="uploaddate"
                                            // error={true}
                                            disabled={true}
                                            editable={!editable}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                          <CurrencyFieldText
                                            label="Subtotal"
                                            name="subtotal"
                                            ErrorToolTip={priceMismatchMsg}
                                            error={priceMismatch}
                                            priceMismatch={priceMismatch}
                                            disabled={!priceMismatch && !editable}
                                            value={subtotal}
                                            onValueChange={(event) => {
                                              handleChangeSubTotalValue(event);
                                            }}
                                          />
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              {subtotalErrorMsg}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                          <CurrencyFieldText
                                            label="Discount"
                                            name="discount"
                                            disabled={!editable}
                                            value={discount}
                                            onValueChange={(event) => {
                                              setDiscount(event.value);
                                              setDiscountStore(event.value);
                                              SetonChanges(true);
                                            }}
                                          />
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              {taxErrorMsg}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={2.5} sx={{ mt: 1 }}>
                                          <CurrencyFieldText
                                            label="Shipping Price"
                                            name="shippingprice"
                                            disabled={!editable}
                                            value={shippingprice}
                                            onValueChange={(event) => {
                                              setShippingprice(event.value);
                                              setShippingpriceStore(event.value);
                                              SetonChanges(true);
                                            }}
                                          />
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              {taxErrorMsg}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                          <CurrencyFieldText
                                            label="Taxes & Fees"
                                            name="tax"
                                            disabled={!editable}
                                            value={tax}
                                            onValueChange={(event) => {
                                              setTax(event.value);
                                              setTaxStore(event.value);
                                              SetonChanges(true);
                                            }}
                                          />
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              {taxErrorMsg}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={2.3} sx={{ mt: 1 }}>
                                          <CurrencyFieldText
                                            label="Total"
                                            name="total"
                                            ErrorToolTip={totalMismatchMsg}
                                            error={totalMismatch}
                                            priceMismatch={totalMismatch}
                                            disabled={!editable && !totalMismatch}
                                            value={total}
                                            onValueChange={(event) => {
                                              handleChangeTotalValue(event);
                                            }}
                                          />
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              {totalErrorMsg}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                      {finalSelect == "Ask Client" || finalSelect == "Classify" || documentStatus == "AskClient" || documentStatus == "Classify" ? (
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} >
                                          {documentSource === "Paynthr" && isSynced === false ? (
                                            <MDBox mt={{ xs: 2, sm: 3 }}>
                                              {!editable &&
                                                canAccess((actions = "update"),(resource = "documents")) && (
                                                  <MDBox
                                                    mt={2}
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="end"
                                                  >
                                                    {selectTab === "AskClient" && (
                                                      <MDButton
                                                        variant="gradient"
                                                        color="info"
                                                        disabled={ocrStatus}
                                                        onClick={() =>
                                                          onButtonItemClick("Classify")
                                                        }
                                                      >
                                                        Move to Classify
                                                      </MDButton>
                                                    )}
                                                    {selectTab === "Classify" && (
                                                    totalIssue !== 0 ?
                                                      <Tooltip
                                                        title={`Cannot move to Review until ${totalIssue} issues are resolved`}
                                                        placement="top"
                                                      >
                                                        <MDBox>
                                                          <MDButton
                                                            sx={{ marginLeft: "8px" }}
                                                            variant="gradient"
                                                            color="info"
                                                            disabled={true}
                                                          >
                                                            Move To Review
                                                          </MDButton>
                                                        </MDBox>
                                                      </Tooltip>:
                                                      <MDButton
                                                      variant="gradient"
                                                      color="info"
                                                      sx={{ marginLeft: "8px" }}
                                                      disabled={totalIssue !== 0}
                                                      onClick={() =>
                                                        onButtonItemClick("ToReview")
                                                      }
                                                    >
                                                      Move To Review
                                                    </MDButton>
                                                    )}
                                                    <MDBox
                                                      ml={{ xs: 0, sm: 1 }}
                                                      mt={{ xs: 1, sm: 0 }}
                                                    >
                                                      <MDButton
                                                        variant="gradient"
                                                        color="info"
                                                        disabled={ocrStatus}
                                                        onClick={() => SetEditable(true)}
                                                      >
                                                        Edit
                                                      </MDButton>
                                                    </MDBox>
                                                  </MDBox>
                                                )}
                                            </MDBox>
                                          ) : (
                                            <MDBox mt={{ xs: 2, sm: 3 }}>
                                              {!editable && canAccess( (actions = "update"), (resource = "documents")) && (
                                                  <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    disabled={ocrStatus}
                                                    onClick={() => setOpenQboSync(true)}
                                                  >
                                                    Edit
                                                  </MDButton>
                                                )}
                                            </MDBox>
                                          )}
                                          {editable && (
                                            <MDBox mt={4} width="100%" display="flex" justifyContent="end" >
                                              <MDButton
                                                sx={{ marginLeft: "8px" }}
                                                disabled={btnDisable}
                                                variant="gradient"
                                                color="light"
                                                onClick={handleClosebtn}
                                              >
                                                Cancel
                                              </MDButton>
                                              <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                <MDButton disabled={btnDisable} type="submit" variant="gradient" color="info" autoFocus >
                                                  {btnDisable ? "Updating..." : "Update"}
                                                </MDButton>
                                              </MDBox>
                                            </MDBox>
                                          )}
                                        </Grid>
                                      ) : ("")}
                                    </Form>
                                  );
                                }}
                              </Formik>
                            </Grid>
                          </Box>
                          <MDBox
                            display="flex"
                            sx={{ justifyContent: "end" }}
                            justify-content="end"
                            mt={4}
                          >
                            {selectTab === "Excluded" && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                disabled={ocrStatus}
                                onClick={() => onButtonItemClick("Classify")}
                              >
                                Move to Classify
                              </MDButton>
                            )}
                            {selectTab === "Approved" && (
                              <Tooltip title={"To move this document you must delete it from your QBO account."} placement="top" >
                                <MDBox>
                                  <MDButton variant="gradient" color="info" disabled={true}>
                                    Move to Classify
                                  </MDButton>
                                </MDBox>
                              </Tooltip>
                            )}
                          </MDBox>
                          {
                            <MDBox
                              display="flex"
                              sx={{ justifyContent: "space-between" }}
                              justify-content="space-between"
                              mt={4}
                            >
                              {selectTab === "ToReview" && (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? { color: "#202940", backgroundColor: "#202940" } :{ color: "#ffffff", backgroundColor: "#ffffff" }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? { color: "#202940", backgroundColor: "#202940" } :{ color: "#ffffff", backgroundColor: "#ffffff" }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("PendingApproval")}
                                >
                                  Move to Pending Approval
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Approved")}
                                >
                                  Move to Approved
                                </MDButton>
                              )}
                            </MDBox>
                          }
                          <MDBox>
                          <MDBox>
                            <Dialog
                              open={showPassDuplicate}
                              // onClose={()=>setShowPassDuplicate(false)}
                              aria-labelledby="alert-dialog-title"
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "40%",
                                    maxWidth: "600px",
                                    backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                                    color: darkMode ? '#ffffff':'#7b809a',
                                  },
                                },
                              }}
                            >
                              <DialogTitle sx={darkMode && { color: "#ffffff" }} align="center">
                                <h3>Duplicate Document Found</h3>
                              </DialogTitle>
                              <DialogContent sx={{paddingTop:'40px !important'}} >
                                <DialogContentText sx={{ display: "flex", justifyContent: "center"  }}>
                                </DialogContentText>
                                <DialogContentText sx={{color: darkMode ? '#ffffff' : '', display: "flex", justifyContent: "center" ,m:5 ,mt:-2}}>
                                    {passDuplicateMsg}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions sx={{ justifyContent: "center" ,mt:-3 ,mb:2}}>
                                <MDButton
                                  variant="gradient"
                                  color="success"
                                  onClick={callApiAfterPassDuplicate}
                                >
                                  Yes
                                </MDButton>
                                <MDButton
                                  variant="gradient"
                                  color="error"
                                  onClick={()=>setShowPassDuplicate(false)}
                                >
                                  No
                                </MDButton>
                              </DialogActions>
                            </Dialog>
                          </MDBox>
                      </MDBox>
                          <MDBox>
                            <MDSnackbar
                              color="success"
                              icon="done"
                              // autoHideDuration={3000}
                              title="Success"
                              content="Document Updated Successfully."
                              open={showSnackbar}
                              close={() => setShowSnackbar(false)}
                            />
                          </MDBox>
                        </MDBox>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <MDBox mt={-3} sx={{ pt: 3 }}>
                        {finalSelect == "Ask Client" ||
                        finalSelect == "Classify" ||
                        documentStatus == "AskClient" ||
                        documentStatus == "Classify" ? (
                          <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                            {showBulkEditButton && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ ml: 2, mr: 1 }}
                                disabled={ocrStatus}
                                onClick={() => setShowBulkEditDialog(true)}
                              >
                                Bulk Update
                              </MDButton>
                            )}
                            <Switch
                              checked={toggleShow}
                              disabled={disButtonOnWork || disAddButtonOnWork}
                              onChange={handleToggle}
                              value="toggleShow"
                            />
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              // onClick={handleToggle}
                              sx={darkMode ? { cursor: "pointer", userSelect: "none", color: "#ffffff" } : { cursor: "pointer", userSelect: "none" }}
                            >
                              &nbsp;&nbsp;Split by Products/Services
                            </MDTypography>
                            {(canAccess((actions = "update"), (resource = "documents")) &&
                              documentStatus === "Synced") ||
                              isSynced === true ? (
                                <MDButton
                                  disabled={ocrStatus || loadingCircul}
                                  variant="contained"
                                  color="info"
                                  sx={{ ml: 2 }}
                                  onClick={() => setOpenQboSync(true)}
                                >
                                  Add New Item
                                </MDButton>
                              ) : (
                                <MDButton
                                  disabled={ocrStatus || loadingCircul}
                                  variant="contained"
                                  color="info"
                                  sx={{ ml: 2 }}
                                  onClick={toggleShow ? handleAddItem : handleCategort}
                                  // onClick={handleAddItem}
                                >
                                  Add New Item
                                </MDButton>
                              )}
                          </MDBox>
                          ) : ("")}
                          <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ padding: '24px 0px 0px 24px' ,width:'106%' }}>
                            {!toggleShow ? (
                              dataTableData?.rows?.length === 0 ? (
                                <MDBox my={5} sx={{ mt: 1 }}>
                                  <ThemeProvider theme={myTheme}>
                                    <DataGrid
                                      rows={itemList}
                                      columns={
                                        finalSelect == "Ask Client" ||
                                        finalSelect == "Classify" ||
                                        documentStatus == "AskClient" ||
                                        documentStatus == "Classify"
                                          ? showClasses
                                            ? cetagorycolumns
                                            : cetagorycolumnsWithoutClass
                                          : showClasses
                                          ? cetagorycolumnsWithotAction
                                          : cetagorycolumnsWithoutClassAction
                                      }
                                      components={{
                                        NoRowsOverlay: () => (
                                          <Stack
                                            height="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            color= {darkMode ? "#ffffff" : ""}
                                          >
                                            No records found
                                          </Stack>
                                        ),
                                      }}
                                      sx={
                                        darkMode
                                          ? { 
                                              ".MuiDataGrid-withBorderColor" : {
                                                  borderTopColor:'#1a2035 !important'
                                              },
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                minHeight:'200px',
                                                overflow : 'hidden',
                                              },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              "& .MuiTablePagination-root": {
                                                display : 'none'
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "#ffffff",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop: "4px"
                                              },
                                              ".MuiTypography-root.MuiTypography-body1": {
                                                color: "#ffffff !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                            }
                                          : {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                minHeight:'200px',
                                                overflow : 'hidden'
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              "& .MuiTablePagination-root": {
                                                display : 'none'
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "rgb(123, 128, 154)",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop : '5px !important',
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                  outline:'transparent !importnat'
                                                }
                                            }
                                      }
                                      pageSizeOptions={[5, 10, 25, 50, 100]}
                                      paginationModel={paginationModel}
                                      onPaginationModelChange={setPaginationModel}
                                    />
                                  </ThemeProvider>
                                </MDBox>
                              ) : (
                                <ThemeProvider theme={myTheme}>
                                <Box
                                  sx={{
                                    "& .caterror": {
                                      borderBottom: darkMode ? `2.5px solid red !important` : `0.5px solid red !important`,
                                    },
                                  }}
                                >
                                  <DataGrid
                                    rows={filteredRows}
                                    columns={catColumns}
                                    getCellClassName={(params) => {
                                      return params?.row?.proderror === true && params?.field === "category" && disButtonOnWork === false
                                        ? "caterror"
                                        : "";
                                    }}
                                    disableRowSelectionOnClick
                                    sx={
                                      darkMode
                                        ? { 
                                            ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                            "& .MuiDataGrid-row": {
                                              borderTop: '0.5px solid #1a2035'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={setSelectedRows}
                                    pagination
                                    pageSize={paginationModel.pageSize}
                                    page={paginationModel.page}
                                    onPageChange={(page) =>
                                      setPaginationModel((prevModel) => ({ ...prevModel, page }))
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    cellModesModel={cellModesModel}
                                    onCellModesModelChange={handleCellModesModelChange}
                                    onCellClick={handleCellClick}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </Box>
                                </ThemeProvider>
                              )
                            ) : dataTableData1?.rows?.length === 0 ? (
                              <MDBox my={5} sx={{ mt: 1 }}>
                                <ThemeProvider theme={myTheme}>
                                  <DataGrid
                                    rows={itemList}
                                    columns={
                                      finalSelect == "Ask Client" ||
                                      finalSelect == "Classify" ||
                                      documentStatus == "AskClient" ||
                                      documentStatus == "Classify"
                                        ? showClasses
                                          ? columns
                                          : columnsWitoutClass
                                        : showClasses
                                        ? columnsWithoutAction
                                        : columnsWitoutClassAction
                                    }
                                    components={{
                                      NoRowsOverlay: () => (
                                        <Stack
                                          height="100%"
                                          alignItems="center"
                                          justifyContent="center"
                                          color= {darkMode ? "#ffffff" : ""}
                                        >
                                          No records found
                                        </Stack>
                                      ),
                                    }}
                                    sx={
                                      darkMode
                                        ? {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden'
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              display : 'none',
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              minHeight:'200px',
                                              overflow : 'hidden'
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                              display : 'none',
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop:'5px !important'
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </ThemeProvider>
                              </MDBox>
                            ) : (
                              <ThemeProvider theme={myTheme}>
                                <Box
                                  sx={{
                                    "& .proerror": {
                                      borderBottom: darkMode ? `2.5px solid red !important` : `0.5px solid red !important`,
                                    },
                                    "& .proerror.tooltiptext": {
                                      visibility: `hidden`,
                                      width: `120px`,
                                      backgroundColor: `black`,
                                      color: `#fff`,
                                      textAlign: `center`,
                                      borderRadius: `6px`,
                                      padding: `5px 0`,
                                      position: `absolute`,
                                      zIndex: 1,
                                      bottom: `100%`,
                                      left: `50%`,
                                      marginLeft: `-60px`,
                                    },
                                    "& .proerror:hover .tooltiptext": {
                                      visibility: `visible`,
                                    },
                                  }}
                                >
                                  <DataGrid
                                    rows={filteredRows}
                                    columns={productColumns}
                                    getCellClassName={(params) => {
                                      return params?.row?.proderror == true &&
                                        params?.field === "product" && disButtonOnWork === false ? "proerror" : "";
                                    }}
                                    disableRowSelectionOnClick
                                    sx={
                                      darkMode
                                        ? {
                                          "& .MuiDataGrid-row": {
                                                borderTop: '0.5px solid #1a2035'
                                            },
                                            ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            ".MuiDataGrid-overlay": {
                                              background:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "#ffffff",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                              marginTop: "4px"
                                            },
                                            ".MuiTypography-root.MuiTypography-body1": {
                                              color: "#ffffff !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                          }
                                        : {
                                            "@media (max-width: 1440px)": {
                                              fontWeight: `0  !important`,
                                              fontSize: `0.775rem`,
                                            },
                                            ".MuiDataGrid-columnSeparator": {
                                              display: "none",
                                            },
                                            ".MuiDataGrid-virtualScroller": {
                                              maxHeight:'500px',
                                              minHeight:'261px',
                                              overflow: filteredRows.length === 0 && "hidden",
                                          },
                                            '& .MuiDataGrid-columnHeaders': {
                                              maxHeight: '75px !important',
                                              minHeight:'75px !important',
                                              lineHeight:'8px !important',
                                              outline:'transparent !important'
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                              overflow: 'visible !important',
                                            },
                                            "& .MuiTablePagination-root": {
                                              color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              display: filteredRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                            },
                                            ".MuiDataGrid-columnHeaderDraggableContainer": {
                                              width: "112% !important",
                                            },
                                            border: 0,
                                            ".MuiDataGrid-columnHeaderTitle": {
                                              color: "rgb(123, 128, 154)",
                                              fontWeight: "bold !important",
                                              overflow: "visible !important",
                                            },
                                            ".MuiDataGrid-columnHeader:focus": {
                                              outline: "none !important",
                                            },
                                            ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                              {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                outline:'transparent !importnat'
                                              }
                                          }
                                    }
                                    selectionModel={selectedRows}
                                    onSelectionModelChange={setSelectedRows}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    cellModesModel={cellModesModel}
                                    onCellModesModelChange={handleCellModesModelChange}
                                    onCellClick={handleCellClick}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={setPaginationModel}
                                  />
                                </Box>
                              </ThemeProvider>
                            )}
                          </MDBox>
                          <MDBox
                            display="flex"
                            sx={{ justifyContent: "end" }}
                            justify-content="end"
                            mt={6}
                          >
                            {selectTab === "Excluded" && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                disabled={ocrStatus}
                                onClick={() => onButtonItemClick("Classify")}
                              >
                                Move to Classify
                              </MDButton>
                            )}
                            {selectTab === "AskClient" && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                disabled={ocrStatus}
                                onClick={() => onButtonItemClick("Classify")}
                              >
                                Move to Classify
                              </MDButton>
                            )}
                          </MDBox>
                          <MDBox display="flex" sx={{ justifyContent: "space-between" }} justify-content="space-between" mt={6} >
                              {selectTab === "ToReview" && (
                                //  && checkFound === true
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? {
                                    paddingLeft: "130px",
                                    color: "#202940",
                                    backgroundColor: "#202940",
                                  }:{
                                    paddingLeft: "130px",
                                    color: "#fffff",
                                    backgroundColor: "#ffffff",
                                  }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={darkMode ? {
                                    paddingLeft: "130px",
                                    color: "#202940",
                                    backgroundColor: "#202940",
                                  }:{
                                    paddingLeft: "130px",
                                    color: "#fffff",
                                    backgroundColor: "#ffffff",
                                  }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Classify")}
                                >
                                  Move to Classify
                                </MDButton>
                              )}
                              {selectTab === "Approved" && (
                                <Tooltip
                                  title={
                                    "To move this document you must delete it from your QBO account."
                                  }
                                  placement="top"
                                >
                                  <MDBox>
                                    <MDButton variant="gradient" color="info" disabled={true}>
                                      Move to Classify
                                    </MDButton>
                                  </MDBox>
                                </Tooltip>
                              )}
                              {selectTab === "Classify" && (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  style={{
                                    paddingLeft: "130px",
                                    color: "#fffff",
                                    backgroundColor: "transparent",
                                  }}
                                  disabled={true}
                                  onClick={() => onButtonItemClick("AskClient")}
                                ></MDButton>
                              )}
                              {selectTab === "Classify" && checkFound && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus || !checkFound}
                                  onClick={() => onButtonItemClick("AskClient")}
                                >
                                  Move to Ask Client
                                </MDButton>
                              )}
                              {selectTab === "Classify" && (
                              totalIssue !== 0 ?
                                <Tooltip
                                  title={`Cannot move to Review until ${totalIssue} issues are resolved`}
                                  placement="top"
                                >
                                  <MDBox>
                                    <MDButton
                                      variant="gradient"
                                      color="info"
                                      disabled={totalIssue !== 0}
                                      onClick={() => onButtonItemClick("ToReview")}
                                    >
                                      Move To Review
                                    </MDButton>
                                  </MDBox>
                                </Tooltip>:
                                 <Tooltip
                                 title={""}
                                 placement="top"
                               >
                                 <MDBox>
                                   <MDButton
                                     variant="gradient"
                                     color="info"
                                     onClick={() => onButtonItemClick("ToReview")}
                                   >
                                     Move To Review
                                   </MDButton>
                                 </MDBox>
                               </Tooltip>
                              )}
                              {selectTab === "ToReview" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("PendingApproval")}
                                >
                                  Move to Pending Approval
                                </MDButton>
                              )}
                              {selectTab === "PendingApproval" && (
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  disabled={ocrStatus}
                                  onClick={() => onButtonItemClick("Approved")}
                                >
                                  Move to Approved
                                </MDButton>
                              )}
                          </MDBox>
                          <MDBox>
                            <Dialog
                              open={alert}
                              onClose={hideAlert}
                              aria-labelledby="alert-dialog-title"
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "45%",
                                    maxWidth: "450px",
                                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                    color: darkMode ? "#ffffff" : "#000000",
                                  },
                                },
                              }}
                            >
                              <DialogContent sx={{ overflowY: "hidden" }}>
                                <DialogContentText
                                  sx={{ display: "flex", justifyContent: "center", mt: 4 , color: darkMode ? "#ffffff" : "#000000",
                                  }}
                                >
                                  <h3>Are you sure?</h3>
                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                  <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{
                                      fontWeight: 300,
                                      fontSize: "0.90rem",
                                      textAlign: "center",
                                      mt: 4,
                                      color: darkMode ? "#ffffff" : "#000000",
                                    }}
                                  >
                                    Once you delete this Item there is no way of getting any of the
                                    documents or data back.
                                  </MDTypography>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                                <MDButton variant="gradient" color="success" onClick={successDelete}>
                                  Yes, delete it!
                                </MDButton>
                                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                                  Cancel
                                </MDButton>
                              </DialogActions>
                            </Dialog>
                          </MDBox>
                          <MDBox>
                          {toggleShow ||
                            (onIssueClick1 && itemType === "ItemBasedExpenseLineDetail") ? (
                                  <DraggableEditItemDialog
                                    open={openEditItem}
                                    showClasses={showClasses}
                                    businessId={businessId}
                                    noProduct={noProduct}
                                    itemListResponse={itemListResponse ? itemListResponse : GetItemListResponse}
                                    currencySymbol={currencySymbol}
                                    invoiceId={invoice_id}
                                    editItemId={editItemId}
                                    itemList={itemList}
                                    documentsId={invoice_id}
                                    onClose={closeProductEdit}
                                    noPrice={noPrice}
                                    itemPriceMismatch={itemPriceMismatch}
                                    noItem={noItem}
                                    noQuantity={noQuantity}
                                    noUnitProduct={noUnitProduct}
                                  />
                            ) : (
                                  <DraggableEditCategoryItemDialog
                                    open={openEditItem}
                                    noCategory={noCategory}
                                    showClasses={showClasses}
                                    businessId={businessId}
                                    itemListResponse={itemListResponse}
                                    currencySymbol={currencySymbol}
                                    invoiceId={invoice_id}
                                    editItemId={editItemId}
                                    itemList={itemList}
                                    onClose={closeProductEdit}
                                  />
                            )}
                          </MDBox>
                        <MDBox>
                          <DraggableAddItemDialog 
                            open={openAddItem}
                                  showClasses={showClasses}
                                  itemList={itemList}
                                  businessId={businessId}
                                  currencySymbol={currencySymbol}
                                  invoiceId={invoiceId}
                                  toggleShow={toggleShow}
                            onClose={() => setOpenAddItem(false)}/>
                        </MDBox>
                        <MDBox>
                          <DraggableAddCategoryDialog 
                            openCategoryAddItem={openCategoryAddItem}
                            showClasses={showClasses}
                            itemList={itemList}
                            businessId={businessId}
                            currencySymbol={currencySymbol}
                            invoiceId={invoiceId}
                            toggleShow={toggleShow}
                            onClose={() => setOpenCategoryAddItem(false)}/>
                        </MDBox>
                      </MDBox>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ padding: '24px 0px 0px 24px' ,width:'105%' }}>
                      {!toggleShow ? (
                                summeryList?.length === 0 ? (
                                  <MDBox my={5} sx={{ mt: 1 }}>
                                    <ThemeProvider theme={myTheme}>
                                      <DataGrid
                                        rows={summeryList}
                                        columns={summarycetagorycolumns}
                                        components={{
                                          NoRowsOverlay: () => (
                                            <Stack
                                              height="100%"
                                              alignItems="center"
                                              justifyContent="center"
                                              color= {darkMode ? "#ffffff" : ""}
                                            >
                                              No records found
                                            </Stack>
                                          ),
                                        }}
                                        sx={
                                          darkMode
                                            ? {
                                              ".MuiDataGrid-withBorderColor" : {
                                                    borderTopColor:'#1a2035 !important'
                                                },
                                                "@media (max-width: 1440px)": {
                                                  fontWeight: `0  !important`,
                                                  fontSize: `0.775rem`,
                                                },
                                                ".MuiDataGrid-virtualScroller": {
                                                  maxHeight:'500px',
                                                  minHeight:'261px',
                                                  overflow : 'hidden',
                                              },
                                                ".MuiDataGrid-columnSeparator": {
                                                  display: "none",
                                                },
                                                '& .MuiDataGrid-columnHeaders': {
                                                  maxHeight: '75px !important',
                                                  minHeight:'75px !important',
                                                  lineHeight:'8px !important',
                                                  outline:'transparent !important'
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                  overflow: 'visible !important',
                                                  marginTop: "5px !important"
                                                },
                                                ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                  color: darkMode ? "#ffffff !important" : "#000000 !important",
                                                },
                                                ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                  width: "112% !important",
                                                  marginTop : '5px !important',
                                                },
                                                "& .MuiTablePagination-root": {
                                                  display : 'none'
                                                },
                                                border: 0,
                                                ".MuiDataGrid-columnHeaderTitle": {
                                                  color: "#ffffff",
                                                  fontWeight: "bold !important",
                                                  overflow: "visible !important",
                                                  marginTop: "5px !important"
                                                },
                                                ".MuiTypography-root.MuiTypography-body1": {
                                                  color: "#ffffff !important",
                                                },
                                                ".MuiDataGrid-columnHeader:focus": {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                  {
                                                    outline: "none !important",
                                                  },
                                              }
                                            : {
                                                "@media (max-width: 1440px)": {
                                                  fontWeight: `0  !important`,
                                                  fontSize: `0.775rem`,
                                                },
                                                ".MuiDataGrid-virtualScroller": {
                                                  maxHeight:'500px',
                                                  minHeight:'261px',
                                                  overflow : 'hidden',
                                              },
                                                ".MuiDataGrid-columnSeparator": {
                                                  display: "none",
                                                },
                                                '& .MuiDataGrid-columnHeaders': {
                                                  maxHeight: '75px !important',
                                                  minHeight:'75px !important',
                                                  lineHeight:'8px !important',
                                                  outline:'transparent !important'
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                  overflow: 'visible !important',
                                                  marginTop: "5px !important"
                                                },
                                                "& .MuiTablePagination-root": {
                                                  display : 'none'
                                                },
                                                ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                  width: "112% !important",
                                                },
                                                border: 0,
                                                ".MuiDataGrid-columnHeaderTitle": {
                                                  color: "rgb(123, 128, 154)",
                                                  fontWeight: "bold !important",
                                                  overflow: "visible !important",
                                                  marginTop: "5px !important"
                                                },
                                                ".MuiDataGrid-columnHeader:focus": {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                  {
                                                    outline: "none !important",
                                                  },
                                                  ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                    outline:'transparent !importnat'
                                                  }
                                              }
                                        }
                                        pageSizeOptions={[5, 10, 25, 50, 100]}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                      />
                                    </ThemeProvider>
                                  </MDBox>
                                ) : (
                                  <ThemeProvider theme={myTheme}>
                                  <Box
                                    sx={{
                                      "& .caterror": {
                                        borderBottom: darkMode ? `2.5px solid red !important` : `0.5px solid red !important`,
                                      },
                                    }}
                                  >
                                    <DataGrid
                                      rows={filteredCategoryRows} // Use filtered rows
                                      columns={summaryCategoryColumns}
                                      disableRowSelectionOnClick
                                      sx={
                                        darkMode
                                          ? {
                                            ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow: filteredCategoryRows.length === 0 && "hidden",
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                                marginTop: "5px !important"
                                              },
                                              ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                                marginTop: "5px !important"
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                                display: filteredCategoryRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "#ffffff",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop: "5px !important"
                                              },
                                              ".MuiTypography-root.MuiTypography-body1": {
                                                color: "#ffffff !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                            }
                                          : {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow: filteredCategoryRows.length === 0 && "hidden",
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                                marginTop: "5px !important"
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                                display: filteredCategoryRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "rgb(123, 128, 154)",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop: "5px !important"
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                              ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                  outline:'transparent !importnat'
                                                }
                                            }
                                      }
                                      selectionModel={selectedRows}
                                      onSelectionModelChange={setSelectedRows}
                                      pagination
                                      pageSize={paginationModel.pageSize}
                                      page={paginationModel.page}
                                      onPageChange={(page) =>
                                        setPaginationModel((prevModel) => ({ ...prevModel, page }))
                                      }
                                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                      pageSizeOptions={[5, 10, 25, 50, 100]}
                                      paginationModel={paginationModel}
                                      onPaginationModelChange={setPaginationModel}
                                    />
                                  </Box>
                                </ThemeProvider>
                                )
                      ) : summeryList?.length === 0 ? (
                                <MDBox my={5} sx={{ mt: 1 }}>
                                  <ThemeProvider theme={myTheme}>
                                    <DataGrid
                                      rows={summeryList}
                                      columns={summaryProductcolumns}
                                      components={{
                                        NoRowsOverlay: () => (
                                          <Stack
                                            height="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                            color= {darkMode ? "#ffffff" : ""}
                                          >
                                            No records found
                                          </Stack>
                                        ),
                                      }}
                                      sx={
                                        darkMode
                                          ? {
                                            ".MuiDataGrid-withBorderColor" : {
                                                  borderTopColor:'#1a2035 !important'
                                                },
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow : 'hidden'
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                                marginTop : '5px',
                                              },
                                              ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "#ffffff",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop: "4px"
                                              },
                                              ".MuiTypography-root.MuiTypography-body1": {
                                                color: "#ffffff !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                            }
                                          : {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow : 'hidden'
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              "& .MuiTablePagination-root": {
                                                display : 'none',
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "rgb(123, 128, 154)",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop : '5px !important',
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                  outline:'transparent !importnat'
                                                }
                                            }
                                      }
                                      pageSizeOptions={[5, 10, 25, 50, 100]}
                                      paginationModel={paginationModel}
                                      onPaginationModelChange={setPaginationModel}
                                    />
                                  </ThemeProvider>
                                </MDBox>
                      ) : (
                                <ThemeProvider theme={myTheme}>
                                  <Box
                                    sx={{
                                      "& .proerror": {
                                        borderBottom: `0.5px solid red !important`,
                                      },
                                      "& .proerror.tooltiptext": {
                                        visibility: `hidden`,
                                        width: `120px`,
                                        backgroundColor: `black`,
                                        color: `#fff`,
                                        textAlign: `center`,
                                        borderRadius: `6px`,
                                        padding: `5px 0`,
                                        position: `absolute`,
                                        zIndex: 1,
                                        bottom: `100%`,
                                        left: `50%`,
                                        marginLeft: `-60px`,
                                      },
                                      "& .proerror:hover .tooltiptext": {
                                        visibility: `visible`,
                                      },
                                    }}
                                  >
                                    <DataGrid
                                    rows={filteredProductRows} // Use filtered product rows
                                    columns={summaryProductColumns}
                                      getCellClassName={(params) => {
                                        return params?.row?.proderror == true &&
                                          params?.field === "product" && disButtonOnWork === false
                                          ? "proerror"
                                          : "";
                                      }}
                                      disableRowSelectionOnClick
                                      sx={
                                        darkMode
                                          ? {
                                            ".MuiDataGrid-overlay": {
                                              color:'#ffffff',
                                              background:'transparent !important'
                                            },
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow: filteredProductRows.length === 0 && "hidden",
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              ".MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                                display: filteredProductRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                marginTop: "4px",
                                                color: "#ffffff",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop : '5px !important',
                                              },
                                              ".MuiTypography-root.MuiTypography-body1": {
                                                color: "#ffffff !important",
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                            }
                                          : {
                                              "@media (max-width: 1440px)": {
                                                fontWeight: `0  !important`,
                                                fontSize: `0.775rem`,
                                              },
                                              ".MuiDataGrid-virtualScroller": {
                                                maxHeight:'500px',
                                                minHeight:'261px',
                                                overflow: filteredProductRows.length === 0 && "hidden",
                                            },
                                              ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                              },
                                              '& .MuiDataGrid-columnHeaders': {
                                                maxHeight: '75px !important',
                                                minHeight:'75px !important',
                                                lineHeight:'8px !important',
                                                outline:'transparent !important'
                                              },
                                              '& .MuiDataGrid-columnHeaderTitle': {
                                                overflow: 'visible !important',
                                              },
                                              "& .MuiTablePagination-root": {
                                                color: darkMode ? "#ffffff !important" : "#000000 !important",
                                                display: filteredProductRows.length === 0 ? "none" : "block", // Conditionally hide if no rows

                                              },
                                              ".MuiDataGrid-columnHeaderDraggableContainer": {
                                                width: "112% !important",
                                              },
                                              border: 0,
                                              ".MuiDataGrid-columnHeaderTitle": {
                                                color: "rgb(123, 128, 154)",
                                                fontWeight: "bold !important",
                                                overflow: "visible !important",
                                                marginTop : '5px !important',
                                              },
                                              ".MuiDataGrid-columnHeader:focus": {
                                                outline: "none !important",
                                              },
                                              ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor":
                                                {
                                                  outline: "none !important",
                                                },
                                                ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within" :{
                                                  outline:'transparent !importnat'
                                                }
                                            }
                                      }
                                      selectionModel={selectedRows}
                                      onSelectionModelChange={setSelectedRows}
                                      pageSizeOptions={[5, 10, 25, 50, 100]}
                                      cellModesModel={cellModesModel}
                                      onCellModesModelChange={handleCellModesModelChange}
                                      onCellClick={handleCellClick}
                                      paginationModel={paginationModel}
                                      onPaginationModelChange={setPaginationModel}
                                    />
                                  </Box>
                                </ThemeProvider>
                      )}
                    </MDBox>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <MDBox>
                            {canAccess((actions = "create"), (resource = "comments")) && (
                              <Formik
                                initialValues={{ name: "" }}
                                // validationSchema={InvoiceEditSchema2}
                                onSubmit={async (values, actions) => {
                                  setsubmitcomment(true);
                                  SetonChanges(false);
                                  var newStr = textAreaVal.replace(/@/g, "");
                                  const newstr2 = newStr.replace(/\(/g, "{{").replace(/\)/g, "}}");
                                  const newStr3 = newstr2.replace(/(?:\[.*?\]|[\[\]])/g, "");
                                  await CreateComentsListAPICall({
                                    payload: [
                                      {
                                        InvoiceId: `${invoice_id}`,
                                        businessId: `${businessId}`,
                                        comment: `${newStr3}`,
                                      },
                                    ],
                                  });
                                  setLoading(true);
                                  setBtnDisable1(true);
                                  setTimeout(() => {
                                    setsubmitcomment(false);
                                    setTextAreaVal("");
                                    actions.setSubmitting(false);
                                    actions.resetForm();
                                  }, 1000);
                                }}
                              >
                                {(props) => (
                                  <form onSubmit={props.handleSubmit}>
                                    <MentionsInput
                                      style={mentionsInputStyle}
                                      placeholder="Leave Your Comment Here"
                                      value={textAreaVal}
                                      onChange={(e) => setTextAreaVal(e.target.value)}
                                    >
                                      <Mention
                                        data={users}
                                        displayTransform={(id, display) => `@${display}`}
                                      />
                                    </MentionsInput>
                                    {textAreaVal.length === 0 && submitcomment === true && (
                                      <MDTypography
                                        sx={{ mt: 1 }}
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        Comment is required.
                                      </MDTypography>
                                    )}
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ mt: 2, display: "flex", justifyContent: "end" }}
                                    >
                                      <MDButton variant="gradient" color="info" type="submit">
                                        {btnDisable1 ? "Submiting..." : "Submit"}
                                      </MDButton>
                                    </Grid>
                                  </form>
                                )}
                              </Formik>
                            )}
                      </MDBox>
                      {canAccess((actions = "read"), (resource = "comments")) && (
                        <>
                          <MDBox style={{ color: darkMode ? "#ffffff" : "" }}>
                                Comment History
                          </MDBox>
                          <MDBox my={3}>
                                {commentsList.length == 0 ? (
                                  <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={
                                      darkMode
                                        ? {
                                            marginLeft: "43%",
                                            fontSize: "1.20rem",
                                            marginTop: "10%",
                                            color: "#ffffff",
                                            zIndex : 999,
                                          }
                                        : { marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }
                                    }
                                  >
                                    No Comments
                                  </MDTypography>
                                ) : (commentsList)}
                          </MDBox>
                        </>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                          <MDBox my={2} sx={{ mt: 2 }}>
                            {AuditLogResponse && auditLogList.length === 0 ? (
                              <MDTypography
                                variant="body2"
                                color="secondary"
                                sx={
                                  darkMode
                                    ? {
                                        color: "#ffffff",
                                        marginLeft: "43%",
                                        fontSize: "1.20rem",
                                        marginTop: "10%",
                                      }
                                    : { marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }
                                }
                              >
                                No Logs
                              </MDTypography>
                            ) : (
                              auditLogList
                            )}
                          </MDBox>
                    </TabPanel>
                    </CardContent>
                </Card>
                <MDBox>{loading && <LoaderDailog />}</MDBox>
                </Grid>
            </Grid>
              <MDBox>
                <MDBox>
                  <Dialog
                    open={openIssueReport}
                    onClose={() => setOpenIssueReport(false)}
                    aria-labelledby="alert-dialog-title"
                    sx={
                      darkMode
                        ? {
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                // height: "45%",
                                maxWidth: "750px",
                                backgroundColor: "#202940 !important",
                                color: "white !important",
                              },
                            },
                          }
                        : {
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                // height: "45%",
                                maxWidth: "750px",
                              },
                            },
                          }
                    }
                  >
                    <MDBox display="flex" justifyContent="space-between">
                      <DialogTitle
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          color: darkMode ? "#ffffff" : "#000000",
                        }}
                      ></DialogTitle>
                      <DialogTitle
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          color: darkMode ? "#ffffff" : "#000000",
                        }}
                      >
                        Leave Your Reports
                      </DialogTitle>
                      <DialogTitle
                        sx={{
                          justifyContent: "end",
                          display: "flex",
                          color:
                            reportStatus == "open"
                              ? "green"
                              : reportStatus == "reopen"
                              ? "#1a73e8"
                              : "red",
                        }}
                      >
                        {reportStatus.charAt(0).toUpperCase() + reportStatus.slice(1)}
                      </DialogTitle>
                    </MDBox>
                    <DialogContent sx={{ overflowY: "hidden" }}>
                      {reportSubmit && (
                        <CircularProgress
                          size={50}
                          sx={{
                            color: green[500],
                            marginTop: "10%",
                            marginLeft: "45%",
                            position: "absolute",
                            zIndex: 9999999,
                          }}
                        />
                      )}
                      <textarea
                        style={{
                          width: "100%",
                          height: "110px",
                          padding: "12px 20px",
                          boxSizing: "border-box",
                          border: "1px solid #ccc",
                          borderRadius: "2px",
                          // backgroundColor: "#f8f8f8",
                          fontSize: "16px",
                          resize: "none",
                          color: darkMode ? "#ffffff" : "#000000",
                          backgroundColor: darkMode ? "#202940" : "#ffffff",
                        }}
                        id="name"
                        required
                        label="Comment"
                        multiline
                        rows={4}
                        placeholder="Leave Your Reports Here..."
                        fullWidth
                        value={reportComment}
                        onChange={onChangeReport}
                        className={darkMode ? "dark-mode-textarea" : ""}
                        type="textarea"
                        name="name"
                      />
                      {showError && (
                        <MDTypography
                          sx={{ mt: 1 }}
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          {" "}
                          Issue is required.
                        </MDTypography>
                      )}
                      <MDBox display="flex" justifyContent="end" sx={{ mt: 2 }}>
                        <MDButton
                          variant="gradient"
                          color="light"
                          sx={{ mr: 1 }}
                          onClick={() => setOpenIssueReport(false)}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          disabled={reportSubmit}
                          onClick={onReportApi}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                      <>
                        {reportListResponse && reportList.length !== 0 ? (
                          <>
                            <MDBox style={{ color: darkMode ? "#ffffff" : "" }}>Report History</MDBox>
                            <MDBox my={3} sx={{ maxHeight: "300px", overflowY: "scroll" }}>
                              {reportList}
                            </MDBox>
                          </>
                        ) : (
                          <MDBox></MDBox>
                        )}
                      </>
                    </DialogContent>
                  </Dialog>
                </MDBox>
                <MDBox>
                  <Dialog
                    open={openAlreadyIssue}
                    onClose={() => setopenAlreadyIssue(false)}
                    aria-labelledby="alert-dialog-title"
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          height: "auto",
                          maxWidth: "450px",
                        },
                      },
                    }}
                  >
                    <DialogTitle
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    ></DialogTitle>
                    <DialogContent
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        mt: 5,
                      }}
                    >
                      The document is already reported.
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "end", mt: 2 }}>
                      <MDButton onClick={() => setopenAlreadyIssue(false)}>Close</MDButton>
                    </DialogActions>
                  </Dialog>
                </MDBox>
                <MDBox>
                  <Dialog
                    open={openEditVendor}
                    onClose={() => setOpenEditVendor(false)}
                    aria-labelledby="alert-dialog-title"
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "570px",
                          minHeight: "30%",
                        },
                      },
                    }}
                  >
                    {" "}
                    <DialogTitle
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Add Vendor
                    </DialogTitle>
                    <DialogContent>
                      <AddVendorDialog
                        vendorSelect={vendorSelect}
                        vendorSelect1={vendorSelect1}
                        invoiceId={invoiceId}
                        businessId={businessId}
                        onClose={handleCloseVendorbtn}
                      />
                    </DialogContent>
                  </Dialog>
                </MDBox>
              </MDBox>
              <MDBox>
              <Dialog
                open={openAddOwner}
                onClose={() => setOpenAddOwner(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                      color: darkMode ? '#ffffff':'#7b809a',
                      // height: "100%"
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    color: darkMode ? '#ffffff':'#7b809a',
                  }}
                >
                  Add Owner For - {ownerName}
                </DialogTitle>
                <DialogContent>
                  <AddOwnerDetailsDialog businessId={businessId} />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={alertDeleteInvoice}
                onClose={hideInvoiceAlert}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      // height: "45%",
                      maxWidth: "450px",
                      backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                      color: darkMode ? '#ffffff':'#7b809a',
                    },
                  },
                }}
              >
                <DialogContent sx={{ overflowY: "hidden" }}>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                    <h3>Are you sure?</h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                    >
                      Once you delete this Invoice there is no way of getting any of the documents
                      or data back?
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                  <MDButton variant="gradient" color="success" onClick={successInoiceDelete}>
                    Yes, delete it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={hideInvoiceAlert}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={oneItemPopup}
                onClose={() => setOneItemPopup(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      // height: "45%",
                      maxWidth: "450px",
                      backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                      color: darkMode ? '#ffffff':'#7b809a',
                    },
                  },
                }}
              >
                <DialogContent sx={{ overflowY: "hidden" }}>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ color: darkMode ? '#ffffff':'#7b809a',fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                    >
                      At least 1 item is required in the document.
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOneItemPopup(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={alertMoveInvoice}
                onClose={hideMoveAlert}
                aria-labelledby="alert-dialog-title"
                sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "500px",
                            backgroundColor: "#202940 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "500px",
                          },
                        },
                      }
                }
              >
                <DialogContent>
                  <DialogContentText
                    sx={{
                      mt: 3,
                      display: "flex",
                      justifyContent: "center",
                      color: darkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    <h3> Are you sure you want to move this invoice? </h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {splitCamelCaseToString(selectTab)}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      color="#037ffd"
                      sx={{
                        fontSize: 40,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                    </MDTypography>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {splitCamelCaseToString(tabsMove)}
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    disabled={disableMoveButton}
                    onClick={successInoiceMove}
                  >
                    Yes, Move it!
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="error"
                    disabled={disableMoveButton}
                    onClick={hideMoveAlert}
                  >
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Modal
                open={showApprovedModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <MDBox sx={{ pl: 0, pb: 5, mb: -1 }} display="flex" justifyContent="center">
                    <MDTypography component="span" variant="body2" color="dark">
                      To move this document you must delete it from your QBO account.
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={{
                      justifyContent: "end",
                      display: "flex",
                      mb: -2.5,
                    }}
                  >
                    <MDButton onClick={() => setShowApprovedModel(false)}>Close</MDButton>
                  </MDBox>
                </Box>
              </Modal>
            </MDBox>
            <MDBox>
              <Dialog
                open={openQboSyncAskClient}
                onClose={closeMoveDialog}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      // height: "40%",
                      maxWidth: "500px",
                    },
                  },
                }}
              >
                <DialogContent>
                  <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                    <h3>Are you sure want to proceed with sending them to </h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <h3>the ask client?</h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {splitCamelCaseToString(selectTab)}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      color="#037ffd"
                      sx={{
                        fontSize: 40,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                    </MDTypography>
                    <MDTypography
                      variant="body1"
                      color="info"
                      sx={{
                        p: 2,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {splitCamelCaseToString("To Review")}
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                  <MDButton variant="gradient" color="success" onClick={successInoiceMove}>
                    Yes, Move it!
                  </MDButton>
                  <MDButton variant="gradient" color="error" onClick={closeMoveDialog}>
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddNewCategory}
                onClose={() => setOpenAddNewCategory(false)}
                aria-labelledby="alert-dialog-title"
                sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "570px",
                            backgroundColor: "#202940 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "570px",
                          },
                        },
                      }
                }
              >
                {" "}
                <DialogTitle
                  sx={
                    darkMode
                      ? {
                          justifyContent: "center",
                          display: "flex",
                          color: "#ffffff",
                        }
                      : {
                          justifyContent: "center",
                          display: "flex",
                        }
                  }
                >
                  Add Category
                </DialogTitle>
                <DialogContent>
                  <AddNewCategoryDialog
                    Edit={false}
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenAddNewCategory(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <MDBox>
                <Dialog
                  open={openAddOption}
                  onClose={() => setOpenAddOption(false)}
                  aria-labelledby="alert-dialog-title"
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "570px",
                        backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                        color: darkMode ? "#ffffff" : "#7b809a",
                      },
                    },
                  }}
                >
                  {" "}
                  <DialogTitle
                    sx={{
                      color: darkMode ? "#ffffff" : "#7b809a",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    Product/Service information--
                  </DialogTitle>
                  <DialogContent>
                    <List sx={{ width: "100%", bgcolor: darkMode ? 'transparent' : "background.paper" }}>
                      <ListItem
                        alignItems="flex-start"
                        key={value}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            aria-label="comment"
                            onClick={() => handleOpenAddNewProduct("Service")}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Service")}>
                          <ListItemAvatar>
                            <Avatar alt="Service" src="./components/services.png" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Service"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color= {darkMode ? "#ffffff" : "text.primary"}
                                >
                                  Services that you provide to customers, for example, landscaping
                                  or tax preparation services.
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem
                        alignItems="flex-start"
                        key={value}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            aria-label="comment"
                            onClick={() => handleOpenAddNewProduct("Inventory")}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct("Inventory")}>
                          <ListItemAvatar>
                            <Avatar alt="Inventory" src="/static/images/avatar/2.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Inventory"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color= {darkMode ? "#ffffff" : "text.primary"}
                                >
                                  Products you buy and/or sell and that you track quantities of.
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem
                        alignItems="flex-start"
                        key={value}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            aria-label="comment"
                            onClick={() => handleOpenAddNewProduct("Non Inventory")}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton onClick={() => handleOpenAddNewProduct("Non Inventory")}>
                          <ListItemAvatar>
                            <Avatar alt="Non Inventory" src="/static/images/avatar/3.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary="Non Inventory"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color= {darkMode ? "#ffffff" : "text.primary"}
                                >
                                  Products you buy and/or sell but don’t need to (or can’t) track
                                  quantities of, for example, nuts and bolts used in an
                                  installation.
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </DialogContent>
                </Dialog>
              </MDBox>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddNewProduct}
                onClose={() => setOpenAddNewProduct(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#7b809a",
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    color: darkMode ? "#ffffff" : "#7b809a",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Add Product
                </DialogTitle>
                <DialogContent>
                  <AddNewProductDialog
                    addProductSelectType={addProductSelectType}
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenAddNewProduct(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddNewCustomer}
                onClose={() => setOpenAddNewCustomer(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#7b809a",
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    color: darkMode ? "#ffffff" : "#7b809a",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Add Customer
                </DialogTitle>
                <DialogContent>
                  <AddCustomerDialog
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenAddNewCustomer(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddNewClass}
                onClose={() => setOpenAddNewClass(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  Add Class
                </DialogTitle>
                <DialogContent>
                  <AddClassDialog
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenAddNewClass(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={showBulkEditDialog}
                onClose={() => setShowBulkEditDialog(false)}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                      backgroundColor: darkMode ? "#202940" : "#ffffff",
                    },
                  },
                }}
              >
                {" "}
                <DialogTitle
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  Bulk Update
                </DialogTitle>
                <DialogContent>
                  <BulkUpdateDialog
                    toggleShow={toggleShow}
                    businessId={businessId}
                    invoiceIds={invoiceId}
                    showClasses={showClasses}
                    selectedItemRow={selectedRows}
                    onClose={() => setShowBulkEditDialog(false)}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <CustomDialog
                DialogContentText={() => QboSyncMsg()}
                open={openQboSync}
                height={"27%"}
                onClose={() => setOpenQboSync(false)}
                Title={""}
                button={"Close"}
              />
            </MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={ErrorMessages}
                open={showErrorMoveSnackbar}
                close={() => setShowErrorMoveSnackbar(false)}
              />
            </MDBox>
             <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="Item Edit successfully"
                open={showSnackbarEdit}
                close={() => setShowSnackbarEdit(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="Item Add successfully"
                open={showSnackbarAdd}
                close={() => setShowSnackbarAdd(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="The item has been assigned to client for review."
                open={showTrueSnackbar}
                close={() => setShowTrueSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content={"The item has been unassigned to client for review"}
                open={showFalseSnackbar}
                close={() => setShowFalseSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content={"Issue Reported Successfully"}
                open={showreportSnackbar}
                close={() => setShowReportSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={ErrorMessagesVendorError}
                open={addVendoreerror}
                close={() => setAddVendoreerror(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed."
                content={ErrorMessages}
                // autoHideDuration={3000}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <CustomDialog2
                DialogContentText={() => AsignNewClientMsg()}
                open={assignNewClient}
                onClose={() => setAssignNewClient(false)}
                onSuccess={successAssignNewClient}
                Title={""}
                center={true}
                height={"27%"}
                buttonOk={"Assign client"}
                button={"Cancel"}
              />
            </MDBox>
          </MDBox>
        </>
      )}
    </>
  );
}

export default EditInvoiceDialog;