import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { useMaterialUIController } from "context";
import React from 'react';
import Draggable from 'react-draggable';
import AddItemDialog from '../additemdialog';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableAddItemDialog = ({ open ,showClasses, itemList, businessId, currencySymbol, invoiceId, toggleShow, onClose }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "570px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle style={{ cursor: 'move' ,justifyContent: "center",display: "flex", color: darkMode ? "#ffffff" : "#7b809a"}} id="draggable-dialog-title">
          Add New Item
        </DialogTitle>
        <DialogContent>
        <AddItemDialog
            showClasses={showClasses}
            toggleShow={toggleShow}
            itemList={itemList}
            businessId={businessId}
            currencySymbol={currencySymbol}
            invoiceId={invoiceId}
            onClose={() => onClose()}
        />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DraggableAddItemDialog;
