import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { setDarkMode, useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import UserHoc from "hoc/userHoc";
import LoaderProfile from "layouts/admin/accountants/components/imgLoader/LoaderProfile";
import FormField from "layouts/auth/components/FormField";
import ClientBaseLayout from "layouts/pages/profile/profile-overview/components/BaseLayout/clientBasicLayout";
import { CreatePassSchema } from "layouts/pages/profile/profile-overview/components/schemas/validations";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useAPICall } from "utils/useapicall";
import { canAccess } from "../../../../helpers";
import { EditClientUserProfile, GetClientUserProfile } from "../../../../services/clientservices";
import { AddNewSchemaclient } from "./components/schemas/addnewclientschema";

function ClientProfile() {
  const [btnDisable, setBtnDisable] = useState(false);
  const [passBtnDisable, setPassBtnDisable] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showPassErrorSnackbar, setShowPassErrorSnackbar] = useState(false);
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [profileRole, setprofileRole] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [editable, SetEditable] = useState(false);
  const [phone, setPhone] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [changePassword, SetChangePassword] = useState(false);
  const handleCerClickShowPassword = () => setShowCPassword(true);
  const handleCerClickClosePassword = () => setShowCPassword(false);
  const handleNewClickShowPassword = () => setShowNPassword(true);
  const handleNewClickClosePassword = () => setShowNPassword(false);
  const handleReClickShowPassword = () => setShowRePassword(true);
  const handleReClickClosePassword = () => setShowRePassword(false);

  const handleDarkMode = async () => {
    await setAPICall({
      payload: [
        {
          themeColor: !darkMode ? "dark" : "light",
        },
      ],
    });
    setDarkMode(dispatch, !darkMode);
    localStorage.setItem("PaynthrClientUiThemeMode", darkMode ? 'light' : 'dark');
  };

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    clientname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: profileRole,
  };

  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditClientUserProfile, {onLoad: false});
  const [{isLoading: isGetLoading, isError: isGetError, response: profileResponse }, getProfileAPICall] = useAPICall(GetClientUserProfile, { onLoad: false });
  var resource = "";
  var actions = "";

  useEffect(async () => {
    await getProfileAPICall({ payload: [] });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response.data.message == "WRONG_PASSWORD") {
        setShowPassErrorSnackbar(true);
        setTimeout(() => {
          setShowPassErrorSnackbar(false);
        }, 5000);
      } else {
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 5000);
      }
      setPassBtnDisable(false);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setBtnDisable(false);
      setPassBtnDisable(false);
      SetChangePassword(false);
      SetEditable(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  }, [response]);

  useEffect(() => {
    if (isGetLoading) {
      return () => null;
    }
    if (isGetError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      return () => null;
    }
    if (
      profileResponse &&
      profileResponse.data.isSuccess === true &&
      profileResponse.data.data &&
      profileResponse.data.data.user
    ) {
      setDarkMode(dispatch, profileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem(
        "PaynthrClientUiThemeMode",
        profileResponse?.data?.data?.user?.themeColor
      );
      setprofileEmail(profileResponse?.data?.data?.user?.email);
      setprofileFname(profileResponse?.data?.data?.user?.fname);
      setprofileLname(profileResponse?.data?.data?.user?.lname);
      setprofileBusinessName(profileResponse?.data?.data?.companyName);
      setprofileNumber(profileResponse?.data?.data?.user?.phone);
      setPhone(profileResponse?.data?.data?.user?.phone);
      setprofileRole(
        profileResponse?.data?.data?.user?.permissions?.role === null
          ? ""
          : profileResponse?.data?.data?.user?.permissions?.role?.title == "Domain Admin"
          ? "Admin"
          : profileResponse?.data?.data?.user?.permissions?.role?.template == "owner"
          ? "Owner"
          : "User"
      );
      setShowForm(true);
    }
  }, [profileResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await setAPICall({
      payload: [
        {
          fname: `${values.firstname}`,
          lname: `${values.lastname}`,
          phone: phone,
          companyName: `${values.clientname}`,
          themeColor: darkMode ? "dark" : "light",
        },
      ],
    });
  };

  const handleClose = async () => {
    window.location.reload();
  };

  const handlePassSubmit = async (values) => {
    setPassBtnDisable(true);

    await setAPICall({
      payload: [
        {
          password: `${values.repeatPassword}`,
          oldPassword: `${values.curpassword}`,
        },
      ],
    });
  };

  return (
    <>
      {showForm ? (
        <ClientBaseLayout>
          <MDBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={AddNewSchemaclient}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                              <Card
                                sx={{
                                  height: "100%",
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                }}
                              >
                                <MDBox p={3}>
                                  <MDBox>
                                    <MDBox
                                      lineHeight={0}
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <MDTypography variant="h5">Update Basic Info</MDTypography>
                                      <MDBox alignItems="center" lineHeight={1} display="flex">
                                        <MDTypography variant="h6">Light / Dark</MDTypography>
                                        <Switch
                                          // disabled={!editable}
                                          checked={darkMode}
                                          onChange={handleDarkMode}
                                        />
                                      </MDBox>
                                    </MDBox>
                                    <MDBox mt={2}>
                                      <MDBox lineHeight={0} mb={1} mt={1}>
                                        <MDTypography variant="h6" textTransform="capitalize">
                                          Profile Info
                                        </MDTypography>
                                      </MDBox>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="First Name *"
                                            disabled={!editable}
                                            name="firstname"
                                            variant="standard"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="Last Name *"
                                            name="lastname"
                                            disabled={!editable}
                                            variant="standard"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormField
                                          type="email"
                                          label="Email"
                                          disabled
                                          name="email"
                                          variant="standard"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <NumberFormat
                                          customInput={TextField}
                                          variant="standard"
                                          type="tel"
                                          fullWidth
                                          disabled={!editable}
                                          value={phone}
                                          label="Phone Number"
                                          InputLabelProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          InputProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          sx={
                                            darkMode
                                              ? {
                                                  "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "white",
                                                  },
                                                  mb: 1,
                                                  fontSize: `0.823rem !important`,
                                                }
                                              : {
                                                  "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                  },
                                                  mb: 1,
                                                  fontSize: `0.823rem !important`,
                                                }
                                          }
                                          format="(###) ###-####"
                                          mask=""
                                          onValueChange={(value) => setPhone(value.formattedValue)}
                                        />
                                      </Grid>
                                    </MDBox>
                                    <Grid item xs={12}>
                                      <FormField
                                        type="text"
                                        label="Role"
                                        name="role"
                                        disabled
                                        variant="standard"
                                      />
                                    </Grid>
                                    <Grid sx={{ display: "flex" }}>
                                      <Grid item xs={12}>
                                        <MDBox mt={{ xs: 2, sm: 3 }} justifyContent="left">
                                          <MDTypography
                                            variant="caption"
                                            sx={{ cursor: "pointer" }}
                                            color="info"
                                            onClick={() => SetChangePassword(true)}
                                          >
                                            Change Password
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid>
                                        <MDBox mt={{ xs: 2, sm: 3 }}>
                                          {!editable &&
                                            canAccess(
                                              (actions = "update"),
                                              (resource = "profiles")
                                            ) && (
                                              <MDButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                onClick={() => SetEditable(true)}
                                              >
                                                Edit
                                              </MDButton>
                                            )}
                                        </MDBox>
                                        {editable && (
                                          <MDBox
                                            mt={2}
                                            width="100%"
                                            display="flex"
                                            justifyContent="end"
                                          >
                                            <MDButton
                                              disabled={btnDisable}
                                              variant="gradient"
                                              color="light"
                                              onClick={handleClose}
                                            >
                                              Cancel
                                            </MDButton>
                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                              <MDButton
                                                disabled={btnDisable}
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                autoFocus
                                              >
                                                {btnDisable ? "Updating..." : "Update"}
                                              </MDButton>
                                            </MDBox>
                                          </MDBox>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* change Password Dialog */}
          <MDBox>
            <Dialog
              open={changePassword}
              onClose={() => SetChangePassword(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Change Password
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={CreatePassSchema}
                    onSubmit={handlePassSubmit}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form autoComplete="off">
                        <MDBox p={3}>
                          <MDBox>
                            <MDBox mt={1.625}>
                              <Grid item xs={12}>
                                <FormField
                                  type={showCPassword ? "text" : "password"}
                                  label="Current Password *"
                                  name="curpassword"
                                  variant="standard"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          sx={{
                                            color: darkMode ? "#ffffff" : "#000000",
                                            fontSize: "0.9rem",
                                          }}
                                        >
                                          {!showCPassword ? (
                                            <VisibilityOff style={darkMode ? { color: "#ffffff" } : {}} onClick={handleCerClickShowPassword} />
                                          ) : (
                                            <Visibility style={darkMode ? { color: "#ffffff" } : {}} onClick={handleCerClickClosePassword} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={showNPassword ? "text" : "password"}
                                  label="New Password *"
                                  name="password"
                                  variant="standard"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          sx={{
                                            color: darkMode ? "#ffffff" : "#000000",
                                            fontSize: "0.9rem",
                                          }}
                                        >
                                          {!showNPassword ? (
                                            <VisibilityOff style={darkMode ? { color: "#ffffff" } : {}} onClick={handleNewClickShowPassword} />
                                          ) : (
                                            <Visibility style={darkMode ? { color: "#ffffff" } : {}} onClick={handleNewClickClosePassword} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={showRePassword ? "text" : "password"}
                                  label="Confirm New Password *"
                                  name="repeatPassword"
                                  variant="standard"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          sx={{
                                            color: darkMode ? "#ffffff" : "#000000",
                                            fontSize: "0.9rem",
                                          }}
                                        >
                                          {!showRePassword ? (
                                            <VisibilityOff style={darkMode ? { color: "#ffffff" } : {}} onClick={handleReClickShowPassword} />
                                          ) : (
                                            <Visibility style={darkMode ? { color: "#ffffff" } : {}} onClick={handleReClickClosePassword} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </MDBox>
                            <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                              {canAccess((actions = "update"), (resource = "profiles")) && (
                                <MDBox mt={2} display="flex" justifyContent="end">
                                  <MDButton
                                    variant="gradient"
                                    color="light"
                                    onClick={() => SetChangePassword(false)}
                                  >
                                    Cancel
                                  </MDButton>
                                  <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                    <MDButton
                                      disabled={passBtnDisable}
                                      type="submit"
                                      variant="gradient"
                                      color="info"
                                    >
                                      {passBtnDisable ? "updating..." : "update password"}
                                    </MDButton>
                                  </MDBox>
                                </MDBox>
                              )}
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              content="Profile Updated Successfully!"
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed"
            content="Profile Updation Failed."
            open={showErrorSnackbar}
            close={() => setShowErrorSnackbar(false)}
          />
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed."
            content="Old Password You Entered Was Incorrect."
            open={showPassErrorSnackbar}
            close={() => setShowPassErrorSnackbar(false)}
          />
        </ClientBaseLayout>
      ) : (
        <MDBox
          sx={{ backgroundColor: darkMode ? "#1a2035 !important" : "#ffffff" }}
          mt={4}
          display="flex"
          justifyContent="center"
        >
          <LoaderProfile />
        </MDBox>
      )}
    </>
  );
}

export default UserHoc(ClientProfile);
