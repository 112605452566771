import * as Yup from "yup";

export const EditCustomerSchema = Yup.object().shape({
    customer: Yup.string()
        .trim()
        .required('Vendor name is required.')
        .min(3, 'Vendor  name should be more than 3 characters.'),
    email: Yup.string()
        .email('Your email address is invalid')
        .required('Email address is required.'),
});