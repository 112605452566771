import * as Yup from "yup";

export const EditVendorSchema = Yup.object().shape({
    vendor: Yup.string()
        .trim()
        .required('Vendor name is required.')
        .min(3, 'Vendor name should be more than 3 characters.'),
    phone: Yup.string()
        .nullable()
        .min(10, "Mobile number is invalid")
        .matches(
            /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            "Mobile number is not valid"
        ),
    address: Yup.string().trim().required("Address is required"),

});